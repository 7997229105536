import { authActions } from './auth';
import { licensesActions } from './licenses';
import { packsActions } from './packs'
import { productsActions } from './products';
import { reportsActions } from './reports';
import { usersActions } from './users';

import {digitalProductsActions} from "@/store/digitalProducts";

export default {
  ...authActions,
  ...productsActions,
  ...digitalProductsActions,
  ...licensesActions,
  ...usersActions,
  ...packsActions,
  ...reportsActions,
};
