export const COLORS = {
  GRAY: {
    hover: '#F9F9FF',
    bg: '#F2F2F8',
    icon: '#D1D8E6',
    text: '#8A94A8',
    dark: '#5C5C70',
  },
  BLACK: '#000',
  BLUE: {
    hover: '#706fdd',
  },
  GREEN: {
    hover: '#4caf50',
  },
  RED: {
    text: '#f03131',
  },
};
