import { AxiosError } from 'axios';

import { errorHandler } from './errorHandler';

export const axiosErrorHandler = (error: AxiosError<{ message: string, error?: string }>, fallbackMsg?: string) => {
  let message: string|undefined =  fallbackMsg ?? 'Неизвестная ошибка';

  if (error.response !== undefined) {
    message = error?.response?.data?.message || error?.response?.data?.error;
  }

  errorHandler(error, message);
};
