import { FC, useEffect } from 'react';
import { ActionIcon, Autocomplete, Loader } from '@mantine/core';
import { getHotkeyHandler, useDebouncedValue } from '@mantine/hooks';

import { useStyles } from './styles';

import { ReactComponent as Clear } from '@/assets/icons/Clear.svg';
import { ReactComponent as Search } from '@/assets/icons/Search.svg';

const NULL_CHARACTERS = 0;

const MIN_CHARACTERS = 1;

const MAX_CHARACTERS = 100;

export interface IPageHeaderSearch {
    placeholder?: string;
    loading?: boolean;
    data: string[];
    query?: string;
    onChange: (value: string) => void;
    onSubmit: (value: string) => void;
    inputLicenseSearch: string;
    setInputLicenseSearch: React.Dispatch<React.SetStateAction<string>>;
}

const LicenseSearch: FC<IPageHeaderSearch> = ({
    placeholder,
    loading,
    data,
    onChange,
    onSubmit,
    inputLicenseSearch,
    setInputLicenseSearch,
}) => {
    const { classes } = useStyles();

    const [debouncedInputValue] = useDebouncedValue(inputLicenseSearch, 800);

    const checkNullLength = (value = debouncedInputValue) => value.trim().length === NULL_CHARACTERS;
    const checkMinLength = (value = debouncedInputValue) => value.trim().length >= MIN_CHARACTERS;
    const checkMaxLength = (value = debouncedInputValue) => value.trim().length < MAX_CHARACTERS;
    const filterSpecialCharacters = (value = debouncedInputValue) =>
        (/\`|\~|\!|\#|\№|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\»»|\;|\:[а-яА-Я]/g.test(value) ? false : true);
    const searchParams = checkMinLength() && checkMaxLength() && filterSpecialCharacters()
    const onClear = () => {
        setInputLicenseSearch('');
        onSubmit('');
    };

    useEffect(() => {
        if (searchParams) {
            onChange(debouncedInputValue);
        }

        if (checkNullLength()) onClear();
    }, [debouncedInputValue]);

    return (
        <Autocomplete
            classNames={{
                root: classes.root,
                input: classes.input,
                item: classes.item,
            }}
            variant="filled"
            icon={<Search />}
            rightSection={
              loading ?
              <Loader size={24} /> :
              (inputLicenseSearch && !loading) && <ActionIcon variant="transparent" onClick={onClear}><Clear /></ActionIcon>
            }
            nothingFound={searchParams && !loading && 'Не найдено'}
            styles={{
                input: {
                    textOverflow: 'ellipsis',
                }
            }}
            placeholder={placeholder}
            data={(checkMinLength() && checkMaxLength() && filterSpecialCharacters()) ? data : []}
            value={inputLicenseSearch}
            onChange={setInputLicenseSearch}
            onKeyDown={getHotkeyHandler([
                ['Enter', () => filterSpecialCharacters() && checkMinLength(inputLicenseSearch) && checkMaxLength(inputLicenseSearch) && onSubmit(inputLicenseSearch)],
            ])}
            onItemSubmit={(item) => onSubmit(item.value)}
        />
    );
};

export default LicenseSearch;
