import {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {
    Box,
    Button,
    Container,
    Modal,
    Text,
    Title} from "@mantine/core";

import {AppPath} from "@/routes/routes-enum";

import { useStyles } from './styles';

import { useActions, useTypedSelector } from "@/store/hooks";

const MultiPacksWithMultiplierFormed: FC = () => {
    const { setShowMultiPacksSchoolFormed, setLicenseFromUser, getPacksExportSimple, setPacksExportSimpleLink } = useActions();
    const { showMultiPacksSchoolFormed, packsActivation, packsLicensesActivation, packsExportSimpleLink } = useTypedSelector(state => state.packs);
    const { classes } = useStyles();
    const navigate = useNavigate();

    const onClose = () => {
        setLicenseFromUser(false);
        setShowMultiPacksSchoolFormed(false);
    }

    const handleExport = () => {
        if (packsLicensesActivation.length) {
            getPacksExportSimple({
                ids: packsLicensesActivation.map(el => el.set_id)
            });
        } else if (packsActivation.length) {
            getPacksExportSimple({
                ids: packsActivation.map(el => el.set_id)
            });
        }
    }

    useEffect(() => {
        if (packsExportSimpleLink) {
            const href = URL.createObjectURL(packsExportSimpleLink);
            const link = document.createElement('a');
            const createDate = new Date().toLocaleString();
            link.href = href;
            link.setAttribute('download', `Список комплектов ${createDate}.xls`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setPacksExportSimpleLink(null);
        }
    }, [packsExportSimpleLink]);

    return (
        <Modal opened={showMultiPacksSchoolFormed} onClose={onClose} padding={20}>
            <Title className={classes.textTitleBlockModal} ta={'center'}>Комплекты сформированы</Title>
            { packsActivation.length > 0 && <Text className={classes.noteSuccessModal} h={'auto'} mx={40} ta={'center'}>
                Ваши комплекты успешно сформированы. Файл со списком комплектов продублирован на Ваш email
            </Text> }
            <Box>
                <Container className={classes.blocklButtonContainer} pb={12}>
                    <Button mt={20} onClick={handleExport}>Экспорт списка комплектов</Button>
                    <Button mt={8} onClick={() => { navigate(AppPath.packs); onClose(); }}>Перейти к списку</Button>
                </Container>
            </Box>
        </Modal>
    );
};

export default MultiPacksWithMultiplierFormed;
