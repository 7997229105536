import {ApiError} from "@/types";

import notify from './notify';

export const errorHandler = (error: any, message?: string) => {
  if (message) notify({ type: 'error', message: message.trim() });
};

export const rejectErrorHandler = (e: any, rejectWithValue: any) => {
  const error = e.response?.data as ApiError;
  return rejectWithValue(error);
}
