import {AppPath, AppPathAccess} from '@/routes/routes-enum';

import DigitalPacks from "@/pages/DigitalPacks/DigitalPacks";
import Licenses from '@/pages/Licenses/Licenses';
import AccessDenied from '@/pages/OAuth/AuthError/AccessDenied';
import NewUser from '@/pages/OAuth/NewUser';
import OAuth from '@/pages/OAuth/OAuth';
import PackActivationEnd from '@/pages/OAuth/PackActivationEnd';
import PackActivationInterim from '@/pages/OAuth/PackActivationInterim';
import Packs from '@/pages/Packs/Packs';
import PacksDetail from "@/pages/Packs/PacksDetail";
import Products from '@/pages/Products/Products';
import Reports from '@/pages/Reports/Reports';
import UserDetail from "@/pages/Users/UserDetail";
import Users from '@/pages/Users/Users';

export const routes = [
    {
        path: AppPath.oauth,
        component: <OAuth />,
        protected: false,
    },
    {
        path: AppPath.access,
        component: <AccessDenied />,
        protected: false,
    },
    {
        path: AppPath.activation,
        component: <PackActivationEnd />,
        protected: false,
        access: AppPathAccess.activation,
    },
    {
        path: AppPath.interim,
        component: <PackActivationInterim />,
        protected: false,
        access: AppPathAccess.interim,
    },
    {
        path: AppPath.new,
        component: <NewUser />,
        protected: false,
        access: AppPathAccess.new,
    },
    {
        path: AppPath.products,
        component: <Products />,
        protected: true,
        access: AppPathAccess.products,
    },
    {
        path: AppPath.licenses,
        component: <Licenses />,
        protected: true,
        access: AppPathAccess.licenses,
    },
    {
        path: AppPath.packs,
        component: <Packs />,
        protected: true,
        access: AppPathAccess.packs,
    },
    {
        path: AppPath.packsDetail,
        component: <PacksDetail />,
        protected: true,
        access: AppPathAccess.packs,
    },
    {
        path: AppPath.reports,
        component: <Reports />,
        protected: true,
        access: AppPathAccess.reports,
    },
    {
        path: AppPath.users,
        component: <Users />,
        protected: true,
        access: AppPathAccess.users,
    },
    {
        path: AppPath.usersDetail,
        component: <UserDetail />,
        protected: true,
        access: AppPathAccess.users,
    },
    {
        path: AppPath.digitalSet,
        component: <DigitalPacks />,
        protected: true,
        access: AppPathAccess.digitalSet,
    },
];
