import {FC, useEffect, useState} from 'react';
import {Button, Group, Select} from '@mantine/core';
import {DateRangePicker, DateRangePickerValue} from '@mantine/dates';

import {formatFormDate} from "@/utils/helpers";

import MultiSelect from '@/ui/MultiSelect/MultiSelect';

import {checkFieldAccess} from '../utils/checkFieldAccess';

import {ReactComponent as Reset} from '@/assets/icons/Reset.svg';
import {useActions, useTypedSelector} from '@/store/hooks';

const LicenseFilter: FC = () => {
  const { getLicensesAssets, setLicensesFilters } = useActions();

  const { user } = useTypedSelector((store) => store.auth);
  const { assetsLicense, filtersLicenses } = useTypedSelector((store) => store.licenses);
  const existExpireBegin = filtersLicenses.expired?.[0] !== undefined ? new Date(filtersLicenses.expired[0]) : null;
  const existExpireEnd = filtersLicenses.expired?.[1] !== undefined ? new Date(filtersLicenses.expired[1]) : null;
  const [filterDate, setFilterDate] = useState<DateRangePickerValue>([existExpireBegin, existExpireEnd]);

  const onChangeFilter = (field: string, value: string[] | string | null) => {
    setLicensesFilters({
      ...filtersLicenses,
      [field]: (Array.isArray(value) && value.length === 0) || value === null ? undefined : value,
    })
  };

  const onResetFilter = () => {
    setLicensesFilters({});
    setFilterDate([null, null]);

  };

  useEffect(() => {
    getLicensesAssets();
  }, []);

  useEffect(() => {
    if (filterDate) {
      let dateSelect: string[] = [];
      dateSelect.push(filterDate?.[0] ? `${formatFormDate(filterDate[0])} 00:00:00` : '');
      dateSelect.push(filterDate?.[1] ? `${formatFormDate(filterDate[1])} 23:59:59` : '');

      if (dateSelect[0] !== '' && dateSelect[1] !== '') {
        onChangeFilter('expired', dateSelect)
      }
    }

  }, [filterDate]);

  return (
    <Group noWrap={false}>
      { checkFieldAccess('filterType', user?.role.code) && (
        <MultiSelect placeholder="Тип" data={assetsLicense.type ? assetsLicense.type : []} value={filtersLicenses.type || []} onChange={(value) => onChangeFilter('type', value)} />
      )}

      { checkFieldAccess('filterClass', user?.role.code) && (
        <MultiSelect placeholder="Класс" data={assetsLicense.class ? assetsLicense.class : []} value={filtersLicenses.class || []} onChange={(value) => onChangeFilter('class', value)} />
      ) }

      { checkFieldAccess('filterService', user?.role.code) && (
        <MultiSelect placeholder="Сервисы" data={assetsLicense.service ? assetsLicense.service : []} value={filtersLicenses.service || []} onChange={(value) => onChangeFilter('service', value)} />
      ) }

      { checkFieldAccess('filterExpired', user?.role.code) && (
        <DateRangePicker styles={{input: { textOverflow: 'ellipsis'}}} clearable={true} initialLevel='date' placeholder={'Дата окончания'} locale='ru' inputFormat="DD.MM.YYYY" value={filterDate} onChange={setFilterDate} />
      ) }

      { checkFieldAccess('filterStatus', user?.role.code) && (
        <Select placeholder="Статус" data={assetsLicense.status ? assetsLicense.status : []} value={filtersLicenses.status || null} onChange={(value) => onChangeFilter('status', value)} />
      ) }

      {((filtersLicenses.service && filtersLicenses.service.length > 0)
        || (filtersLicenses.type && filtersLicenses.type.length > 0)
        || (filtersLicenses.class && filtersLicenses.class.length > 0)
        || filtersLicenses.status
        || filterDate[0]
      )
        &&
        (
          <Button variant="subtle" size="sm" p={0} rightIcon={<Reset />} onClick={onResetFilter}>
            Сбросить
          </Button>
        )}
    </Group>
  );
};

export default LicenseFilter;
