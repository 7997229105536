import {
  DeletePacksParams,
  GetPackActivateParams,
  GetPackActivateResponse,
  GetPackDeactivateParams,
  GetPackDeactivateResponse,
  GetPackDetailParams,
  GetPackDetailResponse,
  GetPackResendParams,
  GetPackResendResponse,
  GetPacksExportParams,
  GetPacksExportParamsSimple,
  GetPacksOrganizationsResponse,
  GetPacksParams,
  GetPacksRolesResponce,
  GetPacksSearchSuggestionsParams,
  PatchPackDeactivateParams,
  PatchPackDeactivateResponse,
  PatchPackEditParams,
  PatchPackExpireDate,
  PatchPackUpdateParams,
  PostCheckUserEmailParams,
  PostCheckUserEmailResponse,
  PostLicenseTemplateResponse,
  PostLiecenseTemplateParams,
  PostLinkPacksParams,
  PostLinkPacksResponse,
  PostPacksLicensesParams,
  PostPacksLicensesResponse,
  PostPacksParams,
  PostPacksResponse,
  PostTemplateParams,
  PostTemplateResponse, TransferSetsParams,
} from '@/types';

import axiosInstance from '../instance';
import {URLS} from '../urls';

export const packsApi = {

    getPacks(params: GetPacksParams) {
        return axiosInstance.request({
            method: 'get',
            url: URLS.packs.getPacks,
            params,
        });
    },

    getPacksAssets() {
        return axiosInstance.request({
            method: 'get',
            url: URLS.packs.getPacksAssets,
        });
    },

    getPacksSearchSuggestions(params: GetPacksSearchSuggestionsParams) {
        return axiosInstance.request({
            method: 'get',
            url: URLS.packs.getPacksSearchSuggestions,
            params,
        });
    },

    getPacksRoles() {
        return axiosInstance.request<GetPacksRolesResponce>({
            method: 'get',
            url: URLS.packs.getPacksRoles,
        });
    },

    getPacksOrganizations() {
        return axiosInstance.request<GetPacksOrganizationsResponse>({
            method: 'get',
            url: URLS.packs.getPacksOrganizations,
        });
    },

    postCheckUserEmail(params: PostCheckUserEmailParams) {
        return axiosInstance.request<PostCheckUserEmailResponse>({
            method: 'post',
            url: URLS.packs.postCheckUserEmail,
            data: {
                email: params.email,
                role: params.role
            }
        });
    },

    postTransferSets(params: TransferSetsParams) {
      return axiosInstance.request({
        method: 'post',
        url: `${URLS.packs.postTransferSetsParams}`,
        data: params,
      })
    },

    postPacksLicenses(params: PostPacksLicensesParams) {
        return axiosInstance.request<PostPacksLicensesResponse>({
            method: 'post',
            url: URLS.packs.postPacksLicenses,
            data: {
                name: params.name,
                organizationId: params.organizationId,
                comment: params.comment,
                role: params.role,
                period: params.period,
                startDate: params.startDate,
                finishDate: params.finishDate,
                emails: params.emails,
                licenses: params.licenses,
                payable: params.payable
            }
        });
    },

    postPacksSchoolLicenses(params: PostPacksLicensesParams) {
        return axiosInstance.request<PostPacksLicensesResponse>({
            method: 'post',
            url: URLS.packs.postPacksSchoolLicenses,
            data: {
                name: params.name,
                organizationId: params.organizationId,
                comment: params.comment,
                role: params.role,
                period: params.period,
                startDate: params.startDate,
                finishDate: params.finishDate,
                emails: params.emails,
                licenses: params.licenses
            }
        });
    },

    postPacks(params: PostPacksParams) {
        return axiosInstance.request<PostPacksResponse>({
            method: 'post',
            url: URLS.packs.postPacks,
            data: {
                name: params.name,
                organizationId: params.organizationId,
                comment: params.comment,
                role: params.role,
                period: params.period,
                startDate: params.startDate,
                finishDate: params.finishDate,
                emails: params.emails,
                licenses: params.licenses,
                payable: params.payable,
                multiplier: params.multiplier
            }
        });
    },

    postTemplate(params: PostTemplateParams) {
        return axiosInstance.request<PostTemplateResponse>({
            method: 'post',
            url: URLS.packs.postTemplate,
            // responseType: 'blob',
            data: {
                template: params.template
            },
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    },

    postLicensesTemplate(params: PostLiecenseTemplateParams) {
        return axiosInstance.request<PostLicenseTemplateResponse>({
            method: 'post',
            url: URLS.packs.postLicensesTemplate,
            data: {
                template: params.template
            },
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    },

    getPacksDetail(params: GetPackDetailParams) {
        return axiosInstance.request<GetPackDetailResponse>({
            method: 'get',
            url: `${URLS.packs.getPacksDetail}${params.id}`,
        });
    },

    patchPackUpdate(params: PatchPackUpdateParams) {
        return axiosInstance.request({
            method: 'patch',
            url: `${URLS.packs.patchPackUpdate}${params.id}`,
            data: {
                name: params.name,
                comment: params.comment,
                finishDateExtendPeriod: params.finishDateExtendPeriod,
                expiredDate: params.expiredDate,
            },
        })
    },

    patchPackExpireDate(params: PatchPackExpireDate) {
        return axiosInstance.request({
            method: 'patch',
            url: `${URLS.packs.patchExpiredDate}/${params.id}`,
            data: {
                expiredDate: params.expiredDate,
            },
        })
    },

    patchPackEdit(params: PatchPackEditParams) {
        return axiosInstance.request({
            method: 'patch',
            url: `${URLS.packs.patchPackEdit}${params.id}`,
            data: {
                id: params.id,
                expiredDate: params.expiredDate,
            },
        })
    },

    deletePacks(params: DeletePacksParams) {
        return axiosInstance.request({
            method: 'delete',
            url: `${URLS.packs.getPacks}`,
            data: {
                ...params
            },
        })
    },

    getPackActivate(params: GetPackActivateParams) {
        return axiosInstance.request<GetPackActivateResponse>({
            method: 'get',
            url: `/set/${params.id}/activate`,
        });
    },

    getPackDeactivate(params: GetPackDeactivateParams) {
        return axiosInstance.request<GetPackDeactivateResponse>({
            method: 'get',
            url: `/set/${params.id}/deactivate`,
        });
    },

    patchPackDeactivate(params: PatchPackDeactivateParams) {
        return axiosInstance.request<PatchPackDeactivateResponse>({
            method: 'patch',
            url: '/sets/deactivate',
            params,
        })
    },

    getPackResend(params: GetPackResendParams) {
        return axiosInstance.request<GetPackResendResponse>({
            method: 'get',
            url: `/set/${params.id}/resend`,
        });
    },

    postLinkPacks(params: PostLinkPacksParams) {
        return axiosInstance.request<PostLinkPacksResponse>({
            method: 'post',
            url: URLS.packs.postLinkPacks,
            data: {
                code: params.code,
                link: params.link,
            }
        });
    },

    getPacksExport(params?: GetPacksExportParams) {
        return axiosInstance.request({
            method: 'get',
            url: URLS.packs.getPacksExport,
            responseType: 'blob',
            params,
        });
    },

    getPacksExportSimple(params?: GetPacksExportParamsSimple) {
        return axiosInstance.request({
            method: 'post',
            url: URLS.packs.getPacksExportSimple,
            responseType: 'blob',
            data: {
                ...params
            },
        });
    },


};
