import {Dispatch, FC, Fragment, ReactNode, SetStateAction, useEffect, useState} from "react";
import { Box, Button, Checkbox, Container, Group, Text, TextInput } from "@mantine/core";
import { v4 as uuidv4 } from 'uuid';

import {validateEmail} from "@/utils/helpers";

import { COLORS } from "@/constants/mantine/colors";

import { EmailItem } from "./CreateLicensePacks";
import { useStyles } from "./styles";

import { ReactComponent as ErrorCircle } from '@/assets/icons/ErrorCircle.svg';
import { ReactComponent as SuccessCircle } from '@/assets/icons/SuccessCirle.svg';
import { ReactComponent as Trash } from '@/assets/icons/Trash.svg';
import { useActions, useTypedSelector } from "@/store/hooks";
import { Organizations } from "@/store/packs/types";

interface IEmailValidator {
    single?: boolean;
    multiUsers?: boolean;
    roleId?: string;
    chooseRoleAMD?: boolean;
    chooseRoleAMSU?: boolean;
    emailList: EmailItem[];
    setEmailList: Dispatch<SetStateAction<EmailItem[]>>;
    noEmail?: boolean;
    setNoEmail: (value: boolean) => void
}

const EmailValidator: FC<IEmailValidator> = ({
    single,
    roleId,
    chooseRoleAMD,
    chooseRoleAMSU,
    multiUsers,
    emailList,
    setEmailList,
    noEmail,
    setNoEmail
}) => {
    const { classes } = useStyles();
    const { postCheckUserEmail, resetPackCheckLoading, delError, setDisableCreateLicense } = useActions();
    const [emailId, setEmailId] = useState<string>('');
    const { packsCheckEmail, error: reduxError, isPacksCheckLoading, disableCreateLicense, licenseFromUser } = useTypedSelector((store) => store.packs);
    const { selectedUsers } = useTypedSelector(state => state.users);

    const onClickAddUser = () => {
        if (emailList.find(val => val.error) || emailList.find(val => val.duplicate) || isPacksCheckLoading) {
            return;
        }

        setEmailList((prevState) => [...prevState, { id: uuidv4(), text: '', error: false, duplicate: false, responseError: null, message: null, onBlur: false, organizations: [] }]);
        resetPackCheckLoading();
        delError(null);
    };

    const onClickDeleteEmail = (id: string) => {
        setEmailList((prevState) => prevState.filter(email => email.id !== id));
        setDisableCreateLicense(false)
        setEmailId(() => id);
    };

    const onChangeEmailText = (id: string, text: string) => {
        const error = validateEmail(text.toLowerCase());
        const duplicate = !!(emailList.find(val => val.text === text.toLowerCase()));
        let errorMessage: ReactNode = null;

        if (error) {
            errorMessage = <span style={{ color: COLORS.RED.text }}><ErrorCircle style={{ marginRight: 4 }} />Неверный формат</span>;
        } else if (duplicate) {
            errorMessage = <span style={{ color: COLORS.RED.text }}><ErrorCircle style={{ marginRight: 4 }} />Введён дубликат email.</span>;
        }

        if (error || duplicate) {
            setDisableCreateLicense(true);
        }

        if (!error && !duplicate) {
            setDisableCreateLicense(false);
        }

        setEmailList((prevState) => [...prevState.map(val => val.id === id ? { ...val, text, error, duplicate: duplicate, message: errorMessage, onBlur: false } : val)]);
        setEmailId(() => id);
    }

    const onBlurEmailInput = (id: string) => {
        const targetEmail = emailList.find(val => val.id == id);
        setEmailList((prevState) => [...prevState.map(val => val.id === id ? { ...val, onBlur: true } : { ...val })]);

        if (!targetEmail?.error && !targetEmail?.duplicate && targetEmail?.text !== '') {
            setDisableCreateLicense(false)
            postCheckUserEmail({
                email: targetEmail?.text as string,
                role: (roleId as string).toString(),
            });
        }
    }

    const clearState = () => {
        setEmailList(() => [{ id: uuidv4(), text: '', error: false, duplicate: false, responseError: null, message: null, onBlur: false, organizations: [] }]);
    };

    useEffect(() => {
        if (isPacksCheckLoading) return;

        const email = emailList.find(val => val.id == emailId);
        let message: ReactNode;
        let organizations: Organizations[] = [];

        if (email?.text.length && email.error) {
            message = (<span style={{ color: COLORS.RED.text }}><ErrorCircle style={{ marginRight: 4 }} /> Неверный формат</span>)
            setDisableCreateLicense(true)
        } else if (email?.text.length && email.duplicate) {
            message = (<span style={{ color: COLORS.RED.text }}><ErrorCircle style={{ marginRight: 4 }} /> Введён дубликат email.</span>)
            setDisableCreateLicense(true)
        } else if (email?.text.length && packsCheckEmail.success && !reduxError) {
            organizations = packsCheckEmail.organizations;
            message = (<span style={{ color: COLORS.BLUE.hover }}><SuccessCircle style={{ marginRight: 4 }} /> Пользователь найден в системе</span>)
            setDisableCreateLicense(false)
        } else if (roleId === 'ROLE_DISTRIBUTOR' && email?.text.length && !packsCheckEmail.success && !reduxError) {
            message = (<span style={{ color: COLORS.RED.text }}><ErrorCircle style={{ marginRight: 4 }} />{ packsCheckEmail.message }</span>)
            setDisableCreateLicense(true)
        } else if (roleId === 'ROLE_SCHOOL_ADMIN' && email?.text.length && !packsCheckEmail.success && !reduxError) {
            message = (<span style={{ color: COLORS.RED.text }}><ErrorCircle style={{ marginRight: 4 }} /> Пользователь не является администратором школы</span>)
            setDisableCreateLicense(true)
        } else if (roleId === 'ROLE_USER' && email?.text.length && !packsCheckEmail.success && !reduxError) {
            message = (<span style={{ color: COLORS.RED.text }}><ErrorCircle style={{ marginRight: 4 }} /> Роль пользователя не совпадает</span>)
            setDisableCreateLicense(true)
        } else if (email?.text.length && !packsCheckEmail.success && reduxError?.response?.status === 401) {
            message = (<span style={{ color: COLORS.RED.text }}><ErrorCircle style={{ marginRight: 4 }} /> Вы не авторизованы</span>)
            setDisableCreateLicense(true)
        } else if (email?.text.length && !packsCheckEmail.success && reduxError?.response?.status === 404) {
            message = (<span style={{ color: COLORS.RED.text }}><ErrorCircle style={{ marginRight: 4 }} /> Пользователь не найден в системе</span>)
            setDisableCreateLicense(false)
        }

        setEmailList((prevState) => [...prevState.map(val => val.id === emailId ? { ...val, message, responseError: reduxError, organizations } : val)]);
    }, [isPacksCheckLoading]);


  useEffect(() => {
    if (multiUsers) {
      setNoEmail(false)
    }
  }, [multiUsers]);

    return (
      <Container mt={48} p={0}>
        <Group className={classes.emailGroup}>
          { emailList.map((email, index) =>
            <div key={email.id} className={classes.emailDiv}>
              <Box className={classes.emailBox}>
                <TextInput
                  id={`${email.id}email`}
                  disabled={noEmail}
                  label={index === 0 ? single ? 'Email пользователя для назначения лицензии' : 'Email пользователя для назначения комплекта' : null}
                  mt={0}
                  required={!noEmail}
                  w={398}
                  withAsterisk
                  readOnly={!!selectedUsers.length && licenseFromUser}
                  placeholder='Введите email'
                  value={email.text}
                  onChange={(event) => {
                    onChangeEmailText(email.id, event.currentTarget.value);
                  }}
                  onBlur={() => onBlurEmailInput(email.id)}
                />
                <Text m={0} size={12}>{email.message}</Text>
              </Box>
              { <Box className={classes.emailInput}>
                  { !noEmail &&
                    <>
                      <Box className={classes.emailInputOrg}>
                        { !email.organizations.length &&
                          <>
                            { chooseRoleAMD && <TextInput disabled={true} label={index === 0 ? <span style={{color: 'transparent'}}>&nbsp;</span> : null} w={index === 0 ? 398 : 340} mt={0} placeholder={''} />}
                            { index === 0 ? null : <Button disabled={!!selectedUsers.length && licenseFromUser} variant='outline' w={52} pl={28} pr={18} ml={7} mt={0} color={COLORS.GRAY.text} leftIcon={<Trash size={22}/>} onClick={() => onClickDeleteEmail(email.id)}></Button> }
                          </>
                        }
                      </Box>
                      <Box className={classes.emailInputOrg}>
                        { email.organizations.map((val, oIndex) =>
                          <Fragment key={val.id}>
                            { chooseRoleAMD &&
                              <TextInput
                                disabled={true}
                                label={oIndex === 0 && index === 0 ? <span style={{color: 'transparent'}}>label</span> : null}
                                w={index === 0 ? 398 : 340}
                                mt={oIndex !== 0 ? 32 : 0}
                                placeholder={val.name}
                              /> }
                            { (index && !oIndex) && <Button disabled={!!selectedUsers.length && licenseFromUser} variant='outline' w={52} pl={28} pr={18} ml={7} mt={oIndex !== 0 ? 32 : 0} color={COLORS.GRAY.text} leftIcon={<Trash size={22}/>} onClick={() => onClickDeleteEmail(email.id)}></Button> || "" }
                          </Fragment>
                        )}
                      </Box>
                    </>
                  }
                </Box>
              }
            </div>
          )}
        </Group>
        { (chooseRoleAMSU && !multiUsers) && <Checkbox mt={16} w={"100%"} label='Не указывать email' checked={noEmail} onChange={(event) => { setNoEmail(event.currentTarget.checked); clearState(); }} /> }
        { multiUsers &&
          <Button
            loading={isPacksCheckLoading}
            loaderPosition="center"
            w={398}
            mt={32}
            style={{display: 'block'}}
            className={classes.modalButton}
            type="submit"
            disabled={
              (!!emailList.find(val => !val.text))
              || !!(emailList.filter((v) => v.error).length)
              || !!(emailList.filter((v) => Number(v.responseError?.status) === 401).length)
              || !!(emailList.filter((v) => v.duplicate).length)
              || disableCreateLicense
              || !!selectedUsers.length && licenseFromUser
            }
            onClick={onClickAddUser}
          >Добавить ещё пользователя</Button>
        }
      </Container>
    );
};

export default EmailValidator;
