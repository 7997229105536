import React, {FC} from 'react';
import {Center} from "@mantine/core";

import AuthorizationAccessError from "@/assets/images/AuthorizationAccessError.svg";
import AuthError from "@/pages/OAuth/AuthError/AuthError";

const Error404: FC = () => {
    return (
        <Center sx={{ height: '100%', flexDirection: 'column' }}>
            <AuthError image={AuthorizationAccessError} title="Ошибка 404" text="Такой страницы не существует" />
        </Center>
    );
};

export default Error404;
