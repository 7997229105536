import { FC } from 'react';
import {Link, useMatch, useResolvedPath} from 'react-router-dom';
import { UnstyledButton, UnstyledButtonProps } from '@mantine/core';

import { useStyles } from './styles';

interface ILayoutHeaderNavButton extends UnstyledButtonProps {
  href: string;
  text: string;
}

const LayoutHeaderNavButton: FC<ILayoutHeaderNavButton> = ({ href, text }) => {
  const { classes, cx } = useStyles();
  let resolved = useResolvedPath(href);
  let match = useMatch(`${resolved.pathname}/*`);

  return (
    <UnstyledButton
      p={'18px 0'}
      className={cx(classes.root, { [classes.active]: match })}
      component={Link}
      title={text}
      to={href}
    >
      { text }
    </UnstyledButton>
  );
};

export default LayoutHeaderNavButton;
