import {
  ApiError, DeletePacksParams,
  GetPackActivateParams,
  GetPackDeactivateParams,
  GetPackDetailParams,
  GetPackResendParams,
  GetPacksExportParams,
  GetPacksExportParamsSimple,
  GetPacksParams,
  GetPacksSearchSuggestionsParams,
  PatchPackDeactivateParams,
  PatchPackDeactivateResponse,
  PatchPackEditParams,
  PatchPackExpireDate,
  PatchPackUpdateParams,
  PostCheckUserEmailParams,
  PostCheckUserEmailResponse,
  PostLicenseTemplateResponse,
  PostLiecenseTemplateParams,
  PostLinkPacksParams,
  PostLinkPacksResponse,
  PostPacksLicensesParams,
  PostPacksLicensesResponse,
  PostPacksParams,
  PostPacksResponse,
  PostTemplateParams,
  PostTemplateResponse, TransferSetsParams
} from '@/types';

import {createAsyncThunk} from '@reduxjs/toolkit';

import {usersApi} from "@/api";
import {packsApi} from '@/api/packs/packs';
import {tryAction} from '@/api/tryAction';

import {rejectErrorHandler} from "@/utils/errorHandler";

export const getPacks = createAsyncThunk<
    any,
    GetPacksParams
>('packs/getPacks', async (arg, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.getPacks(arg);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getPacksSearchSuggestionsParams = createAsyncThunk<
    any,
    GetPacksSearchSuggestionsParams
>('packs/getPacksSearchSuggestions', async (arg, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.getPacksSearchSuggestions(arg);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getPacksAssets = createAsyncThunk(
    'packs/getPacksAssets', async (arg, {rejectWithValue}) => {
        try {
            const {data} = await packsApi.getPacksAssets();

            return data || rejectWithValue(null);
        } catch (e) {
            return rejectErrorHandler(e, rejectWithValue)
        }
    });

export const getPacksRoles = createAsyncThunk('set/getPacksRoles', async (arg, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.getPacksRoles();

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getPacksOrganizations = createAsyncThunk('set/getPacksOrganizations', async (arg, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.getPacksOrganizations();

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const postCheckUserEmail = createAsyncThunk<
    PostCheckUserEmailResponse,
    PostCheckUserEmailParams,
    {
        rejectValue: ApiError
    }
>('set/postCheckUserEmail', async (params: PostCheckUserEmailParams, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.postCheckUserEmail(params);
        return data;
    } catch (e) {
        const error = e as ApiError;
        return rejectWithValue(error);
    }
});

export const postPacksLicenses = createAsyncThunk<
    PostPacksLicensesResponse,
    PostPacksLicensesParams,
    {
        rejectValue: ApiError
    }
>('set/postPacksLicenses', async (params: PostPacksLicensesParams, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.postPacksLicenses(params);
        return data;
    } catch (e) {
      const error = e as ApiError;
      return rejectWithValue(error);
    }
});

export const postPacksSchoolLicenses = createAsyncThunk<
    PostPacksLicensesResponse,
    PostPacksLicensesParams,
    {
        rejectValue: ApiError
    }
>('set/postPacksSchoolLicenses', async (params: PostPacksLicensesParams, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.postPacksSchoolLicenses(params);
        return data;
    } catch (e) {
      const error = e as ApiError;
      return rejectWithValue(error);
    }
});

export const postPacks = createAsyncThunk<
    PostPacksResponse,
    PostPacksParams,
    {
        rejectValue: ApiError
    }
>('set/postPacks', async (params: PostPacksParams, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.postPacks(params);
        return data;
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const postTransferSets = createAsyncThunk<
  any,
  TransferSetsParams
>('set/transferSets', async (params: TransferSetsParams, {rejectWithValue}) => {
  try {
    const {data} = await packsApi.postTransferSets(params);

    return data || rejectWithValue(null);
  } catch (e) {
    return rejectErrorHandler(e, rejectWithValue)
  }
});

export const postTemplate = createAsyncThunk<
    PostTemplateResponse,
    PostTemplateParams,
    {
        rejectValue: ApiError
    }
>('set/postTemplate', async (params: PostTemplateParams, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.postTemplate(params);
        return data;
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const postLicensesTemplate = createAsyncThunk<
    PostLicenseTemplateResponse,
    PostLiecenseTemplateParams,
    {
        rejectValue: ApiError
    }
>('set/postLicensesTemplate', async (params: PostLiecenseTemplateParams, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.postLicensesTemplate(params);
        return data;
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getPacksDetail = createAsyncThunk(
    'set/getPacksDetail',
    async (params: GetPackDetailParams, {rejectWithValue}
    ) => {
        try {
            const {data} = await packsApi.getPacksDetail(params);

            return data || rejectWithValue(null);
        } catch (e) {
            return rejectErrorHandler(e, rejectWithValue)
        }
    });

export const patchPackEdit = createAsyncThunk<
    null,
    PatchPackEditParams,
    {
        rejectValue: ApiError
    }
>('set/patchPackEdit', async (params, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.patchPackEdit(params);
        return data;
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const patchPackUpdate = createAsyncThunk<
    null,
    PatchPackUpdateParams,
    {
        rejectValue: ApiError
    }
>('set/patchPackUpdate', async (params, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.patchPackUpdate(params);
        return data;
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const patchPackExpireDate = createAsyncThunk<
    null,
    PatchPackExpireDate,
    {
        rejectValue: ApiError
    }
>('set/patchPackExpireDate', async (params, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.patchPackExpireDate(params);
        return data;
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const deletePacks = createAsyncThunk<
    null,
    DeletePacksParams,
    {
        rejectValue: ApiError
    }
>('set/deletePacks', async (params, {rejectWithValue}) => {
    try {
        const { data } = await packsApi.deletePacks(params);
        return data;
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getPackActivate = createAsyncThunk<
    { success: boolean, result: string[] },
    GetPackActivateParams,
    {
        rejectValue: ApiError | null
    }
>(
    'set/getPackActivate',
    async (params, {rejectWithValue}) => {
        try {
            const {data} = await packsApi.getPackActivate(params);

            return data || rejectWithValue(null);
        } catch (e: any) {
            return rejectErrorHandler(e, rejectWithValue)
        }
    });
export const getPackDeactivate = createAsyncThunk('set/getPackDeactivate', async (params: GetPackDeactivateParams, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.getPackDeactivate(params);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const patchPackDeactivate = createAsyncThunk<
    PatchPackDeactivateResponse,
    PatchPackDeactivateParams,
    {
        rejectValue: ApiError
    }
>('sets/patchPackDeactivate', async (params, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.patchPackDeactivate(params);
        return data;
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getPackResend = createAsyncThunk<
    PatchPackDeactivateResponse,
    GetPackResendParams,
    {
        rejectValue: ApiError | null
    }>('set/getPackResend', async (params: GetPackResendParams, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.getPackResend(params);

        if (data) {
            return data;
        } else {
            return rejectWithValue(null);
        }
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const postLinkPacks = createAsyncThunk<
    PostLinkPacksResponse,
    PostLinkPacksParams,
    {
        rejectValue: ApiError
    }
>('set/postLinkPacks', async (params: PostLinkPacksParams, {rejectWithValue}) => {
    try {
        const {data} = await packsApi.postLinkPacks(params);
        return data;
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getPacksExport = createAsyncThunk(
    'packs/getPacksExport',
    async (params: GetPacksExportParams, {rejectWithValue}) => {
        const data = await tryAction(packsApi.getPacksExport(params));

        return data || rejectWithValue(null);
    }
);

export const getPacksExportSimple = createAsyncThunk(
    'packs/getPacksExportSimple',
    async (params: GetPacksExportParamsSimple, {rejectWithValue}) => {
        const data = await tryAction(packsApi.getPacksExportSimple(params));

        return data || rejectWithValue(null);
    }
);

export const getUserPacks = createAsyncThunk<
    any,
    GetPacksParams
>('packs/getUserPacks', async (arg, {rejectWithValue}) => {
    try {
        const {data} = await usersApi.getUserPacks(arg);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});
