import { createStyles } from '@mantine/core';

import { COLORS } from '@/constants/mantine/colors';
export const useStyles = createStyles((theme) => ({
  root: {

    position: 'relative',
    fontSize: theme.fontSizes.sm,
    lineHeight: '19px',
    color: COLORS.GRAY.dark,
    padding: 0,
    paddingBottom: 24,
    margin: 0,


    '&:before': {
      position: 'absolute',
      top: -1,
      left: 0,
      right: 0,
      height: 1,
      background: COLORS.GRAY.icon,
    },
  },
  active: {
    color: theme.black,

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 4,
      background: theme.colors.blue[5],
      borderRadius: '2px 2px 0 0',
    },
  },
  exportButton: {
    flexShrink: 0,
    minWidth: 173,
    paddingLeft: 22,
    paddingRight: 22,
  },

  exportButtonIcon: {
    marginRight: 8,
  },
}));
