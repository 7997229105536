import { createStyles } from '@mantine/core';

import { COLORS } from '@/constants/mantine/colors';

export const useStyles = createStyles((theme) => ({
  titleGroup: {
    flexShrink: 0,
    minWidth: 459,
  },

  backButton: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: 42,
    background: COLORS.GRAY.bg,
    marginTop: -2,
    marginBottom: -2,

    '&:hover': {
      color: theme.colors.blue[5],
    },
    '&:active': {
      color: theme.colors.blue[6],
    },
  },

  subTitle: {
    color: COLORS.GRAY.text,
    paddingTop: '.3em'
  },

  exportButton: {
    flexShrink: 0,
    minWidth: 173,
    paddingLeft: 22,
    paddingRight: 22,
  },

  filterButton: {
    flexShrink: 0,
    paddingLeft: 13.2,
    paddingRight: 13.2,
  },

  exportButtonIcon: {
    marginRight: 8,
  },

  filterGroup: {
    '.mantine-InputWrapper-root:not(.mantine-DateRangePicker-root)': {
      width: 174,
    },
  },
}));
