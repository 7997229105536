import React, {FC} from 'react';
import {Box, Image, Text, Title} from "@mantine/core";

import LicenseNotFound from "@/assets/images/LicenseNotFound.svg";
import TableNotFound from "@/assets/images/ReportNotAssemble.svg";
import {useStyles} from "@/pages/Reports/styles";

const ReportsNotFound: FC<{ isNotFound: boolean }> = ({isNotFound}) => {
    const {classes} = useStyles();

    return ( isNotFound ?
            <Box className={classes.stateInfo}>
                <Image src={LicenseNotFound} alt="Не найдено" width="auto" fit="contain"/>
                <Title order={2} mt={9}>Лицензия не найдена</Title>
                <Text size="md" mt={17}>Искомая лицензия не найдена. Искомый продукт не найден. Пожалуйста, проверьте правильность ввода данных</Text>
            </Box>
            :
            <Box className={classes.stateInfo}>
                <Image src={TableNotFound} alt="Не найдено" width="auto" fit="contain"/>
                <Title order={2} mt={9}>Отчёт пока не собран</Title>
                <Text size="md" mt={17}>Чтобы начать собирать отчёт, заполните поля или введите значения в поиск.</Text>
            </Box>
    )
};

export default ReportsNotFound;
