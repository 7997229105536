import {CSSProperties, FC, useEffect, useState} from 'react';
import {
    Box,
    Checkbox,
    Image,
    Paper,
    ScrollArea,
    Table as MTable,
    Text,
    Title,
} from '@mantine/core';
import {useElementSize} from '@mantine/hooks';

import SortButton from './SortButton/SortButton';
import TableRow from './TableRow/TableRow';
import {useStyles} from './styles';

import TableNotFound from '@/assets/images/TableNotFound.svg';
import TablePreloader from '@/assets/images/TablePreloader.png';

export interface IColumn {
    id: string;
    text: string;
    width?: number;
    noWrap?: boolean;
    style?: CSSProperties;
    sortable?: boolean;
    spoiler?: boolean;
    spoilerClickable?: boolean;
}

export interface ErrorMessage {
    message: string;
    id: number;
}

export type ErrorMessages = ErrorMessage[];

interface ITable {
    columns: IColumn[]
    data: Record<string, any>[] | null | undefined
    selectable?: boolean
    onSelectionChange?: (selection: number[]) => void
    onSortChange?: (column: string, direction: 'asc' | 'desc') => void
    onRowClick?: (row: Record<string, any>) => void
    expandControl?: string
    loading?: boolean
    filledRow?: boolean[]
    resetSelect?: boolean
    triggerTableChange?: boolean
    outerSelection?: number[]
    errorMessages?: ErrorMessages
    checkable?: (id: number) => boolean
    defaultSortBy?: string
    defaultSortDirection?: 'asc' | 'desc'
    showHead?: boolean
    emptyImageSize?: number
}

const Table: FC<ITable> = ({
                               columns,
                               data,
                               selectable,
                               onSortChange,
                               onSelectionChange,
                               onRowClick,
                               expandControl,
                               loading = false,
                               filledRow,
                               resetSelect,
                               triggerTableChange,
                               outerSelection,
                               checkable,
                               defaultSortBy,
                               defaultSortDirection,
                               showHead = true,
                               emptyImageSize
                           }) => {
    const {classes, cx} = useStyles();
    const {ref} = useElementSize();
    const [selection, setSelection] = useState<number[]>([]);
    const [sortBy, setSortBy] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [expansion] = useState<number[]>([]);

    const toggleRowSelection = (id: number) => {
        setSelection((prevSelection) =>
            prevSelection.includes(id)
                ? prevSelection.filter((item) => item !== id)
                : [...prevSelection, id]
        );
    };

    const isAllDataInSelected = () => {
        return (data?.filter(val => selection.includes(val.id)) ?? []).length === (data ?? []).length;
    }

    const toggleAllSelection = () => {
        setSelection((prevSelection) =>
            isAllDataInSelected() ?
                [...prevSelection.filter(val => !data?.some(item => item.id === val))] :
                [...prevSelection, ...(data?.filter(val => !prevSelection.includes(val.id)).map(val => val.id) ?? [])]
        );
    };

    const onSort = (column: string) => {
        const direction = sortBy === column ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
        setSortBy(column);
        setSortDirection(direction);
        if (onSortChange) onSortChange(column, direction);
    };

    useEffect(() => {
        if (!resetSelect) {
            setSelection(() => []);
        }
    }, [resetSelect]);

    useEffect(() => {
        if (onSelectionChange) onSelectionChange(selection);
    }, [selection]);

    useEffect(() => {
        if (outerSelection) setSelection(() => [...outerSelection]);
    }, [triggerTableChange]);

    useEffect(() => {
        if (defaultSortBy) setSortBy(defaultSortBy);
    }, [defaultSortBy]);

    useEffect(() => {
        if (defaultSortDirection) setSortDirection(defaultSortDirection);
    }, [defaultSortDirection]);

    return (
        <Paper ref={ref} className={classes.paper} p={0}>
            <ScrollArea classNames={{ viewport: classes.scrollAreaViewport, scrollbar: showHead ? classes.scrollAreaScrollbar : '' }} sx={{height: '100%'}}>
                <MTable className={cx(classes.table, {[classes.tableFull]: loading || data?.length === 0})} verticalSpacing={15} horizontalSpacing={16} highlightOnHover={!loading && !!data && data.length > 0}>
                    { showHead && <thead>
                    <tr>
                        { selectable && (
                            <th style={{width: 44}}>
                                <Checkbox className={classes.checkbox} checked={isAllDataInSelected() && selection.length > 0} onChange={toggleAllSelection} disabled={loading || data?.length === 0}/>
                            </th>
                        ) }
                        {columns.map((column, key) => (
                            <th key={`${column.id}-${key}`}
                                style={{...(column.width && {minWidth: column.width}), ...column.style}}>
                                {column.sortable ? (
                                    <SortButton text={column.text} active={sortBy === column.id}
                                                direction={sortDirection}
                                                onSort={() => onSort(column.id)}/>
                                ) : (
                                    column.text
                                )}
                            </th>
                        ))}
                    </tr>
                    </thead> }
                    <tbody>
                    { data?.length !== 0 && data?.map((row, index) => (
                            <TableRow
                                key={`${row.id}-${index}`}
                                row={row}
                                columns={columns}
                                selectable={selectable}
                                selected={selection.includes(row.id)}
                                onChangeSelected={() => toggleRowSelection(row.id)}
                                onRowClick={onRowClick ? () => onRowClick(row) : undefined}
                                expandControl={expandControl}
                                expansion={expansion}
                                filled={filledRow ? filledRow[index] : false}
                                checkable={checkable}
                            />
                        ))
                    }
                    </tbody>
                </MTable>
                { (data?.length == 0 || loading) && <Box className={classes.stateInfo}>
                    { loading ? (
                        <Image src={TablePreloader} alt="loading..." width="auto" fit="contain" />
                    ) : (
                        <>
                            <Image src={TableNotFound} alt="Не найдено" width={emptyImageSize || "auto"} fit="contain"/>
                            <Title order={2} mt={9}>Не найдено</Title>
                            <Text size="md" mt={17}>По вашему запросу ничего не найдено. Убедитесь, что запрос был задан правильно и попробуйте снова.</Text>
                        </>
                    )}
                </Box> }
            </ScrollArea>

        </Paper>
    );
};

export default Table;
