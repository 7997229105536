import axiosInstance from "@/api/instance";
import {URLS} from "@/api/urls";

export const feedbackApi = {
  send(data: any) {
    return axiosInstance.request({
      method: 'post',
      url: URLS.feedback.send,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  },
}
