import { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Container,
    CopyButton,
    Group,
    Modal,
    Text,
    TextInput
} from "@mantine/core";
import { Copy } from "tabler-icons-react";

import { COLORS } from "@/constants/mantine/colors";
import { AppPath } from '@/routes/routes-enum';

import { useStyles } from './styles';

import { useActions, useTypedSelector } from "@/store/hooks";

interface IPackFormed {
    setId?: number;
    activationLink?: string;
    email?: string;
    pin?: string;
}

const PackFormed: FC<IPackFormed> = ({email, setId, activationLink, pin}) => {
    const { classes } = useStyles();
    const { setShowPacksFormed, setLicenseFromUser } = useActions();
    const { showPacksFormed } = useTypedSelector(state => state.packs)
    const navigate = useNavigate();

    const onClickFormedLicense = () => {
        if (!setId) return;
        setLicenseFromUser(false);
        setShowPacksFormed(false);
        navigate(`${AppPath.packs}/${setId}`);
    }

    const onClose = () => {
        setLicenseFromUser(false);
        setShowPacksFormed(false);
    }

    return (
        <Modal opened={showPacksFormed} onClose={onClose} padding={20}>
            <Text className={classes.textTitleBlockModal} ta={'center'}>Комплект сформирован</Text>
            <Text className={classes.noteSuccessModal} mx={40} ta={'center'}>
                Ваш комплект успешно сформирован.
                { email && <>Активационная ссылка продублирована на: <Text span c={'#322FC6'} inherit >{email}</Text></> }
            </Text>
            <Box>
                <Container className={classes.blocklButtonContainer} pb={12}>
                    <Group mt={8} align="flex-end">
                      <Text className={classes.linkSuccessModal} ta={'left'}>Активационная ссылка на комплект</Text>
                        <TextInput readOnly={true} w={280} placeholder={activationLink} styles={{ input: { textOverflow: 'ellipsis' } }} />
                        <CopyButton value={activationLink ?? ''}>
                            {({ copied, copy }) => (
                                <Button w={162} variant={copied ? 'filled' : 'outline'} px={13} color={copied ? 'blue' : 'teal'} onClick={copy}> Скопировать
                                    <Copy size={23} style={{
                                            margin: 0,
                                            marginLeft: 11,
                                            transform: `rotate(90deg)`,
                                            color: COLORS.GRAY.icon,
                                        }} />
                                </Button>
                            )}
                        </CopyButton>
                    </Group>
                    <Text className={classes.linkSuccessModal} ta={'left'}>Код активации на комплект</Text>
                    <Group mt={16} align="flex-end">
                        <TextInput readOnly={true} w={280} placeholder={pin} styles={{ input: { textOverflow: 'ellipsis' } }} />
                        <CopyButton value={pin ?? ''}>
                            {({ copied, copy }) => (
                                <Button w={162} variant={copied ? 'filled' : 'outline'} px={13} color={copied ? 'blue' : 'teal'} onClick={copy}> Скопировать
                                    <Copy size={23} style={{ margin: 0, marginLeft: 11, transform: `rotate(90deg)`, color: COLORS.GRAY.icon }} />
                                </Button>
                            )}
                        </CopyButton>
                    </Group>
                    <Button mt={20} onClick={onClose}>Закрыть</Button>
                    <Button mt={8} onClick={onClickFormedLicense}>Перейти к комплекту</Button>
                </Container>
            </Box>
        </Modal>
    );
};

export default PackFormed;
