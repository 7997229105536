import {LicenseReportItem} from "@/types";

import {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, Button, Group, Paper} from "@mantine/core";

import ModalConfirm from "@/ui/ModalConfirm/ModalConfirm";
import LicenseSearch from "@/ui/PageHeaderReports/LicenseSearch/LicenseSearch";
import PageHeaderReports from "@/ui/PageHeaderReports/PageHeaderReports";
import Table from "@/ui/Table/Table";
import TableControls from "@/ui/TableControls/TableControls";

import ReportFilter from "./ReportFilter/ReportFilter";
import {useStyles} from './styles';

import {ReactComponent as Download} from '@/assets/icons/Download.svg';
import ReportsNotFound from "@/pages/Reports/ReportsNotFound";
import {useActions, useTypedSelector} from "@/store/hooks";

const Reports: FC = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const {
    getLicenseExists,
    getLicensesReport,
    getLicensesReportExport,
    getLicensesReportSearch,
    setLicensesReportPage,
    setLicensesReportSort,
    setLicensesReportSearchQuery,
    setLicensesReportSearchQueryId,
    setClearReportExportLink,
  } = useActions();

  const { user } = useTypedSelector((store) => store.auth);

  const {
    isLoadingLicenseExist,
    isErrorLicenseExist,
    reportsExportLink,
    existLicense,
    pagination,
    searchId,
    reports,
    filters,
    search,
    isReportLoading,
    isReportLoadingAssets,
    isReportLoadingSearch,
    isReportLoadingExport,
    isErrorLicenseExport,
    sort,
  } = useTypedSelector((store) => store.reports);

  const [selected, setSelected] = useState<number[]>([]);
  const reportData = [
    { value: '0', label: 'Отчёт по лицензии', disabled: !(user?.role.code === 'ROLE_ADMIN' || user?.role.code === 'ROLE_MANAGER')},
    { value: '1', label: 'Отчёт по пользователю', disabled: true },
    { value: '2', label: 'Отчёт по сервисам', disabled: true },
    { value: '3', label: 'Отчёт по продукту', disabled: true },
  ];
  const [inputValue, setInputValue] = useState<string>(searchId.previousQueryId ?? '');
  const [inputLicenseSearch, setInputLicenseSearch] = useState<string>(search.previousQuery ?? '');
  const report= reportData[0].value;
  const [showFilter, setShowFilter] = useState(false);
  const [reportsExport, setReportsExport] = useState<boolean>(false)
  const [modalExportOpened, setModalExportOpened] = useState<boolean>(false);
  const disabledPagination = isReportLoading || isReportLoadingAssets || isReportLoadingSearch || isReportLoadingExport
  const columns = [
    { id: 'licenseId', text: 'ID', width: 50 },
    { id: 'date', text: 'Дата', sortable: true, width: 95, noWrap: true },
    { id: 'status', text: 'Действие', width: 160, noWrap: true },
    { id: 'setName', text: 'Название комплекта', width: 318 },
    { id: 'payable', text: 'Тип комплекта', width: 158 },
    { id: 'licenseName', text: 'Название лицензии', width: 318 },
    { id: 'nm', text: 'Номенклатура', width: 116 },
    { id: 'userRole', text: 'Роль пользователя', width: 157 },
    { id: 'externalCode', text: 'Родительская номенклатура', width: 198 },
  ];
  const tableReports = reports?.map(({ date, ...item }) => ({
    date: (
      new Date(date).toLocaleString('ru-RU')
    ),
    ...item,
  }));
  const searchLicense = {
    placeholder: 'Поиск по ID лицензии',
    loading: isReportLoadingSearch,
    data: searchId.suggestionsId,
    onChange: (queryId: string) => {
      if (inputValue.length) {
        getLicenseExists({ id: Number(queryId) });
      }
    },
    onSubmit: setLicensesReportSearchQueryId,
  };

  useEffect(() => {
    if (reportsExport) {
      if (!isReportLoading && inputValue && existLicense) {
        getLicensesReportExport({
          role: filters.role,
          action: filters.action,
          date: filters.date?.join(','),
          payable: filters.payable,
          id: Number(inputValue),
        });
      }

      if (!isReportLoadingExport && !isErrorLicenseExport) {
        setReportsExport(false);
      }
    }
  }, [reportsExport]);

  const onGetLicensesReport = () => {
    getLicensesReport({
      id: Number(inputValue),
      page: pagination.currentPage,
      sort: sort?.field,
      direction: sort?.direction,
      query: search.query,
      ...Object.fromEntries(
        Object.entries(filters).map(([key, value]) => [
          key,
          Array.isArray(value) ? value.join(',') : value,
        ])
      ),
    });
  };

  useEffect(() => {
    if (isReportLoading || isReportLoadingAssets || isReportLoadingSearch || isReportLoadingExport || isLoadingLicenseExist) return;
    if (!isErrorLicenseExist || !!existLicense || !!Number(inputValue)) {
      if (existLicense) onGetLicensesReport();
    }
  }, [pagination.currentPage, sort?.field, sort?.direction, filters, search.query, isLoadingLicenseExist]);

  useEffect(() => {
    if (reportsExportLink) {
      const href = URL.createObjectURL(reportsExportLink);
      const link = document.createElement('a');
      let createDate = new Date().toLocaleString();
      link.href = href;
      link.setAttribute('download', `Список лицензий ${createDate}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setClearReportExportLink(null);
    }
  }, [reportsExportLink]);

  const onClickTableRow = (row: LicenseReportItem) => {
    navigate(`/set/${row.setId}`);
  };

  return (
    <>
      <PageHeaderReports
        title={report ? reportData[Number(report)].label : 'Создание отчётов'}
        subTitle={pagination.totalItems}
        searchId={searchLicense}
        inputValue={inputValue}
        setInputValue={setInputValue}
        setInputLicenseSearch={setInputLicenseSearch}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />

      { report === '0' && existLicense && inputValue !== '' && showFilter
        &&
        <Paper py={20} mb={16}>
          {(search) && (
            <Box className={classes.searchBox}>
              {search &&
                <LicenseSearch
                  placeholder={'Поиск по ID пользователя, комплекта'}
                  loading={isReportLoadingSearch}
                  data={search.suggestions}
                  onChange={(query: string) => getLicensesReportSearch({
                    query,
                    id: Number(search.id ?? searchId.queryId)
                  })}
                  onSubmit={(query: string) => setLicensesReportSearchQuery(query)}
                  inputLicenseSearch={inputLicenseSearch}
                  setInputLicenseSearch={setInputLicenseSearch}
                />
              }
              <Group className={classes.searchGroup} position="apart" noWrap={true}>
                <ReportFilter licenseId={Number(inputValue)}/>
              </Group>
            </Box>
          )}
        </Paper>
      }

      { (report === '0' && existLicense && inputValue !== '')
        ?
        (<Table
          columns={columns}
          data={tableReports}
          onRowClick={(row) => { onClickTableRow(row as LicenseReportItem) }}
          selectable={false}
          onSelectionChange={(selection) => setSelected(selection)}
          onSortChange={(field, direction) => {setLicensesReportSort({ field, direction }); setLicensesReportPage(1);}}
          expandControl="name"
          loading={isReportLoading}
          defaultSortBy={'date'}
          defaultSortDirection={'asc'}
        />)
        : <ReportsNotFound isNotFound={report === '0' && !existLicense && !isLoadingLicenseExist && inputValue !== ''} />
      }
      { (report === '0' && existLicense && inputValue !== '')
        &&
        <TableControls selectedCount={selected.length} currentPage={pagination.currentPage} totalPages={pagination.totalPages} onChangePage={setLicensesReportPage} disabledPagination={disabledPagination}>
          <Button classNames={{ root: classes.exportButton, leftIcon: classes.exportButtonIcon }} variant="outline" leftIcon={<Download />} onClick={() => setModalExportOpened(true)}>Экспорт</Button>
        </TableControls>
      }
      <ModalConfirm
        opened={modalExportOpened}
        onClose={() => setModalExportOpened(false)}
        title="Экспортировать список?"
        text="Выгрузка списка продуктов будет произведена с учётом настроенных фильтров"
        accept="Да, экспортировать"
        decline="Нет, отменить"
        onAccept={() => { setReportsExport(true); setModalExportOpened(false); }}
      />
    </>
  );
};

export default Reports;
