import {
    GetLicensesExportParams,
    GetLicensesParams,
    GetLicensesSearchSuggestionsParams
} from '@/types';

import {createAsyncThunk} from '@reduxjs/toolkit';

import {licensesApi} from '@/api';
import {tryAction} from '@/api/tryAction';

export const getLicensesAssets = createAsyncThunk(
    'licenses/getLicensesAssets',
    async (arg, {rejectWithValue}) => {
        const data = await tryAction(licensesApi.getLicensesAssets());

        return data || rejectWithValue(null);
    }
);

export const getLicenses = createAsyncThunk(
    'licenses/getLicenses',
    async (params: GetLicensesParams, {rejectWithValue}) => {
        const data = await tryAction(licensesApi.getLicenses(params));

        return data || rejectWithValue(null);
    }
);

export const getLicensesSearchSuggestions = createAsyncThunk(
    'licenses/getLicensesSearchSuggestions',
    async (params: GetLicensesSearchSuggestionsParams, {rejectWithValue}) => {
        const data = await tryAction(licensesApi.getLicensesSearchSuggestions(params));

        return data || rejectWithValue(null);
    }
);

export const getLicensesExport = createAsyncThunk(
    'licenses/getLicensesExport',
    async (params: GetLicensesExportParams, {rejectWithValue}) => {
        const data = await tryAction(licensesApi.getLicensesExport(params));

        return data || rejectWithValue(null);
    }
);
