import { FC, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { Center, Loader } from "@mantine/core";

import { AppPath } from "@/routes/routes-enum";

import AuthError from "./AuthError/AuthError";

import AuthorizationAccessError from '@/assets/images/AuthorizationAccessError.svg';
import AuthorizationError from '@/assets/images/AuthorizationError.svg';
import { logout } from "@/store/auth/actions";
import { useActions, useTypedSelector } from "@/store/hooks";

const PackActivationEnd: FC = () => {
    const navigate = useNavigate();
    const { status, user } = useTypedSelector((store) => store.auth);
    const { isLinkPacksLoading, isLinkPacksSuccess, isLinkPacksError } = useTypedSelector((store) => store.packs);
    const { postAuthUrl, postLinkPacks, setUserLogout } = useActions();

    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const authUrl = window.location.href;
    let fromUrl = (authUrl.indexOf('code') !== -1) ? (authUrl.substring(0, authUrl.indexOf('code'))) : authUrl;
    let activationLink = (fromUrl[fromUrl.length - 1] === '&') ? fromUrl.slice(0, -1) : fromUrl;

    useEffect(() => {
        if (!code && !(user?.redirect)) {
            postAuthUrl({ redirectLink: activationLink });
        }
    }, []);

    useEffect(() => {
        if (status.authInProcess) return;
        if (status.isAuthSuccess) {
            if (user?.redirect) {
                const pin = user?.redirect.split('pin=').pop()
                if (pin) postLinkPacks({ link: user?.redirect, code: pin });
            }
            return;
        }
    }, [status.authInProcess]);

    useEffect(() => {
        if (isLinkPacksLoading) return;

        if (isLinkPacksSuccess) {
            window.location.href = 'https://lecta.ru';
        }
    }, [isLinkPacksLoading]);

    return (
        <Center py={100} sx={{ minHeight: '100vh', flexDirection: 'column' }}>
            {(status.authInProcess || status.loadingAuthUrl || isLinkPacksLoading) ? (
                <Loader size="lg" />
            ) : status.isAccessError ? (
                <AuthError image={AuthorizationAccessError} title="Ошибка авторизации" text="Нет доступа. Пожалуйста, обратитесь к менеджеру" />
            ) : status.isAuthError && !(status.isAuthSuccess) ? (
                (
                    <AuthError image={AuthorizationError} title="Ошибка авторизации" text="При авторизации произошла ошибка. Попробуйте позднее" button="Вернуться назад"
                               buttonOnClick={() => {
                                    logout();
                                    setUserLogout()
                                    localStorage.removeItem('userInfo');
                                    navigate(AppPath.activation)
                                }}
                    />
                )
            ) : (isLinkPacksError &&
                (
                    <AuthError
                        image={AuthorizationError}
                        title="Ошибка активации"
                        text="Срок действия активационной ссылки истёк."
                        button="Вернуться назад"
                        buttonOnClick={() => {
                            logout();
                            setUserLogout()
                            localStorage.removeItem('userInfo');
                            navigate(AppPath.activation)
                        }}
                    />
                )
            )
            }
        </Center>
    );

}
export default PackActivationEnd
