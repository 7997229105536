import {FC, ReactNode} from 'react';
import { Box, Group, Pagination, Paper } from '@mantine/core';

import { useStyles } from './styles';

interface ITableControls {
  children?: ReactNode;
  showSelectedCount?: boolean;
  selectedCount?: number;
  currentPage?: number;
  totalPages?: number;
  onChangePage?: (page: number) => void;
  disabledPagination?: boolean;
}

const TableControls: FC<ITableControls> = ({
  children,
  showSelectedCount = true,
  selectedCount = 0,
  currentPage = 1,
  totalPages = 0,
  onChangePage,
  disabledPagination
}) => {
  const { classes } = useStyles();
  return (
    <>
      { (children || totalPages > 1) && <Paper component={Group} className={classes.root} py={6} mt={16} spacing={16}>
        { children }
        { (showSelectedCount && selectedCount > 0) && <Box>
            <span className={classes.selectedTitle}>Выбрано:</span> {selectedCount}
          </Box>
        }
        { totalPages > 1 && <Pagination disabled={disabledPagination} ml="auto" page={currentPage} total={totalPages} onChange={onChangePage} /> }
      </Paper> }
    </>
  );
};

export default TableControls;
