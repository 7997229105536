import {StatusEnum} from "@/types/enum/StatusEnum";

import {FC, useEffect, useState} from 'react';
import { Box, Group, Indicator, Title } from '@mantine/core';

import {getColor, getStatusLabel} from "@/utils/helpers";

import { COLORS } from '@/constants/mantine/colors';

import { ReactComponent as Timelaps } from '@/assets/icons/Timelaps.svg';
import { ReactComponent as UserCircle } from '@/assets/icons/UserCircle.svg';
import { useTypedSelector } from '@/store/hooks';

interface IDetailHeaderTitle {
    title: string;
    name?: string;
    subTitle?: string;
    status?: number;
    detail?: boolean;
}

interface IStatusColor {
  bgColor: string,
  color: string
}

const DetailHeaderTitle: FC<IDetailHeaderTitle> = ({ title, name, subTitle, status, detail }) => {
    const [statusColor, setStatusColor] = useState<IStatusColor>(getColor);
    const { isUserDetailLoading } = useTypedSelector(state => state.users);
    const { isPacksDetailLoading, isPackEditLoading, isPackUpdateLoading } = useTypedSelector(state => state.packs);

    useEffect(() => {
        setStatusColor(getColor(status))
    }, [status]);

    return (
        <Title order={1}>
            <Group>
                {title}
                {(!(isPacksDetailLoading || isPackEditLoading || isPackUpdateLoading || isUserDetailLoading) && !detail) && <UserCircle style={{color: COLORS.GRAY.icon}} />}

                { !detail &&
                    <Box sx={() => ({
                            display: 'block',
                            fontSize: '14px',
                        })}>
                        {(!(isPacksDetailLoading || isPackEditLoading || isPackUpdateLoading || isUserDetailLoading) && !detail) ? subTitle : ''}
                    </Box>
                }

                {!(isPacksDetailLoading || isPackEditLoading || isPackUpdateLoading || isUserDetailLoading) &&
                    <Box h={31} px={12} pt={7} sx={() => ({
                            display: 'block',
                            fontSize: '14px',
                            border: 'none',
                            borderRadius: 16,
                            backgroundColor: statusColor?.bgColor,
                            color: statusColor?.color
                        })}>
                        <Indicator zIndex={0} pl={12} size={8} color={statusColor?.color} position="middle-start">{ getStatusLabel(status) }</Indicator>
                    </Box>
                }

                { detail && status === StatusEnum.Active &&
                    <Box sx={() => ({ display: 'block', fontSize: '14px', color: COLORS.GRAY.text })}>
                        <Group position='left' >
                            { name }
                        </Group>
                    </Box>}
            </Group>
        </Title>
    );
};

export default DetailHeaderTitle;
