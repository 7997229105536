import {
    GetProductsExportParams,
    GetProductsParams,
    GetProductsSearchSuggestionsParams,
    PostDigitalProductsParams, PostDigitalProductsResponse,
} from '@/types';

import axiosInstance from '../instance';
import { URLS } from '../urls';


export const productsApi = {
  getProductsAssets() {
    return axiosInstance.request({
      method: 'get',
      url: URLS.products.getProductsAssets,
    });
  },

  getProducts(params: GetProductsParams) {
    return axiosInstance.request({
      method: 'get',
      url: URLS.products.getProducts,
      params,
    });
  },

  getProductsSearchSuggestions(params: GetProductsSearchSuggestionsParams) {
    return axiosInstance.request({
      method: 'get',
      url: URLS.products.getProductsSearchSuggestions,
      params,
    });
  },

  getProductsExport(params?: GetProductsExportParams) {
    return axiosInstance.request({
      method: 'get',
      url: URLS.products.getProductsExport,
      responseType: 'blob',
      params,
    });
  },

    getProductChildren(id: number) {
        return axiosInstance.request({
            method: 'get',
            url: `${URLS.products.getProducts}/${id}`
        });
    },

    postDigitalProductsTemplate(params: PostDigitalProductsParams) {
        return axiosInstance.request<PostDigitalProductsResponse>({
            method: 'post',
            url: URLS.products.postDigitalProductsTemplate,
            data: {
                template: params.template
            },
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    },
};
