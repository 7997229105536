import {Dispatch, FC, SetStateAction, useEffect, useState} from "react";
import {Group, Radio, Select} from "@mantine/core";
import {DateRangePicker, DateRangePickerValue} from "@mantine/dates";

import {formatFormDate} from "@/utils/helpers";

import ModalConfirm from "@/ui/ModalConfirm/ModalConfirm";

import {useActions, useTypedSelector} from "@/store/hooks";

interface IExportLicensePacks {
    modalExportOpened: boolean;
    setModalExportOpened: Dispatch<SetStateAction<boolean>>;
}

const ExportLicensePacks: FC<IExportLicensePacks> = ({
    modalExportOpened,
    setModalExportOpened
}) => {
    const assetsValidity = [
        { value: '1', label: '1 месяц' },
        { value: '3', label: '3 месяца' },
        { value: '6', label: '6 месяцев' },
        { value: '12', label: '1 год' },
    ];

    const { getPacksExport} = useActions();
    const { sort, filters, loadingStatus } = useTypedSelector((store) => store.packs);
    const [dateInterval, setDateInterval] = useState('1');
    const [validity, setValidity] = useState<string | null>(null);
    const [filterDate, setFilterDate] = useState<DateRangePickerValue>([null, null]);
    const [parsedFilterDate, setParsedFilterDate] = useState<string[]>();

    const onAcceptExport = async () => {
        if (!loadingStatus.loading)
            getPacksExport({
                sort: sort?.field,
                direction: sort?.direction,
                type: filters.type,
                status: filters.status,
                createdAt: filters.createdAt ? filters.createdAt?.join(',') : parsedFilterDate?.join(','),
                organization: filters.organization,
                showAll: filters.showAll === 'false' ? '0' : '1'
            });
    }

    function subMonths(date: Date, months: number) {
        date.setMonth(date.getMonth() - months);
        return date;
    }

    useEffect(() => {
        if (filterDate) {
            if (dateInterval === '2') {
                let dateSelect: string[] = [];
                filterDate.map((result) => dateSelect.push(result ? (formatFormDate(result)) : ''));

                if (dateSelect[0] !== '' && dateSelect[1] !== '') {
                    setParsedFilterDate(dateSelect)
                }
            }
        }
    }, [filterDate]);

    useEffect(() => {
        if (validity) {
            if (dateInterval === '1') {
                let dateSelect: string[] = [];
                let currentDate = new Date()
                const beginDate = subMonths(currentDate, Number(validity));
                dateSelect.push(beginDate ? (formatFormDate(beginDate)) : '');
                dateSelect.push(formatFormDate(new Date()));
                if (dateSelect[0] !== '' && dateSelect[1] !== '') setParsedFilterDate(dateSelect);
            }
        }
    }, [validity]);

    return (
        <ModalConfirm
            opened={modalExportOpened}
            onClose={() => {
                setModalExportOpened(false);
                if (validity) setValidity(null);
                if (filterDate) setFilterDate([null, null]);
                setParsedFilterDate(undefined)
                setDateInterval('1')
            }}
            title="Экспортировать список?"
            text="Выгрузка списка продуктов будет произведена с учётом настроенных фильтров"
            accept="Да, экспортировать"
            decline="Нет, отменить"
            onAccept={onAcceptExport}>
          {!filters.createdAt &&
            <>
              <Radio.Group
                label={'Способ экспорта'}
                mt={24}
                mb={16}
                value={dateInterval}
                onChange={(val) => {
                  setDateInterval(val);
                  if (validity) setValidity(null);
                  if (filterDate) setFilterDate([null, null]);
                  setParsedFilterDate(undefined)
                }}
                styles={{
                  label: {
                    padding: 0,
                    fontSize: '16px',
                    fontWeight: 500,
                  },
                }}
              >
                <Group
                  style={{
                    marginTop: 6,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }}>
                  <Radio value='1' label="По периодам" styles={{ root: { paddingTop: 0 }, label: { fontSize: '14px' } }}/>
                  <Radio value='2' label="Конкретные даты" styles={{ label: { fontSize: '14px' } }}/>
                </Group>
              </Radio.Group>

              { dateInterval === '1' && <Select disabled={!!filters.createdAt} placeholder={'1 год'} label='Выберите период экспорта' value={validity} data={assetsValidity} onChange={(val) => setValidity(val)} /> }

              { dateInterval === '2' &&
                <DateRangePicker label='Выберите период экспорта' placeholder={'Период экспорта'} disabled={!!filters.createdAt} styles={{ input: { textOverflow: 'ellipsis' } }}
                  clearable={true}
                  initialLevel='date'
                  locale='ru'
                  inputFormat="DD.MM.YYYY"
                  value={filterDate}
                  onChange={(val) => setFilterDate(val)}
                />}
            </>
          }
        </ModalConfirm>
    );
};

export default ExportLicensePacks;
