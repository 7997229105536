import {FC, useEffect, useRef} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {Button, Center, Loader} from '@mantine/core';

import { AppPath } from '@/routes/routes-enum';

import AuthError from './AuthError/AuthError';

import AuthorizationAccessError from '@/assets/images/AuthorizationAccessError.svg';
import AuthorizationError from '@/assets/images/AuthorizationError.svg';
import { useActions, useTypedSelector } from '@/store/hooks';

const OAuth: FC = () => {
  const navigate = useNavigate();
  const { authorize } = useActions();
  const { status } = useTypedSelector((store) => store.auth);
  const { user } = useTypedSelector((store) => store.auth);
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const isNewUser = user?.redirect?.includes("new-user");
  const inProgress = useRef(false);

  useEffect(() => {
    if (!inProgress.current) {
      inProgress.current = true;
      authorize({ code, state });
    }
  }, [inProgress]);

  useEffect(() => {
    if (status.authInProcess) return;

    if (status.isAuthSuccess) {
      const navigateTo = ((user?.role.code === 'ROLE_ADMIN') || (user?.role.code === 'ROLE_MANAGER'))
        ? AppPath.products : (user?.role.code === 'ROLE_DISTRIBUTOR')
          ? AppPath.licenses : (user?.role.code === 'ROLE_USER' && !!(user.redirect))
            ? AppPath.activation : (user?.role.code === 'ROLE_SCHOOL_ADMIN' && !!(user.redirect))
              ? (isNewUser ? AppPath.licenses : AppPath.interim) : (user?.role.code === 'ROLE_USER' && !(user.redirect))
                ? AppPath.access : (user?.role.code === 'ROLE_SCHOOL_ADMIN' && !(user.redirect))
                  ? AppPath.licenses
                  : AppPath.access;
      navigate(navigateTo);
    }
  }, [status.isAuthSuccess, status.authInProcess]);

  return (
    <Center py={100} sx={{ minHeight: '100vh', flexDirection: 'column' }}>
      { status.authInProcess ? (
        <Loader size="lg" />
      ) : status.badOrganization ? (
        <AuthError image={AuthorizationAccessError} title="Получите доступ к лицензиям!" text={<>
          Вы авторизованы под учетной записью физического лица.<br/>
          Для доступа к системе управления лицензиями необходима учетная запись юридичсекого лица.<br/><br/>
          Создайте новую учетную запись, и мы ее активируем для вас!<br/>
          Свяжитесь с нами, чтобы мы могли помочь.
          <Button component="a" href="mailto:digital@prosv.ru?subject=Доступ к «Системе управления лицензиями»" w={269} mt={33}>Написать в поддержку</Button>
        </>}/>
      ) : status.isAccessError ? (
        <AuthError image={AuthorizationAccessError} title="Ошибка авторизации" text="Нет доступа. Пожалуйста, обратитесь к менеджеру" />
      ) : (
        status.isAuthError && !status.isAuthSuccess && (
          <AuthError image={AuthorizationError} title="Ошибка авторизации" text="При авторизации произошла ошибка. Попробуйте позднее" button="Вернуться назад" buttonOnClick={() => navigate(AppPath.oauth)} />
        )
      )}
    </Center>
  );
};

export default OAuth;
