import * as React from 'react';
import {useState} from "react";
import {Box, Group, Paper, UnstyledButton} from "@mantine/core";

import {FeedbackForm} from "@/components/FeedbackForm";
import {COLORS} from "@/constants/mantine/colors";

import {useStyles} from "@/ui/TableControls/styles";

import {useTypedSelector} from "@/store/hooks";

export const LayoutFooter = () => {
    const { classes } = useStyles();
    const { user } = useTypedSelector((store) => store.auth);
    const instructionLink = user?.role?.code === 'ROLE_SCHOOL_ADMIN' ? 'https://digital.prosv.ru/librarian/' : 'https://digital.prosv.ru/distributor/';
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);

    return (
        <Paper component={Group} className={classes.footer} py={6} mt={12} spacing={16}>
            <Box>
                <span style={{color: COLORS.GRAY.text, marginRight: '1em'}}>Техническая поддержка:</span>
                <a style={{color: COLORS.GRAY.dark, marginRight: '1em', textDecoration: 'none'}} href={'tel:+74957893020'}>+7 495 789 30 20</a>
                <a href={'mailto:digital@prosv.ru'} style={{color: '#3330C6', textDecoration: 'none'}}>digital@prosv.ru</a>
            </Box>
            <Box>
                <UnstyledButton style={{color: COLORS.GRAY.dark, textDecoration: 'none', marginRight: '1em'}} onClick={() => setShowFeedbackForm(true)}>Обратная связь</UnstyledButton>
                <a href={instructionLink} target={'_blank'} style={{color: COLORS.GRAY.dark, textDecoration: 'none'}}>Инструкция по работе с сервисом</a>
            </Box>
            <FeedbackForm setShow={setShowFeedbackForm} show={showFeedbackForm} />
        </Paper>
    );
};
