import {StatusEnum} from "@/types/enum/StatusEnum";

import { FC, useEffect, useState } from "react";
import { Button, CopyButton, Group, TextInput } from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import { Copy } from "tabler-icons-react";

import {plural} from "@/utils/helpers";

import { COLORS } from "@/constants/mantine/colors";

import ModalConfirm from "@/ui/ModalConfirm/ModalConfirm";

import { ReactComponent as TimerCircle } from '@/assets/icons/TimerCircle.svg';
import { useActions, useTypedSelector } from "@/store/hooks";

interface IRightButtonPacksDetail {
    id: number;
    statusPacks?: number;
    statusName?: boolean;
    pin?: string;
    link?: string;
}

const RightButtonPacksDetail: FC<IRightButtonPacksDetail> = ({id, statusPacks, statusName, pin, link}) => {
    const {
        getPackActivate,
        getPackDeactivate,
        getPackResend,
        setMutatePacksDetail
    } = useActions();

    const {
        isPacksDetailLoading,
        packsDetail,
        mutatePacks,
        isGetPackActivateLoading,
        isGetPackDeactivateLoading,
        isGetPackActivateSuccess,
        isGetPackDeactivateSuccess,
        isLinkPacksLoading,
        isPackEditLoading,
        isPackUpdateLoading,
        mutatePacksDetail
    } = useTypedSelector(state => state.packs);

    const [modalDeactivatePack, setModalDeactivatePack] = useState<boolean>(false);
    const [modalActivatePack, setModalActivatePack] = useState<boolean>(false);
    const [timerActive, setTimerActive] = useState<boolean>(false);
    const [minutes, setMinutes] = useState(5);

    if (!isPacksDetailLoading && packsDetail) {
        const activationTime = +new Date(packsDetail?.activationDate ? packsDetail?.activationDate : -300000);
        const currentTime = +new Date();
        const timeShift = (Math.round(Math.round((currentTime - (activationTime + 300000)) / 1000) / 60));

        if (!timerActive && timeShift < 0) {
            const timeShiftPlus = Math.abs(timeShift)
            setMinutes(timeShiftPlus);
            setTimerActive(true);
        }
    }

    const handleSendActivationLink = () => {
      getPackResend({ id })
      setMutatePacksDetail(true)
    }

    useEffect(() => {
        if (minutes > 0 && timerActive) {
            setTimeout(setMinutes, 60000, minutes - 1);
        } else {
            setTimerActive(false);
        }
    }, [minutes, timerActive]);

    useEffect(() => {
        if (isGetPackActivateLoading) return;

        if (isGetPackActivateSuccess) {
            if (!mutatePacks) {
                setMutatePacksDetail(!mutatePacksDetail)
                setModalActivatePack(false)
            }
        }
    }, [isGetPackActivateLoading]);

    useEffect(() => {
        if (isGetPackDeactivateLoading) return;

        if (isGetPackDeactivateSuccess) {
            if (!mutatePacks) {
                setMutatePacksDetail(!mutatePacksDetail)
                setModalDeactivatePack(false)
            }
        }
    }, [isGetPackDeactivateLoading]);

    if (statusPacks === StatusEnum.Deleted) return null;

    return (
        <>
            { !statusName &&
                <Group align="flex-end">
                    <CopyButton value={pin ?? ''}>
                        {({ copy }) => (
                            <TextInput readOnly={true}
                                       w={201} value={(!(isPacksDetailLoading || isPackEditLoading || isPackUpdateLoading) || pin) ? pin : ''}
                                       styles={{input: {textOverflow: 'ellipsis', color: '#8a94a8', borderColor: '#d1d8e6 !important', cursor: 'pointer' }}}
                                       onFocus={e => {
                                           copy();
                                           showNotification({ title: 'Успех', message: 'Код активации скопирован' })
                                           e.target.blur();
                                       }}
                            />
                        )}
                    </CopyButton>
                    { link && <CopyButton value={link ?? ''}>
                        {({ copy }) => (
                            <>
                                <TextInput
                                    readOnly={true}
                                    w={269}
                                    value={(!(isPacksDetailLoading || isPackEditLoading || isPackUpdateLoading) || link) ? link : ''}
                                    styles={{
                                        input: {
                                            textOverflow: 'ellipsis',
                                            color: '#8a94a8',
                                            borderColor: '#d1d8e6 !important',
                                            cursor: 'pointer',
                                        }
                                    }}
                                    onFocus={e => {
                                        copy();
                                        showNotification({ title: 'Успех', message: 'Ссылка на активацию комплекта скопирована' });
                                        e.target.blur();
                                    }}
                                />
                                <Button variant={'outline'} px={14} color={'teal'} onClick={() => {
                                    copy();
                                    showNotification({ title: 'Успех', message: 'Ссылка на активацию комплекта скопирована' })
                                }}>
                                    <Copy size={23} style={{ margin: 0, transform: `rotate(90deg)`, color: COLORS.GRAY.icon }} />
                                </Button>
                            </>
                        )}
                    </CopyButton> }
                </Group>
            }

            { !statusName ?
                <>
                    <Button
                        disabled={timerActive || isLinkPacksLoading || isPacksDetailLoading || isPackEditLoading || isPackUpdateLoading}
                        loading={isLinkPacksLoading}
                        loaderPosition="center"
                        w={178}
                        px={20}
                        variant={'outline'}
                        onClick={() => { setMinutes(5); setTimerActive(true); handleSendActivationLink() }}
                    >
                        { !isLinkPacksLoading &&
                          (timerActive ? <span>
                              <TimerCircle style={{ position: 'absolute', top: 17, left: 41 }} />
                              <span style={{ position: 'absolute', top: 17, left: 70 }}>{minutes} { plural(['минута', 'минуты', 'минут'], minutes)}</span>
                          </span> : 'Отправить') }
                    </Button>

                    {statusPacks &&
                        (statusPacks === StatusEnum.Active) ?
                        <Button
                            loading={isGetPackDeactivateLoading || isPacksDetailLoading}
                            loaderPosition="center"
                            w={178}
                            disabled={isGetPackDeactivateLoading || isPacksDetailLoading || isPacksDetailLoading || isPackEditLoading || isPackUpdateLoading}
                            onClick={() => setModalDeactivatePack(true)}
                        >{!(isGetPackDeactivateLoading || isPacksDetailLoading) ? 'Деактивировать' : ''}
                        </Button>
                        :
                        <Button
                            loading={isGetPackActivateLoading || isPacksDetailLoading}
                            loaderPosition="center"
                            w={178}
                            disabled={isGetPackActivateLoading || isPacksDetailLoading || isPacksDetailLoading || isPackEditLoading || isPackUpdateLoading}
                            onClick={() => setModalActivatePack(true)}
                        >{!(isGetPackActivateLoading || isPacksDetailLoading) ? 'Активировать' : ''}
                        </Button>
                    }
                </>
                :
                null
            }

            <ModalConfirm
                opened={modalActivatePack}
                onClose={() => setModalActivatePack(false)}
                title="Активировать комплект?"
                accept="Да, активировать"
                decline="Нет, отменить"
                onAccept={() => {
                    getPackActivate({ id });
                }}
            />

            <ModalConfirm
                opened={modalDeactivatePack}
                onClose={() => setModalDeactivatePack(false)}
                title="Деактивировать комплект?"
                text="Все дочерние комплекты будут удалены, в том числе комплекты созданные из дочерних комплектов."
                accept="Да, деактивировать"
                decline="Нет, отменить"
                onAccept={() => {
                    getPackDeactivate({ id });
                }}
            />
        </>
    );
}

export default RightButtonPacksDetail;
