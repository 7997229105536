import {
    GetProductsParams,
    GetProductsSearchSuggestionsParams
} from '@/types';

import {createAsyncThunk} from '@reduxjs/toolkit';

import {productsApi} from '@/api';
import {tryAction} from '@/api/tryAction';

export const getDigitalProductsAssets = createAsyncThunk(
    'digitalProducts/getProductsAssets',
    async (arg, {rejectWithValue}) => {
        const data = await tryAction(productsApi.getProductsAssets());

        return data || rejectWithValue(null);
    }
);

export const getDigitalProducts = createAsyncThunk(
    'digitalProducts/getProducts',
    async (params: GetProductsParams, {rejectWithValue}) => {
        const data = await tryAction(productsApi.getProducts(params));

        return data || rejectWithValue(null);
    }
);

export const getDigitalProductsSearchSuggestions = createAsyncThunk(
    'digitalProducts/getProductsSearchSuggestions',
    async (params: GetProductsSearchSuggestionsParams, {rejectWithValue}) => {
        const data = await tryAction(productsApi.getProductsSearchSuggestions(params));

        return data || rejectWithValue(null);
    }
);
