import { FC } from 'react';
import { Group } from '@mantine/core';

import { navigation } from '@/routes/navigation';

import LayoutHeaderNavButton from '../LayoutHeaderNavButton/LayoutHeaderNavButton';

import { useStyles } from './styles';

import { useTypedSelector } from '@/store/hooks';

const LayoutHeaderNav: FC = () => {
  const { classes } = useStyles();
  const { user } = useTypedSelector((store) => store.auth);

  return (
    <Group className={classes.root} noWrap spacing={32} px={24}>
      { navigation.map(
        (item) =>
          (!!user?.role.code && item.access.includes(user.role.code)) && <LayoutHeaderNavButton key={item.href} href={item.href} text={item.title} />
      )}
    </Group>
  );
};

export default LayoutHeaderNav;
