import {showNotification} from "@mantine/notifications";
import {createListenerMiddleware, isFulfilled} from "@reduxjs/toolkit";

import {
    getPackActivate,
    getPackDeactivate,
    getPackResend,
    patchPackExpireDate,
    patchPackUpdate,
    postLinkPacks,
    postTransferSets} from "@/store/packs/actions";
import {
  getSendActivationLink,
  patchChangeRole,
  putUsers
} from "@/store/users/actions";

const listenerMiddleware = createListenerMiddleware()

const { startListening} = listenerMiddleware

const INITIAL_SUCCESS = {
    title: 'Успех',
}

startListening({
    actionCreator: putUsers.fulfilled,
    effect: (action) => {
        if (isFulfilled(action)) {
            showNotification({
                ...INITIAL_SUCCESS,
                message: 'Пользователи успешно созданы. Приглашения отправлены на указанные адреса',
            });
        }
    }
});

startListening({
    actionCreator: postLinkPacks.fulfilled,
    effect: (action) => {
        if (isFulfilled(action)) {
            showNotification({
                ...INITIAL_SUCCESS,
                message: 'Активация прошла успешно',
            });
        }
    }
});

startListening({
    actionCreator: patchPackUpdate.fulfilled,
    effect: (action) => {
        if (isFulfilled(action)) {
            showNotification({
                ...INITIAL_SUCCESS,
                message: 'Комплект успешно изменен',
            });
        }
    }
});

startListening({
    actionCreator: getPackActivate.fulfilled,
    effect: (action) => {
        if (isFulfilled(action)) {
            showNotification({
                ...INITIAL_SUCCESS,
                message: 'Комплект успешно активирован',
            });
        }
    }
});

startListening({
    actionCreator: getPackDeactivate.fulfilled,
    effect: (action) => {
        if (isFulfilled(action)) {
            showNotification({
                ...INITIAL_SUCCESS,
                message: 'Комплект успешно деактивирован',
            });
        }
    }
});

startListening({
    actionCreator: patchChangeRole.fulfilled,
    effect: (action) => {
        if (isFulfilled(action)) {
            showNotification({
                ...INITIAL_SUCCESS,
                message: 'Роль была изменена',
            });
        }
    }
});

startListening({
    actionCreator: getPackResend.fulfilled,
    effect: (action) => {
        if (isFulfilled(action)) {
            showNotification({
                ...INITIAL_SUCCESS,
                message: 'Письмо для активации комплекта успешно отправлено',
            });
        }
    }
});

startListening({
    actionCreator: getSendActivationLink.fulfilled,
    effect: (action) => {
        if (isFulfilled(action)) {
            showNotification({
                ...INITIAL_SUCCESS,
                message: 'Письмо для активации пользователя успешно отправлено',
            });
        }
    }
});

startListening({
    actionCreator: patchPackExpireDate.fulfilled,
    effect: (action) => {
        if (isFulfilled(action)) {
            showNotification({
                ...INITIAL_SUCCESS,
                message: 'Дата окончания была изменена',
            });
        }
    }
});

startListening({
    actionCreator: postTransferSets.fulfilled,
    effect: (action) => {
        if (isFulfilled(action)) {
            showNotification({
                ...INITIAL_SUCCESS,
                message: 'Комплекты успешно перенесены',
            });
        }
    }
});

export default listenerMiddleware;
