import {showNotification} from "@mantine/notifications";
import {
  configureStore,
  isRejected,
  Middleware,
} from '@reduxjs/toolkit';

import listenerMiddleware from "@/utils/notificationsListener";

import rootReducer from './rootReducer';

export const errorNotifications: Middleware = () => (next) => (action) => {
    if (isRejected(action)) {
      const message = action.payload?.data?.message || action.payload?.error;

      if (message) {
          showNotification({
              color: "red",
              title: "Ошибка",
              message: message,
          });
      }
    }

    return next(action)
}

export const store = configureStore(
  {
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .prepend(errorNotifications)
        .prepend(listenerMiddleware.middleware)
    ,
  },
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
