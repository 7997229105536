import { RoleCode } from '@/constants/common';

export const checkFieldAccess = (field: string, role: string | undefined) => {

  if (!role) return false;

  const amds = (role === RoleCode.ADMIN || role === RoleCode.MANAGER || role === RoleCode.DISTRIBUTOR || role === RoleCode.SCHOOL_ADMIN);
  const amd = (role === RoleCode.ADMIN || role === RoleCode.MANAGER || role === RoleCode.DISTRIBUTOR);
  const am = (role === RoleCode.ADMIN || role === RoleCode.MANAGER);

  switch (field) {

    case 'expired':
      return amds;

    case 'status':
      return amds;

    case 'showAll':
      return am;

    case 'organization':
      return amd;

    case 'type':
      return am;

    default:
      return false;
  }
};