import { FC } from 'react';
import { Button, Modal, ModalProps, Stack, Text } from '@mantine/core';

interface IModalConfirm extends ModalProps {
  text?: string;
  accept?: string;
  decline?: string;
  isDisableAcceptButton?: boolean;
  onAccept?: () => void;
  onExit?: () => void;
}

const ModalConfirm: FC<IModalConfirm> = ({
  text,
  accept,
  decline,
  onAccept,
  onExit,
  onClose,
  children,
  isDisableAcceptButton,
  ...props
}) => {
  return (
    <Modal onClose={onClose} {...props}>
      {text && (
        <Text size="sm" ta="center">
          {text}
        </Text>
      )}
      { children }
      <Stack spacing={8} mt={32}>
        <Button
          disabled={isDisableAcceptButton}
          type='button'
          onClick={() => {
            if (onAccept) onAccept();
            onClose();
          }}
        >
          {accept || 'Да'}
        </Button>

        <Button variant="outline" onClick={() => {
          if (onExit) onExit();
          onClose();
        }}
        >
          {decline || 'Нет'}
        </Button>
      </Stack>
    </Modal>
  );
};

export default ModalConfirm;
