import {FC, useEffect, useState} from "react";
import {Button, Group, Select} from "@mantine/core";
import {DateRangePicker, DateRangePickerValue} from "@mantine/dates";

import {formatFormDate} from "@/utils/helpers";

import MultiSelect from "@/ui/MultiSelect/MultiSelect";

import {ReactComponent as Reset} from '@/assets/icons/Reset.svg';
import {checkFieldAccess} from "@/pages/Packs/PacksFilters/utils/checkFieldAccess";
import {useActions, useTypedSelector} from "@/store/hooks";

const PacksFilters: FC = () => {

    const { getPacksAssets, setPacksFilters } = useActions();
    const { user } = useTypedSelector((store) => store.auth);
    const { assets, filters } = useTypedSelector((store) => store.packs);
    const existExpireBegin = filters.expired?.[0] !== undefined ? new Date(filters.expired[0]) : null;
    const existExpireEnd = filters.expired?.[1] !== undefined ? new Date(filters.expired[1]) : null;
    const [filterDate, setFilterDate] = useState<DateRangePickerValue>([existExpireBegin, existExpireEnd]);

    const onChangeFilter = (field: string, value: string[] | string | null) => {
        setPacksFilters({
            ...filters,
            [field]: (Array.isArray(value) && value.length === 0) || value === null ? undefined : value,
        });
    };

    const onResetFilter = () => {
        setPacksFilters({});
        setFilterDate([null, null]);
    };

    useEffect(() => {
        getPacksAssets();
    }, []);

    useEffect(() => {
        if (filterDate) {
            let dateSelect: string[] = [];
            filterDate.map((result) => dateSelect.push(result ? formatFormDate(result) : ''));

            if (dateSelect[0] !== '' && dateSelect[1] !== '') {
                onChangeFilter('expired', dateSelect)
            }
        }
    }, [filterDate]);

    return (
        <Group noWrap={false} style={{ display: 'flex', alignContent: 'center' }}>
            {checkFieldAccess('expired', user?.role.code) && (
                <DateRangePicker
                    styles={{input: { textOverflow: 'ellipsis' }}}
                    clearable={true}
                    initialLevel='date'
                    placeholder={'Сроки действия'}
                    locale='ru'
                    inputFormat="DD.MM.YYYY"
                    value={filterDate}
                    onChange={setFilterDate} />
            )}

            {checkFieldAccess('status', user?.role.code) && (
                <MultiSelect
                    placeholder="Статус"
                    data={assets.status || []}
                    value={filters.status || []}
                    onChange={(value) => {
                        onChangeFilter('status', value);
                    }} />
            )}

            {checkFieldAccess('showAll', user?.role.code) && (
                <Select
                    styles={{
                        withIcon: { paddingLeft: '102px' },
                        icon: { width: '114px', fontSize: '14px', fontWeight: 400, color: 'black', top: 2 }
                    }}
                    mb={0}
                    mt={0}
                    icon={'Показывать: '}
                    searchable={false}
                    defaultValue='0'
                    data={assets.showAll.map(value => {
                        if (!value) {
                            return { value: '0', label: 'мои' };
                        } else {
                            return { value: '1', label: 'все' };
                        }
                    })}
                    value={filters?.showAll ?? '0'}
                    onChange={(value) => onChangeFilter('showAll', value)} />
            )}

            {checkFieldAccess('organization', user?.role.code) && (

                <MultiSelect
                    placeholder="Юр. лицо"
                    data={assets.organization || []}
                    value={filters.organization || []}
                    sx={{
                        '.mantine-MultiSelect-dropdown': {
                            width: '360px !important',
                            left: '0 !important',
                        },
                    }}
                    onChange={(value) => onChangeFilter('organization', value)} />
            )}

            {checkFieldAccess('type', user?.role.code) && (
                <MultiSelect
                    placeholder="Тип назначения"
                    data={assets.type || []}
                    value={filters.type || []}
                    onChange={(value) => onChangeFilter('type', value)} />
            )}

            {((filters.showAll && filters.showAll.length > 0)
                || (filters.type && filters.type.length > 0)
                || (filters.organization && filters.organization.length > 0)
                || (filters.status && filters.status.length > 0)
                || filterDate[0])
                &&
                (
                    <Button variant="subtle" size="sm" p={0} rightIcon={<Reset />} onClick={onResetFilter}>
                        Сбросить
                    </Button>
                )}
        </Group>

    );

};

export default PacksFilters;
