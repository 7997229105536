import {MantineThemeOverride} from '@mantine/core';
import {ChevronDown} from 'tabler-icons-react';

import {BREAKPOINTS} from '@/constants/mantine/breakpoints';
import {COLORS} from '@/constants/mantine/colors';

import 'dayjs/locale/ru';

import {ReactComponent as Calendar} from '@/assets/icons/Calendar.svg';
import ErrorCircle from '@/assets/icons/ErrorCircle.svg';

export const appTheme: MantineThemeOverride = {
    breakpoints: BREAKPOINTS,
    fontFamily: 'Golos, sans-serif',
    lineHeight: 1.35,
    headings: {
        fontWeight: 600,
        fontFamily: 'Golos',
        sizes: {
            h1: {fontSize: 26, lineHeight: 1.1},
            h2: {lineHeight: 1.1},
            h3: {lineHeight: 1.35},
            h4: {lineHeight: 1.35},
            h5: {lineHeight: 1.35},
            h6: {lineHeight: 1.35},
        },
    },
    colors: {
        blue: [
            '#F3F3FC',
            '#CECDF3',
            '#A6A4EA',
            '#7D7BE0',
            '#5552D6',
            '#322FC6',
            '#28259D',
            '#1D1B73',
            '#13124A',
            '#080821',
        ],
    },
    primaryShade: {light: 5},
    cursorType: 'pointer',
    datesLocale: 'ru',

    globalStyles: () => ({
        body: {
            backgroundColor: COLORS.GRAY.bg,
        },
    }),

    components: {
        Indicator: {
            styles: () => ({
                root: {
                    whiteSpace: 'nowrap',
                },
            }),
        },

        Button: {
            defaultProps: {
                size: 'md',
            },
            styles: (theme, params) => ({
                root: {
                    fontWeight: 500,
                    borderRadius: 12,
                    height: params.size === 'md' ? 52 : undefined,
                    color: params.variant === 'outline' ? theme.black : undefined,
                    borderColor: params.variant === 'outline' ? COLORS.GRAY.icon : undefined,

                    '&:not([data-disabled]):hover': {
                        color: params.variant === 'outline' ? theme.colors.blue[5] : undefined,
                        borderColor: params.variant === 'outline' ? COLORS.BLUE.hover : undefined,
                        backgroundColor:
                            params.variant === 'outline'
                                ? 'transparent'
                                : params.variant === 'filled'
                                    ? COLORS.BLUE.hover
                                    : params.variant === 'subtle'
                                        ? 'transparent'
                                        : undefined,
                    },
                    '&:not([data-disabled]):active': {
                        color: params.variant === 'outline' ? theme.colors.blue[6] : undefined,
                        borderColor: params.variant === 'outline' ? theme.colors.blue[6] : undefined,
                        backgroundColor:
                            params.variant === 'outline'
                                ? 'transparent'
                                : params.variant === 'filled'
                                    ? theme.colors.blue[6]
                                    : undefined,
                    },

                    '&[data-loading]': {
                        color: params.variant === 'outline' ? theme.colors.blue[5] : undefined,
                        borderColor: params.variant === 'outline' ? theme.colors.blue[5] : undefined,
                        backgroundColor:
                            params.variant === 'outline'
                                ? 'transparent'
                                : params.variant === 'filled'
                                    ? COLORS.BLUE.hover
                                    : undefined,

                        '&:before': {
                            display: 'none',
                        },
                    },

                    '&[data-disabled]': {
                        color: COLORS.GRAY.text,
                        borderColor: params.variant === 'outline' ? COLORS.GRAY.icon : undefined,
                        backgroundColor:
                            params.variant === 'outline'
                                ? 'transparent'
                                : params.variant === 'filled'
                                    ? COLORS.GRAY.bg
                                    : undefined,
                    },
                },
                rightIcon: {
                    marginLeft: params.size === 'sm' && params.variant === 'subtle' ? 4 : undefined,
                },
            }),
        },

        ActionIcon: {
            defaultProps: {
                size: 24,
            },
            styles: (theme, params) => ({
                root: {
                    border: params.variant === 'transparent' ? 'none' : undefined,
                },
            }),
        },

        Checkbox: {
            styles: (theme, params) => ({
                root: {
                    display: 'flex',
                    alignItems: 'center',
                },
                input: {
                    borderColor: COLORS.GRAY.icon,

                    '&:disabled': {
                        borderColor: COLORS.GRAY.icon,
                        backgroundColor: COLORS.GRAY.bg,
                    },
                },
                label: {
                    paddingLeft: params.labelPosition === 'right' ? 8 : undefined,
                    paddingRight: params.labelPosition === 'left' ? 8 : undefined,
                },
            }),
        },

        TextInput: {
            defaultProps: {
                radius: 12,
                iconWidth: 44,
                rightSectionWidth: 36,
            },
            styles: (theme) => ({
                label: {
                    fontSize: theme.fontSizes.md,
                    lineHeight: 1.5,
                    marginBottom: 15,
                    cursor: 'pointer'
                },
                invalid: {
                  color: '#000',

                  '&::placeholder': {
                    color: COLORS.GRAY.text,
                  },
                },
                error: {
                    backgroundImage: `url(${ErrorCircle})`,
                    backgroundPositionY: 2.5,
                    paddingLeft: "1.2em",
                    backgroundRepeat: "no-repeat",
                    color: COLORS.RED.text
                },
                input: {
                    height: 52,
                    borderColor: COLORS.GRAY.icon,

                    '&:not(:disabled):hover': {
                        borderColor: COLORS.BLUE.hover,
                    },
                    '&:not(:disabled):focus': {
                        borderColor: theme.colors.blue[5],
                    },

                    '&:disabled': {
                        color: COLORS.GRAY.text,
                        borderColor: COLORS.GRAY.bg,
                        backgroundColor: COLORS.GRAY.bg,
                    },

                    '&::placeholder': {
                        color: COLORS.GRAY.text,
                    },
                },
                icon: {
                    color: COLORS.GRAY.text,
                    paddingLeft: 12,
                    paddingRight: 8,
                },
                rightSection: {
                    paddingRight: 12,

                    svg: {
                        color: COLORS.GRAY.text,
                    },
                },
            }),
        },

        Textarea: {
            defaultProps: {
                radius: 12,
            },
            styles: (theme, params) => ({
                wrapper: {
                    borderRadius: params.radius,
                    overflow: 'hidden',
                },
                label: {
                    fontSize: theme.fontSizes.md,
                    lineHeight: '19px',
                    marginBottom: 15,
                },
                invalid: {
                  color: '#000',

                  '&::placeholder': {
                    color: COLORS.GRAY.text,
                  },
                },
                error: {
                  backgroundImage: `url(${ErrorCircle})`,
                  backgroundPositionY: 2.5,
                  paddingLeft: "1.2em",
                  backgroundRepeat: "no-repeat",
                  color: COLORS.RED.text
                },
                input: {
                    lineHeight: '19px',
                    minHeight: 52,
                    paddingTop: '15px !important',
                    paddingBottom: '15px !important',
                    borderColor: COLORS.GRAY.icon,

                    '&:not(:disabled):hover': {
                        borderColor: COLORS.BLUE.hover,
                    },
                    '&:not(:disabled):focus': {
                        borderColor: theme.colors.blue[5],
                    },

                    '&:disabled': {
                        color: COLORS.GRAY.text,
                        borderColor: COLORS.GRAY.bg,
                        backgroundColor: COLORS.GRAY.bg,
                    },

                    '&::placeholder': {
                        color: COLORS.GRAY.text,
                    },
                },
            }),
        },

        Select: {
            defaultProps: {
                radius: 12,
                transition: 'pop',
                transitionDuration: 333,
                transitionTimingFunction: 'ease',
                searchable: true,
                nothingFound: 'Не найдено',
                dropdownPosition: 'bottom',
                maxDropdownHeight: 254,
                rightSection: <ChevronDown/>,
                rightSectionWidth: 36,
            },
            styles: (theme) => ({
                root: {
                    position: 'relative',
                },
                label: {
                    fontSize: theme.fontSizes.md,
                    lineHeight: '19px',
                    marginBottom: 15,
                },
                input: {
                    height: 52,
                    borderColor: COLORS.GRAY.icon,

                    '&:not(:disabled):hover': {
                        borderColor: COLORS.BLUE.hover,
                    },
                    '&:not(:disabled):focus': {
                        borderColor: theme.colors.blue[5],
                    },

                    '&:disabled': {
                        color: COLORS.GRAY.text,
                        borderColor: COLORS.GRAY.bg,
                        backgroundColor: COLORS.GRAY.bg,
                    },

                    '&::placeholder': {
                        color: COLORS.GRAY.text,
                    },
                },
                rightSection: {
                    pointerEvents: 'none',
                    paddingRight: 12,

                    svg: {
                        color: COLORS.GRAY.text,
                        transitionDuration: '.333s',
                        transitionTimingFunction: 'ease',

                        '[aria-expanded="true"] &': {
                            color: theme.colors.blue[5],
                            transform: 'rotate(-180deg)',
                        },
                    },
                },
                dropdown: {
                    width: 'calc(100% + 26px) !important',
                    overflow: 'hidden',
                    borderRadius: 12,
                    borderColor: COLORS.GRAY.icon,
                    boxShadow: '0 4px 32px rgba(0, 0, 0, 0.05)',

                    '& .mantine-ScrollArea-viewport': {
                        padding: 0,

                        '& > *': {
                            display: 'block !important',
                        },
                    },
                    '& .mantine-ScrollArea-scrollbar[data-orientation="vertical"]': {
                        width: 4,
                        marginTop: 16,
                        marginBottom: 16,
                    },
                },
                itemsWrapper: {
                    padding: 0,
                },
                item: {
                    wordBreak: 'break-word',
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 51,
                    padding: '6px 20px',
                    borderRadius: 0,
                },
                nothingFound: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: 51,
                    paddingLeft: 20,
                    paddingRight: 20,
                },
            }),
        },

        MultiSelect: {
            defaultProps: {
                radius: 12,
                transition: 'pop',
                transitionDuration: 333,
                transitionTimingFunction: 'ease',
                searchable: true,
                clearSearchOnBlur: true,
                nothingFound: 'Не найдено',
                dropdownPosition: 'bottom',
                maxDropdownHeight: 254,
                rightSection: <ChevronDown/>,
                rightSectionWidth: 36,
            },
            styles: (theme) => ({
                root: {
                    position: 'relative',
                },
                label: {
                    fontSize: theme.fontSizes.md,
                    lineHeight: '19px',
                    marginBottom: 15,
                },
                input: {
                    minHeight: 52,
                    borderColor: COLORS.GRAY.icon,

                    '&:not(:disabled):hover': {
                        borderColor: COLORS.BLUE.hover,
                    },
                    '&:not(:disabled):focus, &:not(:disabled):focus-within': {
                        borderColor: theme.colors.blue[5],
                    },

                    '&:disabled': {
                        color: COLORS.GRAY.text,
                        borderColor: COLORS.GRAY.bg,
                        backgroundColor: COLORS.GRAY.bg,
                    },
                },
                values: {
                    minHeight: 50,
                },
                searchInput: {
                    height: 50,

                    '&::placeholder': {
                        color: COLORS.GRAY.text,
                    },
                },
                rightSection: {
                    pointerEvents: 'none',
                    paddingRight: 12,

                    svg: {
                        color: COLORS.GRAY.text,
                        transitionDuration: '.333s',
                        transitionTimingFunction: 'ease',

                        '[aria-expanded="true"] &': {
                            color: theme.colors.blue[5],
                            transform: 'rotate(-180deg)',
                        },
                    },
                },
                dropdown: {
                    width: 'calc(100% + 26px) !important',
                    overflow: 'hidden',
                    borderRadius: 12,
                    borderColor: COLORS.GRAY.icon,
                    boxShadow: '0 4px 32px rgba(0, 0, 0, 0.05)',

                    '& .mantine-ScrollArea-viewport': {
                        padding: 0,

                        '& > *': {
                            display: 'block !important',
                        },
                    },
                    '& .mantine-ScrollArea-scrollbar[data-orientation="vertical"]': {
                        width: 4,
                        marginTop: 16,
                        marginBottom: 16,
                    },
                },
                itemsWrapper: {
                    padding: 0,
                },
                item: {
                    wordBreak: 'break-word',
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 51,
                    padding: '6px 20px',
                    borderRadius: 0,
                    '&[data-selected] .mantine-Checkbox-labelWrapper': {
                        color: '#fff'
                    },
                },
                nothingFound: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: 51,
                    paddingLeft: 20,
                    paddingRight: 20,
                },
            }),
        },

        Autocomplete: {
            defaultProps: {
                radius: 12,
                transition: 'pop',
                transitionDuration: 333,
                transitionTimingFunction: 'ease',
                iconWidth: 44,
                rightSectionWidth: 36,
                maxDropdownHeight: 152,
                limit: 6,
            },
            styles: (theme, params) => ({
                input: {
                    height: 52,
                    borderColor: params.variant === 'filled' ? 'transparent' : COLORS.GRAY.icon,
                    backgroundColor: params.variant === 'filled' ? COLORS.GRAY.bg : undefined,

                    '&:not(:disabled):hover': {
                        borderColor: params.variant !== 'filled' ? COLORS.BLUE.hover : undefined,
                    },
                    '&:not(:disabled):hover:not(:focus)': {
                        backgroundColor: params.variant === 'filled' ? 'rgba(209, 216, 230, 0.5)' : undefined,
                    },
                    '&:not(:disabled):focus': {
                        borderColor: params.variant === 'filled' ? 'transparent' : theme.colors.blue[5],
                    },

                    '&:disabled': {
                        color: COLORS.GRAY.text,
                        borderColor: COLORS.GRAY.bg,
                        backgroundColor: COLORS.GRAY.bg,
                    },

                    '&::placeholder': {
                        color: COLORS.GRAY.text,
                    },
                },
                icon: {
                    color: COLORS.GRAY.text,
                    paddingLeft: 12,
                    paddingRight: 8,
                },
                rightSection: {
                    paddingRight: 12,

                    svg: {
                        color: COLORS.GRAY.text,
                    },
                },
                dropdown: {
                    overflow: 'hidden',
                    boxShadow: '0 4px 32px rgba(0, 0, 0, 0.05)',
                    borderColor: COLORS.GRAY.icon,
                    borderRadius: 12,

                    '& .mantine-ScrollArea-viewport': {
                        padding: 0,

                        '& > *': {
                            display: 'block !important',
                        },
                    },
                    '& .mantine-ScrollArea-scrollbar[data-orientation="vertical"]': {
                        width: 4,
                        marginTop: 16,
                        marginBottom: 16,
                    },
                },
                itemsWrapper: {
                    padding: 0,
                },
                item: {
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 0,
                    minHeight: 50,
                    padding: '6px 12px',
                },
                nothingFound: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: 50,
                    paddingLeft: 12,
                    paddingRight: 12,
                },
            }),
        },

        DateRangePicker: {
            defaultProps: {
                radius: 12,
                transition: 'pop',
                transitionDuration: 333,
                transitionTimingFunction: 'ease',
                rightSection: <Calendar/>,
                rightSectionWidth: 36,
            },
            styles: (theme) => ({
                input: {
                    height: 52,
                    borderColor: COLORS.GRAY.icon,

                    '&:not(:disabled):hover': {
                        borderColor: COLORS.BLUE.hover,

                        '& ~ .mantine-Input-rightSection svg': {
                            color: COLORS.BLUE.hover,
                        },
                    },
                    '&:not(:disabled):focus:not(:hover)': {
                        borderColor: COLORS.GRAY.icon,
                    },

                    '&:disabled': {
                        color: COLORS.GRAY.text,
                        borderColor: COLORS.GRAY.bg,
                        backgroundColor: COLORS.GRAY.bg,

                        '& ~ .mantine-Input-rightSection svg': {
                            color: COLORS.GRAY.icon,
                        },
                    },

                    '&::placeholder': {
                        color: COLORS.GRAY.text,
                    },
                },
                rightSection: {
                    pointerEvents: 'none',
                    paddingRight: 12,

                    svg: {
                        color: COLORS.GRAY.text,
                    },
                },
                label: {
                    fontSize: theme.fontSizes.md,
                    lineHeight: '19px',
                    marginBottom: 15,
                },
            }),
        },

        DatePicker: {
            defaultProps: {
                radius: 12,
                transition: 'pop',
                transitionDuration: 333,
                transitionTimingFunction: 'ease',
                rightSection: <Calendar/>,
                rightSectionWidth: 36,
            },
            styles: (theme) => ({
                input: {
                    height: 52,
                    borderColor: COLORS.GRAY.icon,

                    '&:not(:disabled):hover': {
                        borderColor: COLORS.BLUE.hover,

                        '& ~ .mantine-Input-rightSection svg': {
                            color: COLORS.BLUE.hover,
                        },
                    },
                    '&:not(:disabled):focus:not(:hover)': {
                        borderColor: COLORS.GRAY.icon,
                    },

                    '&:disabled': {
                        color: COLORS.GRAY.text,
                        borderColor: COLORS.GRAY.bg,
                        backgroundColor: COLORS.GRAY.bg,

                        '& ~ .mantine-Input-rightSection svg': {
                            color: COLORS.GRAY.icon,
                        },
                    },

                    '&::placeholder': {
                        color: COLORS.GRAY.text,
                    },
                },
                rightSection: {
                    pointerEvents: 'none',
                    paddingRight: 12,

                    svg: {
                        color: COLORS.GRAY.text,
                    },
                },
                label: {
                    fontSize: theme.fontSizes.md,
                    lineHeight: '19px',
                    marginBottom: 15,
                },
            }),
        },

        Divider: {
            defaultProps: {
                color: COLORS.GRAY.icon,
            },
        },

        Pagination: {
            defaultProps: {
                withControls: false,
                siblings: 1,
                spacing: '0px',
            },
            styles: (theme) => ({
                item: {
                    fontSize: theme.fontSizes.sm,
                    height: 42,
                    minWidth: 42,
                    borderRadius: 8,
                    border: 'none',

                    '&:focus': {
                        outline: 'none',
                    },

                    '&[data-dots]': {
                        color: COLORS.GRAY.text,
                        minWidth: 13,
                        padding: 0,

                        svg: {
                            marginTop: 9,
                            marginLeft: -2,
                            marginRight: -2,
                        },
                    },
                },
            }),
        },

        Paper: {
            defaultProps: {
                p: 24,
                radius: 16,
                shadow: '0 4px 32px rgba(0, 0, 0, 0.05)',
            },
        },

        ScrollArea: {
            defaultProps: {
                type: 'auto',
                scrollbarSize: 6,
                offsetScrollbars: true,
            },
            styles: () => ({
                scrollbar: {
                    padding: 0,

                    '&:hover': {
                        '& .mantine-ScrollArea-thumb': {
                            backgroundColor: COLORS.GRAY.icon,
                        },
                    },

                    '&[data-orientation="horizontal"]': {
                        marginLeft: 24,
                        marginRight: 24,
                        marginBottom: 8,
                    },

                    '&[data-orientation="vertical"]': {
                        marginTop: 24,
                        marginBottom: 24,
                        marginRight: 8,
                    },
                },
                thumb: {
                    backgroundColor: COLORS.GRAY.icon,
                    borderRadius: 33,
                },
            }),
        },

        Modal: {
            defaultProps: {
                size: 578,
                centered: true,
                overlayColor: '#000',
                overlayOpacity: 0.65,
                shadow: '0 4px 32px rgba(0, 0, 0, 0.05)',
                radius: 32,
                padding: "30px 60px 30px 60px",
            },
            styles: (theme) => ({
                close: {
                    color: COLORS.GRAY.text,
                    background: 'rgba(0, 0, 0, 0.4)',
                    borderRadius: 42,
                    width: 52,
                    height: 52,
                    position: 'absolute',
                    top: 0,
                    left: '100%',
                    marginLeft: 16,
                    transitionDuration: '.333s',
                    transitionTimingFunction: 'ease',

                    svg: {
                        width: 24,
                        height: 24,
                    },

                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
                header: {
                    justifyContent: 'center',
                    marginTop: 1,
                    marginRight: 0,
                },
                title: {
                    fontWeight: 600,
                    fontSize: theme.headings.sizes.h2.fontSize,
                    lineHeight: theme.headings.sizes.h2.lineHeight,
                    textAlign: 'center',
                    marginRight: 0,
                },
            }),
        },

        NumberInput: {
            defaultProps: {
                radius: 12,
                rightSectionWidth: 15,
            },
            styles: () => ({

                input: {
                    height: 40,
                    width: 57,
                    paddingLeft: 9,
                    marginRight: 5,
                    margin: 0,
                    borderColor: COLORS.GRAY.icon,
                },

                rightSection: {
                    icon: {
                        paddingLeft: 0,
                        paddingRight: 0,
                        margin: 0
                    },
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingRight: 16,
                    paddingLeft: 0,
                    svg: {
                        height: 10
                    },
                },
                controlUp: {
                    width: 20,
                    border: 'none',
                    backgroundColor: 'transparent',
                    cursor: "pointer"
                },
                controlDown: {
                    width: 20,
                    border: 'none',
                    backgroundColor: 'transparent',
                    cursor: "pointer"
                },
            }),
        },
    },
};
