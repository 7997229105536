import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({

  stateInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24,
    paddingBottom: 24 + 50,
    marginTop: 'auto',
    marginBottom: 'auto',
    position: 'sticky',
    left: 0,
  },

  exportButton: {
    flexShrink: 0,
    minWidth: 173,
    paddingLeft: 22,
    paddingRight: 22,
  },

  exportButtonIcon: {
    marginRight: 8,
  },

  searchGroup: {
    '.mantine-InputWrapper-root:not(.mantine-DateRangePicker-root)': {
      width: 174,
    },
  },

  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    width: '100%'
  },
}));
