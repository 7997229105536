import React, {useEffect, useState} from 'react';
import {DatePicker} from "@mantine/dates";
import moment from "moment/moment";

import {formatFormDate} from "@/utils/helpers";

import {useActions} from "@/store/hooks";

const DateChangeInput = ({id, date}: {date: string, id: number}) => {
    const [value, setValue] = useState<Date | null>(null);

    useEffect(() => {
        if (date) {
            setValue(moment(date).toDate());
        }
    }, [date])

    const { patchPackExpireDate } = useActions();

    const onClickChangeDate = (newDate: Date, packId: number) => {
        patchPackExpireDate({ expiredDate: formatFormDate(newDate), id: packId });
    }

    function handleChange(newDate: Date | null) {
        const newVal = newDate ? new Date(newDate) : value;
        setValue(newVal);

        if (newVal && newVal.toString() !== value?.toString()) {
            onClickChangeDate(newVal, id);
        }
    }

    return (
        <DatePicker
            key={id}
            defaultValue={null}
            allowFreeInput={false}
            locale="ru"
            inputFormat="DD.MM.YYYY"
            labelFormat="DD.MM.YYYY"
            minDate={new Date()}
            value={value}
            onChange={handleChange}
            withinPortal={true}
        />
    );
};

export default DateChangeInput;
