import {createStyles} from '@mantine/core';

import {COLORS} from '@/constants/mantine/colors';

export const useStyles = createStyles((theme) => ({
    paper: {
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'hidden',
    },

    scrollAreaViewport: {
        paddingRight: 0,
        paddingBottom: 22,

        '& > div': {
            height: '100%',
        },
    },

    scrollAreaScrollbar: {
        '&[data-orientation="vertical"]': {
            marginTop: 50 + 24,
        },
    },

    table: {
        borderSpacing: 0,
        borderCollapse: 'separate',
        borderRadius: '16px',

        thead: {
            lineHeight: '18px',
            backgroundColor: COLORS.GRAY.icon,
            position: 'sticky',
            zIndex: 2,
            top: 0,

            tr: {
                th: {
                    fontWeight: 600,
                    whiteSpace: 'nowrap',
                    color: COLORS.GRAY.dark,
                    borderBottom: 'none',
                },
            },
        },

        tbody: {
            lineHeight: '19px',

            tr: {
                td: {
                    // height: 69,
                    // padding: 12,
                    fontSize: 13,
                    borderColor: COLORS.GRAY.icon,
                },

                '&:last-of-type': {
                    td: {
                        borderBottom: `1px solid ${COLORS.GRAY.icon}`,
                    },
                },
            },
        },

        tr: {
            '& td, & th': {
                '&:first-of-type': {
                    paddingLeft: 16 + 8,
                },

                '&:last-of-type': {
                    paddingRight: 16 + 8,
                },
            },
        },

        '&[data-hover]': {
            '& tbody tr': {
                cursor: 'pointer',

                '&:hover': {
                    backgroundColor: COLORS.GRAY.hover,
                },
            },
        },
    },

    tableFull: {
        minHeight: '100%',
    },

    rowFilled: {
        backgroundColor: '#FEF1F1',
    },

    rowChecked: {
        backgroundColor: COLORS.GRAY.bg,
    },

    rowExpanded: {
        position: 'relative',
        backgroundColor: COLORS.GRAY.bg,
        boxShadow: '0 4px 32px rgba(0, 0, 0, 0.05)',

        '& td:first-of-type::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: 4,
            background: theme.colors.blue[5],
        },
    },

    rowNested: {
        position: 'relative',
        backgroundColor: 'rgba(242, 242, 248, 0.25)',

        '& td:first-of-type::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: 4,
            background: theme.colors.blue[5],
        },
    },

    rowNested2: {
        backgroundColor: COLORS.GRAY.bg,

        '& td:first-of-type::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: 8,
            top: 0,
            bottom: 0,
            width: 4,
            background: COLORS.BLUE.hover,
        },
    },

    checkbox: {
        marginRight: -16,
    },

    sortControl: {
        fontWeight: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        color: 'inherit',
        display: 'inline-flex',
        alignItems: 'center',
    },

    sortControlIcon: {
        marginLeft: 5,
    },

    sortControlIconActive: {
        color: theme.colors.blue[5],
    },

    expandIcon: {
        flexShrink: 0,
        color: COLORS.GRAY.icon,
    },

    expandIconActive: {
        color: theme.colors.blue[5],
    },

    expandIconActive1: {
        color: COLORS.GRAY.text,
    },

    expandArrow: {
        flexShrink: 0,
        color: COLORS.GRAY.text,
        marginLeft: 'auto',
    },

    expandArrowActive: {
        color: theme.colors.blue[5],
        transform: 'scale(-1)',
    },

    noWrap: {
        whiteSpace: "nowrap"
    },

    tdSingle: {
        padding: '0 !important',
        borderBottom: 'none !important',
    },

    stateInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 24 + 50,
        paddingBottom: 24 + 50,
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#fff'
    },
}));
