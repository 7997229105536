import { FC } from "react";
import { ActionIcon, Group, Text } from "@mantine/core";

import { useStyles } from "./styles";

import { ReactComponent as Check } from '@/assets/icons/Check.svg'
import { ReactComponent as Close } from '@/assets/icons/Close.svg'
import { ReactComponent as Edit } from '@/assets/icons/Edit.svg'
import { useTypedSelector } from "@/store/hooks";

interface Props {
    title: string;
    hideAll?: boolean;
    hideAccept?: boolean;
    hideEdit?: boolean;
    onClickAccept?: () => void;
    onClickHide?: () => void;
    onClickEdit?: () => void;
}

const BoxTitle: FC<Props> = ({
    title,
    onClickAccept,
    onClickEdit,
    onClickHide,
    hideAll,
    hideAccept,
    hideEdit
}) => {
    const { classes } = useStyles();
    const { isPacksDetailLoading } = useTypedSelector((store) => store.packs);
    return (
        <Text className={classes.SpecifyFieldsText}>
            <Group className={classes.SpecifyFieldsGroup}>
                { title }
                { !hideAll &&
                    <Group>
                        { hideEdit &&
                            <>
                                <ActionIcon disabled={hideAccept} loading={isPacksDetailLoading} onClick={onClickAccept} variant="transparent">
                                    <Check />
                                </ActionIcon>
                                <ActionIcon onClick={onClickHide} variant="transparent">
                                    <Close />
                                </ActionIcon>
                            </>}
                        { !hideEdit &&
                            <ActionIcon loading={isPacksDetailLoading} variant="transparent" onClick={onClickEdit}>
                                <Edit />
                            </ActionIcon> }
                    </Group>
                }
            </Group>
        </Text>
    )
}

export default BoxTitle;
