import { FC, useEffect, useState } from 'react';
import { ActionIcon, Autocomplete, Loader } from '@mantine/core';
import { getHotkeyHandler, useDebouncedValue } from '@mantine/hooks';

import { useStyles } from './styles';

import { ReactComponent as Clear } from '@/assets/icons/Clear.svg';
import { ReactComponent as Search } from '@/assets/icons/Search.svg';

const NULL_CHARACTERS = 0;
const MIN_CHARACTERS = 1;
const MAX_CHARACTERS = 100;

export interface IPageHeaderSearch {
  placeholder?: string;
  loading?: boolean;
  data: string[];
  query?: string;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
}

const PageHeaderSearch: FC<IPageHeaderSearch> = ({
  placeholder,
  loading,
  data,
  query,
  onChange,
  onSubmit,
}) => {
  const { classes } = useStyles();
  const [inputValue, setInputValue] = useState<string>(query ?? '');
  const [debouncedInputValue] = useDebouncedValue(inputValue, 800);
  const checkNullLength = (value = debouncedInputValue) => value.trim().length === NULL_CHARACTERS;
  const checkMinLength = (value = debouncedInputValue) => value.trim().length >= MIN_CHARACTERS;
  const checkMaxLength = (value = debouncedInputValue) => value.trim().length < MAX_CHARACTERS;
  const filterSpecialCharacters = (value = debouncedInputValue) =>
    (!/`|~|!|#|№|\$|%|\^|&|\*|\(|\)|\+|=|\[|\{|]|}|\||\\|'|<|,|>|\?|\/|»»|;|:[А-я]/g.test(value));
  const searchParams = checkMinLength() && checkMaxLength() && filterSpecialCharacters()
  const onClear = () => {
    setInputValue('');
    onSubmit('');
  };

  useEffect(() => {
    if (searchParams) {
      onChange(debouncedInputValue);
    }

    if (checkNullLength()) onClear();
  }, [debouncedInputValue]);

  const uniqueItems = (items: string[]) => [...new Set(items)];

  return (
    <Autocomplete
      classNames={{
        root: classes.root,
        input: classes.input,
        item: classes.item,
      }}
      variant="filled"
      icon={<Search />}
      rightSection={
        loading ? (
          <Loader size={24} />
        ) : (
          (!loading && inputValue) &&
          (
            <ActionIcon variant="transparent" onClick={onClear}>
              <Clear />
            </ActionIcon>
          )
        )
      }
      nothingFound={searchParams && !loading && 'Не найдено'}
      styles={{
        input: {
          textOverflow: 'ellipsis',
        }
      }}
      placeholder={placeholder}
      data={(checkMinLength() && checkMaxLength() && filterSpecialCharacters()) ? uniqueItems(data) : []}
      value={inputValue}
      onChange={setInputValue}
      onKeyDown={getHotkeyHandler([
        ['Enter', () => filterSpecialCharacters() && checkMinLength(inputValue) && checkMaxLength(inputValue) && onSubmit(inputValue.trim())],
      ])}
      onItemSubmit={(item) => onSubmit(item.value.trim())}
    />
  );
};

export default PageHeaderSearch;
