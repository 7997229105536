import { combineReducers } from 'redux';

import authReducer from './auth';
import digitalProductsReducer from './digitalProducts';
import licensesReducer from './licenses';
import packsReducer from './packs'
import productsReducer from './products';
import reportsReducer from './reports';
import usersReducer from './users';

const rootReducer = combineReducers({
  auth: authReducer,
  products: productsReducer,
  digitalProducts: digitalProductsReducer,
  licenses: licensesReducer,
  users: usersReducer,
  packs: packsReducer,
  reports: reportsReducer,
});

export default rootReducer;
export type IRootReducer = ReturnType<typeof rootReducer>;
