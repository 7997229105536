import {FC, useEffect} from 'react';
import {Button, Group} from '@mantine/core';

import MultiSelect from '@/ui/MultiSelect/MultiSelect';

import {ReactComponent as Reset} from '@/assets/icons/Reset.svg';
import {useActions, useTypedSelector} from '@/store/hooks';

const DigitalSetFilter: FC = () => {
    const {getDigitalProductsAssets, setDigitalProductsFilters} = useActions();
    const {assets, filters} = useTypedSelector((store) => store.digitalProducts);

    const onChangeFilter = (field: string, value: string[] | string | null) => {
        setDigitalProductsFilters({
            ...filters,
            [field]: (Array.isArray(value) && value.length === 0) || value === null ? undefined : value,
        })
    };

    const onResetFilter = () => {
        setDigitalProductsFilters({});
    };

    useEffect(() => {
        getDigitalProductsAssets();
    }, []);

    return (<Group noWrap={false}>
            <MultiSelect
                placeholder="Класс"
                data={assets.class}
                value={filters.class || []}
                onChange={(value) => onChangeFilter('class', value)}
            />
            <MultiSelect
                placeholder="Уровень обр-ния"
                data={assets.eduLevel}
                value={filters.eduLevel || []}
                onChange={(value) => onChangeFilter('eduLevel', value)}
            />
            <MultiSelect
                placeholder="Сервисы"
                data={assets.service}
                value={filters.service || []}
                onChange={(value) => onChangeFilter('service', value)}
            />
            {((filters.service && filters.service.length > 0) || (filters.class && filters.class.length > 0) || (filters.eduLevel && filters.eduLevel.length > 0))
                &&
                <Button variant="subtle" size="sm" p={0} rightIcon={<Reset/>}
                        onClick={onResetFilter}>
                    Сбросить
                </Button>
            }
        </Group>
    );
};

export default DigitalSetFilter;
