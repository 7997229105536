import { createStyles } from '@mantine/core';

import { COLORS } from '@/constants/mantine/colors';

export const useStyles = createStyles(() => ({

  // Modal "ChangeRole" #####

  textTitleModal: {
    top: 0,
    marginTop: 40,
    height: 29,
    fontFamily: 'Golos',
    fontWeight: 600,
    fontSize: 26,
    lineHeight: '110%',
    alignItems: 'center',
    textAlign: 'center',
    color: '#000000',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },

  selectDropdownMenu: {
    boxSizing: "border-box",
    fontSize: 14,
    alignItems: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '14px, 12px',
    width: 442,
    flexGrow: 0,
  },

  textInputCreateUser: {
    boxSizing: "border-box",
    fontSize: 14,
    alignItems: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '14px, 12px',
    width: 442,
    flexGrow: 0,
  },

  modalAddAnotherUser: {
    fontSize: 14,
    marginTop: '8px',
    marginLeft: '40px',
    color: '#322FC6',
    flexGrow: 0,
  },

  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: 0,
  },

  modalButton: {
    width: 213,
    height: 52,
    marginTop: 32,
  },


  // Modal "BlockUnblockUser" #####

  textTitleBlockModal: {
    top: 0,
    marginTop: 0,
    // height: 29,
    fontFamily: 'Golos',
    fontWeight: 600,
    fontSize: 26,
    lineHeight: '110%',
    alignItems: 'center',
    textAlign: 'center',
    color: '#000000',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },


  textNoteBlockModal: {
    top: 0,
    marginTop: 16,
    height: 38,
    fontFamily: 'Golos',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '135%',
    alignItems: 'center',
    textAlign: 'center',
    color: '#000000',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },

  blocklButtonContainer: {
    padding: '0px 40px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  blockButton: {
    width: 458,
    height: 52,
    marginTop: 32,
  },

  reverseButton: {
    width: 458,
    height: 52,
    marginTop: 8,
  },

  noteSuccessModal: {
    top: 0,
    marginTop: 16,
    height: 38,
    fontFamily: 'Golos',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '135%',
    alignItems: 'center',
    textAlign: 'center',
    color: '#000000',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },

  linkSuccessModal: {
    top: 0,
    marginTop: 32,
    height: 19,
    fontFamily: 'Golos',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '120%',
    alignItems: 'center',
    textAlign: 'center',
    color: '#000000',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },

  linkField: {
    width: 280,
    height: 52,
    marginTop: 16,
  },

  copySuccessButton: {
    width: 162,
    height: 52,
    marginTop: 16,
  },

  closeSuccessButton: {
    width: 458,
    height: 52,
    marginTop: 32,
  },

  selectedTitle: {
    color: COLORS.GRAY.text,
  },

  anchor: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '135%',
    color: COLORS.RED.text,
  },

  containerRole: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },

  selectRole: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },

  emailGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },

  emailDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '16px'
  },
  emailBox: {
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative'
  },

  emailInput: {
    // display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  },

  emailInputOrg: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  },

  groupPackName: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },

  groupValidity: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },

  groupRadio: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },

  boxTable: {
    overflow: 'auto',
    display: 'flex',
    flexShrink: 0,
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.05)'
  },

  boxInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0
  },

  tableBoxName: {
    adding: 0,
    width: 170,
    height: 57,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  textBlockTitle: {
    fontFamily: 'Golos',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '19px',
    textAlign: 'left',
  }

}));
