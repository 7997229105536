import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { AppShell } from '@mantine/core';

import {LayoutFooter} from "@/ui/Layout/LayoutFooter/LayoutFooter";

import LayoutHeader from './LayoutHeader/LayoutHeader';

const Layout: FC = () => {
  return (
    <AppShell
      fixed
      header={<LayoutHeader />}
      styles={{ main: { display: 'flex', flexDirection: 'column', height: '100vh' } }}
    >
      <Outlet />
      <LayoutFooter />
    </AppShell>
  );
};

export default Layout;
