import {Dispatch, FC, SetStateAction, useEffect} from "react";
import { ActionIcon, Box, Button, Modal, Stack, Text } from "@mantine/core";

import Table from "@/ui/Table/Table";

import { ReactComponent as Clear } from '@/assets/icons/Clear.svg';
import { useActions, useTypedSelector } from "@/store/hooks";

interface IDeletePacks {
    modalDeletePacks: boolean;
    setModalDeletePacks: Dispatch<SetStateAction<boolean>>
}

const DeletePacks: FC<IDeletePacks> = ({ modalDeletePacks, setModalDeletePacks }) => {
    const { setTriggerTableChange, setSelectedPacks, setMutatePacks, deletePacks } = useActions();
    const { triggerTableChange, selectedPacks, isPacksDeleteLoading, isPacksDeleteSuccess } = useTypedSelector(state => state.packs);
    const onClear = (id: number) => {
        setSelectedPacks(selectedPacks.filter(val => val.id !== id));
        setTriggerTableChange(!triggerTableChange);
    };

    useEffect(() => {
        if (isPacksDeleteLoading) return;

        if (isPacksDeleteSuccess) {
            setSelectedPacks([]);
            setTriggerTableChange(!triggerTableChange);
            setMutatePacks(true);
            setModalDeletePacks(false);
        }
    }, [isPacksDeleteLoading]);

    const tablePacksDelete = selectedPacks?.map(({ ...item }) => ({
        deletePackRow: (
            <Text>
                <ActionIcon variant="transparent" onClick={() => onClear(item.id)}>
                    <Clear />
                </ActionIcon>
            </Text>
        ),
        ...item,
    }));

    const columns = [
        { id: 'id', text: '', width: 110, style: { backgroundColor: 'red', padding: 0 } },
        { id: 'name', text: '', width: 259, style: { backgroundColor: 'red', padding: 0 } },
        { id: 'deletePackRow', text: '', width: 56, style: { backgroundColor: 'red', padding: 0 } },
    ];

    return (
        <Modal opened={modalDeletePacks} onClose={() => setModalDeletePacks(false)} title={"Удалить комплекты?"}>
            <Text size="sm" ta="center">Удалённые комплекты будут отображены в конце выдачи комплектов. Это действие нельзя вернуть.</Text>
            <Box h={243} mt={24} style={{overflow: 'auto', display: 'flex', flexShrink: '0', boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.05)'}}>
                <Table columns={columns} data={tablePacksDelete}/>
            </Box>
            <Stack spacing={8} mt={32}>
                <Button onClick={() => { deletePacks({ ids: selectedPacks.map(pack => pack.id) }) }}>Да, удалить</Button>
                <Button variant="outline" onClick={() => setModalDeletePacks(false)}>Нет, отменить</Button>
            </Stack>
        </Modal>
    );
};

export default DeletePacks;
