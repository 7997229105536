import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PAGINATION_PER_PAGE } from '@/constants/common';

import {
  getLicenseExists,
  getLicensesReport,
  getLicensesReportAssets,
  getLicensesReportExport,
  getLicensesReportSearch,
} from './actions';
import { IReport, IReportsFilters, ISort, ReportsState } from './types';

const initialState: ReportsState = {

  isReportLoading: false,
  isReportLoadingAssets: false,
  isReportLoadingSearch: false,
  isReportLoadingExport: false,

  isErrorLicenseExport: false,

  assets: {
    role: [],
    action: [],
    date: [],
    payable: [],
  },
  reports: null,
  pagination: {
    currentPage: 1,
    perPage: PAGINATION_PER_PAGE,
  },
  sort: null,
  filters: {},
  search: {
    id: undefined,
    previousQuery: undefined,
    query: undefined,
    suggestions: [],
  },

  searchId: {
    previousQueryId: undefined,
    queryId: undefined,
    suggestionsId: [],
  },
  reportsExportLink: null,

  existLicense: false,

  isLoadingLicenseExist: false,
  isErrorLicenseExist: false,

  initialReportsSort: {
    sort: '',
    direction: 'asc',
  }
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setLicensesReportPage(state, action: PayloadAction<number>) {
      state.pagination.currentPage = action.payload;
    },
    setLicensesReportSort(state, action: PayloadAction<ISort>) {
      state.sort = action.payload;
    },
    setLicensesReportFilters(state, action: PayloadAction<IReportsFilters>) {
      state.filters = action.payload;
      state.pagination.currentPage = initialState.pagination.currentPage;
    },
    setLicensesReportSearchQuery(state, action: PayloadAction<string>) {
      state.search.previousQuery = action.payload ?? state.search.previousQuery;
      state.search.query = action.payload ? action.payload : undefined;
      state.pagination.currentPage = initialState.pagination.currentPage;
    },
    setLicensesReportSearchQueryId(state, action: PayloadAction<string>) {
      state.searchId.previousQueryId = action.payload ?? state.searchId.previousQueryId;
      state.searchId.queryId = action.payload ? action.payload : undefined;
      state.pagination.currentPage = initialState.pagination.currentPage;
    },
    setClearReportSearchQuery(state) {
      state.searchId.previousQueryId = undefined;
      state.searchId.queryId = undefined;
      state.search.previousQuery = undefined;
      state.search.query = undefined;
      state.search.suggestions = [];
      state.pagination.currentPage = initialState.pagination.currentPage;
    },
    setLicensesReport(state, action: PayloadAction<IReport[] | null>) {
      state.reports = action.payload;
    },
    setExistLicense(state, action: PayloadAction<boolean>) {
      state.existLicense = action.payload;
    },
    setClearReportExportLink(state, action: PayloadAction<Blob | null>) {
      state.reportsExportLink = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getLicensesReportAssets
    builder
      .addCase(getLicensesReportAssets.pending, (state) => {
        state.isReportLoadingAssets = true;
      })
      .addCase(getLicensesReportAssets.fulfilled, (state, action) => {
        state.isReportLoadingAssets = false;
        state.assets = action.payload.result;
      })
      .addCase(getLicensesReportAssets.rejected, (state) => {
        state.isReportLoadingAssets = false;
      });

    // getReports
    builder
      .addCase(getLicensesReport.pending, (state) => {
        state.isReportLoading = true;
      })
      .addCase(getLicensesReport.fulfilled, (state, action) => {
        state.isReportLoading = false;
        state.reports = action.payload.result.items;
        state.pagination.currentPage = action.payload.result.currentPage;
        state.pagination.perPage = action.payload.result.perPage;
        state.pagination.totalItems = action.payload.result.totalItems;
        state.pagination.totalPages = action.payload.result.totalPages;
        // state.initialReporsSort.sort = action.payload.result.sort;
        // state.initialReportsSort.direction = action.payload.result.direction;
      })
      .addCase(getLicensesReport.rejected, (state) => {
        state.isReportLoading = false;
      });

    // getLicenseReportSearch
    builder
      .addCase(getLicensesReportSearch.pending, (state) => {
        state.isReportLoadingSearch = true;
      })
      .addCase(getLicensesReportSearch.fulfilled, (state, action) => {
        state.isReportLoadingSearch = false;
        state.search.suggestions = action.payload.result.map((item: string | number) =>
          item.toString()
        );
      })
      .addCase(getLicensesReportSearch.rejected, (state) => {
        state.isReportLoadingSearch = false;
      });

    //ExportReport
    builder
      .addCase(getLicensesReportExport.pending, (state) => {
        state.isErrorLicenseExport = false;
        state.isReportLoadingExport = true;
      })
      .addCase(getLicensesReportExport.fulfilled, (state, action) => {
        state.isErrorLicenseExport = false;
        state.isReportLoadingExport = false;
        state.reportsExportLink = action.payload;
      })
      .addCase(getLicensesReportExport.rejected, (state) => {
        state.isErrorLicenseExport = true;
        state.isReportLoadingExport = false;
      });

    // getLicensesExists
    builder
      .addCase(getLicenseExists.pending, (state) => {
        state.isErrorLicenseExist = false;
        state.isLoadingLicenseExist = true;
      })
      .addCase(getLicenseExists.fulfilled, (state, action) => {
        state.isErrorLicenseExist = false;
        state.isLoadingLicenseExist = false;
        state.existLicense = action.payload.result;
      })
      .addCase(getLicenseExists.rejected, (state) => {
        state.isErrorLicenseExist = true;
        state.isLoadingLicenseExist = false;
      });
  },
});

export const reportsActions = {
  getLicenseExists,
  getLicensesReport,
  getLicensesReportAssets,
  getLicensesReportExport,
  getLicensesReportSearch,
  ...reportsSlice.actions,
};

export default reportsSlice.reducer;
