import React, { FC, useEffect, useRef, useState } from "react";
import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    Checkbox,
    Container,
    Modal,
    Select,
    Text,
    TextInput
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Plus } from "tabler-icons-react";
import { v4 as uuidv4 } from 'uuid';

import {validateEmail} from "@/utils/helpers";

import {RoleCode} from "@/constants/common";
import { COLORS } from "@/constants/mantine/colors";

import { ReactComponent as ErrorCircle } from '../../../assets/icons/ErrorCircle.svg';

import { useStyles } from './styles';

import { ReactComponent as Clear } from '@/assets/icons/Clear.svg';
import { ReactComponent as Trash } from '@/assets/icons/Trash.svg';
import ConsumerCreated from "@/pages/Users/Modal/ConsumerCreated";
import { useActions, useTypedSelector } from "@/store/hooks";

export interface ICreateUserModal {
    modalTitle: string;
}

interface EmailItem {
    id: string;
    text: string;
    error: boolean;
    duplicate: boolean;
    onBlur: boolean;
}

const CreateUserModal: FC<ICreateUserModal> = ({
    modalTitle
}) => {
    const {
        getCheckEmail,
        putUsers,
        getUsersRoles,
        patchChangeRole
    } = useActions();
    const { classes } = useStyles();
    const { setCreateUserModal } = useActions();
    const { user } = useTypedSelector((store) => store.auth);
    const {
        users,
        roles,
        createUserModal,
        checkEmail,
        isPutUsersLoading,
        isCheckEmailLoading,
        isCheckEmailSuccess,
        isChangeRoleUsersLoading,
        isChangeRoleUsersSuccess
    } = useTypedSelector((store) => store.users);

    const [selectUserRole, setSelectUserRole] = useState<string | null>(null);
    const [rejectEmail, setRejectEmail] = useState<boolean>(false)
    const [emailList, setEmailList] = useState<EmailItem[]>([{ id: uuidv4(), text: '', error: false, duplicate: false, onBlur: false }]);
    const [createUser, setCreateUser] = useState<boolean>(false);
    const [createConsumer, setCreateConsumer] = useState<boolean>(false);
    const [consumerCreated, setConsumerCreated] = useState<boolean>(false);
    const idCheckEmail = useRef<string | null>(null);
    const currentUserRole = user?.role?.code ?? '';
    const userRoleAM = currentUserRole === 'ROLE_ADMIN' || currentUserRole === 'ROLE_MANAGER';

    const emailToCreate: string[] = [];
    emailList.forEach((val) => {
        emailToCreate.push(val.text)
    });
    const listEmail = emailToCreate.toString();
    const singleUser = emailToCreate.length === 1 || emailToCreate.length === 0;

    const clearState = () => {
        setSelectUserRole(null);
        setEmailList(() => [{ id: uuidv4(), text: '', error: false, duplicate: false, onBlur: false }]);
        setRejectEmail(false);
        setCreateUser(false);
        setCreateConsumer(false);
    };

    const clearErrorEmail = () => {
        setEmailList(() => [{ id: uuidv4(), text: '', error: false, duplicate: false, onBlur: false }]);
        setRejectEmail(false);
    };

    const onChangeEmailText = (id: string, text: string) => {
        const error = validateEmail(text.toLowerCase());
        const duplicate = !!(emailList.find(val => val.text === text.toLowerCase()))
        setEmailList((prevState) => [...prevState.map(val => val.id === id ? { id: val.id, text, error, duplicate: duplicate, onBlur: false } : val)]);
        const targetEmail = emailList.find(val => val.id == id);
        const check = emailList.length - 1;
        const lastId = emailList[check].id;
        if ((id !== lastId) && (!targetEmail?.error || !targetEmail?.duplicate)) getCheckEmail({ email: targetEmail?.text as string });
        setRejectEmail(false);
    }

    const handleAddUser = () => {
        setCreateUser(false);

        let check = emailList.length - 1;
        let id = emailList[check].id;

        setEmailList((prevState) => [...prevState.map(val => val.id === id ? { ...val, onBlur: true } : { ...val })]);

        if ((check + 1) && (!(emailList[check].error) || !(emailList[check].duplicate))) {
            getCheckEmail({ email: emailList[check].text as string });
        }
    }

    const onClickDeleteEmail = (id: string) => {
        setEmailList((prevState) => prevState.filter(email => email.id !== id));
        if (id === idCheckEmail.current) {
            setRejectEmail(false);
        }
    }

    const handleCreateUser = () => {
        const check = emailList.length - 1
        const email = emailList[check].text.toString()
        setCreateUser(true);

        if (rejectEmail && checkEmail?.result.id && selectUserRole) {
            patchChangeRole({
                id: checkEmail?.result.id.toString(),
                role: selectUserRole
            });
        } else if (check + 1) {
            getCheckEmail({ email: email });
        }
    }

    const emails: { [key: string]: (value: string) => "Неверный формат" | null } = {};

    const form = useForm({
        validate: emails,
    });

    const rolesEnum = roles.map((result) => ({ value: result.code, label: result.name }))

    useEffect(() => {
        if (isCheckEmailLoading) return;

        if (isCheckEmailSuccess) {
            if (!checkEmail) {
                return setRejectEmail(false);
            } else if (checkEmail.result.email) {
                setRejectEmail(true);
            } else {
                setRejectEmail(false);
                if (emailList.find(val => (val.error || val.duplicate))) return;

                if (createUser) {
                    const consumer = singleUser ? createConsumer : false;

                    putUsers({
                        "email": listEmail,
                        "role": selectUserRole ? selectUserRole.toString() : '',
                        "consumer": Number(consumer)
                    });
                } else {
                    setEmailList((prevState) => [...prevState, {
                        id: uuidv4(),
                        text: '',
                        error: false,
                        duplicate: false,
                        onBlur: false
                    }]);
                }
            }
        } else if (!isCheckEmailSuccess) {
            setCreateUser(false);
        }
    }, [isCheckEmailLoading]);

    useEffect(() => {
        if (!isPutUsersLoading) {
            setCreateUser(false);
            setRejectEmail(false);

            if (!createConsumer) {
                setCreateUserModal(false);
            } else {
                setConsumerCreated(true);
            }

            clearState();
        }
    }, [isPutUsersLoading])

    useEffect(() => {
        if (isChangeRoleUsersLoading) return;

        if (isChangeRoleUsersSuccess) {
            setCreateUser(false)
            setRejectEmail(false);
            setCreateUserModal(false);
            clearState();
        }
    }, [isChangeRoleUsersLoading]);

    useEffect(() => {
        idCheckEmail.current = emailList[emailList.length - 1].id;
    }, [emailList]);

    useEffect(() => {
        if (selectUserRole !== RoleCode.MANAGER &&
            selectUserRole !== RoleCode.ADMIN &&
            selectUserRole
        ) {
            setCreateConsumer(false);
        }
    }, [selectUserRole]);

    const onClose = () => {
        clearState();
        setCreateUserModal(false);
        setConsumerCreated(false);
    }

    return (
      <Modal opened={createUserModal} onClose={onClose}>
        <Text className={classes.textTitleModal} ta={'center'}>
          { modalTitle }
        </Text>
          { !consumerCreated ? <>
              <Select
                  mt={32}
                  mx={0}
                  label="Роль"
                  placeholder={'Выберите роль'}
                  onDropdownOpen={() => roles.length == 0 && getUsersRoles()}
                  className={classes.selectDropdownMenu}
                  required={true}
                  data={rolesEnum}
                  value={selectUserRole}
                  onChange={setSelectUserRole}
              />
              <Box id="block" className={classes.formBox} mt={32}>
                  { emailList.map((email, index) =>
                      <Box key={email.id} style={{display: 'flex', gap: '4px', flexDirection: 'column'}}>
                          <TextInput
                              className={classes.textInputCreateUser}
                              key={email.id}
                              readOnly={!email.duplicate && email.id !== emailList[emailList.length - 1].id}
                              mt={index !== 0 ? 16 : 0}
                              rightSection={index === 0 ? (rejectEmail && (emailList.length === 1) ?
                                      <ActionIcon variant="transparent" onClick={clearErrorEmail}><Clear /></ActionIcon>
                                      : null) :
                                  <Trash onClick={() => onClickDeleteEmail(email.id)} size={24} strokeWidth={1} color={COLORS.GRAY.text} />
                              }
                              disabled={!selectUserRole}
                              placeholder="Введите e-mail" {...form.getInputProps(email.id)}
                              label={index === 0 ? "Email пользователя" : null}
                              error={
                                  rejectEmail &&
                                  (index === emailList.length - 1
                                      ? (<span style={{color: 'black'}}>
                                            <ErrorCircle style={{marginRight: 4}}/>
                                            Такой пользователь уже существует в системе. Вы можете изменить его роль.<br/><br/>
                                              { checkEmail?.result.name ? ` Имя пользователя: ${checkEmail?.result.name} ` : ''}<br/><br/>
                                              { checkEmail?.result.organization ? checkEmail?.result.organization[0] ? ` Организация: ${checkEmail?.result.organization[0].name} ` : '' : '' }
                                      </span>
                                      ) : null)
                              }
                              withAsterisk
                              value={email.text.toLowerCase()}
                              onChange={(event) =>
                                  onChangeEmailText(email.id, event.currentTarget.value)
                              }
                          />
                          <Text color={COLORS.RED.text} size={12}>
                              { email.error && email.text ?
                                  <span style={{color: 'black'}}><ErrorCircle style={{marginRight: 4}}/>Неверный формат</span> :
                                  email.duplicate ?
                                      <span style={{color: COLORS.RED.text}}><ErrorCircle style={{marginRight: 4}}/> Введён дубликат email.</span> :
                                      null
                              }
                          </Text>
                      </Box>
                  )
                  }
              </Box>
              <Anchor
                  className={classes.modalAddAnotherUser}
                  component="button" type="button"
                  onClick={handleAddUser}
                  disabled={(!!emailList.find(val => !val.text))
                      || !!(emailList.filter((v) => v.error).length)
                      || !!(emailList.filter((v) => v.duplicate).length)
                      || rejectEmail
                  }
                  style={{
                      color: ((!!emailList.find(val => !val.text))
                          || !!(emailList.filter((v) => v.error).length)
                          || !!(emailList.filter((v) => v.duplicate).length)
                          || rejectEmail) ? COLORS.GRAY.text : COLORS.BLUE.hover
                  }}
              > Добавить еще пользователя {<Plus size={12}/>}
              </Anchor>
              { (userRoleAM && singleUser && (selectUserRole === RoleCode.MANAGER || selectUserRole === RoleCode.DISTRIBUTOR)) && <Checkbox
                  label='Доступ к API'
                  mt={16}
                  checked={createConsumer}
                  onChange={event => { setCreateConsumer(event.currentTarget.checked); }}
              /> }
              <Container className={classes.modalButtonContainer}>
                  <Button
                      disabled={
                          (!!emailList.find(val => !val.text))
                          || !!(emailList.filter((v) => v.error).length)
                          || !!(emailList.filter((v) => v.duplicate).length)
                          || rejectEmail && emailList.length > 1
                      }
                      className={classes.modalButton}
                      onClick={handleCreateUser}
                  >{ !rejectEmail || emailList.length > 1 ? 'Подтвердить' : 'Сменить роль' }</Button>
                  <Button className={classes.modalButton} variant="outline" onClick={onClose}>Отменить</Button>
              </Container>
          </> : <ConsumerCreated user={users?.[0]} onClose={onClose} />
          }
      </Modal>
    );
};

export default CreateUserModal;
