import { FC } from "react";
import { Center } from "@mantine/core";

import AuthError from "./AuthError";

import AuthorizationAccessError from '@/assets/images/AuthorizationAccessError.svg';

const AccessDenied: FC = () => {
    localStorage.clear();

    return (
        <Center py={100} sx={{ minHeight: '100vh', flexDirection: 'column' }}>
            <AuthError
                image={AuthorizationAccessError}
                title="Ошибка авторизации"
                text="Нет доступа. Пожалуйста, обратитесь к менеджеру"
            />
        </Center>
    )
};

export default AccessDenied;
