import {
  GetLicensesExportParams,
  GetLicensesParams,
  GetLicensesSearchSuggestionsParams
} from '@/types';

import axiosInstance from '../instance';
import { URLS } from '../urls';


export const licensesApi = {
  getLicensesAssets() {
    return axiosInstance.request({
      method: 'get',
      url: URLS.licenses.getLicensesAssets,
    });
  },

  getLicenses(params: GetLicensesParams) {
    return axiosInstance.request({
      method: 'get',
      url: URLS.licenses.getLicenses,
      params,
    });
  },

  getLicensesSearchSuggestions(params: GetLicensesSearchSuggestionsParams) {
    return axiosInstance.request({
      method: 'get',
      url: URLS.licenses.getLicensesSearchSuggestions,
      params,
    });
  },

  getLicensesExport(params?: GetLicensesExportParams) {
    return axiosInstance.request({
      method: 'get',
      url: URLS.licenses.getLicensesExport,
      responseType: 'blob',
      params,
    });
  },
};
