import React, {CSSProperties, FC, MouseEvent, useState} from 'react';

interface ISpoiler {
    value: string | number;
    width?: number;
    rows?: number;
    clickable?: boolean;
}

const Spoiler: FC<ISpoiler> = ({value, width, rows = 2, clickable = false}) => {
    const styles: CSSProperties = {
        overflow: 'hidden',
        width: width ? width - 32 : undefined,
    };
    const [show, setShow] = useState(false)

    if (rows === 1) {
        styles.whiteSpace = 'nowrap';
        styles.textOverflow = 'ellipsis';
    } else {
        styles.display = '-webkit-box';
        styles.WebkitBoxOrient = 'vertical';
        styles.WebkitLineClamp = rows;
    }

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if (clickable) {
            event.stopPropagation();
            setShow(!show)
        }
    }

    return (
        <div style={!show ? styles : undefined} title={value.toString() ?? ''} onClick={handleClick}>
            { value }
        </div>
    );
};

export default Spoiler;
