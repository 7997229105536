import { FC, useEffect } from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';

import { AppFonts } from '@/utils/AppFonts';
import AppHistory from '@/utils/AppHistory';

import { appTheme } from '@/constants/mantine/appTheme';

import AppRouter from './routes/AppRouter';
import { useActions } from './store/hooks';

const App: FC = () => {
  const { setAuthFromStorage } = useActions();

  useEffect(() => {
    setAuthFromStorage();
  }, []);

  return (
    <MantineProvider withNormalizeCSS withGlobalStyles theme={appTheme}>
      <AppFonts />
      <NotificationsProvider position="top-right" autoClose={8000}>
        <HistoryRouter history={AppHistory}>
          <AppRouter />
        </HistoryRouter>
      </NotificationsProvider>
    </MantineProvider>
  );
};

export default App;
