import {
    GetProductsExportParams,
    GetProductsParams,
    GetProductsSearchSuggestionsParams
} from '@/types';

import {createAsyncThunk} from '@reduxjs/toolkit';

import {productsApi} from '@/api';
import {tryAction} from '@/api/tryAction';

export const getProductsAssets = createAsyncThunk(
    'products/getProductsAssets',
    async (arg, {rejectWithValue}) => {
        const data = await tryAction(productsApi.getProductsAssets());

        return data || rejectWithValue(null);
    }
);

export const getProducts = createAsyncThunk(
    'products/getProducts',
    async (params: GetProductsParams, {rejectWithValue}) => {
        const data = await tryAction(productsApi.getProducts(params));

        return data || rejectWithValue(null);
    }
);

export const getProductsSearchSuggestions = createAsyncThunk(
    'products/getProductsSearchSuggestions',
    async (params: GetProductsSearchSuggestionsParams, {rejectWithValue}) => {
        const data = await tryAction(productsApi.getProductsSearchSuggestions(params));

        return data || rejectWithValue(null);
    }
);

export const getProductsExport = createAsyncThunk(
    'products/getProductsExport',
    async (params: GetProductsExportParams, {rejectWithValue}) => {
        const data = await tryAction(productsApi.getProductsExport(params));

        return data || rejectWithValue(null);
    }
);
