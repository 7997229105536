import React, {FC, useEffect, useState} from "react";
import {Button, Container, Modal, Text, TextInput} from "@mantine/core";

import { useStyles } from './styles';

import { useActions, useTypedSelector } from "@/store/hooks";

export interface IChangeRoleModal {
  selectedID: number[];
  onGetPacks: () => void
}

const TransferSets: FC<IChangeRoleModal> = ({selectedID, onGetPacks }) => {
    const { classes } = useStyles();
    const { setTransferSetModal, postTransferSets } = useActions();
    const { transferSetModal, isTransferSetsLoading, isTransferSetsSuccess } = useTypedSelector(state => state.packs);
    const [uuid, setUuid] = useState<string>('');

    const onExit = () => {
        setTransferSetModal(false);
    }

    useEffect(() => {
      if (isTransferSetsLoading) return;

      if (isTransferSetsSuccess) {
        onGetPacks();
        onExit();
      }
    }, [isTransferSetsLoading]);

    return (
        <Modal opened={transferSetModal} onClose={onExit}>
            <Text className={classes.textTitleModal} ta={'center'}>Перенос комплектов</Text>
            <TextInput
                mt={32}
                placeholder="ff67d441-0b8a-4488-8653-baa70490a301"
                label={"Укажите uuid пользователя, на которого будет выполнен перенос комплектов лицензий"}
                value={uuid}
                name={'uuid'}
                minLength={36}
                maxLength={36}
                onChange={e => setUuid(e.currentTarget.value)}
                withAsterisk
            />

            <Container className={classes.modalButtonContainer}>
                <Button className={classes.modalButton} onClick={() => postTransferSets({ids: selectedID, uuid})} loading={isTransferSetsLoading}>Подтвердить</Button>
                <Button className={classes.modalButton} variant="outline" onClick={onExit}>Отменить</Button>
            </Container>
        </Modal>
    );
};

export default TransferSets;


