import React, {FC} from 'react';
import {Button, Container,CopyButton, Group, Text, TextInput} from "@mantine/core";
import {Copy} from "tabler-icons-react";

import {COLORS} from "@/constants/mantine/colors";

import {useStyles} from "@/pages/Packs/Modal/styles";
import {IUser} from "@/store/users/types";

interface IChangeRoleModal {
    user?: IUser;
    onClose: () => void
}

const ConsumerCreated: FC<IChangeRoleModal> = ({ user, onClose }) => {
    const { classes } = useStyles();
    const uuid = user?.consumers[0]?.uuid;
    const privateKey = user?.consumers[0]?.privateKey;

    return (
        <>
            <Text className={classes.noteSuccessModal} mx={40} ta={'center'}>
                Данные для доступа к API
            </Text>
            <Text className={classes.linkSuccessModal} mt={0} ta={'left'}>UUID</Text>
            <Group mt={8} align="flex-end">
                <TextInput readOnly={true} w={280} placeholder={uuid} styles={{ input: { textOverflow: 'ellipsis' } }} />
                <CopyButton value={uuid ?? ''}>
                    {({ copied, copy }) => (
                        <Button w={162} variant={copied ? 'filled' : 'outline'} px={13} color={copied ? 'blue' : 'teal'} onClick={copy}> Скопировать
                            <Copy size={23} style={{
                                margin: 0,
                                marginLeft: 11,
                                transform: `rotate(90deg)`,
                                color: COLORS.GRAY.icon,
                            }} />
                        </Button>
                    )}
                </CopyButton>
            </Group>
            <Text className={classes.linkSuccessModal} mt={20} ta={'left'}>Ключ</Text>
            <Group mt={16} align="flex-end">
                <TextInput readOnly={true} w={280} placeholder={privateKey} styles={{ input: { textOverflow: 'ellipsis' } }} />
                <CopyButton value={privateKey ?? ''}>
                    {({ copied, copy }) => (
                        <Button w={162} variant={copied ? 'filled' : 'outline'} px={13} color={copied ? 'blue' : 'teal'} onClick={copy}> Скопировать
                            <Copy size={23} style={{ margin: 0, marginLeft: 11, transform: `rotate(90deg)`, color: COLORS.GRAY.icon }} />
                        </Button>
                    )}
                </CopyButton>
            </Group>
            <Container className={classes.blocklButtonContainer} pl={0} pr={0} mt={32} pb={0}>
                <Button onClick={onClose}>Закрыть</Button>
            </Container>
        </>
    );
}

export default ConsumerCreated;
