import { FC, useEffect } from "react";
import { Button, Container, Modal, Text } from "@mantine/core";

import { useStyles } from './styles';

import { useActions, useTypedSelector } from "@/store/hooks";

export interface IBlockUserModal {
    modalTitle: string;
    blockButton: string;
    reverseButton: string;
    single: boolean;
    selectedID: number[];
}

const BlockUserModal: FC<IBlockUserModal> = ({
    modalTitle,
    blockButton,
    reverseButton,
    single,
    selectedID
}) => {
    const { classes } = useStyles();
    const { setBlockUserModal, patchBlockUsers, setMutateUsers } = useActions();
    const { blockUserModal, isBlockUsersLoading, isBlockUsersSuccess, mutateUsers } = useTypedSelector(state => state.users)
    const isSingle = single ? 'пользователя' : 'пользователей';
    const isSingleOne = single ? 'его' : 'их';

    useEffect(() => {
        if (isBlockUsersLoading) return;

        if (isBlockUsersSuccess && !mutateUsers) {
            setMutateUsers(true);
            setBlockUserModal(false);
        }
    }, [isBlockUsersLoading]);

    return (
        <Modal opened={blockUserModal} onClose={() => setBlockUserModal(false)} padding={20}>
            <Text className={classes.textTitleBlockModal} ta={'center'}>{modalTitle}</Text>
            <Text className={classes.textNoteBlockModal} mx={40} ta={'center'}>Доступ {isSingle} к системе будет закрыт, все лицензии <br /> деактивированы. Вы сможете разблокировать {isSingleOne} позже.</Text>
            <Container className={classes.blocklButtonContainer}>
                <Button disabled={false} className={classes.blockButton} onClick={() => patchBlockUsers({ id: selectedID.join() })}>{blockButton}</Button>
                <Button className={classes.reverseButton} variant="outline" onClick={() => { setBlockUserModal(false) }}>{reverseButton}</Button>
            </Container>
        </Modal>
    );
};

export default BlockUserModal;
