import { FC } from 'react';
import { UnstyledButton } from '@mantine/core';
import { ChevronDown, ChevronUp } from 'tabler-icons-react';

import { useStyles } from '../styles';

interface ISortButton {
  text: string;
  active: boolean;
  direction: 'asc' | 'desc';
  onSort: () => void;
}

const SortButton: FC<ISortButton> = ({ text, active, direction, onSort }) => {
  const { classes, cx } = useStyles();

  const Icon = active ? (direction === 'asc' ? ChevronUp : ChevronDown) : ChevronDown;

  return (
    <UnstyledButton className={classes.sortControl} onClick={onSort}>
      {text}
      <Icon
        className={cx(classes.sortControlIcon, { [classes.sortControlIconActive]: active })}
        size={20}
      />
    </UnstyledButton>
  );
};

export default SortButton;
