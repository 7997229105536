import { FC } from "react";
import { ActionIcon, Group, Text } from "@mantine/core";

import { useStyles } from "./styles";

import { ReactComponent as Edit } from '@/assets/icons/Edit.svg'

interface Props {
    title?: string;
    hideAll?: boolean;
    hideEdit?: boolean;
    onClickEdit?: () => void;
}

const UserBoxTitle: FC<Props> = ({
    title,
    onClickEdit,
    hideAll = false,
    hideEdit = false
}) => {
    const { classes } = useStyles();
    return (
        <Text className={classes.SpecifyFieldsText}>
            <Group className={classes.SpecifyFieldsGroup}>
                { title }
                { !hideAll &&
                    <Group>
                        { !hideEdit &&
                            <ActionIcon variant="transparent" onClick={onClickEdit}>
                                <Edit />
                            </ActionIcon>
                        }
                    </Group>
                }
            </Group>
        </Text>
    )
}

export default UserBoxTitle;
