import { FC } from 'react';
import { Group } from '@mantine/core';

import { useStyles } from './styles';

import { ReactComponent as Logo } from '@/assets/icons/Logo.svg';

const LayoutHeaderLogo: FC = () => {
  const { classes } = useStyles();

  return (
    <Group className={classes.root} noWrap align="center" spacing={20}>
      <Logo className={classes.icon} width={125} height={48} />

      <div className={classes.text}>Система управления лицензиями</div>
    </Group>
  );
};

export default LayoutHeaderLogo;
