import {FC, useEffect, useRef, useState} from 'react';
import {FileInput} from '@mantine/core';
import {showNotification} from "@mantine/notifications";

import {productsApi} from "@/api";

import {countFilters} from "@/utils/helpers";

import ModalDigitalPack from "@/components/DigitalPackModal/ModalDigitalPack";

import PageHeader from '@/ui/PageHeader/PageHeader';
import Table from '@/ui/Table/Table';
import TableControls from '@/ui/TableControls/TableControls';

import {ActivationModals} from '../Packs/Modal/ActivationModals';

import { ReactComponent as Upload } from '@/assets/icons/Upload.svg';
import DigitalSetFilter from '@/pages/DigitalPacks/Filter/DigitalSetFilter';
import ModalUpload from "@/pages/DigitalPacks/Modal/ModalUpload";
import {useActions, useTypedSelector} from '@/store/hooks';
import {IProduct} from "@/store/products/types";

const DigitalPacks: FC = () => {
    const {
        getDigitalProducts,
        getDigitalProductsSearchSuggestions,
        setDigitalProductsPage,
        setDigitalProductsSort,
        setDigitalProductsSearchQuery,
    } = useActions();
    const {
        products,
        pagination,
        sort,
        filters,
        search,
        statusProducts,
        initialProductsSort
    } = useTypedSelector(
        (store) => store.digitalProducts
    );
    const [modalImportOpened, setModalImportOpened] = useState<boolean>(false);
    const [modalPackDetail, setModalPackDetail] = useState<boolean>(false);
    const disabledPagination = statusProducts.isProductsLoading || statusProducts.isProductsLoadingAssets || statusProducts.isProductsLoadingSearch;
    const [file, setFile] = useState<File | null>(null);
    const [digitalPackId, setDigitalPackId] = useState<number>(0);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLButtonElement>(null);
    const type = ['Комплект электронный'];

    function onClose() {
        setModalImportOpened(false);
        setFile(null);
    }

    const columns = [
        {id: 'externalCode', text: 'Номенклатура', width: 150, spoiler: true, style: {width: 150}},
        {id: 'name', text: 'Название', sortable: true, width: 400},
        {id: 'author', text: 'Автор', sortable: true, width: 450, spoiler: true, spoilerClickable: true, style: {width: 450}},
        {id: 'class', text: 'Класс', sortable: true, width: 150, style: {width: 150}},
        {id: 'service', text: 'Сервис', sortable: true, width: 312, style: {width: 312}},
    ];

    const handlePacksImport = async () => {
        setIsUploading(true);

        await productsApi
            .postDigitalProductsTemplate({
                template: file
            })
            .then(() => {
                showNotification({title: 'Успех', message: 'Электронные комплекты обновлены'});
                onClose();
            })
            .catch(() => {
                showNotification({
                        color: 'red',
                        title: 'Ошибка',
                        message: 'Не удалось обновить комплекты'
                    }
                )
            })
            .finally(() => {
                setIsUploading(false);
            })
        ;
    };

    useEffect(() => {
        if (!statusProducts.isProductsLoading &&
            !statusProducts.isProductsLoadingAssets &&
            !statusProducts.isProductsLoadingSearch
        ) {
            getDigitalProducts({
                page: pagination.currentPage,
                sort: sort?.field,
                direction: sort?.direction,
                query: search.query,
                type: type[0],
                ...Object.fromEntries(
                    Object.entries(filters).map(([key, value]) => [
                        key,
                        Array.isArray(value) ? value.join(',') : value,
                    ])
                ),
            });
        }
    }, [pagination.currentPage, sort?.field, sort?.direction, filters, search.query]);

    const handleRowClick = (row: Record<string, any> | IProduct): void => {
        setDigitalPackId(row.id);
        setModalPackDetail(true);
    }

    const extraProps: any = { placeholder: 'Выберите файл' };

    return (
        <>
            <ActivationModals/>
            <PageHeader
                title="Электронные комплекты"
                subTitle={pagination.totalItems}
                search={{
                    placeholder: 'Поиск по номенклатуре, названию',
                    loading: statusProducts.isProductsLoadingSearch,
                    data: search.suggestions,
                    query: search.query ?? search.previousQuery,
                    onChange: (query) => getDigitalProductsSearchSuggestions({query}),
                    onSubmit: setDigitalProductsSearchQuery,
                }}
                onImport={() => setModalImportOpened(true)}
                filter={<DigitalSetFilter />}
                filterCount={countFilters(filters)}
                disabledExportButton={!products?.length}
            />
            <Table
                columns={columns}
                data={products}
                onSortChange={(field, direction) => {
                    setDigitalProductsSort({field, direction});
                    setDigitalProductsPage(1);
                }}
                onRowClick={handleRowClick}
                expandControl="name"
                loading={statusProducts.isProductsLoading}
                defaultSortBy={initialProductsSort.sort}
                defaultSortDirection={initialProductsSort.direction}
            />
            <TableControls currentPage={pagination.currentPage}
                           totalPages={pagination.totalPages} onChangePage={setDigitalProductsPage}
                           disabledPagination={disabledPagination}/>
            <ModalUpload opened={modalImportOpened}
                          onClose={onClose}
                          loading={isUploading}
                          isDisableAcceptButton={!file}
                          title="Импорт списка"
                          text="Загруженный список перезапишет содержимое всех электронных комплектов"
                          accept="Импортировать"
                          onAccept={handlePacksImport}>
                <FileInput accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                           size="md"
                           icon={<Upload/>}
                           onChange={f => setFile(f)}
                           ref={fileInputRef}
                           mt={16}
                           pb={0 }
                           {...extraProps}
                />
            </ModalUpload>
            <ModalDigitalPack id={digitalPackId} show={modalPackDetail} setShow={setModalPackDetail}/>
        </>
    );
};

export default DigitalPacks;
