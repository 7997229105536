import { FC } from "react";
import {useNavigate} from "react-router-dom";
import { ActionIcon, Group, Text } from "@mantine/core";

import { useStyles } from "./styles";

import { ReactComponent as Eye } from '@/assets/icons/Eye.svg'
import { useTypedSelector } from "@/store/hooks";

interface Props {
    title: string;
    link?: string | null;
}

const BoxTitleLink: FC<Props> = ({
    title,
    link
}) => {
    const navigate = useNavigate();
    const { classes } = useStyles();
    const { isPacksDetailLoading } = useTypedSelector((store) => store.packs);

    return (
      <Text className={classes.SpecifyFieldsText}>
        <Group className={classes.SpecifyFieldsGroup}>
          { title }
          <Group>
            { link && <ActionIcon loading={isPacksDetailLoading} variant="transparent" onClick={() => navigate(link)}>
              <Eye />
            </ActionIcon> }
          </Group>
        </Group>
      </Text>
    )
}

export default BoxTitleLink;
