import {FC, useCallback, useEffect, useState} from 'react';
import {Button, Indicator} from '@mantine/core';
import moment from "moment/moment";

import {countFilters, formatDate, getStatusColor, getStatusLabel} from "@/utils/helpers";

import ModalConfirm from '@/ui/ModalConfirm/ModalConfirm';
import PageHeader from '@/ui/PageHeader/PageHeader';
import Table from '@/ui/Table/Table';
import TableControls from '@/ui/TableControls/TableControls';

import { ActivationModals } from '../Packs/Modal/ActivationModals';
import CreateLicensePack from '../Packs/Modal/CreateLicensePacks';

import LicenseFilter from './LicenseFilter/LicenseFilter';
import { checkFieldAccess } from './utils/checkFieldAccess';

import { ReactComponent as Infinity } from '@/assets/icons/Infinity.svg';
import CreateLicensePacksSchool from "@/pages/Packs/Modal/CreateLicensePacksSchool";
import { useActions, useTypedSelector } from '@/store/hooks';

const Licenses: FC = () => {
  const {
    getLicenses,
    getLicensesSearchSuggestions,
    setLicensesPage,
    setLicensesSort,
    getLicensesExport,
    setLicensesSearchQuery,
    setTriggerTableChange,
    setDistrLicenses,
    setUpdateLicenses,
    setLicensesExportLink
  } = useActions();

  const { user } = useTypedSelector((store) => store.auth);
  const { licenses, pagination, sort, filtersLicenses, updateLicenses, search, statusLoading, licensesExportLink, initialLicensesSort } = useTypedSelector((store) => store.licenses);
  const { distrLicensesPack, triggerTableChange } = useTypedSelector((store) => store.packs);
  const [modalExportOpened, setModalExportOpened] = useState<boolean>(false);
  const [modalLicensesPackOpened, setModalLicensesPackOpened] = useState<boolean>(false);
  const disabledPagination = statusLoading.loadingLicenses || statusLoading.loadingLicensesAssets || statusLoading.loadingLicensesSearch;

  const columns = [
    { id: 'externalCode', text: 'Номенклатура', width: 150, spoiler: true, style: {width: 150}},
    { id: 'type', text: 'Тип продукта', sortable: true, width: 150, spoiler: true, style: {width: 150} },
    { id: 'name', text: 'Название', sortable: true, width: 400 },
    { id: 'author', text: 'Автор', sortable: true, width: 222, spoiler: true, spoilerClickable: true, style: {width: 222} },
    { id: 'class', text: 'Класс', sortable: true, width: 99 },
    { id: 'expired', text: 'Дата окончания', sortable: true, width: 160 },
    { id: 'status', text: 'Статус', sortable: true, width: 104 },
    { id: 'total', text: 'Кол-во', sortable: true, width: 105 },
    { id: 'volume', text: 'Остаток', sortable: true, width: 113 },
    { id: 'service', text: 'Сервис', sortable: true, width: 212 },
    { id: 'activatedAt', text: 'Дата получения', sortable: true, width: 190 },
  ];

  const columnsByRole = columns
    .map((column) => ({
      ...column,
      visible: checkFieldAccess(column.id, user?.role.code),
    }))
    .filter((column) => column.visible);

  const handleLicensesExport = async () => {
    getLicensesExport({
      type: filtersLicenses.type,
      service: filtersLicenses.service,
      status: filtersLicenses.status,
      class: filtersLicenses.class,
      eduLevel: filtersLicenses.eduLevel,
    });
  }

  const onSelectLicense = (selection: number[]) => {
    if (!licenses) return;

    if (selection.length > distrLicensesPack.length) {
      const targetIds = selection.filter(sId =>
        !distrLicensesPack.find(license => license.id === sId)
      );
      setDistrLicenses([...(distrLicensesPack ?? []), ...licenses.filter(val => targetIds.includes(val.id))]);
    } else if (selection.length < distrLicensesPack.length) {
      const targetObjs = distrLicensesPack.filter(license =>
        !selection.find(sId => sId === license.id)
      );
      setDistrLicenses(distrLicensesPack.filter(val => !targetObjs.find(item => item.id === val.id)));
    }
  }

  const uncheckAll = () => {
    setDistrLicenses([]);
    setTriggerTableChange(!triggerTableChange);
  };

  const checkable = (id: number) => {
    const targetLicense = licenses?.find(val => val.id === id);

    const isExpired = targetLicense?.expired ? moment(targetLicense?.expired).unix() < moment().unix() : false;

    return targetLicense?.total && !targetLicense.volume || isExpired;
  }

  useEffect(() => {
    if (licensesExportLink) {
      const href = URL.createObjectURL(licensesExportLink);
      const link = document.createElement('a');
      let createDate = new Date().toLocaleString();
      link.href = href;
      link.setAttribute('download', `Список лицензий ${createDate}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setLicensesExportLink(null);
    }
  }, [licensesExportLink]);

  const onGetLicenses = useCallback(() => {
      getLicenses({
          page: pagination.currentPage,
          sort: sort?.field,
          direction: sort?.direction,
          query: search.query,
          ...Object.fromEntries(
              Object.entries(filtersLicenses).map(([key, value]) => [
                  key,
                  Array.isArray(value) ? value.join(',') : value,
              ])
          ),
      });

  }, [pagination.currentPage, sort?.field, sort?.direction, filtersLicenses, search.query])

  useEffect(() => {
      if (!statusLoading.loadingLicenses
          && !statusLoading.loadingLicensesAssets
          && !statusLoading.loadingLicensesSearch) {
          onGetLicenses()
      }

      return () => {
          setUpdateLicenses(false)
      }
  }, [pagination.currentPage, sort?.field, sort?.direction, filtersLicenses, search.query, updateLicenses]);

  const tableProduct = licenses?.map(({ expired, activated, activatedAt, total, volume, status, ...item }) => ({
    total: (
      Number(total) === 0 ? <Infinity mt={16} /> : total
    ),
    volume: (
      Number(total) === 0 ? <Infinity mt={16} /> : volume
    ),
    expired: (
       formatDate(expired)
    ),
    activatedAt: (
      formatDate(activatedAt)
    ),
    activated: (
      activated > 0 ? activated : 0
    ),
    status: (
      <Indicator color={getStatusColor(status)} position="middle-start" zIndex={0} size={8} px={10}>
        { getStatusLabel(status) }
      </Indicator>
    ),
    ...item,
  }));

  return (
    <>
      <ActivationModals />
      <PageHeader
        title="Каталог продуктов"
        subTitle={pagination.totalItems}
        search={{
          placeholder: 'Поиск по коду номенклатуры, названию, автору',
          loading: statusLoading.loadingLicensesSearch,
          data: search.suggestions,
          query: search.query ?? search.previousQuery,
          onChange: (query) => getLicensesSearchSuggestions({ query }),
          onSubmit: setLicensesSearchQuery,
        }}
        onExport={() => setModalExportOpened(true)}
        filter={<LicenseFilter />}
        filterCount={countFilters(filtersLicenses)}
        disabledExportButton={!tableProduct?.length}
        uncheckAll={uncheckAll}
        isCheck={distrLicensesPack.length}
      />

      <Table
        columns={columnsByRole}
        data={tableProduct}
        selectable
        onSelectionChange={(selection) => onSelectLicense(selection)}
        onSortChange={(field, direction) => {
          setLicensesSort({ field, direction });
          setLicensesPage(1);
        }}
        expandControl="name"
        loading={statusLoading.loadingLicenses}
        triggerTableChange={triggerTableChange}
        outerSelection={distrLicensesPack?.map(val => val.id) ?? []}
        checkable={checkable}
        defaultSortBy={initialLicensesSort.sort}
        defaultSortDirection={initialLicensesSort.direction}
      />

      <TableControls selectedCount={distrLicensesPack.length} currentPage={pagination.currentPage} totalPages={pagination.totalPages} onChangePage={setLicensesPage} disabledPagination={disabledPagination}>
        <Button onClick={() => setModalLicensesPackOpened(true)}>
          Выдать лицензии
        </Button>
      </TableControls>

      <ModalConfirm opened={modalExportOpened} onClose={() => setModalExportOpened(false)} title="Экспортировать список?" text="Выгрузка списка продуктов будет произведена с учётом настроенных фильтров" accept="Да, экспортировать" decline="Нет, отменить" onAccept={handleLicensesExport}/>

      { user?.role?.code !== 'ROLE_SCHOOL_ADMIN' ?
        <CreateLicensePack createLicensePack={modalLicensesPackOpened} setCreateLicensePack={setModalLicensesPackOpened} /> :
        <CreateLicensePacksSchool createLicensePack={modalLicensesPackOpened} setCreateLicensePack={setModalLicensesPackOpened} /> }
    </>
  );
};

export default Licenses;
