import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({

    inputEllipsisPlaceholder: {
        "::placeholder": {
            textOverflow: 'ellipsis',
        }
    },

    boxLayout: {
        display: 'flex',
        flexDirection: 'column',
        // flexGrow: 1,
        gap: '16px',
        width: 'auto'
    },

    boxDetailElement: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        border: '1px solid #D1D8E6',
        borderRadius: '16px',
        padding: '16px',
        margin: 'auto',
        width: '97%',
        // minWidth: 300,
        minHeight: 88
    },

    textElementTitle: {
        fontFamily: 'Golos',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '150%',
        color: '#5C5C70',
        marginTop: 0
    },

    textElementValue: {
        fontFamily: 'Golos',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '150%',
        color: '#000000'
    },







    SpecifyFieldsText: {
        fontFamily: 'Golos',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '150%',
        color: '#5C5C70',
        marginTop: 0
    },

    SpecifyFieldsGroup: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    ColumnBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        border: '1px solid #D1D8E6',
        borderRadius: '16px',
        padding: '16px',
        margin: 'auto',
        width: '100%',
        // minWidth: 300,
        minHeight: 88
    },

    Paper: {
        // display: 'flex',
        // flexDirection: 'row',
        // alignItems: 'flex-start',
        gap: '16px',
        display: 'grid',
        alignItems: 'flex-start',
        gridTemplateColumns: "1fr 1fr 1fr",

    },

    Box: {
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'flex-start',
        // flexGrow: 1,
        // flexShrink: 1,
        gap: '16px',
        width: 'auto'
    },
    TextTitle: {
        fontFamily: 'Golos',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '150%',
        color: '#5C5C70',
        marginTop: 0
    },

    Title: {
        fontFamily: 'Golos',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '120%',
        color: '#000000',
        marginTop: 0,
        minWidth: 290,
        marginLeft: 5,
        paddingTop: 4
    },

    BoxText: {
        fontFamily: 'Golos',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '150%',
        color: '#000000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

}));
