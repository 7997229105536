import { createStyles } from '@mantine/core';

import { COLORS } from '@/constants/mantine/colors';

export const useStyles = createStyles((theme) => ({
  root: {
    fontSize: theme.fontSizes.sm,
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    gap: 8,

    '& svg': {
      flexShrink: 0,
      color: COLORS.GRAY.icon,
    },
  },

  text: {
    display: 'flex',
    flexDirection: 'column',
  },

  note: {
    fontSize: theme.fontSizes.xs,
    color: COLORS.GRAY.text,
  },
}));
