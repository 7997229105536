import React, { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import {Badge, Button, Group, Paper, Title} from '@mantine/core';

import { IPageHeaderSearch } from '../PageHeader/PageHeaderSearch/PageHeaderSearch';
import { useStyles } from '../PageHeaderReports/styles';

import PageHeaderReportSearch, { IPageHeaderReportSearch } from './PageHeaderReportSearch/PageHeaderReportSearch';

import { ReactComponent as Filter } from '@/assets/icons/Filter.svg';
import {useTypedSelector} from "@/store/hooks";

interface IPageHeaderReports {
  title: string;
  subTitle?: string | number;
  backLink?: string;
  search?: IPageHeaderSearch;
  searchId?: IPageHeaderReportSearch;
  reportChoice?: ReactNode;
  filter?: ReactNode;
  rightButton?: ReactNode;
  inputValue: string;
  showFilter: boolean;
  setInputValue: Dispatch<SetStateAction<string>>;
  setShowFilter: Dispatch<SetStateAction<boolean>>;
  setInputLicenseSearch: Dispatch<SetStateAction<string>>;
}

const PageHeaderReports: FC<IPageHeaderReports> = ({
  title,
  backLink,
  searchId,
  inputValue,
  setInputValue,
  setShowFilter,
  showFilter,
  setInputLicenseSearch
}) => {
  const { classes } = useStyles();
  const {
    existLicense,
    filters
  } = useTypedSelector((store) => store.reports);
  const filterCount = Object.keys(filters).length;

  return (
    <Paper py={backLink ? 20 : 14} mb={16}>
      <Group position='apart' noWrap={true}>
        <Group className={classes.titleGroup} position='apart' noWrap={true}>
          <Title order={1}>{title}</Title>
        </Group>
        { searchId && <PageHeaderReportSearch {...searchId} inputValue={inputValue} setInputValue={setInputValue} setInputLicenseSearch={setInputLicenseSearch} /> }
        <Button px={13.2} variant="outline" onClick={() => setShowFilter(!showFilter)} disabled={!existLicense}>
          <Filter />  { filterCount > 0 && <Badge style={{position: 'absolute', right: '-1em', top: '-1em'}}>{ filterCount }</Badge> }
        </Button>
      </Group>
    </Paper>
  );
};

export default PageHeaderReports;
