import { AppPath,AppPathAccess } from '@/routes/routes-enum';

export const navigation = [
  {
    href: AppPath.products,
    title: 'Каталог',
    access: AppPathAccess.products,
  },
  {
    href: AppPath.licenses,
    title: 'Каталог',
    access: AppPathAccess.licenses,
  },
  {
    href: AppPath.packs,
    title: 'Комплекты лицензий',
    access: AppPathAccess.packs,
  },
  {
    href: AppPath.digitalSet,
    title: 'Электронные комплекты',
    access: AppPathAccess.digitalSet,
  },
  {
    href: AppPath.reports,
    title: 'Отчёты',
    access: AppPathAccess.reports,
  },
  {
    href: AppPath.users,
    title: 'Пользователи',
    access: AppPathAccess.users,
  },
];
