import { RoleCode } from '@/constants/common';

export const checkFieldAccess = (field: string, role: string | undefined) => {

  if (!role) return false;

  const amds = RoleCode.ADMIN || role === RoleCode.MANAGER || role === RoleCode.DISTRIBUTOR || role === RoleCode.SCHOOL_ADMIN;
  const ds = role === RoleCode.DISTRIBUTOR || role === RoleCode.SCHOOL_ADMIN;

  switch (field) {
    case 'externalCode':
      return 'externalCode';

    case 'nm':
      return amds;

    case 'id':
      return amds;

    case 'type':
      return amds;

    case 'name':
      return amds;

    case 'author':
      return amds;

    case 'class':
      return amds;

    case 'year':
      return amds;

    case 'expired':
      return ds;

    case 'status':
      return ds;

    case 'total':
      return amds;

    case 'volume':
      return ds;

    case 'activated':
      return ds;

    case 'service':
      return amds;

    case 'brand':
      return amds;

    case 'activatedAt':
      return ds;

    case 'filterType':
      return amds;

    case 'filterClass':
      return amds;

    case 'filterService':
      return amds;

    case 'filterExpired':
      return ds;

    case 'filterStatus':
      return ds;

    default:
      return false;
  }
};
