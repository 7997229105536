import { FC, useEffect } from "react";
import { ActionIcon, Box, Button, Modal, Stack, Text } from "@mantine/core";

import Table from "@/ui/Table/Table";

import { ReactComponent as Clear } from '@/assets/icons/Clear.svg';
import { useActions, useTypedSelector } from "@/store/hooks";

interface IDeactivatePacks {
    modalDeactivatePacks: boolean;
    setModalDeactivatePacks: React.Dispatch<React.SetStateAction<boolean>>
}
const DeactivatePacks: FC<IDeactivatePacks> = ({ modalDeactivatePacks, setModalDeactivatePacks }) => {

    const { setTriggerTableChange, setSelectedPacks, patchPackDeactivate, setMutatePacks } = useActions();
    const { triggerTableChange,
        selectedPacks,
        mutatePacks,
        isPackDeactivateLoading,
        isPackDeactivateSuccess,
    } = useTypedSelector(state => state.packs);

    const uncheckAll = () => {
        setSelectedPacks([]);
        setTriggerTableChange(!triggerTableChange);
    }

    const onClear = (id: number) => {
        setSelectedPacks(selectedPacks.filter(val => val.id !== id));
        setTriggerTableChange(!triggerTableChange);
    };

    const tablePacksDeactivate = selectedPacks?.map(({ ...item }) => ({
        deletePackRow: (
            <Text>
                <ActionIcon variant="transparent" onClick={() => { onClear(item.id) }}><Clear />
                </ActionIcon>
            </Text>
        ),
        ...item,
    }));

    const packsToDeactivate: string[] = [];
    selectedPacks.map((val) => packsToDeactivate.push(val.id.toString()))

    const columns = [
        { id: 'id', text: '', width: 110, style: { padding: 0 } },
        { id: 'name', text: '', width: 259, style: { padding: 0 } },
        { id: 'deletePackRow', text: '', width: 56, style: { padding: 0 } },
    ];

    useEffect(() => {
        if (isPackDeactivateLoading) return;

        if (isPackDeactivateSuccess) {
            if (!mutatePacks) {
                uncheckAll();
                setMutatePacks(true);
                setModalDeactivatePacks(false)
            }
        }
    }, [isPackDeactivateLoading]);

    useEffect(() => {
        if (!packsToDeactivate.length) setModalDeactivatePacks(false);
    }, [packsToDeactivate])

    return (
        <Modal opened={modalDeactivatePacks} onClose={() => setModalDeactivatePacks(false)} title={"Деактивировать комплекты?"}>
            <Text size="sm" ta="center">Деактивировав комплекты, вы сможете активировать их снова</Text>
            <Box h={243} mt={24} style={{overflow: 'auto', display: 'flex', flexShrink: 0, boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.05)'}}>
                <Table columns={columns} data={tablePacksDeactivate} />
            </Box>
            <Stack spacing={8} mt={32}>
                <Button loading={isPackDeactivateLoading} onClick={() => { patchPackDeactivate({ id: packsToDeactivate.toString() }) }}>Да, деактивировать</Button>
                <Button variant="outline" onClick={() => setModalDeactivatePacks(false)}>Нет, отменить</Button>
            </Stack>
        </Modal>
    );
};

export default DeactivatePacks;
