import {AuthorizeParams, PostAuthUrlParams} from '@/types';

import {createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';

import {authApi} from '@/api';
import {tryAction} from '@/api/tryAction';

export const postAuthUrl = createAsyncThunk('auth/postAuthUrl', async (arg: PostAuthUrlParams, {rejectWithValue}) => {
    const data = await tryAction(authApi.postAuthUrl(arg));

    return data || rejectWithValue(null);
});

export const getAuthUrl = createAsyncThunk('auth/getAuthUrl', async (arg, {rejectWithValue}) => {
    const data = await tryAction(authApi.getAuthUrl());

    return data || rejectWithValue(null);
});

export const authorize = createAsyncThunk(
    'auth/authorize',
    async (params: AuthorizeParams, {rejectWithValue}) => {
        try {
            const {data} = await authApi.authorize(params);
            return data || rejectWithValue(null);
        } catch (e) {
            const error = e as AxiosError<{ status: number, badOrganization: boolean }>;
            return rejectWithValue({
              status: error.response?.status,
              badOrganization: error.response?.data?.badOrganization
            });
        }
    }
);

export const logout = createAsyncThunk('auth/logout', async (arg, {rejectWithValue}) => {
    const data = await tryAction(authApi.logout());

    return data || rejectWithValue(null);
});
