import {FC, ReactNode} from 'react';
import { Button, Image, Text, Title } from '@mantine/core';

interface IAuthError {
  image: string;
  title: string;
  text: string | ReactNode;
  button?: string;
  buttonOnClick?: () => void;
}

const AuthError: FC<IAuthError> = ({ image, title, text, button, buttonOnClick }) => {
  return (
    <>
      <Image src={image} alt="" width={536} height={353} fit="contain" styles={{ image: { objectPosition: 'bottom' } }} />
      <Title order={2}>{ title }</Title>
      <Text align={'center'} mt={17} style={{maxWidth: 600}}>{ text }</Text>
      { button && <Button w={269} mt={33} onClick={buttonOnClick}>{ button }</Button> }
    </>
  );
};

export default AuthError;
