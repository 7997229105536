import {
    GetLicenseExistsReportParams,
    GetLicensesReportAssetsParams,
    GetLicensesReportExportParams,
    GetLicensesReportParams,
    GetLicensesReportSearchParams,
} from '@/types/api/reports';

import {createAsyncThunk} from '@reduxjs/toolkit';

import {reportsApi} from '@/api';
import {tryAction} from '@/api/tryAction';

import {rejectErrorHandler} from "@/utils/errorHandler";

export const getLicensesReportAssets = createAsyncThunk('report/getLicensesReportAssets', async (params: GetLicensesReportAssetsParams, {rejectWithValue}) => {
    try {
        const {data} = await reportsApi.getLicensesReportAssets(params);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getLicensesReport = createAsyncThunk('report/getLicensesReport', async (params: GetLicensesReportParams, {rejectWithValue}) => {
    try {
        const {data} = await reportsApi.getLicensesReport(params);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getLicensesReportSearch = createAsyncThunk(
    'report/getLicensesReportSearch',
    async (params: GetLicensesReportSearchParams, {rejectWithValue}) => {
        try {
            const {data} = await reportsApi.getLicensesReportSearch(params);

            return data || rejectWithValue(null);
        } catch (e) {
            return rejectErrorHandler(e, rejectWithValue)
        }
});

export const getLicensesReportExport = createAsyncThunk(
    'reports/getLicensesReportExport',
    async (params: GetLicensesReportExportParams, {rejectWithValue}) => {
        const data = await tryAction(reportsApi.getLicensesReportExport(params));

        return data || rejectWithValue(null);
});

export const getLicenseExists = createAsyncThunk(
    'report/getLicenseExists',
    async (params: GetLicenseExistsReportParams, {rejectWithValue}) => {
        try {
            const {data} = await reportsApi.getLicenseExists(params);

            return data || rejectWithValue(null);
        } catch (e) {
            return rejectErrorHandler(e, rejectWithValue)
        }
});
