import { FC, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import {Button, Center, Loader} from "@mantine/core";

import { AppPath } from "@/routes/routes-enum";

import AuthError from "./AuthError/AuthError";

import AuthorizationAccessError from '@/assets/images/AuthorizationAccessError.svg';
import AuthorizationError from '@/assets/images/AuthorizationError.svg';
import { logout } from "@/store/auth/actions";
import { useActions, useTypedSelector } from "@/store/hooks";

const NewUser: FC = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const pin = searchParams.get('pin');
    const signature = searchParams.get('signature');
    const authUrl = window.location.href;
    const { postAuthUrl, setUserLogout } = useActions();
    const { status } = useTypedSelector((store) => store.auth);
    const { isLinkPacksLoading, isLinkPacksError } = useTypedSelector((store) => store.packs);

    useEffect(() => {
        if (signature && !code && !pin) {
            postAuthUrl({ redirectLink: authUrl });
        }
    }, []);

    useEffect(() => {
        if (status.authInProcess) return;

        if (status.isAuthSuccess) {
            navigate(AppPath.licenses);
        }
    }, [status.authInProcess]);

    return (
        <Center py={100} sx={{ minHeight: '100vh', flexDirection: 'column' }}>
            {(status.authInProcess || status.loadingAuthUrl || isLinkPacksLoading) ? (
                <Loader size="lg" />
            ) : status.badOrganization ? (
              <AuthError image={AuthorizationAccessError} title="Получите доступ к лицензиям!" text={<>
                Вы авторизованы под учетной записью физического лица.<br/>
                Для доступа к системе управления лицензиями необходима учетная запись юридичсекого лица.<br/><br/>
                Создайте новую учетную запись, и мы ее активируем для вас!<br/>
                Свяжитесь с нами, чтобы мы могли помочь.
                <Button component="a" href="mailto:digital@prosv.ru?subject=Доступ к «Системе управления лицензиями»" w={269} mt={33}>Написать в поддержку</Button>
              </>}/>
            )  : status.isAccessError ? (
                <AuthError image={AuthorizationAccessError} title="Ошибка авторизации" text="Нет доступа. Пожалуйста, обратитесь к менеджеру" />
            ) : status.isAuthError && !status.isAuthSuccess ? (
                (
                    <AuthError image={AuthorizationError} title="Ошибка авторизации" text="При авторизации произошла ошибка. Попробуйте позднее" button="Вернуться назад"
                        buttonOnClick={() => {
                            logout()
                            setUserLogout()
                            localStorage.removeItem('userInfo')
                            navigate(AppPath.new)
                        }}
                    />
                )
            ) : (isLinkPacksError &&
                (
                    <AuthError image={AuthorizationError} title="Ошибка активации" text="Срок действия активационной ссылки истёк." button="Вернуться назад"
                        buttonOnClick={() => {
                            logout();
                            setUserLogout();
                            localStorage.removeItem('userInfo');
                            navigate(AppPath.new)
                        }}
                    />
                )
            )}
        </Center>
    );

}
export default NewUser
