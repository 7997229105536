import { FC, ReactNode } from 'react';
import { UnstyledButton, UnstyledButtonProps } from '@mantine/core';

import { useStyles } from './styles';

interface ILayoutHeaderButton extends UnstyledButtonProps {
  text?: string;
  note?: string;
  icon?: ReactNode;
  onClick?: () => void;
}

const LayoutHeaderButton: FC<ILayoutHeaderButton> = ({ text, note, icon, onClick}) => {
  const { classes } = useStyles();

  return (
    <UnstyledButton className={classes.root} title={text} onClick={onClick}>
      { icon }
      <span className={classes.text}>
        { text }
        { note && <span className={classes.note}>{note}</span> }
      </span>
    </UnstyledButton>
  );
};

export default LayoutHeaderButton;
