import { Dispatch, FC, SetStateAction } from "react";

import ModalConfirm from "@/ui/ModalConfirm/ModalConfirm";

import { useActions, useTypedSelector } from "@/store/hooks";

interface IExportLicensePacks {
    modalExportOpened: boolean;
    setModalExportOpened: Dispatch<SetStateAction<boolean>>;
}

const ExportPacksConsist: FC<IExportLicensePacks> = ({ modalExportOpened, setModalExportOpened}) => {
    const { getLicensesExport } = useActions();
    const { loadingStatus, packsDetailId } = useTypedSelector((store) => store.packs);

    const onAcceptExport = async () => {
        if (!loadingStatus.loading && packsDetailId) {
            getLicensesExport({
                setId: Number(packsDetailId),
            });
        }
    }

    return (
        <ModalConfirm opened={modalExportOpened} onClose={() => { setModalExportOpened(false); }} title="Экспортировать состав комплекта?" accept="Да, экспортировать" decline="Нет, отменить" onAccept={onAcceptExport} />
    );
};

export default ExportPacksConsist
