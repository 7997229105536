export const URLS = {
    auth: {
        postAuthUrl: '/login',
        getAuthUrl: '/login',
        oauth: '/oauth2',
        logout: '/logout',
    },

    feedback: {
        send: '/feedback'
    },

    products: {
        getProducts: '/products',
        getProductsAssets: '/products/filters',
        getProductsSearchSuggestions: '/products/search',
        getProductsExport: '/products/export',
        postDigitalProductsTemplate: '/products/template',
    },

    licenses: {
        getLicenses: '/licenses',
        getLicensesAssets: '/licenses/filters',
        getLicensesSearchSuggestions: '/licenses/search',
        getLicensesExport: '/licenses/export',
        getLicenseExists: '/licenses/exists',
    },

    users: {
        getUsers: '/users',
        getUsersAssets: '/users/filters',
        getUsersSearchSuggestions: '/users/search',
        getUserDetail: '/users/',
        getUsersRoles: '/users/roles',
        putUsers: '/users',
        patchChangeRoleParams: '/users/role/change',
        patchBlockUsersParams: '/users/block',
        patchUnblockUsersParams: '/users/unblock',
        getLegalEntities: '/users/entities',
        getChangeLegalEntity: '/users/entity/change',
        getCheckEmail: '/users/email/check',
    },

    packs: {
        getPacks: '/sets',
        getPacksAssets: '/sets/filters',
        getPacksSearchSuggestions: '/sets/search',
        getPacksDetail: '/set/',
        patchPackUpdate: '/set/',
        patchPackEdit: '/set/licenses/',
        getPacksRoles: '/set/roles',
        getPacksOrganizations: '/set/organizations',
        getPacksExport: '/sets/export',
        postTransferSetsParams: '/sets/transfer',
        getPacksExportSimple: '/sets/export-simple',
        postCheckUserEmail: '/set/email',
        postPacksLicenses: '/set/licenses',
        postPacksSchoolLicenses: '/set/school',
        postPacks: '/set',
        postTemplate: '/set/template',
        postLicensesTemplate: '/set/licenses/template',
        postLinkPacks: '/set/link',
        patchPackDeactivate: '/sets/deactivate',
        patchExpiredDate: '/licenses',

    },

    reports: {
        getReports: '/reports',
        getReportsAssets: '/reports/filters',
        getReportsSearchSuggestions: '/reports/search',
        getReportsExport: '/reports/export',
    },
};
