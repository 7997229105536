import { FC } from 'react';
import { Center, Loader } from '@mantine/core';

const LoaderFull: FC = () => {
  return (
    <Center h="100vh">
      <Loader size="lg" />
    </Center>
  );
};

export default LoaderFull;
