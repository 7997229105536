import { FC } from "react";

import MultiPacksFormed from "./MultiPacksFormed";
import PackFormed from "./PackFormed";

import MultiPacksWithMultiplierFormed from "@/pages/Packs/Modal/MultiPacksWithMultiplierFormed";
import { useTypedSelector } from "@/store/hooks";

export const ActivationModals: FC = () => {
    const { user } = useTypedSelector((store) => store.auth);
    const {packsLicensesActivation, packsActivation} = useTypedSelector((store) => store.packs);
    const roleIN = user?.role.code ? user?.role.code : '';
    const userRoleAM = roleIN === 'ROLE_ADMIN' || roleIN === 'ROLE_MANAGER'
    const userRoleDS = roleIN === 'ROLE_DISTRIBUTOR' || roleIN === 'ROLE_SCHOOL_ADMIN'

    return (
        <>
            { userRoleAM && !!packsActivation.length &&
                <>
                    <PackFormed
                        email={packsActivation.length ? packsActivation[0].email : ''}
                        activationLink={packsActivation.length ? packsActivation[0].activation_link : ''}
                        setId={packsActivation.length ? packsActivation[0].set_id : undefined}
                        pin={packsActivation.length ? packsActivation[0].pin : ''}
                    />
                    <MultiPacksFormed email={packsActivation.length ? packsActivation.map((e) => e.email).join(', ') : ''} />
                    <MultiPacksWithMultiplierFormed />
                </> }

            { userRoleDS && !!packsLicensesActivation.length &&
                <>
                    <PackFormed
                        email={packsLicensesActivation[0]?.email || ''}
                        activationLink={packsLicensesActivation[0]?.activation_link || ''}
                        setId={packsLicensesActivation[0]?.set_id || undefined}
                        pin={packsLicensesActivation[0]?.pin || ''}
                    />
                    <MultiPacksFormed email={packsLicensesActivation.length ? packsLicensesActivation.map((e) => e.email).join(', ') : ''} />
                    <MultiPacksWithMultiplierFormed />
                </> }
        </>
    )
}
