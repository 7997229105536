import { ApiError } from "@/types";

import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect, useRef,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    Checkbox,
    Container,
    FileButton,
    Group,
    Modal,
    NumberInput,
    Radio,
    Select,
    Text,
    Textarea,
    TextInput,
    Title,
} from "@mantine/core";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import { v4 as uuidv4 } from 'uuid';
import { read } from 'xlsx'

import {formatFormDate} from "@/utils/helpers";

import { COLORS } from "@/constants/mantine/colors";
import { AppPath } from "@/routes/routes-enum";

import Table, { ErrorMessages } from "@/ui/Table/Table";

import { ReactComponent as ErrorCircle } from '../../../assets/icons/ErrorCircle.svg';

import EmailValidator from "./EmailValidator";
import { useStyles } from './styles';

import { ReactComponent as Calendar } from '@/assets/icons/Calendar.svg';
import { ReactComponent as Clear } from '@/assets/icons/Clear.svg';
import { ReactComponent as InfinitySvg } from '@/assets/icons/Infinity.svg';
import {assetsValidity} from "@/pages/Packs/Modal/constans";
import { useActions, useTypedSelector } from "@/store/hooks";
import { ILicense } from "@/store/licenses/types";
import { ILicensesTemplate, Organizations } from "@/store/packs/types";
import { IProduct } from "@/store/products/types";

interface ICreateLicensePack {
    createLicensePack: boolean;
    setCreateLicensePack: Dispatch<SetStateAction<boolean>>;
}

export interface EmailItem {
    id: string;
    text: string;
    error: boolean;
    duplicate: boolean;
    responseError: ApiError | null;
    message: ReactNode;
    onBlur: boolean;
    organizations: Organizations[];
}

const CreateLicensePack: FC<ICreateLicensePack> = ({ createLicensePack, setCreateLicensePack }) => {
    const resetRef = useRef<() => void>(null);
    const {
        getPacksOrganizations,
        getPacksRoles,
        postTemplate,
        postLicensesTemplate,
        postPacks,
        postPacksLicenses,
        setLicenses,
        setTriggerTableChange,
        setDistrLicenses,
        setDisableCreateLicense,
        setShowPacksFormed,
        setShowMultiPacksFormed,
        resetPackLoading,
        setTemplateLicenses,
        setComplectName,
        addLicenseValues,
        delLicenseValues,
        setLicenseValue,
        setLicnseValues,
        setSelectedUsers,
        setLicenseFromUser,
        resetPacksTemplate,
        resetPacksLicensesTemplate,
        setPacksTemplateProducts,
        setPacksLicensesTemplate,
        setUpdateLicenses,
        setShowMultiPacksSchoolFormed
    } = useActions();

    const { user } = useTypedSelector((store) => store.auth);
    const { statusProducts, products } = useTypedSelector((store) => store.products);
    const { licenses, updateLicenses } = useTypedSelector((store) => store.licenses);

    const {
        error,
        isPacksLicensesError,
        packsRoles,
        packsOrganizations,
        licensesPack,
        distrLicensesPack,
        packsTemplate,
        packsLicensesTemplate,
        packsActivation,
        triggerTableChange,
        loadingStatus,
        disableCreateLicense,
        isPacksLoading,
        isPacksLicensesLoading,
        isPacksSuccess,
        isPacksLicensesSuccess,
        complectName,
        licensesValues,
        licenseFromUser,
        fetchLicenseValues,
    } = useTypedSelector((store) => store.packs);
    const { selectedUsers } = useTypedSelector((store) => store.users)
    const internalOrg = packsOrganizations.filter((result) => (!(result.external)));
    const internalOrganizations = internalOrg.map((result) => ({ value: result.id.toString(), label: result.name }) as { value: string; label: string });
    const defaultInternalOrg = internalOrganizations.filter((result) => (result.label.trim() === 'ОТ ПРО' || result.label.trim() === 'Образовательные технологии ПРО'));
    const externalOrg = packsOrganizations.filter((result) => (result.external));
    const externalOrganizations = externalOrg.map((result) => ({ value: result.id.toString(), label: result.name }) as { value: string; label: string });
    const navigate = useNavigate();
    const { classes } = useStyles();
    const [checkedNoLimit, setCheckedNoLimit] = useState<boolean>(false);
    const [multiUsers, setMultiUsers] = useState<boolean>(false);
    const [noEmail, setNoEmail] = useState<boolean>(false);
    const [templateFileError, setTemplateFileError] = useState<boolean>(false);
    const [templateLoad, setTemplateLoad] = useState<boolean>(false);
    const [issueLicense, setIssueLicense] = useState<boolean>(false);
    const [payMode, setPayMode] = useState('paid');
    const [outMode, setOutMode] = useState('manual');
    const [validity, setValidity] = useState<string | null>('12');
    const [roleId, setRoleId] = useState<string | null>('');
    const [jurId, setJurId] = useState<string | null>(null);
    const [dateFrom, setDateFrom] = useState<DateRangePickerValue>([new Date(), new Date((new Date()).setDate((new Date()).getDate() + 90))]);
    const [file, setFile] = useState<File | null>(null);
    const [comment, setComment] = useState('');
    const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
    const [errorTemplateMessages, setErrorTemplateMessages] = useState<ErrorMessages>([]);
    const commonErrorMessages = outMode === 'manual' ? errorMessages : errorTemplateMessages;

    const disableCreateLicenseButton = () => {
        if (noEmail) return false;
        return selectedUsers.length ? false : disableCreateLicense;
    };

    const setErrorMessage = (index: number, message: string) => {
        if (outMode === 'manual') {
            setErrorMessages((prevState) => {
                const newState = [...prevState];
                newState[index].message = message
                return [...newState];
            })
        } else {
            setErrorTemplateMessages((prevState) => {
                const newState = [...prevState];
                newState[index].message = message
                return [...newState];
            })
        }
    }

    const setAllErrorMessages = (arr: ErrorMessages) => {
        if (outMode === 'manual') setErrorMessages(() => arr)
        else setErrorTemplateMessages(() => arr)
    }

    const roles = packsRoles.map((result) => ({ value: result.code, label: result.name }) as { value: string; label: string }, [])
    const roleIN = user?.role?.code ?? '';
    const userRoleAMD = roleIN === 'ROLE_ADMIN' || roleIN === 'ROLE_MANAGER' || roleIN === 'ROLE_DISTRIBUTOR'
    const userRoleAM = roleIN === 'ROLE_ADMIN' || roleIN === 'ROLE_MANAGER'
    const userRoleD = roleIN === 'ROLE_DISTRIBUTOR'
    const userRoleM = roleIN === 'ROLE_MANAGER'
    const userRoleA = roleIN === 'ROLE_ADMIN'
    const chooseRoleDS = roleId === 'ROLE_DISTRIBUTOR' || roleId === 'ROLE_SCHOOL_ADMIN'
    const chooseRoleAMD = roleId === 'ROLE_ADMIN' || roleId === 'ROLE_MANAGER' || roleId === 'ROLE_DISTRIBUTOR'
    const chooseRoleAMSU = roleId === 'ROLE_ADMIN' || roleId === 'ROLE_MANAGER' || roleId === 'ROLE_SCHOOL_ADMIN' || roleId === 'ROLE_USER'
    const chooseRoleD = roleId === 'ROLE_DISTRIBUTOR'
    const chooseRoleSU = roleId === 'ROLE_SCHOOL_ADMIN' || roleId === 'ROLE_USER'
    const navigateFromUser = userRoleAM ? AppPath.products : AppPath.licenses;
    const [multiplier, setMultiplier] = useState<number>(1)

    const readExcelFile = (f: File) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(f);
        fileReader.onload = (e) => {
            setTemplateFileError(true);
            const bufferArray = e.target?.result;
            const wb = read(bufferArray, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const colsData = ws["!ref"];

            if (!colsData) {
                setTemplateFileError(true);
                return;
            }

            const columnNM = Object.entries(ws).filter((vol) => vol[0].startsWith('A'));
            const listNM = columnNM.map((vol) => Object.values(vol[1])[1]);
            const columnVolumeNM = Object.entries(ws).filter((vol) => vol[0].startsWith('B'));
            const listVolumeNM = columnVolumeNM.map((vol) => Object.values(vol[1])[1]);

            if (listNM.length > 0) {
                const templateValues = listNM.map((val, i) => ({ id: val as string, value: listVolumeNM[i] as number }));
                setLicnseValues({ licenses: templateValues.map(val => ({ id: val.id, value: val.value.toString() })), outMode });

                if (userRoleAM) {
                    setTemplateFileError(false)
                    setTemplateLoad(true)
                    postTemplate({ template: file })
                }

                if (userRoleD) {
                    setTemplateFileError(false)
                    setTemplateLoad(true)
                    postLicensesTemplate({ template: file })
                }
            } else {
              setTemplateFileError(true)
            }
        }

        setFile(null);
        resetRef.current?.();
    };

    const [emailList, setEmailList] = useState<EmailItem[]>([{ id: uuidv4(), text: '', error: false, duplicate: false, responseError: null, message: null, onBlur: false, organizations: [] }]);


    const emailToCreate: string[] = [];
    emailList.forEach((val) => { emailToCreate.push(val.text) });
    const emailArrayLength = emailToCreate.filter((el) => el !== '').length;

    let licensesPackData: IProduct[] | ILicense[] | ILicensesTemplate[];

    const onRemoveLicensePack = (id: number) => {
      if (userRoleAM && licensesPack.length) {
        setLicenses(licensesPack.filter(val => val.id !== id));
        setTriggerTableChange(!triggerTableChange);
      } else if (userRoleD && distrLicensesPack.length) {
        setDistrLicenses(distrLicensesPack.filter(val => val.id !== id));
      } else if (outMode === 'template') {
        if (userRoleAM) {
          setPacksTemplateProducts(packsTemplate.products.filter(val => val.id !== id));
        } else {
          setPacksLicensesTemplate(packsLicensesTemplate.licenses.filter(val => val.id !== id));
        }

        setTriggerTableChange(!triggerTableChange);
      }
    }

    if (outMode === 'template') {
        if (userRoleAM) {
            licensesPackData = packsTemplate.products;
        }
        else {
            licensesPackData = packsLicensesTemplate.licenses;
        }
    } else if (userRoleD) {
        licensesPackData = distrLicensesPack;
    } else {
        licensesPackData = licensesPack;
    }

    const commonLicenseValues = outMode === 'template' ? fetchLicenseValues : licensesValues;

    const onResetAllVolume = () => {
        setLicenses([]);
        setDistrLicenses([]);
        setTemplateLicenses([]);
        setTriggerTableChange(!triggerTableChange);
    }

    const checkErrors = () => {
        let isError = false;
        commonErrorMessages.forEach((val) => { isError = isError || !!val.message });

        return isError;
    }

    const onChangeInputValue = (itemId: number, volume: number, currentVal?: number) => {
        if (!document.getElementById(`${itemId}_inputNumberLicense`)) return;

        const errorIndex = commonErrorMessages.findIndex(mes => mes.id === itemId);
        const current = (document.getElementById(`${itemId}_inputNumberLicense`) as HTMLInputElement).value;

        if (volume < (emailArrayLength === 0 ? 1 : emailArrayLength) * Number(current)) {
            const message = `Указанное количество превышает ваше количество лицензий. Доступно лицензий: ${Math.floor(volume / (emailArrayLength === 0 ? 1 : emailArrayLength))}`;
            (document.getElementById(`${itemId}_inputNumberLicense`) as HTMLInputElement).value = (Math.floor(volume / (emailArrayLength === 0 ? 1 : emailArrayLength)) ?? 1).toString();
            setErrorMessage(errorIndex, message);

            const targetElIndex = commonLicenseValues.find(val => val.id === itemId.toString())?.id;

            if (targetElIndex) {
                setLicenseValue({ id: targetElIndex, value: (Math.floor(volume / (emailArrayLength === 0 ? 1 : emailArrayLength)) ?? 1).toString(), outMode });
            }
        } else {
            setErrorMessage(errorIndex, '');

            const targetElIndex = commonLicenseValues.find(val => val.id === itemId.toString())?.id;

            if (targetElIndex && currentVal) {
                setLicenseValue({
                  id: targetElIndex,
                  value: currentVal.toString(),
                  outMode
                })
            }
        }
    }

    const single = outMode !== 'template' && (licensesPackData.length === 1 || licensesPackData.length === 0);

    function getMax(total: number | string | undefined, volume: number | undefined) {
        if (!Number(total)) {
            return Infinity;
        } else {
            return Math.floor(volume as number / (emailArrayLength === 0 ? 1 : emailArrayLength)) ?? 1;
        }
    }

    const tableLicensesPack = licensesPackData?.map(({ total, volume, ...item }) => ({
        volume: (
            <Text>
                <Box className={classes.boxInput} >
                    { userRoleAM && ((checkedNoLimit || ((outMode === 'template') && !Number(volume))) ?
                        <InfinitySvg mt={16} /> :
                        <NumberInput
                            id={`${item.id}_inputNumberLicense`}
                            stepHoldDelay={500}
                            stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                            disabled={false}
                            placeholder='1'
                            defaultValue={commonLicenseValues.findIndex(val => val.id === item.id.toString()) !== -1 ? Number(commonLicenseValues.find(val => val.id === item.id.toString())?.value) : 1}
                            min={1}
                            onChange={(current) => {
                                const targetElIndex = commonLicenseValues.find(val => val.id === item.id.toString())?.id;

                                if (targetElIndex && current) {
                                    setLicenseValue({ id: targetElIndex, value: current.toString(), outMode })
                                }
                            }}
                        />
                    )}
                    { userRoleD && ((checkedNoLimit && !Number(total)) || ((outMode === 'template') && !Number(volume)) ?
                        <InfinitySvg mt={16} /> :
                        <NumberInput
                            id={`${item.id}_inputNumberLicense`}
                            stepHoldDelay={500}
                            stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                            placeholder='1'
                            defaultValue={commonLicenseValues.findIndex(val => val.id === item.id.toString()) !== -1 ? Number(commonLicenseValues.find(val => val.id === item.id.toString())?.value) : 1}
                            min={1}
                            max={getMax(total, volume)}
                            onChange={(current) => {
                                if (Number(total)) {
                                    onChangeInputValue(item.id, volume as number, current);
                                } else {
                                    const targetElIndex = commonLicenseValues.find(val => val.id === item.id.toString())?.id;
                                    if (targetElIndex && current) {
                                        setLicenseValue({ id: targetElIndex, value: current.toString(), outMode })
                                    }
                                }
                            }}
                        />
                    )}
                    <ActionIcon ml={10} variant="transparent" onClick={() => onRemoveLicensePack(item.id)}>
                        <Clear />
                    </ActionIcon>
                </Box>
            </Text>
        ),
        ...item,
    }));

    const columns = [
        { id: 'externalCode', text: 'Номенклатура', width: 103 },
        { id: 'name', text: 'Название', width: 280 },
        { id: 'author', text: 'Автор', width: 146 },
        { id: 'volume', text: 'Кол-во', visible: true, width: 90 },
    ];

    const clearState = () => {
        setEmailList(() => [{ id: uuidv4(), text: '', error: false, duplicate: false, responseError: null, onBlur: false, organizations: [], message: '' }]);
        setPayMode('paid');
        setOutMode('manual');
        setMultiUsers(false);
        setNoEmail(false);
        setRoleId('');
        setComplectName('');
    };

    const getFormattedDate = (date: Date | null) => {
        if (userRoleAMD && chooseRoleSU && date) {
            return (userRoleAMD && chooseRoleD) ? formatFormDate(new Date()) : formatFormDate(date);
        } else {
            return (userRoleAMD && chooseRoleD) ? formatFormDate(new Date()) : null;
        }
    }

    const startDate = getFormattedDate(dateFrom?.[0]);
    const finishDate = getFormattedDate(dateFrom?.[1]);

    useEffect(() => {
        if (licensesPackData && userRoleD) {
            licensesPackData.forEach(val => Number(val.total) && onChangeInputValue(val.id, val.volume as number));
        }

        if (licensesPackData && outMode === 'template') {
            licensesPackData.forEach(val => {
                const targetEl = commonLicenseValues.find(lic => lic.id === val.id.toString())

                if (targetEl && (document.getElementById(`${val.id}_inputNumberLicense`))) {
                    (document.getElementById(`${val.id}_inputNumberLicense`) as HTMLInputElement).value = targetEl.value
                }
            });
        }

        if (licensesPackData.length > commonLicenseValues.length) {
            if (!commonLicenseValues.length) {
                licensesPackData.forEach(val =>
                    addLicenseValues({ id: val.id.toString(), value: '1', outMode })
                );

                return;
            }

            const targetEl = (licensesPackData as ILicense[]).find(val => !commonLicenseValues.find(lVal => lVal.id === val.id.toString()))

            if (targetEl) {
                addLicenseValues({ id: targetEl.id.toString(), value: '1', outMode });
            }
        } else if (licensesPackData.length < commonLicenseValues.length) {
            if (!licensesPackData.length) {
                setLicnseValues({ licenses: [], outMode });
                return;
            }

            const targetElIndex = commonLicenseValues.find(val => !(licensesPackData as ILicense[]).find(lVal => lVal.id.toString() === val.id))?.id

            if (targetElIndex) {
                delLicenseValues({ id: targetElIndex, outMode });
            }
        }
    }, [licensesPackData, emailArrayLength])

    useEffect(() => {
      if (issueLicense && roleId && validity) {
        if (userRoleAM) {
          if (!products) return;

          const choicesLicense: { product_id: number; volume: number; }[] = [];

          licensesPackData.forEach(val => {
            let numberVal: number;
            if (checkedNoLimit || ((outMode === 'template') && !Number(val.volume))) {
              numberVal = 0;
            } else {
              numberVal = Number((document.getElementById(`${val.id}_inputNumberLicense`) as HTMLInputElement).value);
            }
            choicesLicense.push({product_id: val.id, volume: Number(numberVal)});
            resetPacksTemplate();
            resetPacksLicensesTemplate();
          });

          postPacks({
              name: complectName,
              organizationId: Number(jurId),
              comment: comment,
              role: roleId,
              period: Number(validity),
              startDate: startDate ?? '',
              finishDate: finishDate ?? '',
              emails: emailToCreate[0] === '' ? [] : emailToCreate,
              licenses: choicesLicense,
              payable: payMode === 'paid',
              multiplier
          });
        }

        if (userRoleD) {
          if (!licenses) return;

          let choicesLicense: { license_id: number; volume: number; }[] = [];
          if (outMode === 'template') {
            (licensesPackData as ILicensesTemplate[]).forEach(val => {
              let numberVal: number;
              if ((checkedNoLimit && !(Number(val.total)) || ((outMode === 'template') && !Number(val.volume)))) {
                numberVal = 0;
              } else {
                numberVal = Number((document.getElementById(val.id + "_inputNumberLicense") as HTMLInputElement).value);
              }
              choicesLicense.push({license_id: Number(val.id), volume: Number(numberVal)});
              resetPacksTemplate();
              resetPacksLicensesTemplate();
            });
          } else {
            (licensesPackData as ILicense[]).forEach(val => {
              let numberVal: number;
              if ((checkedNoLimit && !(Number(val.total)) || ((outMode === 'template') && !Number(val.volume)))) {
                numberVal = 0;
              } else {
                numberVal = Number((document.getElementById(`${val.id}_inputNumberLicense`) as HTMLInputElement).value);
              }
              choicesLicense.push({license_id: Number(val.id), volume: Number(numberVal)});
            });
          }

          postPacksLicenses({
            name: complectName,
            organizationId: Number(jurId),
            comment: comment,
            role: roleId,
            period: Number(validity),
            startDate: startDate ?? '',
            finishDate: finishDate ?? '',
            emails: emailToCreate[0] === '' ? [] : emailToCreate,
            licenses: choicesLicense,
            payable: payMode === 'paid',
          });
        }
      }

        setIssueLicense(false);
    }, [issueLicense]);

    useEffect(() => {
        if (file) {
            readExcelFile(file);
        }
    }, [file]);

    useEffect(() => {
        if (isPacksLoading) return;
        if (isPacksSuccess) {
            if (noEmail && multiplier > 1) {
                setShowMultiPacksSchoolFormed(true);
            } else if (multiUsers) {
              setShowMultiPacksFormed(true)
            } else {
              setShowPacksFormed(true)
            }
            setDisableCreateLicense(true);
            setCreateLicensePack(false);
            resetPackLoading();
            setOutMode('manual');
            clearState();
            onResetAllVolume();
        }
    }, [isPacksLoading]);

    useEffect(() => {
        if (isPacksLicensesLoading) return;

        if (isPacksLicensesSuccess) {
            if (noEmail && multiplier > 1) {
                setShowMultiPacksSchoolFormed(true);
            } else if (multiUsers) {
              setShowMultiPacksFormed(true);
            }  else {
              setShowPacksFormed(true);
            }

            setDisableCreateLicense(true);
            setCreateLicensePack(false);
            resetPackLoading();
            setOutMode('manual');
            clearState();
            onResetAllVolume();

            if (!updateLicenses) {
                setUpdateLicenses(true)
            }
        }

        return () => {
            setUpdateLicenses(false)
        }
    }, [isPacksLicensesLoading]);

    useEffect(() => {
        if (createLicensePack) {
            if (packsOrganizations.length === 0) {
              getPacksOrganizations();
            }

            if (packsRoles.length === 0) {
              getPacksRoles();
            }
        }
    }, [createLicensePack]);

    useEffect(() => {
        setAllErrorMessages(licensesPackData.map(val => ({ id: val.id, message: "" })))
    }, [licensesPackData])

    useEffect(() => {
        setEmailList((prevState) => {
            let newState = prevState.filter(val => val.text && !val.error && !val.responseError).filter(val => !val.id.includes('emailUsers'));
            newState = [...newState, ...selectedUsers.map(val =>
                ({ id: `${val.id}_emailUsers`, text: val.email ?? "", error: false, duplicate: false, responseError: null, message: null, onBlur: false, organizations: val.organization ?? [] })
            )]

            return newState;
        })

        if (selectedUsers.length) {
            setRoleId(() => selectedUsers[0].role ?? "")
        }

        if (selectedUsers.length > 1) {
            setMultiUsers(() => true);
        }
    }, [selectedUsers]);

    useEffect(() => {
        if (loadingStatus.loading || jurId !== null) return;

        setJurId(() => packsOrganizations?.[0]?.id.toString() ?? null);
    }, [packsOrganizations]);

    useEffect(() => {
        setShowPacksFormed(false);
        setShowMultiPacksFormed(false);
        if (selectedUsers.length) {
            setRoleId(() => selectedUsers[0].role ?? "");
            setEmailList((prevState) => {
                let newState = prevState.filter(val => val.text && !val.error && !val.responseError).filter(val => !val.id.includes('emailUsers'));
                newState = [...newState, ...selectedUsers.map(val =>
                    ({ id: val.id + '_emailUsers', text: val.email ?? "", error: false, duplicate: false, responseError: null, message: null, onBlur: false, organizations: val.organization ?? [] })
                )]
                return newState;
            });
        }
        if (selectedUsers.length > 1) {
            setMultiUsers(() => true);
        }
    }, []);

    function handleRoleChange(e: string | null) {
        clearState();

        if (outMode === 'template') {
            setOutMode('template')
        } else {
            setOutMode('manual');
        }

        setRoleId(e);
        setNoEmail(false);
        setMultiplier(1);

        if (e === 'ROLE_SCHOOL_ADMIN' || e === 'ROLE_USER') {
            setCheckedNoLimit(false)
        }
    }

    function handleNoEmailChange(value: boolean) {
        setNoEmail(value);

        if (!value) {
            setMultiplier(1);
        }
    }

    return (
        <Modal size={934} opened={createLicensePack} onClose={() => {
                setCreateLicensePack(false)
            }}>
            <Title className={classes.textTitleBlockModal} ta={'center'}>{ single ? 'Выдача лицензии' : 'Создание комплекта лицензий' }</Title>
            <Container className={classes.containerRole} mt={31} p={0}>
                {userRoleAMD &&
                    (
                        userRoleAM ?
                            <Select
                                label='Юридическое лицо продавца'
                                readOnly={userRoleM && (internalOrganizations.length === 1)}
                                required
                                w={398}
                                styles={{
                                    input: {
                                        textOverflow: 'ellipsis',
                                    }
                                }}
                                placeholder={defaultInternalOrg?.[0]?.label}
                                value={jurId}
                                defaultValue={defaultInternalOrg?.[0]?.value ?? ''}
                                data={internalOrganizations}
                                onChange={(val) => setJurId(val)}
                            />
                            : userRoleD && <Select
                                    label='Организация'
                                    readOnly={(externalOrganizations.length === 1)}
                                    required
                                    w={398}
                                    styles={{
                                        input: {
                                            textOverflow: 'ellipsis',
                                        }
                                    }}
                                    placeholder={externalOrganizations ? externalOrganizations[0]?.label : ''}
                                    value={jurId}
                                    defaultValue={externalOrganizations ? externalOrganizations[0]?.value : ''}
                                    data={externalOrganizations}
                                    onChange={(val) => setJurId(val)}
                                />
                    )
                }

                <Group className={classes.selectRole}>
                    <Select
                        label='Роль'
                        required
                        readOnly={!!selectedUsers.length && licenseFromUser}
                        w={398}
                        placeholder={'Выберите роль'}
                        value={roleId}
                        data={roles}
                        onDropdownOpen={() => roles.length == 0 && getPacksRoles()}
                        onChange={handleRoleChange}
                    />
                    { userRoleAMD && chooseRoleDS && <Checkbox disabled={!!selectedUsers.length && licenseFromUser} label='Назначить на нескольких пользователей' checked={multiUsers} onChange={(event) => setMultiUsers(event.currentTarget.checked)}/> }
                </Group>
            </Container>

          { roleId && <EmailValidator single={single} multiUsers={multiUsers} roleId={roleId || ''} chooseRoleAMD={chooseRoleAMD} chooseRoleAMSU={chooseRoleAMSU} emailList={emailList} setEmailList={setEmailList} noEmail={noEmail} setNoEmail={handleNoEmailChange} /> }

            { chooseRoleSU && <Container mt={48} p={0}>
                <DateRangePicker
                    locale="ru"
                    amountOfMonths={3}
                    required
                    w={398}
                    rightSectionWidth={30}
                    clearable={true}
                    label={single ? 'Срок активации лицензии' : 'Срок активации комплекта'}
                    initialLevel='date'
                    rightSection={<Calendar color="GRAY" size={18} />}
                    placeholder={'Выбрать даты'}
                    minDate={new Date()}
                    maxDate={new Date((new Date()).setDate((new Date()).getDate() + 90))}
                    defaultValue={[new Date(), new Date((new Date()).setDate((new Date()).getDate() + 90))]}
                    value={dateFrom}
                    onChange={setDateFrom}
                />
            </Container> }

            <Container mt={48} p={0}>
                <Group mt={16} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Group className={classes.groupPackName}>
                        <TextInput
                            disabled={!roleId}
                            label={single ? 'Название лицензии' : 'Название комплекта'}
                            required
                            w={577}
                            placeholder={single ? 'Введите название лицензии' : 'Введите название комплекта'}
                            styles={{
                                input: {
                                    textOverflow: 'ellipsis',
                                }
                            }}
                            value={complectName}
                            onChange={(event) => setComplectName(event.currentTarget.value)}
                            onBlur={() => setComplectName(complectName.trim())}
                        />
                    </Group>
                    { !userRoleD && <Group className={classes.groupValidity}>
                        <Select disabled={!roleId} w={221} placeholder={'1 год'} label='Срок действия' required value={validity} defaultValue={assetsValidity[11].label} data={assetsValidity} onChange={setValidity} />
                    </Group> }
                </Group>
            </Container>

            {((userRoleAM && chooseRoleD) || (userRoleA && chooseRoleSU)) &&
                <Container mt={48} p={0}>
                    <Text className={classes.textBlockTitle}>
                        {single ? 'Тип лицензии' : 'Тип комплекта'}{<span style={{ fontSize: 16, color: COLORS.RED.text }} >*</span>}
                    </Text>
                    <Radio.Group mt={16} value={payMode} withAsterisk onChange={setPayMode}>
                        <Group className={classes.groupRadio}>
                            {<Radio disabled={!roleId} value="paid" label={single ? 'Платная' : 'Платный'} />}
                            {<Radio disabled={!roleId} value="free" label={single ? 'Бесплатная' : 'Бесплатный'} />}
                        </Group>
                    </Radio.Group>
                </Container>}

            {((userRoleAM && chooseRoleD) || (userRoleAM && chooseRoleSU) || userRoleD) &&
                <Container mt={48} p={0} >
                    <Text className={classes.textBlockTitle}>
                        Способ выдачи{<span style={{ fontSize: 16, color: COLORS.RED.text }} >*</span>}
                    </Text>
                    <Radio.Group mt={16} value={outMode} onChange={setOutMode} withAsterisk >
                        <Group className={classes.groupRadio}>
                            <Radio disabled={!roleId} value="manual" label="Вручную" />
                            <Radio disabled={!roleId} value="template" label="По шаблону" />
                        </Group>
                    </Radio.Group>
                </Container>}

            <Container mt={48} p={0}>
                <Text className={classes.textBlockTitle}>
                    { single ? 'Состав лицензии' : 'Состав комплекта' }
                    { <span style={{ fontSize: 16, color: COLORS.RED.text }} >*</span> }
                    { (emailArrayLength && emailArrayLength !== 1) ? <span style={{ fontSize: 16, color: COLORS.GRAY.text, marginLeft: 8 }} >x {emailArrayLength}</span> : '' }
                </Text>

                { !(userRoleA && chooseRoleSU || userRoleD || userRoleM && chooseRoleSU) &&
                    <Checkbox
                        disabled={!roleId || (outMode === 'template') || (outMode === 'pricelist')}
                        label='Неограниченное количество лицензий'
                        mt={16}
                        checked={checkedNoLimit}
                        onChange={(event) => setCheckedNoLimit(event.currentTarget.checked)}
                    />
                }
            </Container>

            <Container mt={24} p={0}>
                { (userRoleA && chooseRoleSU && noEmail) && <Group style={{fontSize: 14, fontWeight: 400, lineHeight: '135%'}} mb={16}>
                    <span className={classes.selectedTitle}>Количество комплектов:</span>
                    <NumberInput
                        stepHoldDelay={500}
                        stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                        placeholder={'1'}
                        defaultValue={1}
                        value={multiplier}
                        min={1}
                        max={500}
                        onChange={value => setMultiplier(value ?? 1)}
                    />
                </Group>}

                {
                    ((outMode !== 'template' && licensesPackData.length) || licensesPackData.length) ?
                        <>
                            <Box>
                                <Group style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Group style={{ fontSize: 14, fontWeight: 400, lineHeight: '135%' }}>
                                        <span className={classes.selectedTitle}>Выбрано:</span>
                                        {((outMode !== 'template' && licensesPackData.length)
                                            || licensesPackData.length) ? licensesPackData.length : null}
                                    </Group>
                                    {(roleId && (outMode !== 'template')) && <Anchor className={classes.anchor} onClick={() => {
                                        onResetAllVolume();
                                        if (licensesPackData.length || ((outMode === 'template') && !licensesPackData.length)) return;
                                        setCreateLicensePack(false);
                                    }}>Удалить всё</Anchor>}
                                </Group>
                            </Box>

                            <Box h={398} mt={16} className={classes.boxTable}>
                                <Table
                                    key={outMode}
                                    columns={columns.filter((column) => column.visible !== false)}
                                    data={tableLicensesPack}
                                    loading={statusProducts.isProductsLoading}
                                    errorMessages={commonErrorMessages}
                                />
                            </Box>
                        </>
                        : null
                }

                {(outMode === 'template') &&
                    <Text color={COLORS.RED.text} size={12} mt={8}>
                        { (!!packsTemplate.message || !!packsLicensesTemplate.message) &&
                            <span>
                                <ErrorCircle style={{ marginRight: 4 }} />{ packsTemplate.message || packsLicensesTemplate.message || ''}
                            </span>
                        }
                    </Text> }
            </Container>

            <Container p={0}>
                { outMode !== 'template' ? outMode !== 'pricelist' &&
                    <Button
                        disabled={!roleId}
                        w={398}
                        className={classes.modalButton}
                        onClick={() => {
                            navigate(navigateFromUser);
                            setTemplateLoad(false);
                            setCreateLicensePack(false)
                        }}
                    >Добавить продукт
                    </Button>
                    :
                    <>
                        <FileButton
                            onChange={(f: File) => {
                                resetPacksTemplate();
                                resetPacksLicensesTemplate();
                                setFile(f);
                            }}
                            resetRef={resetRef}
                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        >
                            {(props) =>
                                <Button variant={templateLoad ? 'default' : 'filled'} w={398} className={classes.modalButton} {...props}>
                                    { templateLoad ? 'Загрузить новый шаблон' : 'Загрузить шаблон' }
                                </Button>
                            }
                        </FileButton>
                        {
                            templateFileError &&
                            <Text color={COLORS.RED.text} size={12} mt={16} w={398}>
                                <span><ErrorCircle style={{ marginRight: 4 }} />
                                    Не удалось прочитать файл. Пожалуйста, убедитесь, что файл имеет верную структуру.
                                </span>
                            </Text>
                        }
                    </>
                }
                <Textarea
                    disabled={!roleId}
                    mt={48}
                    minRows={6}
                    placeholder='Комментарий...'
                    label='Комментарий'
                    w={'auto'}
                    value={comment}
                    onChange={(event) => setComment(event.currentTarget.value)}
                />

              { (error?.response?.data?.error && isPacksLicensesError) && <Text color={COLORS.RED.text} size={12} mt={8}>
                <span><ErrorCircle style={{marginRight: 4}}/>{ error.response.data.error }</span>
              </Text> }
            </Container>

            <Container className={classes.modalButtonContainer}>
                <Button
                    disabled={
                        !roleId
                        || (!licensesPackData.length && !packsActivation.length)
                        || !complectName
                        || disableCreateLicenseButton()
                        || checkErrors()
                        || isPacksLicensesLoading
                        || isPacksLoading
                    }
                    w={398}
                    className={classes.modalButton}
                    onClick={() => { setIssueLicense(true) }}
                >{single ? 'Выдать лицензию' : 'Создать комплект'}
                </Button>
                <Button
                    w={398}
                    className={classes.modalButton}
                    c={COLORS.RED.text}
                    variant="outline"
                    onClick={() => {
                        resetPacksTemplate();
                        resetPacksLicensesTemplate();
                        clearState();
                        setOutMode('manual');
                        setLicenseFromUser(false);
                        setSelectedUsers([]);
                        onResetAllVolume();
                        setCreateLicensePack(false);
                    }}
                >Отменить
                </Button>
            </Container>
        </Modal>
    );
};

export default CreateLicensePack;
