import {FC, useEffect} from 'react';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';

import {RoleCode} from '@/constants/common';

import Layout from '@/ui/Layout/Layout';
import LoaderFull from '@/ui/LoaderFull/LoaderFull';

import {routes} from './routes';
import {AppPath} from './routes-enum';

import Error404 from "@/pages/Errors/Error404";
import {useActions, useTypedSelector} from '@/store/hooks';

const ProtectedRoute: FC = () => {
    const {getAuthUrl} = useActions();

    const {
        status: {isAuthSuccess},
    } = useTypedSelector((store) => store.auth);

    useEffect(() => {
        if (isAuthSuccess === false) {
            getAuthUrl()
        }
    }, [isAuthSuccess]);

    return isAuthSuccess ? <Outlet/> : <LoaderFull/>;
};

const AppRouter: FC = () => {
    const {user} = useTypedSelector((store) => store.auth);
    let indexUrl: string;

    switch (user?.role?.code) {
        case RoleCode.ADMIN :
        case RoleCode.MANAGER :
            indexUrl = AppPath.products;
            break
        case RoleCode.DISTRIBUTOR :
        case RoleCode.SCHOOL_ADMIN :
            indexUrl = AppPath.licenses;
            break
        case RoleCode.USER :
            indexUrl = AppPath.activation;
            break
        default :
            indexUrl = AppPath.access;
    }

    return (
        <Routes>
            { routes
                .filter(route => !route.protected)
                .map(route => <Route key={route.path} path={route.path} element={route.component}/>)
            }

            <Route element={<ProtectedRoute/>}>
                <Route element={<Layout/>}>
                    { routes
                        .filter(route => route.protected)
                        .map(route => {
                            if (!!route.access && (!user?.role.code || !route.access.includes(user.role.code))) return null;
                            return <Route key={route.path} path={route.path} element={route.component} />;
                        })}
                    <Route index element={<Navigate to={indexUrl} replace/>} />
                    <Route path="*" element={<Error404 />} />
                </Route>
            </Route>
        </Routes>
    );
};

export default AppRouter;
