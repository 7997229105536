import { RoleCode } from '@/constants/common';

export const AppPath = Object.freeze({
  oauth: '/oauth2',
  catalog: '/catalog',
  products: '/products',
  digitalSet: '/digital-set',
  licenses: '/licenses',
  packs: '/set',
  packsDetail: '/set/:id',
  certificates: '/certificates',
  reports: '/reports',
  users: '/users',
  usersDetail: '/users/:id',
  new: '/new-user',
  activation: '/pack-activation-end',
  interim: '/pack-activation-interim',
  access: '/access-denied'
});

export const AppPathAccess = {
  products: [RoleCode.ADMIN, RoleCode.MANAGER],
  digitalSet: [RoleCode.ADMIN, RoleCode.MANAGER],
  licenses: [RoleCode.DISTRIBUTOR, RoleCode.SCHOOL_ADMIN],
  packs: [RoleCode.ADMIN, RoleCode.MANAGER, RoleCode.DISTRIBUTOR, RoleCode.SCHOOL_ADMIN],
  certificates: [RoleCode.ADMIN, RoleCode.MANAGER, RoleCode.CERT_MANAGER],
  reports: [RoleCode.ADMIN, RoleCode.MANAGER],
  users: [RoleCode.ADMIN, RoleCode.MANAGER, RoleCode.DISTRIBUTOR],
  new: [RoleCode.ADMIN, RoleCode.MANAGER, RoleCode.DISTRIBUTOR, RoleCode.SCHOOL_ADMIN, RoleCode.CERT_MANAGER, RoleCode.USER],
  activation: [RoleCode.ADMIN, RoleCode.MANAGER, RoleCode.DISTRIBUTOR, RoleCode.SCHOOL_ADMIN],
  interim: [RoleCode.ADMIN, RoleCode.MANAGER, RoleCode.DISTRIBUTOR, RoleCode.SCHOOL_ADMIN],
  access: [RoleCode.ADMIN, RoleCode.MANAGER, RoleCode.DISTRIBUTOR, RoleCode.SCHOOL_ADMIN],
};
