import {FC, useEffect, useState} from "react";
import {Button, Group, Select} from "@mantine/core";
import {DateRangePicker, DateRangePickerValue} from "@mantine/dates";

import {formatFormDate} from "@/utils/helpers";

import MultiSelect from '@/ui/MultiSelect/MultiSelect';

import {ReactComponent as Reset} from '@/assets/icons/Reset.svg';
import {useActions, useTypedSelector} from "@/store/hooks";

const UsersFilters: FC = () => {
    const { getUsersAssets, setUsersFilters } = useActions();
    const { assets, filters } = useTypedSelector((store) => store.users);
    const existExpireBegin = filters.createdAt?.[0] !== undefined ? new Date(filters.createdAt[0]) : null;
    const existExpireEnd = filters.createdAt?.[1] !== undefined ? new Date(filters.createdAt[1]) : null;
    const [filterDate, setFilterDate] = useState<DateRangePickerValue>([existExpireBegin, existExpireEnd]);

    const onChangeFilter = (field: string, value: string[] | string | null) => {
        setUsersFilters({
            ...filters,
            [field]: (Array.isArray(value) && value.length === 0) || value === null ? undefined : value,
        });
    };
    const rolesUserSelectData = assets?.roles?.map((result) => ({ value: result.code, label: result.name })) as { value: string; label: string }[];
    const [roleUserFilter, setRoleUserFilter] = useState<string | null>(filters.role ? filters.role : null);

    const onResetFilter = () => {
        setUsersFilters({});
        setRoleUserFilter(null);
        setFilterDate([null, null]);

    };

    useEffect(() => {
        getUsersAssets();
    }, []);

    useEffect(() => {
        rolesUserSelectData?.map((result) => { if (result.value === roleUserFilter) onChangeFilter('role', result.value) })
    }, [roleUserFilter]);

    useEffect(() => {
        if (filterDate) {
            let dateSelect: string[] = [];
            filterDate.map((result) => dateSelect.push(result ? formatFormDate(result) : ''));

            if (dateSelect[0] !== '' && dateSelect[1] !== '') {
                onChangeFilter('createdAt', dateSelect)
            }
        }
    }, [filterDate]);

    return (
      <Group>
        <Select
          placeholder="Статус"
          data={assets.status || []}
          value={filters.status ?? null}
          onChange={(value) => onChangeFilter('status', value)}
        />
        <Select
          placeholder="Роль"
          data={rolesUserSelectData || []}
          value={roleUserFilter ?? null}
          onChange={setRoleUserFilter}
        />

        <MultiSelect
          placeholder="Регион"
          data={assets.region || []}
          value={filters.region || []}
          onChange={(value) => onChangeFilter('region', value)}
        />

        <DateRangePicker
          styles={{
            input: {
              textOverflow: 'ellipsis',
            }
          }}
          clearable={true}
          initialLevel='date'
          placeholder={'Дата создания'}
          locale='ru'
          inputFormat="DD.MM.YYYY"
          value={filterDate}
          onChange={setFilterDate}
        />

        {((filters.region && filters.region.length > 0)
            || roleUserFilter
            || filters.status
            || filterDate[0])
          &&
          (
            <Button variant="subtle" size="sm" p={0} rightIcon={<Reset/>} onClick={onResetFilter}>
              Сбросить
            </Button>
          )}
      </Group>
    )
};

export default UsersFilters;
