import { FC, useEffect } from "react";
import { Button, Container, Modal, Text } from "@mantine/core";

import { useStyles } from './styles';

import { useActions, useTypedSelector } from "@/store/hooks";

export interface IUnblockUserModal {
    modalTitle: string;
    unblockButton: string;
    reverseButton: string;
    single: boolean;
    selectedID: number[];
}

const UnblockUserModal: FC<IUnblockUserModal> = ({modalTitle, unblockButton, reverseButton, single, selectedID}) => {
    const { classes } = useStyles();
    const { setUnblockUserModal, patchUnblockUsers, setMutateUsers } = useActions();
    const { unblockUserModal, mutateUsers, isUnBlockUsersLoading, isUnBlockUsersSuccess } = useTypedSelector(state => state.users)
    const isSingle = single ? 'пользователя' : 'пользователям';

    useEffect(() => {
        if (isUnBlockUsersLoading) return;

        if (isUnBlockUsersSuccess) {
            if (!mutateUsers) {
                setMutateUsers(true);
                setUnblockUserModal(false);
            }
        }
    }, [isUnBlockUsersLoading]);

    return (
        <Modal opened={unblockUserModal} onClose={() => setUnblockUserModal(false)} padding={20}>
            <Text className={classes.textTitleBlockModal} ta={'center'}>{modalTitle}</Text>
            <Text className={classes.textNoteBlockModal} mx={40} ta={'center'}>Доступ {isSingle} к системе будет открыт, <br /> все лицензии активированы.</Text>
            <Container className={classes.blocklButtonContainer}>
                <Button disabled={false} className={classes.blockButton} onClick={() => patchUnblockUsers({ id: selectedID.join() })}>{unblockButton}</Button>
                <Button className={classes.reverseButton} variant="outline" onClick={() => { setUnblockUserModal(false); }}>{reverseButton}</Button>
            </Container>
        </Modal>
    );
};

export default UnblockUserModal;


