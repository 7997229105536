import {Consumer} from "@/types";

import React, { FC } from "react";
import {Box, Button, CopyButton, Group, Text, TextInput, Title} from "@mantine/core";

import { useStyles } from './styles';
import UserBoxTitle from "./UserBoxTitle";
import UserColumnWrapper from "./UserColumnWrapper";
import {Copy} from "tabler-icons-react";
import {COLORS} from "@/constants/mantine/colors";

const UserDetailApi: FC<{ consumers: Consumer[] | undefined }> = ({ consumers }) => {
    const { classes } = useStyles();
    const consumer = consumers?.[0];

    return (
      (consumers !== undefined && consumers?.length > 0) && <>
          <Title style={{gridColumn: '1/4'}}>API</Title>
          <UserColumnWrapper>
            <Box className={classes.ColumnBox} mx={'auto'}>
              <UserBoxTitle title="UUID" hideAll />
              <Text className={classes.BoxText}>
                { consumer?.uuid }
              </Text>
            </Box>
            { consumer?.privateKey && <Box className={classes.ColumnBox} mx={'auto'}>
              <UserBoxTitle title="Ключ" hideAll />
              <Group  align="flex-end">
                <TextInput readOnly={true} placeholder={consumer?.privateKey} styles={{ root: { flexGrow: 1}, input: { textOverflow: 'ellipsis' } }} />
                <CopyButton value={consumer?.privateKey ?? ''}>
                  {({ copied, copy }) => (
                    <Button w={162} variant={copied ? 'filled' : 'outline'} px={13} color={copied ? 'blue' : 'teal'} onClick={copy}> Скопировать
                      <Copy size={23} style={{ margin: 0, marginLeft: 11, transform: `rotate(90deg)`, color: COLORS.GRAY.icon }} />
                    </Button>
                  )}
                </CopyButton>
              </Group>
            </Box> }
          </UserColumnWrapper>
          <UserColumnWrapper>
            <Box className={classes.ColumnBox} mx={'auto'}>
              <UserBoxTitle title="Название" hideAll />
              <Text className={classes.BoxText}>
                { consumer?.name }
              </Text>
            </Box>
          </UserColumnWrapper>
          <UserColumnWrapper>
            <Box className={classes.ColumnBox} mx={'auto'}>
              <UserBoxTitle title="Активен" hideAll />
              <Text className={classes.BoxText}>
                { consumer?.active ? 'Да' : 'Нет' }
              </Text>
            </Box>
          </UserColumnWrapper>
        </>
    )
}

export default UserDetailApi;
