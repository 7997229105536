import {StatusEnum} from "@/types/enum/StatusEnum";

import {COLORS} from "@/constants/mantine/colors";

export const getStatusColor = (status?: number | null): string => {
  let result;

    switch (status) {
        case StatusEnum.Created :
            result = COLORS.BLUE.hover;
            break;
        case StatusEnum.Active :
            result = COLORS.GREEN.hover;
            break;
        case StatusEnum.Inactive :
            result = COLORS.RED.text;
            break;
        case StatusEnum.Deleted :
            result = COLORS.GRAY.text;
            break;
        case StatusEnum.Expired :
        default :
            result = COLORS.BLACK;
    }

  return result;
}

export const getStatusLabel = (status?: number | null): string => {
    let result;

    switch (status) {
        case StatusEnum.Created :
            result = 'Создан'
            break;
        case StatusEnum.Active :
            result = 'Активен';
            break;
        case StatusEnum.Inactive :
            result = 'Неактивен';
            break;
        case StatusEnum.Expired :
            result = 'Истёк';
            break;
        case StatusEnum.Deleted :
            result = 'Удалён';
            break;
        case StatusEnum.Blocked :
            result = 'Заблокирован';
            break;
        default :
            result = 'Не определен';
    }

    return result;
}

export function plural (forms: string[], n: number): string {
  let idx

  if (n % 10 === 1 && n % 100 !== 11) {
    idx = 0
  } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    idx = 1
  } else {
    idx = 2
  }

  return forms?.[idx] ?? ''
}

export function formatDate (date?: string|null, placeholder: string = '-'): string {
  if (date) {
    return new Date(date).toLocaleDateString('ru-RU')
  } else {
    return placeholder
  }
}

export function formatFormDate (date: Date) {
  const dateString = date.toLocaleDateString('ru-RU');

  return `${dateString.slice(6, 10)}-${dateString.slice(3, 5)}-${dateString.slice(0, 2)}`;
}

export function validateEmail (value: string): boolean {
    return (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/.test(value));
}

export function getColor (status?: number | null) {
    let result = {
        bgColor: '',
        color: ''
    };

    switch (status) {
        case StatusEnum.Blocked:
            result.bgColor = 'rgba(138, 148, 168, 0.1)';
            result.color = COLORS.GRAY.icon;
            break;
        case StatusEnum.Inactive:
            result.bgColor = 'rgba(240, 49, 49, 0.1)';
            result.color = COLORS.RED.text;
            break;
        case StatusEnum.Expired:
        case StatusEnum.Deleted:
            result.bgColor = 'rgba(138, 148, 168, 0.1)';
            result.color = COLORS.BLACK;
            break;
        case StatusEnum.Active:
            result.bgColor = 'rgba(47, 198, 60, 0.1)';
            result.color = COLORS.GREEN.hover;
            break;
        case StatusEnum.Created:
        default :
            result.bgColor = 'rgba(50, 47, 198, 0.1)';
            result.color = COLORS.BLUE.hover;
    }

    return result;
}

export function countFilters (obj: { [k: string]: any }): number {
    let count = 0;

    if (typeof obj === "object") {
        Object.keys(obj).forEach(key => {
            if (obj[key]) {
                count++;
            }
        });
    }

    return count;
}
