import {FC, useEffect, useState} from 'react';
import {Button, Group, Select} from '@mantine/core';
import {DateRangePicker, DateRangePickerValue} from '@mantine/dates';

import {formatFormDate} from "@/utils/helpers";

import {ReactComponent as Reset} from '@/assets/icons/Reset.svg';
import {useActions, useTypedSelector} from '@/store/hooks';

interface ILicenseData {
  licenseId: number;
}

const ReportFilter: FC<ILicenseData> = () => {
  const { getLicensesReportAssets, setLicensesReportFilters } = useActions();
  const { assets, filters, searchId } = useTypedSelector((store) => store.reports);
  const existExpireBegin = filters.date?.[0] !== undefined ? new Date(filters.date[0]) : null;
  const existExpireEnd = filters.date?.[1] !== undefined ? new Date(filters.date[1]) : null;
  const [filterDate, setFilterDate] = useState<DateRangePickerValue>([existExpireBegin, existExpireEnd]);

  const onChangeFilter = (field: string, value: string[] | string | null) => {
    setLicensesReportFilters({
      ...filters,
      [field]: (Array.isArray(value) && value.length === 0) || value === null ? undefined : value,
    });
  };

  const roleSelectData = assets.role.map((result) => ({ value: result.code, label: result.name })) as { value: string; label: string }[];
  const [roleFilter, setRoleFilter] = useState<string | null>(filters.role ? filters.role : null);
  const actionSelectData = assets.action.map((result) => ({ value: result.code, label: result.name })) as { value: string; label: string }[];
  const [actionFilter, setActionFilter] = useState<string | null>(filters.action ? filters.action : null);
  const payableSelectData = assets.payable.map((result) => (result === 'Платный' ? { value: '1', label: result } : result === 'Бесплатный' ? { value: '0', label: result } : '')) as { value: string; label: string }[];
  const [payableFilter, setPayableFilter] = useState<string | null>(filters.payable ? filters.payable : null);

  const onResetFilter = () => {
    setLicensesReportFilters({});
    setFilterDate([null, null]);
    setActionFilter(null);
    setRoleFilter(null);
    setPayableFilter(null);
  };

  useEffect(() => {
    if (searchId.previousQueryId)
      getLicensesReportAssets({ id: Number(searchId.previousQueryId) });
  }, [searchId.previousQueryId]);

  useEffect(() => {
    roleSelectData.map((result) => { if (result.value === roleFilter) onChangeFilter('role', result.value) })
  }, [roleFilter]);

  useEffect(() => {
    actionSelectData.map((result) => { if (result.value === actionFilter) onChangeFilter('action', result.value) })
  }, [actionFilter]);

  useEffect(() => {
    payableSelectData.map((result) => { if (result.value === payableFilter) onChangeFilter('payable', result.value) })
  }, [payableFilter]);

  useEffect(() => {
    if (filterDate) {
      let dateSelect: string[] = [];
      dateSelect.push(filterDate[0] ? (`${formatFormDate(filterDate[0])} 00:00:00`) : '');
      dateSelect.push(filterDate[1] ? (`${formatFormDate(filterDate[1])} 23:59:59`) : '');
      if (dateSelect[0] !== '' && dateSelect[1] !== '') {
        onChangeFilter('date', dateSelect)
      }
    }
  }, [filterDate]);

  return (
    <Group noWrap={true}>
      <Select placeholder="Роль" data={roleSelectData} value={roleFilter || null} onChange={setRoleFilter} />
      <Select placeholder="Действие" data={actionSelectData} value={actionFilter || null} onChange={setActionFilter} />
      <DateRangePicker styles={{input: {textOverflow: 'ellipsis',}}} clearable={true} initialLevel='date' placeholder={'Дата'} locale='ru' inputFormat="DD.MM.YYYY" value={filterDate} onChange={setFilterDate} />
      <Select placeholder=" Тип комплекта" data={payableSelectData} value={payableFilter || null} onChange={setPayableFilter} />
      {
        (roleFilter || actionFilter || payableFilter || filterDate[0]) && <Button variant="subtle" size="sm" p={0} rightIcon={<Reset />} onClick={onResetFilter}>Сбросить</Button>}
    </Group>
  );
};

export default ReportFilter;
