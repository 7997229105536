import { FC, forwardRef, useState } from 'react';
import {
  Checkbox,
  MultiSelect as MMultiSelect,
  MultiSelectProps,
  useComponentDefaultProps,
} from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';

import { useStyles } from './styles';

import { useTypedSelector } from '@/store/hooks';

const MultiSelect: FC<MultiSelectProps> = (props) => {
  const { classNames, placeholder, searchable, onSearchChange, nothingFound } =
    useComponentDefaultProps('MultiSelect', {}, props);

  const [selectValue, setSelectValue] = useUncontrolled({
    value: props.value,
    defaultValue: [],
    finalValue: [],
    onChange: props.onChange,
  });

  const { classes, cx } = useStyles({
    searchable,
    fakePlaceholder: selectValue.length === 0 ? placeholder : undefined,
  });
  const { statusProducts } = useTypedSelector((store) => store.products);
  const { statusLoading } = useTypedSelector((store) => store.licenses);
  const { loadingStatus } = useTypedSelector((store) => store.packs);
  const { isReportLoading } = useTypedSelector((store) => store.reports);
  const { loadingUsers } = useTypedSelector((store) => store.users);

  const checkDisable = loadingStatus.loading || statusProducts.isProductsLoading || statusLoading.loadingLicenses || !!loadingUsers || !!isReportLoading
  const [searchActive, setSearchActive] = useState<boolean>(false);

  const isItemSelected = (value: string) => {
    return selectValue.includes(value);
  };

  return (
    <MMultiSelect
      {...props}
      value={selectValue}
      onChange={setSelectValue}
      classNames={{
        ...classNames,
        input: cx(classes.input, classNames?.input),
        values: cx(classes.values, classNames?.values),
        value: cx(classes.value, classNames?.value),
        searchInput: cx(classes.searchInput, classNames?.searchInput),
        item: cx(classes.item, classNames?.item),
      }}
      searchable={true}
      onSearchChange={(query: string) => {
        setSearchActive(query !== '');
        if (searchable && typeof onSearchChange === 'function') {
          onSearchChange(query);
        }
      }}
      nothingFound={searchActive && nothingFound}
      valueComponent={({ value, label, className }) => {
        if (searchActive || selectValue.indexOf(value) !== 0) {
          return null;
        }
        return (
          <div className={className}>
            {`${placeholder ?? 'Выбрано'}: `}
            { selectValue.length === 1 ? label || value : selectValue.length }
          </div>
        );
      }}
      filter={(value, selected, item) =>
        !!item.label?.toLowerCase().trim().includes(value.toLowerCase().trim())
      }
      itemComponent={forwardRef(({ value, label, ...others }) => (
        <div {...others}>
          <Checkbox disabled={checkDisable} readOnly checked={isItemSelected(value)} label={label || value} />
        </div>
      ))}
    />
  );
};

export default MultiSelect;
