import { createStyles } from '@mantine/core';

// import { COLORS } from '@/constants/mantine/colors';

export const useStyles = createStyles(() => ({

  // Modal "ChangeRole" #####

  textTitleModal: {
    top: 0,
    height: 29,
    fontFamily: 'Golos',
    fontWeight: 600,
    fontSize: 26,
    lineHeight: '110%',
    alignItems: 'center',
    textAlign: 'center',
    color: '#000000',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },

  selectDropdownMenu: {
    boxSizing: "border-box",
    fontSize: 14,
    alignItems: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '14px, 12px',
    width: 458,
    flexGrow: 0,
  },

  textInputCreateUser: {
    boxSizing: "border-box",
    fontSize: 14,
    alignItems: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '14px, 12px',
    width: 458,
    maxWidth: '100%',
    flexGrow: 0,
  },

  modalAddAnotherUser: {
    fontSize: 14,
    marginTop: '8px',
    color: '#322FC6',
    flexGrow: 0,
  },

  modalButtonContainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },

  modalButton: {
    width: 213,
    height: 52,
    marginTop: 32,
  },


  // Modal "BlockUnblockUser" #####
  textTitleBlockModal: {
    top: 0,
    marginTop: 0,
    fontFamily: 'Golos',
    fontWeight: 600,
    fontSize: 26,
    lineHeight: '110%',
    alignItems: 'center',
    textAlign: 'center',
    color: '#000000',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },


  textNoteBlockModal: {
    top: 0,
    marginTop: 16,
    height: 38,
    fontFamily: 'Golos',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '135%',
    alignItems: 'center',
    textAlign: 'center',
    color: '#000000',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },

  blocklButtonContainer: {
    padding: '0px 40px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  blockButton: {
    width: 458,
    height: 52,
    marginTop: 32,
  },

  reverseButton: {
    width: 458,
    height: 52,
    marginTop: 8,
  },

  noteSuccessModal: {
    top: 0,
    marginTop: 32,
    height: 38,
    fontFamily: 'Golos',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '135%',
    alignItems: 'center',
    textAlign: 'center',
    color: '#000000',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },

  linkSuccessModal: {
    top: 0,
    marginTop: 32,
    height: 19,
    fontFamily: 'Golos',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '120%',
    alignItems: 'center',
    textAlign: 'center',
    color: '#000000',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },

  linkField: {
    width: 280,
    height: 52,
    marginTop: 16,
  },

  copySuccessButton: {
    width: 162,
    height: 52,
    marginTop: 16,
  },

  closeSuccessButton: {
    width: 458,
    height: 52,
    marginTop: 32,
  },

  formBox: {
    // "::-webkit-scrollbar": {
    //   width: '2px',
    // },
    width: '100%',
    overflowY: 'auto',
    msOverflowY: 'auto',
    overflowX: 'hidden',
    msOverflowX: 'hidden',
    maxHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    scrollbarWidth: 'none'
  },


}));
