export const roles = [
    { value: 'ROLE_ADMIN', label: 'Администратор' },
    { value: 'ROLE_MANAGER', label: 'Менеджер' },
    { value: 'ROLE_DISTRIBUTOR', label: 'Дистрибьютор' },
    { value: 'ROLE_SCHOOL_ADMIN', label: 'Администратор школы' },
    { value: 'ROLE_USER', label: 'Пользователь' }
];

export const assetsValidity = [
    { value: '1', label: '1 месяц', group: 'По месяцам' },
    { value: '2', label: '2 месяца', group: 'По месяцам' },
    { value: '3', label: '3 месяца', group: 'По месяцам' },
    { value: '4', label: '4 месяца', group: 'По месяцам' },
    { value: '5', label: '5 месяцев', group: 'По месяцам' },
    { value: '6', label: '6 месяцев', group: 'По месяцам' },
    { value: '7', label: '7 месяцев', group: 'По месяцам' },
    { value: '8', label: '8 месяцев', group: 'По месяцам' },
    { value: '9', label: '9 месяцев', group: 'По месяцам' },
    { value: '10', label: '10 месяцев', group: 'По месяцам' },
    { value: '11', label: '11 месяцев', group: 'По месяцам' },
    { value: '12', label: '1 год', group: 'По годам' },
    { value: '24', label: '2 года', group: 'По годам' },
    { value: '36', label: '3 года', group: 'По годам' },
    { value: '48', label: '4 года', group: 'По годам' },
    { value: '60', label: '5 лет', group: 'По годам' },
    { value: '0', label: 'бессрочно' },
];
