import React, {useEffect, useState} from 'react';
import {Box, Modal} from "@mantine/core";

import {productsApi} from "@/api";

import Table from "@/ui/Table/Table";

import {IProduct} from "@/store/products/types";

type IModalDigitalPack = {
    id: number,
    show: boolean,
    setShow: (value: boolean) => void
}

const ModalDigitalPack = ({id, show, setShow}: IModalDigitalPack) => {
    const [children, setChildren] = useState<IProduct[]>([]);
    const [childrenIsLoading, setChildrenIsLoading] = useState<boolean>(false);

    const onClose = (): void => {
        setShow(false);
        setChildren([]);
    }

    useEffect(() => {
        if (show && id) {
            setChildrenIsLoading(true);

            productsApi
                .getProductChildren(id)
                .then(({data}) => {
                    setChildren(data.result);
                })
                .finally(() => setChildrenIsLoading(false));
        }
    }, [id, show])

    return (
        <Modal opened={show} onClose={onClose} title={"Состав комплекта"}>
            <Box h={500} mt={24} style={{overflow: 'auto', display: 'flex', flexShrink: 0, boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.05)'}}>
                <Table
                    columns={[
                        { id: 'externalCode', text: '', width: 110, noWrap: true },
                        { id: 'name', text: '', width: 259 }
                    ]}
                    data={children}
                    showHead={false}
                    loading={childrenIsLoading}
                    emptyImageSize={150}
                />
            </Box>
        </Modal>
    );
};

export default ModalDigitalPack;
