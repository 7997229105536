import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PAGINATION_PER_PAGE } from '@/constants/common';

import { IProductsFilters, ISort, ProductsState } from '../products/types';

import { getDigitalProducts, getDigitalProductsAssets, getDigitalProductsSearchSuggestions } from './actions';

const initialState: ProductsState = {
  statusProducts: {
    isProductsLoading: false,
    isProductsLoadingAssets: false,
    isProductsLoadingSearch: false,
  },

  isProductsExportLoading: false,

  assets: {
    type: [],
    service: [],
    class: [],
    eduLevel: [],
  },

  products: null,

  pagination: {
    currentPage: 1,
    perPage: PAGINATION_PER_PAGE,
  },
  sort: null,
  filters: {},
  search: {
    previousQuery: undefined,
    query: undefined,
    suggestions: [],
  },
  productsExportLink: null,
  initialProductsSort: {
    sort: '',
    direction: 'asc',
  }
};

const digitalProductsSlice = createSlice({
  name: 'digitalProducts',
  initialState,
  reducers: {
    setDigitalProductsPage(state, action: PayloadAction<number>) {
      state.pagination.currentPage = action.payload;
    },
    setDigitalProductsSort(state, action: PayloadAction<ISort>) {
      state.sort = action.payload;
    },
    setDigitalProductsFilters(state, action: PayloadAction<IProductsFilters>) {
      state.filters = action.payload;
      state.pagination.currentPage = initialState.pagination.currentPage;
    },
    setDigitalProductsSearchQuery(state, action: PayloadAction<string>) {
      state.search.previousQuery = action.payload ?? state.search.previousQuery;
      state.search.query = action.payload ? action.payload : undefined;
      state.pagination.currentPage = initialState.pagination.currentPage;
    },
    setDigitalClearProductsSearchQuery(state) {
      state.search.previousQuery = undefined;
      state.search.query = undefined;
    },
  },
  extraReducers: (builder) => {
    // getProductsAssets
    builder
      .addCase(getDigitalProductsAssets.pending, (state) => {
        state.statusProducts.isProductsLoadingAssets = true;
      })
      .addCase(getDigitalProductsAssets.fulfilled, (state, action) => {
        state.statusProducts.isProductsLoadingAssets = false;
        state.assets = action.payload.result;
      })
      .addCase(getDigitalProductsAssets.rejected, (state) => {
        state.statusProducts.isProductsLoadingAssets = false;
      });

    // getProducts
    builder
      .addCase(getDigitalProducts.pending, (state) => {
        state.statusProducts.isProductsLoading = true;
      })
      .addCase(getDigitalProducts.fulfilled, (state, action) => {
        state.statusProducts.isProductsLoading = false;
        state.products = action.payload.result.items;
        state.pagination.currentPage = action.payload.result.currentPage;
        state.pagination.perPage = action.payload.result.perPage;
        state.pagination.totalItems = action.payload.result.totalItems;
        state.pagination.totalPages = action.payload.result.totalPages;
        state.initialProductsSort.sort = action.payload.result.sort;
        state.initialProductsSort.direction = action.payload.result.direction;
      })
      .addCase(getDigitalProducts.rejected, (state) => {
        state.statusProducts.isProductsLoading = false;
      });

    // getProductsSearchSuggestions
    builder
      .addCase(getDigitalProductsSearchSuggestions.pending, (state) => {
        state.statusProducts.isProductsLoadingSearch = true;
      })
      .addCase(getDigitalProductsSearchSuggestions.fulfilled, (state, action) => {
        state.statusProducts.isProductsLoadingSearch = false;
        state.search.suggestions = action.payload.result.map((item: string | number) =>
          item.toString()
        );
      })
      .addCase(getDigitalProductsSearchSuggestions.rejected, (state) => {
        state.statusProducts.isProductsLoadingSearch = false;
      });
  },
});

export const digitalProductsActions = {
  getDigitalProducts: getDigitalProducts,
  getDigitalProductsAssets: getDigitalProductsAssets,
  getDigitalProductsSearchSuggestions: getDigitalProductsSearchSuggestions,
  ...digitalProductsSlice.actions,
};

export default digitalProductsSlice.reducer;
