import {FC} from 'react';
import {Button, Modal, ModalProps, Stack, Text} from '@mantine/core';

interface IModalUpload extends ModalProps {
    text?: string;
    accept: string;
    isDisableAcceptButton?: boolean;
    onAccept: () => void;
    onClose: () => void;
    loading: boolean
}

const ModalUpload: FC<IModalUpload> = ({
                                            text,
                                            accept,
                                            onAccept,
                                            onClose,
                                            children,
                                            loading,
                                            isDisableAcceptButton,
                                            ...props
                                        }) => {
    return (
        <Modal onClose={onClose} {...props}>
            { text && (
                <Text size="sm" ta="center">
                    { text }
                </Text>
            ) }
            { children }
            <Stack spacing={8} mt={32}>
                <Button disabled={isDisableAcceptButton} loading={loading} type='button' onClick={onAccept}>
                    { accept }
                </Button>
            </Stack>
        </Modal>
    );
};

export default ModalUpload;
