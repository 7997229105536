import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PAGINATION_PER_PAGE } from '@/constants/common';

import { getLicenses, getLicensesAssets, getLicensesExport, getLicensesSearchSuggestions } from './actions';
import { ILicensesFilters, ISort, Licenses, LicensesState } from './types';

const initialState: LicensesState = {
  statusLoading: {
    loadingLicenses: false,
    loadingLicensesAssets: false,
    loadingLicensesSearch: false,
  },
  assetsLicense: {
    type: [],
    class: [],
    service: [],
    eduLevel: [],
    status: [],
  },

  licenses: null,
  updateLicenses: false,

  selectedLicenses: [],

  pagination: {
    currentPage: 1,
    perPage: PAGINATION_PER_PAGE,
  },
  sort: null,
  filtersLicenses: {},
  search: {
    previousQuery: undefined,
    query: undefined,
    setId: undefined,
    suggestions: [],
  },
  licensesExportLink: null,
  initialLicensesSort: {
    sort: '',
    direction: 'asc',
  }
};

const licensesSlice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {
    setLicensesPage(state, action: PayloadAction<number>) {
      state.pagination.currentPage = action.payload;
    },
    setLicensesSort(state, action: PayloadAction<ISort>) {
      state.sort = action.payload;
    },
    setLicensesFilters(state, action: PayloadAction<ILicensesFilters>) {
      state.filtersLicenses = action.payload;
      state.pagination.currentPage = initialState.pagination.currentPage;
    },
    setLicensesSearchQuery(state, action: PayloadAction<string>) {
      state.search.previousQuery = action.payload ?? state.search.previousQuery;
      state.search.query = action.payload ? action.payload : undefined;
      state.pagination.currentPage = initialState.pagination.currentPage;
    },
    setClearLicensesSearchQuery(state) {
      state.search.previousQuery = undefined;
      state.search.query = undefined;
    },
    setSelectedLicenses(state, action: PayloadAction<Licenses[]>) {
      state.selectedLicenses = action.payload;
    },
    setLicensesExportLink(state, action: PayloadAction<Blob | null>) {
      state.licensesExportLink = action.payload;
    },
    setUpdateLicenses(state, action: PayloadAction<boolean>) {
      state.updateLicenses = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getLicensesAssets
    builder
      .addCase(getLicensesAssets.pending, (state) => {
        state.statusLoading.loadingLicensesAssets = true;
      })
      .addCase(getLicensesAssets.fulfilled, (state, action) => {
        state.statusLoading.loadingLicensesAssets = false;
        state.assetsLicense = action.payload.result;
      })
      .addCase(getLicensesAssets.rejected, (state) => {
        state.statusLoading.loadingLicensesAssets = false;
      });

    // getLicenses
    builder
      .addCase(getLicenses.pending, (state) => {
        state.statusLoading.loadingLicenses = true;
      })
      .addCase(getLicenses.fulfilled, (state, action) => {
        state.statusLoading.loadingLicenses = false;
        state.licenses = action.payload.result.items;
        state.pagination.currentPage = action.payload.result.currentPage;
        state.pagination.perPage = action.payload.result.perPage;
        state.pagination.totalItems = action.payload.result.totalItems;
        state.pagination.totalPages = action.payload.result.totalPages;
        state.initialLicensesSort.sort = action.payload.result.sort;
        state.initialLicensesSort.direction = action.payload.result.direction;
      })
      .addCase(getLicenses.rejected, (state) => {
        state.statusLoading.loadingLicenses = false;
      });

    builder
      .addCase(getLicensesSearchSuggestions.pending, (state) => {
        state.statusLoading.loadingLicensesSearch = true;
      })
      .addCase(getLicensesSearchSuggestions.fulfilled, (state, action) => {
        state.statusLoading.loadingLicensesSearch = false;
        state.search.suggestions = action.payload.result.map((item: string | number) =>
          item.toString()
        );
      })
      .addCase(getLicensesSearchSuggestions.rejected, (state) => {
        state.statusLoading.loadingLicensesSearch = false;
      });

    builder
      .addCase(getLicensesExport.pending, (state) => {
        state.statusLoading.loadingLicenses = true;
      })
      .addCase(getLicensesExport.fulfilled, (state, action) => {
        state.statusLoading.loadingLicenses = false;
        state.licensesExportLink = action.payload;
      })
      .addCase(getLicensesExport.rejected, (state) => {
        state.statusLoading.loadingLicenses = false;
      });
  },
});

export const licensesActions = {
  getLicenses,
  getLicensesAssets,
  getLicensesExport,
  getLicensesSearchSuggestions,
  ...licensesSlice.actions,
};

export default licensesSlice.reducer;
