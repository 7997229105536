import { Global } from '@mantine/core';

import demibold from '@/assets/fonts/golos_demibold.woff2';
import medium from '@/assets/fonts/golos_medium.woff2';
import regular from '@/assets/fonts/golos_regular.woff2';

export function AppFonts() {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Golos',
            src: `url('${regular}') format("woff2")`,
            fontWeight: 400,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Golos',
            src: `url('${medium}') format("woff2")`,
            fontWeight: 500,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Golos',
            src: `url('${demibold}') format("woff2")`,
            fontWeight: 600,
            fontStyle: 'normal',
          },
        },
      ]}
    />
  );
}
