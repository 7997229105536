import { createStyles } from '@mantine/core';

import { COLORS } from '@/constants/mantine/colors';

export const useStyles = createStyles((theme) => ({
  root: {
    marginRight: 'auto',
  },

  icon: {
    flexShrink: 0,
  },

  text: {
    fontSize: theme.fontSizes.sm,
    lineHeight: '20px',
    borderLeft: `1px solid ${COLORS.GRAY.icon}`,
    padding: '7px 0 1px 23px',
  },
}));
