import {StatusEnum} from "@/types/enum/StatusEnum";

import { FC, useState } from "react";
import { Button, CopyButton, Group, TextInput } from "@mantine/core";
import { Copy } from "tabler-icons-react";

import {plural} from "@/utils/helpers";

import { COLORS } from "@/constants/mantine/colors";

import { ReactComponent as TimerCircle } from '@/assets/icons/TimerCircle.svg';
import { useActions, useTypedSelector } from "@/store/hooks";

interface ICopySendUserDetail {
    status?: number;
}

const CopySendUserDetail: FC<ICopySendUserDetail> = ({ status }) => {
    const [timerActive, setTimerActive] = useState<boolean>(false);
    const [minutes, setMinutes] = useState(5);
    const { getSendActivationLink } = useActions();
    const { userDetailId, isUserDetailLoading, userDetail } = useTypedSelector(state => state.users);

    if (!isUserDetailLoading && userDetail) {
        const activationTime = +new Date(userDetail.activationLinkSentAt ? userDetail.activationLinkSentAt : -300000)
        const currentTime = +new Date()
        const timeShift = (Math.round(Math.round((currentTime - (activationTime + 300000)) / 1000) / 60));

        if (!timerActive && timeShift < 0) {
            const timeShiftPlus = Math.abs(timeShift)
            setMinutes(timeShiftPlus);
            setTimerActive(true);
        }
    }

    return (
        status === StatusEnum.Inactive ?
            <Group mt={24}
                align="flex-end">
                <TextInput readOnly={true} w={269} placeholder={!isUserDetailLoading && userDetail?.activationLink ? userDetail.activationLink : ''} styles={{input: { textOverflow: 'ellipsis' }}} />

                <CopyButton value={!isUserDetailLoading && userDetail?.activationLink ? userDetail.activationLink : ''}>
                    {({ copied, copy }) => (
                        <Button disabled={false} variant={copied ? 'filled' : 'outline'} px={14} color={copied ? 'blue' : 'teal'}  onClick={copy}>
                            <Copy size={23} style={{margin: 0, transform: `rotate(90deg)`, color: COLORS.GRAY.icon }} />
                        </Button>
                    )}
                </CopyButton>
                <Button
                    disabled={timerActive}
                    loading={isUserDetailLoading}
                    loaderPosition="center"
                    w={199}
                    px={20}
                    variant={'outline'}
                    onClick={() => {
                        setMinutes(5);
                        setTimerActive(true);
                        if (userDetailId) getSendActivationLink({ id: userDetailId });
                    }}
                >
                    { !isUserDetailLoading &&
                        (timerActive
                            ? <span>
                                <TimerCircle style={{ position: 'absolute', top: 17, left: 51 }} />
                                <span style={{ position: 'absolute', top: 17, left: 80 }}>{minutes} { plural(['минута', 'минуты', 'минут'], minutes) }</span>
                            </span>
                            : 'Отправить')
                    }
                </Button>
            </Group>
            : null

    );
}

export default CopySendUserDetail;
