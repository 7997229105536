import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PAGINATION_PER_PAGE } from '@/constants/common';

import { getProducts, getProductsAssets, getProductsExport, getProductsSearchSuggestions } from './actions';
import { IProductsFilters, ISort, ProductsState } from './types';

const initialState: ProductsState = {
  statusProducts: {
    isProductsLoading: false,
    isProductsLoadingAssets: false,
    isProductsLoadingSearch: false,
  },

  isProductsExportLoading: false,

  assets: {
    type: [],
    service: [],
    class: [],
    eduLevel: [],
  },

  products: null,

  pagination: {
    currentPage: 1,
    perPage: PAGINATION_PER_PAGE,
  },
  sort: null,
  filters: {},
  search: {
    previousQuery: undefined,
    query: undefined,
    suggestions: [],
  },
  productsExportLink: null,
  initialProductsSort: {
    sort: '',
    direction: 'asc',
  }
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductsPage(state, action: PayloadAction<number>) {
      state.pagination.currentPage = action.payload;
    },
    setProductsSort(state, action: PayloadAction<ISort>) {
      state.sort = action.payload;
    },
    setProductsFilters(state, action: PayloadAction<IProductsFilters>) {
      state.filters = action.payload;
      state.pagination.currentPage = initialState.pagination.currentPage;
    },
    setProductsSearchQuery(state, action: PayloadAction<string>) {
      state.search.previousQuery = action.payload ?? state.search.previousQuery;
      state.search.query = action.payload ? action.payload : undefined;
      state.pagination.currentPage = initialState.pagination.currentPage;
    },
    setProductsExportLink(state, action: PayloadAction<Blob | null>) {
      state.productsExportLink = action.payload;
    },
    setClearProductsSearchQuery(state) {
      state.search.previousQuery = undefined;
      state.search.query = undefined;
    },
  },
  extraReducers: (builder) => {
    // getProductsAssets
    builder
      .addCase(getProductsAssets.pending, (state) => {
        state.statusProducts.isProductsLoadingAssets = true;
      })
      .addCase(getProductsAssets.fulfilled, (state, action) => {
        state.statusProducts.isProductsLoadingAssets = false;
        state.assets = action.payload.result;
      })
      .addCase(getProductsAssets.rejected, (state) => {
        state.statusProducts.isProductsLoadingAssets = false;
      });

    // getProducts
    builder
      .addCase(getProducts.pending, (state) => {
        state.statusProducts.isProductsLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.statusProducts.isProductsLoading = false;
        state.products = action.payload.result.items;
        state.pagination.currentPage = action.payload.result.currentPage;
        state.pagination.perPage = action.payload.result.perPage;
        state.pagination.totalItems = action.payload.result.totalItems;
        state.pagination.totalPages = action.payload.result.totalPages;
        state.initialProductsSort.sort = action.payload.result.sort;
        state.initialProductsSort.direction = action.payload.result.direction;
      })
      .addCase(getProducts.rejected, (state) => {
        state.statusProducts.isProductsLoading = false;
      });

    // getProductsSearchSuggestions
    builder
      .addCase(getProductsSearchSuggestions.pending, (state) => {
        state.statusProducts.isProductsLoadingSearch = true;
      })
      .addCase(getProductsSearchSuggestions.fulfilled, (state, action) => {
        state.statusProducts.isProductsLoadingSearch = false;
        state.search.suggestions = action.payload.result.map((item: string | number) =>
          item.toString()
        );
      })
      .addCase(getProductsSearchSuggestions.rejected, (state) => {
        state.statusProducts.isProductsLoadingSearch = false;
      });

    // Export
    builder
      .addCase(getProductsExport.pending, (state) => {
        state.isProductsExportLoading = true;
      })
      .addCase(getProductsExport.fulfilled, (state, action) => {
        state.isProductsExportLoading = false;
        state.productsExportLink = action.payload;
      })
      .addCase(getProductsExport.rejected, (state) => {
        state.isProductsExportLoading = false;
      });
  },
});

export const productsActions = {
  getProducts,
  getProductsAssets,
  getProductsExport,
  getProductsSearchSuggestions,
  ...productsSlice.actions,
};

export default productsSlice.reducer;
