import {StatusEnum} from "@/types/enum/StatusEnum";

import { FC, useEffect, useState } from "react";
import { Box, Group, Paper, Select, Text, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";

import {formatDate} from "@/utils/helpers";

import { COLORS } from "@/constants/mantine/colors";

import ModalConfirm from "@/ui/ModalConfirm/ModalConfirm";
import PageHeaderSearch from "@/ui/PageHeader/PageHeaderSearch/PageHeaderSearch";

import BoxTitle from "./BoxTitle";
import ColumnWrapper from "./ColumnWrapper";
import { useStyles } from "./styles";
import { IShowPacksDetailInfo } from "./types";

import { ReactComponent as ErrorCircle } from '@/assets/icons/ErrorCircle.svg';
import { ReactComponent as Infinity } from '@/assets/icons/Infinity.svg';
import { ReactComponent as SuccessCircle } from '@/assets/icons/SuccessCirle.svg';
import BoxTitleLink from "@/pages/Packs/PacksDetail/BoxTitleLink";
import { useActions, useTypedSelector } from "@/store/hooks";

const PacksDetailBody: FC<IShowPacksDetailInfo> = ({ status, jsonData }) => {
    const {
        patchPackUpdate,
        setMutatePacksDetail,
        getProductsSearchSuggestions
    } = useActions();

    const getErrorMessage = (message: string) => {
        return (
            <span style={{ color: COLORS.RED.text }}>
                <ErrorCircle style={{ marginRight: 4 }} />
                { message }
            </span>
        )
    };

    const validateName = (value: string) => {
        if (!value.length) return getErrorMessage("Пустое поле. Пожалуйста, введите новое название");
        else if (!value.replace(/\s/g, '').length) return getErrorMessage("Поле не должно состоять из пробелов!");
        else return null;
    };

    const InputStyles = {
        input: {
            minHeight: 24,
            height: 52,
            margin: 0,
            fontFamily: 'Golos',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            color: '#000000',
        }
    };

    const { classes } = useStyles();
    const { user } = useTypedSelector((store) => store.auth);
    const {
        packsDetailId,
        packsDetail,
        mutatePacksDetail,
        isPackEditLoading,
        isPackEditSuccess,
        isPackUpdateLoading,
        isPacksDetailLoading,
    } = useTypedSelector((store) => store.packs);
    const { search, userDetail } = useTypedSelector((store) => store.users);
    const [dateExpired, setDateExpired] = useState<Date | null>(null);
    const [dateActivated, setDateActivated] = useState('');
    const [modalChangePackOwner, setModalChangePackOwner] = useState<boolean>(false);
    const [modalChangeExpired, setModalChangeExpired] = useState<boolean>(false);
    const [changeExpired, setChangeExpired] = useState<boolean>(false);
    const [modalChangeActivated, setModalChangeActivated] = useState<boolean>(false);
    const [changeActivated, setChangeActivated] = useState<boolean>(false);
    const [name, setName] = useState(jsonData.name || "");
    const [comment, setComment] = useState(jsonData.comment  || "");
    const [changeName, setChangeName] = useState<boolean>(false);
    const [changeComment, setChangeComment] = useState<boolean>(false);
    const [selectedFields, setSelectedFields] = useState<string[]>([]);
    const roleAMD = user?.role.code === 'ROLE_ADMIN' || user?.role.code === 'ROLE_MANAGER' || user?.role.code === 'ROLE_DISTRIBUTOR';
    const acceptUserRole = userDetail?.role === 'ROLE_SCHOOL_ADMIN' || userDetail?.role === 'ROLE_USER'
    const assetsValidity = [
        { value: '1', label: '1 месяц' },
        { value: '2', label: '2 месяца' },
        { value: '3', label: '3 месяца' },
    ];

  const onClickAccept = (title: string) => {
    if ((title === "name" && !name.length) || !name.replace(/\s/g, '').length) return;

    setSelectedFields((prevState) => {
      const newState = [...prevState];
      newState.splice(prevState.findIndex(item => item === title), 1);
      return newState;
    });
  };

    useEffect(() => {
        if (isPackUpdateLoading) return;

        if (changeName) {
            setChangeName(false);
            onClickAccept("name");
        }
    }, [isPackUpdateLoading]);

    useEffect(() => {
        if (isPackUpdateLoading) return;

        if (changeComment) {
            setChangeComment(false);
            onClickAccept("comment");
        }
    }, [isPackUpdateLoading]);

    useEffect(() => {
        if (isPackUpdateLoading) return;

        if (changeExpired) {
            setChangeExpired(false);
            setDateExpired(null);
        }
    }, [isPackUpdateLoading]);

    useEffect(() => {
        if (isPackUpdateLoading) return;

        if (changeActivated) {
            setDateActivated('');
            setChangeActivated(false);

            if (!mutatePacksDetail) setMutatePacksDetail(true);
        }
    }, [isPackUpdateLoading]);

    const onClickEdit = (title: string) => {
        setSelectedFields((prevState) => {
            if (prevState.includes(title)) {
                const newState = [...prevState];
                newState.splice(prevState.findIndex(item => item === title), 1);
                return newState;
            }
            else return [...prevState, title];
        })
    };

    const onClickHide = (title: string) => {
        setComment(() => jsonData.comment ? jsonData.comment : '')
        setSelectedFields((prevState) => {
            const newState = [...prevState];
            newState.splice(prevState.findIndex(item => item === title), 1);
            return newState;
        });
    };

    const addHours = (date: Date) => {
        date.setHours(date.getHours() + 7);
        return date;
    };

    const oldExpired = new Date(jsonData.expirationDate).toISOString();

    const addMaxActivMonth = (date: Date) => {
        date.setMonth(date.getMonth() + Number(3));
        return date;
    };
    const maxActivDate = addMaxActivMonth(new Date(jsonData.finishDate)).toISOString();

    const addMonths = (date: Date) => {
        date.setMonth(date.getMonth() + Number(dateActivated));
        return date;
    };
    const calcNewActivatedDate = (addMonths(new Date(jsonData.finishDate))).toISOString();
    const maxActiv = (Date.parse(maxActivDate) > Date.parse(calcNewActivatedDate)) ? calcNewActivatedDate : maxActivDate;
    const newActivateDate = (Date.parse(maxActiv) >= Date.parse(oldExpired)) ? new Date(oldExpired) : new Date(maxActiv)

    const forecastDate = newActivateDate.toLocaleDateString('ru-RU')
    const isDisableAcceptButton = Date.parse(maxActiv) > Date.parse(forecastDate)

    useEffect(() => {
        if (isPackEditLoading) return;

        if (isPackEditSuccess && !mutatePacksDetail) {
          setMutatePacksDetail(true);
        }
    }, [isPackEditLoading]);

    function setProductsSearchQuery(_value: string): void {
        throw new Error("Function not implemented.");
    }

    return (
        <>
            { jsonData &&
                <Paper className={classes.Paper}>
                    <ColumnWrapper title="О владельце">
                        { user?.role.code !== 'ROLE_USER' && <Box className={classes.ColumnBox} mx={'auto'}>
                            <BoxTitleLink title="Идентификатор владельца" link={(jsonData.userId && roleAMD) ? `/users/${jsonData.userId}` : null} />
                            { !(isPacksDetailLoading || isPackEditLoading) &&
                                <Text className={classes.BoxText}>
                                    { jsonData.userId ? `#${jsonData.userId}` : "-" }
                                </Text>
                            }
                        </Box>}
                        <Box className={classes.ColumnBox} mx={'auto'}>
                            <BoxTitleLink title="Email владельца" link={(jsonData.userId && roleAMD) ? `/users/${jsonData.userId}` : null} />
                            { !(isPacksDetailLoading || isPackEditLoading) &&
                              <Text className={classes.BoxText}>
                                  { jsonData.userEmail || "-" }
                              </Text>
                            }
                        </Box>
                        { user?.role.code !== 'ROLE_USER' &&
                            <Box className={classes.ColumnBox} mx={'auto'}>
                                <BoxTitleLink title="ФИО владельца" link={(jsonData.userId && roleAMD) ? `/users/${jsonData.userId}` : null} />
                                { !(isPacksDetailLoading || isPackEditLoading) &&
                                    <Text className={classes.BoxText}>
                                        { jsonData.userName || "-" }
                                    </Text>
                                }
                        </Box> }
                        <Box className={classes.ColumnBox} mx={'auto'}>
                            <BoxTitle title="Организация" hideAll />
                            { !(isPacksDetailLoading || isPackEditLoading) &&
                                <Text className={classes.BoxText}>
                                    { jsonData.userOrganizations ? jsonData.userOrganizations : '-' }
                                </Text>
                            }
                        </Box>
                    </ColumnWrapper>

                    <ColumnWrapper title="О комплекте">
                        <Box className={classes.ColumnBox} mx={'auto'}>
                            <BoxTitle
                                title="Название"
                                hideAll={!(roleAMD || jsonData.userId === jsonData.creatorId)}
                                hideAccept={!name.length}
                                hideEdit={selectedFields.includes("name")}
                                onClickEdit={() => {
                                    document.getElementById("name")?.focus();
                                    onClickEdit("name");
                                }}
                                onClickHide={() => onClickHide("name")}
                                onClickAccept={() => {
                                    if (typeof packsDetailId !== 'number') return;
                                    patchPackUpdate({
                                        id: packsDetailId,
                                        name
                                    });
                                    setChangeName(true);
                                }}
                            />
                            { selectedFields.includes("name") ?
                                (!(isPacksDetailLoading || isPackEditLoading) &&
                                    <TextInput
                                        value={name}
                                        onChange={(e) => { setName(e.currentTarget.value) }}
                                        error={validateName(name)}
                                        styles={InputStyles}
                                        maxLength={50}
                                        minLength={1}
                                    />)
                                :
                                (!(isPacksDetailLoading || isPackEditLoading) &&
                                <Text className={classes.BoxText}>{ jsonData.name || "-" }</Text>)}
                        </Box>
                        <Box className={classes.ColumnBox} mx={'auto'}>
                            <BoxTitle
                                title="Активно / Всего лиц."
                                hideAll
                                onClickEdit={() => onClickEdit("licencesVolumeActivated")}
                                onClickHide={() => onClickHide("licencesVolumeActivated")}
                                onClickAccept={() => onClickAccept("licencesVolumeActivated")}
                            />
                            { selectedFields.includes("licencesVolumeActivated") ?
                                <Group style={{ display: 'flex', flexWrap: 'nowrap' }} >
                                    <TextInput defaultValue={jsonData.licensesActivated.toString() || "0"} styles={InputStyles} type='number' />
                                    {" / "}
                                    {
                                        jsonData.licensesVolume === 0 ?
                                            <Text className={classes.BoxText}><Infinity mt={16} /></Text> :
                                            <TextInput defaultValue={jsonData.licensesVolume.toString() || "0"} styles={InputStyles} type='number' />
                                    }
                                </Group>
                                :
                                <Text className={classes.BoxText}>
                                    <span>
                                        { jsonData.licensesActivated || 0 } / { jsonData.licensesVolume === 0 ? <Infinity mt={16} /> : jsonData.licensesVolume || 0 }
                                    </span>
                                </Text>}
                        </Box>
                        <Box className={classes.ColumnBox} mx={'auto'}>
                            <BoxTitle
                                title="Комментарий"
                                hideAll={!(roleAMD || jsonData.userId === jsonData.creatorId)}
                                hideEdit={selectedFields.includes("comment")}
                                onClickEdit={() => onClickEdit("comment")}
                                onClickHide={() => onClickHide("comment")}
                                onClickAccept={() => {
                                    if (typeof packsDetailId !== 'number') return;

                                    patchPackUpdate({
                                        id: packsDetailId,
                                        comment
                                    });
                                    setChangeComment(true);
                                }}
                            />
                            {selectedFields.includes("comment") ?
                                (!(isPacksDetailLoading || isPackEditLoading) &&
                                    <TextInput value={comment} onChange={(e) => { setComment(e.currentTarget.value) }} styles={InputStyles} maxLength={100} />)
                                :
                                (!(isPacksDetailLoading || isPackEditLoading) && <Text className={classes.BoxText}>{ jsonData.comment || '-'}</Text>)
                            }
                        </Box>
                        <Box className={classes.ColumnBox} mx={'auto'}>
                            <BoxTitle title="Тип назначения объекта" hideAll />
                            {!(isPacksDetailLoading || isPackEditLoading) &&
                                <Text className={classes.BoxText}>
                                    { jsonData.type || '-' }
                                </Text> }
                        </Box>
                    </ColumnWrapper>

                    <ColumnWrapper title="Сроки">
                        <Box className={classes.ColumnBox} mx={'auto'}>
                            <BoxTitle title="Дата создания" hideAll />
                            {!(isPacksDetailLoading || isPackEditLoading) &&
                                <Text className={classes.BoxText}>
                                    { formatDate(jsonData.creationDate) }
                                </Text>}
                        </Box>
                        {(roleAMD && (status === StatusEnum.Inactive || status === StatusEnum.Created)) &&
                            <Box className={classes.ColumnBox} mx={'auto'}>
                                <BoxTitle
                                    title="Срок активации"
                                    hideAll={(roleAMD && acceptUserRole && (status === StatusEnum.Inactive || status === StatusEnum.Created))}
                                    onClickEdit={() => setModalChangeActivated(() => true)}
                                />
                                {!(isPacksDetailLoading || isPackEditLoading) &&
                                    <Text className={classes.BoxText}>
                                        { formatDate(packsDetail?.startDate) }
                                        {" - "}
                                        { formatDate(packsDetail?.finishDate) }
                                    </Text>}
                            </Box>
                        }

                        {(status === StatusEnum.Active) &&
                            <Box className={classes.ColumnBox} mx={'auto'}>
                                <BoxTitle title="Дата активации" hideAll />
                                { !(isPacksDetailLoading || isPackEditLoading) &&
                                    <Text className={classes.BoxText}>
                                        { formatDate(jsonData.activationDate) }
                                    </Text> }
                            </Box>}
                        <Box className={classes.ColumnBox} mx={'auto'}>
                            <BoxTitle
                                title="Дата окончания доступа"
                                onClickEdit={() => setModalChangeExpired(() => true)}
                                hideAll={user?.role.code !== 'ROLE_ADMIN'}
                            />
                            {!(isPacksDetailLoading || isPackEditLoading) &&
                                <Text className={classes.BoxText}>
                                    { formatDate(jsonData.expirationDate) }
                                </Text>}
                        </Box>
                    </ColumnWrapper>
                </Paper>}

            <ModalConfirm
                opened={modalChangePackOwner}
                onClose={() => { onClickHide('Email владельца'); setModalChangePackOwner(false) }}
                title="Изменить владельца?"
                text="Владелец комплекта будет изменён"
                accept="Да, переназначить"
                decline="Нет, отменить"
                onAccept={() => { }}
            >
              <>
                <Text mt={24} mb={16}>Введите нового владельца</Text>
                <PageHeaderSearch
                  placeholder={'Введите e-mail или ID пользователя'}
                  data={search.suggestions}
                  onChange={(query) => getProductsSearchSuggestions({ query })}
                  onSubmit={setProductsSearchQuery}
                />
                <Text style={{ margin: 0 }} size={12} mt={16} />
              </>
            </ModalConfirm>
            <ModalConfirm
                opened={modalChangeExpired}
                onClose={() => { onClickHide('Дата окончания доступа'); setModalChangeExpired(false) }}
                title="Изменить дату окончания?"
                text="Дата окончания будет изменена. Выберите новую дату окончания в календаре"
                accept="Да, изменить"
                decline="Нет, отменить"
                onAccept={() => {
                    if (packsDetailId && dateExpired) {
                        const newExpired = (new Date((addHours(new Date(dateExpired))).toISOString())).toISOString().slice(0, 10) + oldExpired.slice(0, 10)
                        patchPackUpdate({
                            id: packsDetailId,
                            expiredDate: `${newExpired.slice(0, 10)} 23:59:59`,
                            expirationDate: `${newExpired.slice(0, 10)} 23:59:59`
                        });
                    }

                    setChangeExpired(true);
                }}
                onExit={() => { setChangeExpired(false); setDateExpired(null); }}
            >
              <>
                <DatePicker
                  defaultValue={null}
                  mt={32}
                  allowFreeInput
                  locale="ru"
                  inputFormat="DD.MM.YYYY"
                  labelFormat="DD.MM.YYYY"
                  label={'Новая дата окончания'}
                  placeholder={"Дата окончания"}
                  minDate={new Date()}
                  value={dateExpired}
                  onChange={e => setDateExpired(e ? new Date(e) : null)}
                />
                { dateExpired &&
                  ( Date.parse(jsonData.expirationDate)) > (Date.parse((new Date((addHours(new Date(dateExpired))).toISOString())).toISOString().slice(0, 10) + oldExpired.slice(0, 10))) &&
                  <Text size={12} mt={8}>
                    <span><SuccessCircle style={{ marginRight: 4 }} /> Срок действия комплекта будет сокращён. Выбранная дата раньше, чем изначальная дата комплекта</span>
                  </Text> }
              </>
            </ModalConfirm>

            <ModalConfirm
                opened={modalChangeActivated}
                onClose={() => { onClickHide('Срок активации'); setModalChangeActivated(false) }}
                title="Изменить срок активации?"
                text="Срок активации будет изменён. Выберите на сколько месяцев вы хотите продлить срок активации"
                accept="Да, изменить"
                decline="Нет, отменить"
                isDisableAcceptButton={isDisableAcceptButton}
                onAccept={() => {
                    if (packsDetailId) {
                        patchPackUpdate({
                            id: packsDetailId,
                            finishDateExtendPeriod: Number(dateActivated),
                        });
                    }

                    setChangeActivated(true);
                }}
                onExit={() => { setChangeActivated(false); setDateActivated(''); }}
            >
              <>
                <Select placeholder={'Срок активации'} mt={32} label={'Продление срока активации'} defaultValue={''} value={dateActivated} data={assetsValidity} onChange={e => { setDateActivated(e || '') }} />

                { dateActivated &&
                  <Text size={14} mt={24}>
                    {(Date.parse(maxActiv) >= Date.parse(oldExpired))
                      ? (<span><ErrorCircle style={{ marginRight: 4 }} /> Выбранный срок активации выходит за пределы срока действия лицензий комплекта <br /><br /> Прогнозируемая дата окончания срока активации: {forecastDate}</span>)
                      : (<span><SuccessCircle style={{ marginRight: 4 }} /> Прогнозируемая дата окончания срока активации: {forecastDate}</span>)}
                  </Text>}
              </>
            </ModalConfirm>
        </>
    )
}

export default PacksDetailBody;
