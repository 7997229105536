import {FC, ReactElement} from 'react';
import {Checkbox, Group} from '@mantine/core';

import Spoiler from "@/ui/Spoiler/Spoiler";

import { useStyles } from '../styles';
import { IColumn } from '../Table';

import { ReactComponent as Docs } from '@/assets/icons/Docs.svg';
import { ReactComponent as Folder } from '@/assets/icons/Folder.svg';

interface ITableRow {
  row: Record<string, any>;
  nestingLevel?: number;
  columns: IColumn[];
  selectable?: boolean;
  selected?: boolean;
  filled?: boolean;
  onChangeSelected?: () => void;
  onRowClick?: () => void;
  expandControl?: string;
  expansion: number[];
  checkable?: (id: number) => boolean
}

const TableRow: FC<ITableRow> = ({
  row,
  nestingLevel = 0,
  columns,
  selectable,
  selected,
  onChangeSelected,
  onRowClick,
  expandControl,
  expansion,
  filled,
  checkable
}) => {
  const { classes, cx } = useStyles();
  const isExpanding = row.children && row.children.length > 0 || row.type === 'Комплект электронный';
  const getPlaceholder = (id: string) => {
      return Number.isInteger(row[id]) ? row[id] : '-';
  }

  const getValue = (column: IColumn): string | number | null | ReactElement => {
      if (row[column.id] && row[column.id] !== ' ') {
         return !column.spoiler ? row[column.id] : <Spoiler width={column.width} value={row[column.id]} rows={1} clickable={column?.spoilerClickable} />
      }

      return null;
  }

  return (
    <tr
      className={cx({
        [classes.rowFilled]: filled,
        [classes.rowChecked]: selected,
        [classes.rowExpanded]: expansion.includes(row.id),
        [classes.rowNested]: nestingLevel > 0,
        [classes.rowNested2]: nestingLevel > 1,
      })}
      onClick={onRowClick}
      style={{position: 'relative'}}
    >
      { selectable && (
        <td>
          { !nestingLevel && (
            <Checkbox disabled={checkable?.(row.id)} className={classes.checkbox} checked={selected} onChange={onChangeSelected} onClick={(e) => e.stopPropagation()}/>
          ) }
        </td>
      ) }
      { columns.map((column) => (
        <td key={column.id} className={cx('', {[classes.noWrap]: column.noWrap})}>
          {expandControl === column.id ? (
            <Group noWrap={true} align="center" spacing={8}>
              {isExpanding ? (
                <Folder
                  className={cx(classes.expandIcon, {
                    [classes.expandIconActive]: expansion.includes(row.id),
                    [classes.expandIconActive1]: expansion.includes(row.id) && nestingLevel > 0,
                  })}
                />
              ) : (
                <Docs className={classes.expandIcon}/>
              )}
              { row[column.id] || 'Без названия' }
            </Group>
          ) : (
              getValue(column) || getPlaceholder(column.id)
          )}
        </td>
      )) }
    </tr>
  );
};

export default TableRow;
