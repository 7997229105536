import {StatusEnum} from "@/types/enum/StatusEnum";

import {FC, useEffect} from 'react';
import { Button, Group, Select } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';

import MultiSelect from '@/ui/MultiSelect/MultiSelect';

import { checkFieldAccess } from '../utils/checkFieldAccess';

import { ReactComponent as Reset } from '@/assets/icons/Reset.svg';
import { useActions, useTypedSelector } from '@/store/hooks';

const ProductFilter: FC = () => {
  const { getProductsAssets, setProductsFilters } = useActions();
  const { user } = useTypedSelector((store) => store.auth);
  const { assets, filters } = useTypedSelector((store) => store.products);
  const { filtersLicenses } = useTypedSelector((store) => store.licenses);

  const onChangeFilter = (field: string, value: string[] | string | null) => {
    setProductsFilters({
      ...filters,
      [field]: (Array.isArray(value) && value.length === 0) || value === null ? undefined : value,
    })
  };

  const onResetFilter = () => {
    setProductsFilters({});
  };

  useEffect(() => {
    getProductsAssets();
  }, []);

  return ( <Group noWrap={false}>
      { checkFieldAccess('filterType', user?.role.code) && (
        <MultiSelect
          placeholder="Тип"
          data={assets.type}
          value={filters.type ?? []}
          onChange={(value) => onChangeFilter('type', value)}
        />
      )}
      { checkFieldAccess('filterClass', user?.role.code) && (
        <MultiSelect
          placeholder="Класс"
          data={assets.class}
          value={filters.class ?? []}
          onChange={(value) => onChangeFilter('class', value)}
        />
      )}
      { checkFieldAccess('filterService', user?.role.code) && (
        <MultiSelect
          placeholder="Сервисы"
          data={assets.service}
          value={filters.service ?? []}
          onChange={(value) => onChangeFilter('service', value)}
        />
      )}
      { checkFieldAccess('filterExpired', user?.role.code) && (
        <DateRangePicker placeholder="Дата окончания"/>
      )}
      { checkFieldAccess('filterStatus', user?.role.code) && (
        <Select
          placeholder="Статус"
          data={[
              {
                  value: StatusEnum.Active.toString(),
                  label : 'Активен'
              },
              {
                  value: StatusEnum.Inactive.toString(),
                  label: 'Истёк'
              }
          ]}
          value={filtersLicenses.status ?? null}
          onChange={(value) => onChangeFilter('status', value)}
        />
      )}

      {((filters.service && filters.service.length > 0)
          || (filters.type && filters.type.length > 0)
          || (filters.class && filters.class.length > 0)
        ) &&
        <Button variant="subtle" size="sm" p={0} rightIcon={<Reset/>} onClick={onResetFilter}>
          Сбросить
        </Button>
      }
    </Group>
);
};

export default ProductFilter;
