import { FC, ReactNode } from 'react';
import { ActionIcon, Box, Button, Group } from '@mantine/core';
import { Refresh } from 'tabler-icons-react';

import PageHeaderSearch, { IPageHeaderSearch } from '../PageHeader/PageHeaderSearch/PageHeaderSearch';

import DetailHeaderTitle from './DetailHeaderTitle';
import { useStyles } from './styles';

import { ReactComponent as Download } from '@/assets/icons/Download.svg';

interface IDetailHeader {
    title: string;
    name?: string;
    subTitle?: string;
    rightButton?: ReactNode;
    linkCopySend?: ReactNode;
    status: number;
    detail?: boolean;
    search?: IPageHeaderSearch;
    uncheckAll?: () => void;
    isCheck?: number;
    showPacksDetailTable: boolean;
    disabledExportBtn?: boolean;
    onExport?: () => void;

}

const DetailHeader: FC<IDetailHeader> = ({
    name,
    title,
    status,
    detail,
    search,
    subTitle,
    rightButton,
    linkCopySend,
    uncheckAll,
    isCheck,
    showPacksDetailTable,
    disabledExportBtn,
    onExport
}) => {
    const { classes } = useStyles();

    return (
      <Box style={{display: 'flex', flexDirection: 'column'}}>
        <Group position="apart" sx={{ alignContent: 'center', flexWrap: 'wrap' }}>
          <Group style={{flexShrink: 0, minWidth: 250 }}>
            <DetailHeaderTitle title={title} name={name} subTitle={subTitle} status={status} detail={detail} />
            { !!isCheck && showPacksDetailTable &&
              <ActionIcon onClick={() => uncheckAll?.()}>
                <Refresh size={22}/>
              </ActionIcon>
            }
          </Group>

          <Group position="right" sx={{alignItems: 'center'}}>
            { rightButton }
          </Group>

          { search &&
            <Group position="right" w={680} noWrap={true}>
              <PageHeaderSearch {...search} />
              { onExport && <Button
                m={0}
                disabled={disabledExportBtn}
                classNames={{root: classes.exportButton, leftIcon: classes.exportButtonIcon}}
                variant="outline"
                leftIcon={<Download/>}
                onClick={onExport}
              >Экспорт</Button> }
            </Group>
          }
        </Group>
        <Group position='left'>
          { linkCopySend }
        </Group>
      </Box>
    );
};

export default DetailHeader;
