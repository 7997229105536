import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import {injectStore} from "@/api/instance";

import App from './App';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
injectStore(store);

root.render(
    <React.StrictMode>
      <Provider store={store}>
          <App />
      </Provider>
    </React.StrictMode>
);
