import {
  GetLicenseExistsReportParams,
  GetLicenseIdReportSearchParams,
  GetLicensesReportAssetsParams,
  GetLicensesReportAssetsResponse,
  GetLicensesReportExportParams,
  GetLicensesReportParams,
  GetLicensesReportResponse,
  GetLicensesReportSearchParams,
} from '@/types/api/reports';

import axiosInstance from '../instance';
import { URLS } from '../urls';


export const reportsApi = {

  // Get Licenses List
  getLicensesReport(params: GetLicensesReportParams) {
    return axiosInstance.request<GetLicensesReportResponse>({
      method: 'get',
      url: `/licenses/${params.id}/report`,
      params,
    });
  },

  // Get License Filters Assets
  getLicensesReportAssets(params: GetLicensesReportAssetsParams) {
    return axiosInstance.request<GetLicensesReportAssetsResponse>({
      method: 'get',
      url: `/licenses/${params.id}/report/filters`,
    });
  },

  // Search Licenses by ID
  getLicenseIdReportSearch(params: GetLicenseIdReportSearchParams) {
    return axiosInstance.request({
      method: 'get',
      url: `/licenses/id/report/search`,
      params
    });
  },

  // Search Licenses by ID & search query
  getLicensesReportSearch( params: GetLicensesReportSearchParams) {
    return axiosInstance.request({
      method: 'get',
      url: `/licenses/${params.id}/report/search`,
      params: {query: params.query}
    });
  },

  // Licenses Export Fetch
  getLicensesReportExport(params?: GetLicensesReportExportParams) {
    return axiosInstance.request({
      method: 'get',
      url: `/licenses/${params?.id}/report/export`,
      responseType: 'blob',
      params,
    });
  },

  // is Licenses 'Id' Exists ?
  getLicenseExists(params?: GetLicenseExistsReportParams) {
    return axiosInstance.request({
      method: 'get',
      url: URLS.licenses.getLicenseExists,
      params,
    });
  },

};
