import {Dispatch, FC, SetStateAction, useEffect, useRef} from 'react';
import { ActionIcon, Loader, TextInput } from '@mantine/core';
import { getHotkeyHandler, useDebouncedValue } from '@mantine/hooks';

import { useStyles } from './styles';

import { ReactComponent as Clear } from '@/assets/icons/Clear.svg';
import { ReactComponent as Search } from '@/assets/icons/Search.svg';
import { useActions, useTypedSelector } from '@/store/hooks';

const NULL_CHARACTERS = 0;
const MIN_CHARACTERS = 1;
const MAX_CHARACTERS = 10;

export interface IPageHeaderReportSearch {
  placeholder?: string;
  loading?: boolean;
  data: string[];
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
}

export interface Props extends IPageHeaderReportSearch {
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  setInputLicenseSearch: Dispatch<SetStateAction<string>>;
}

const PageHeaderReportSearch: FC<Props> = ({
  placeholder,
  loading,
  onChange,
  onSubmit,
  inputValue,
  setInputValue,
  setInputLicenseSearch,
}) => {
  const { setLicensesReportFilters, setLicensesReport, setExistLicense, setClearReportSearchQuery } = useActions();
  const { isLoadingLicenseExist, isErrorLicenseExist } = useTypedSelector(state => state.reports);
  const { classes } = useStyles();
  const debounceOff = useRef(false);
  const [debouncedInputValueId] = useDebouncedValue(inputValue, 800);

  const checkNullLength = (value = debouncedInputValueId) => value.trim().length === NULL_CHARACTERS;
  const checkMinLength = (value = debouncedInputValueId) => value.trim().length >= MIN_CHARACTERS;
  const checkMaxLength = (value = debouncedInputValueId) => value.trim().length < MAX_CHARACTERS;

  const onClear = () => {
    setClearReportSearchQuery();
    setLicensesReport(null);
    setExistLicense(false);
    setLicensesReportFilters({});
    setInputValue('');
    setInputLicenseSearch('');
    onSubmit('');
  };

  useEffect(() => {
    if (checkMinLength() && checkMaxLength()) {
      if (debounceOff.current) {
        debounceOff.current = false;
        return;
      }
      onChange(debouncedInputValueId);
    }
    if (checkNullLength()) onClear();
  }, [debouncedInputValueId]);

  useEffect(() => {
    if (isLoadingLicenseExist) return;
    if (!isErrorLicenseExist) onSubmit(inputValue);
  }, [isLoadingLicenseExist])

  return (
    <TextInput
      style={{ backgroundColor: '#F2F2F8', borderRadius: '12px' }}
      classNames={{ root: classes.root, input: classes.input }}
      variant="unstyled"
      icon={<Search />}
      rightSection={
        loading ? (
          <Loader size={24} />
        ) : (
         inputValue && <ActionIcon variant="transparent" onClick={onClear}>
            <Clear />
          </ActionIcon>
        )
      }
      placeholder={placeholder}
      value={inputValue}
      onChange={(event) => setInputValue(event.currentTarget.value.replace(/\D/g, ''))}
      onKeyDown={getHotkeyHandler([
        ['Enter', () => {
          if (checkMinLength(inputValue) && checkMaxLength(inputValue)) {
            debounceOff.current = true;
            onChange(inputValue);
          }
        }],
      ])}
    />
  );
};

export default PageHeaderReportSearch;
