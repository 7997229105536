import { createStyles } from '@mantine/core';

import { COLORS } from '@/constants/mantine/colors';

export const useStyles = createStyles(() => ({
  root: {
    position: 'relative',

    '&:before': {
      content: '""',
      position: 'absolute',
      top: -1,
      left: 0,
      right: 0,
      height: 1,
      background: COLORS.GRAY.icon,
    },
  },

  supportBtn: {
    width: 173,
    marginTop: -1,
  },
}));
