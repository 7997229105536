import {RoleEnum} from "@/types/enum/RoleEnum";

import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Modal, Select, Text } from "@mantine/core";

import { useStyles } from './styles';

import { useActions, useTypedSelector } from "@/store/hooks";

export interface IChangeRoleModal {
    modalTitle: string;
    selectedID: number[];
}

const ChangeRoleModal: FC<IChangeRoleModal> = ({ modalTitle, selectedID }) => {
    const { classes } = useStyles();
    const { setChangeRoleModal, patchChangeRole, setMutateUsers, getUsersRoles } = useActions();
    const { roles, userDetail, userDetailId, selectedUsers } = useTypedSelector((store) => store.users);
    const { changeRoleModal, isChangeRoleUsersLoading, isChangeRoleUsersSuccess } = useTypedSelector(state => state.users)
    const [valueUserRole, setValueUserRole] = useState<string | null | undefined>(null);
    const [rolesEnum, setRolesEnum] = useState(roles.map((result) => ({ value: result.code, label: result.name })));
    const { id } = useParams();

    useEffect(() => {
        if (changeRoleModal) {
            const currentUserRole = userDetail?.role;

            if (userDetail && userDetailId && currentUserRole) {
                setValueUserRole(currentUserRole);

                if (roles.length === 0) {
                    setRolesEnum([{value: currentUserRole, label: RoleEnum[userDetail.role]}])
                }
            } else {
                setValueUserRole(null);
            }
        }
    }, [userDetail, userDetailId, changeRoleModal]);

    useEffect(() => {
        setRolesEnum(roles.map(result => ({ value: result.code, label: result.name })))
    }, [roles]);

    const onExit = () => {
        setChangeRoleModal(false);
    }

    useEffect(() => {
      if (isChangeRoleUsersLoading) return;

      if (isChangeRoleUsersSuccess) {
        setMutateUsers(true);
        onExit();
      }
    }, [isChangeRoleUsersLoading]);

    const isRoleDisabled = !valueUserRole ||
        (valueUserRole === userDetail?.role && userDetailId !== null) ||
        (!id && selectedUsers.length === 1 && valueUserRole === selectedUsers[0]?.role)
    ;

    return (
        <Modal opened={changeRoleModal} onClose={onExit}>
            <Text className={classes.textTitleModal} ta={'center'}>{ modalTitle }</Text>
            <Select mt={32} label="Роль" className={classes.selectDropdownMenu} onDropdownOpen={() => roles.length == 0 && getUsersRoles()} required={false} placeholder={'Выберите роль'} data={rolesEnum} value={valueUserRole} onChange={setValueUserRole} />
            <Container className={classes.modalButtonContainer}>
                <Button className={classes.modalButton} disabled={isRoleDisabled} onClick={() => { if (valueUserRole) patchChangeRole({ id: selectedID.join(), role: valueUserRole }) }}>Изменить</Button>
                <Button className={classes.modalButton} variant="outline" onClick={onExit}>Отменить</Button>
            </Container>
        </Modal>
    );
};

export default ChangeRoleModal;


