import { User } from '@/types';
import {StatusEnum} from "@/types/enum/StatusEnum";

import { FC, useEffect, useState } from 'react';
import {Button} from '@mantine/core';

import {countFilters} from "@/utils/helpers";

import ModalDigitalPack from "@/components/DigitalPackModal/ModalDigitalPack";

import ModalConfirm from '@/ui/ModalConfirm/ModalConfirm';
import PageHeader from '@/ui/PageHeader/PageHeader';
import Table from '@/ui/Table/Table';
import TableControls from '@/ui/TableControls/TableControls';

import { ActivationModals } from '../Packs/Modal/ActivationModals';
import CreateLicensePack from '../Packs/Modal/CreateLicensePacks';

import ProductFilter from './ProductFilter/ProductFilter';
import { checkFieldAccess } from './utils/checkFieldAccess';

import { ReactComponent as Infinity } from '@/assets/icons/Infinity.svg';
import { useActions, useTypedSelector } from '@/store/hooks';
import {IProduct} from "@/store/products/types";

const Products: FC = () => {
    const {
        getProducts,
        getProductsSearchSuggestions,
        setProductsPage,
        setProductsSort,
        getProductsExport,
        setProductsSearchQuery,
        setLicenses,
        setTriggerTableChange,
        setShowMultiPacksFormed,
        setShowPacksFormed,
        setProductsExportLink
    } = useActions();
    const {user} = useTypedSelector((store) => store.auth);
    const {
        licensesPack,
        triggerTableChange,
        licenseFromUser,
    } = useTypedSelector((store) => store.packs);
    const {
        products,
        pagination,
        sort,
        filters,
        search,
        statusProducts,
        productsExportLink,
        initialProductsSort
    } = useTypedSelector(
        (store) => store.products
    );
    const {selectedUsers} = useTypedSelector((store) => store.users);
    const [modalExportOpened, setModalExportOpened] = useState<boolean>(false);
    const [modalPackDetail, setModalPackDetail] = useState<boolean>(false);
    const [modalLicensesPackOpened, setModalLicensesPackOpened] = useState<boolean>(false);
    const [digitalPackId, setDigitalPackId] = useState<number>(0);

    const disabledPagination = statusProducts.isProductsLoading || statusProducts.isProductsLoadingAssets || statusProducts.isProductsLoadingSearch

    const tableProduct = products?.map(({...item}) => ({
        total: (
            <Infinity mt={16}/>
        ),
        ...item,
    }));

    const columns = [
        {id: 'externalCode', text: 'Номенклатура', width: 150, spoiler: true, style: {width: 150}},
        {id: 'type', text: 'Тип продукта', sortable: true, width: 150, spoiler: true, style: {width: 150}},
        {id: 'name', text: 'Название', sortable: true, width: 400},
        {id: 'author', text: 'Автор', sortable: true, width: 360, spoiler: true, spoilerClickable: true, style: {width: 360}},
        {id: 'class', text: 'Класс', sortable: true, width: 110, style: {width: 110}},
        {id: 'expired', text: 'Дата окончания', sortable: true, width: 168, style: {width: 168}},
        {id: 'status', text: 'Статус', sortable: true, width: 104, style: {width: 104}},
        {id: 'total', text: 'Кол-во', sortable: false, width: 105, style: {width: 105}},
        {id: 'volume', text: 'Остаток', sortable: true, width: 113, style: {width: 113}},
        {id: 'activated', text: 'Активировано', sortable: true, width: 157, style: {width: 157}},
        {id: 'service', text: 'Сервис', sortable: true, width: 212, spoiler: true, style: {width: 212} },
        {id: 'activatedAt', text: 'Дата получения', sortable: true, width: 176, style: {width: 176}},
    ];
    const [disableLicensesButton, setDisableLicensesButton] = useState<boolean>(true);
    const disableLicensesArray: boolean[] = [];
    let firstElement: string;
    const disableButtonLicenses = (e: User[]) => {
        e.forEach((value) => {
            if (value.role === 'ROLE_DISTRIBUTOR')
                firstElement = 'ROLE_DISTRIBUTOR';
            if (value.role === 'ROLE_SCHOOL_ADMIN')
                firstElement = 'ROLE_SCHOOL_ADMIN';
        })
        e.forEach((value) => {
            if ((value.role === firstElement) && (value.status === StatusEnum.Active)) {
                disableLicensesArray.push(false);
            } else disableLicensesArray.push(true);
        })
        setDisableLicensesButton(!!(disableLicensesArray.find(item => item)))
    }

    const columnsByRole = columns
        .map((column) => ({
            ...column,
            visible: checkFieldAccess(column.id, user?.role.code),
        }))
        .filter((column) => column.visible);

    const handleProductsExport = async () => {
        getProductsExport({
            type: filters.type,
            service: filters.service,
            class: filters.class,
            eduLevel: filters.eduLevel,
        });
    };

    const onSelectLicense = (selection: number[]) => {
        if (!products) return;

        if (selection.length > licensesPack.length) {
            const targetIds = selection.filter(sId =>
                !licensesPack.find(license => license.id === sId)
            );
            setLicenses([...(licensesPack ?? []), ...products.filter(val => targetIds.includes(val.id))]);
        } else if (selection.length < licensesPack.length) {
            const targetObjs = licensesPack.filter(license =>
                !selection.find(sId => sId === license.id)
            );
            setLicenses(licensesPack.filter(val => !targetObjs.find(item => item.id === val.id)));
        }
    };

    const uncheckAll = () => {
        setLicenses([]);
        setTriggerTableChange(!triggerTableChange);
    };

    useEffect(() => {
        if (productsExportLink) {
            const href = URL.createObjectURL(productsExportLink);
            const link = document.createElement('a');
            let createDate = new Date().toLocaleString();
            link.href = href;
            link.setAttribute('download', `Список продуктов ${createDate}.xls`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setProductsExportLink(null);
        }
    }, [productsExportLink]);

    useEffect(() => {
        if (selectedUsers.length === 0) {
            setDisableLicensesButton(true);
            return
        }

        disableButtonLicenses(selectedUsers);
    }, [selectedUsers]);

    useEffect(() => {
        if (!statusProducts.isProductsLoading && !statusProducts.isProductsLoadingAssets && !statusProducts.isProductsLoadingSearch) {
            getProducts({
                page: pagination.currentPage,
                sort: sort?.field,
                direction: sort?.direction,
                query: search.query,
                ...Object.fromEntries(
                    Object.entries(filters).map(([key, value]) => [
                        key,
                        Array.isArray(value) ? value.join(',') : value,
                    ])
                ),
            });
        }
    }, [pagination.currentPage, sort?.field, sort?.direction, filters, search.query]);

    useEffect(() => {
        setShowPacksFormed(false);
        setShowMultiPacksFormed(false)
    }, []);

    const handleRowClick = (row: Record<string, any> | IProduct): void => {
        if (row.type === 'Комплект электронный') {
            setModalPackDetail(true);
            setDigitalPackId(row.id);
        }
    }

  return (
    <>
      <ActivationModals />
      <PageHeader
        title="Каталог продуктов"
        subTitle={pagination.totalItems}
        search={{
          placeholder: 'Поиск по номенклатуре, названию, автору',
          loading: statusProducts.isProductsLoadingSearch,
          data: search.suggestions,
          query: search.query ?? search.previousQuery,
          onChange: (query) => getProductsSearchSuggestions({ query }),
          onSubmit: setProductsSearchQuery,
        }}
        onExport={() => setModalExportOpened(true)}
        filter={<ProductFilter />}
        filterCount={countFilters(filters)}
        disabledExportButton={!tableProduct?.length}
        uncheckAll={uncheckAll}
        isCheck={licensesPack.length}
      />
      <Table
        columns={columnsByRole}
        data={tableProduct}
        selectable
        onSelectionChange={(selection) => onSelectLicense(selection)}
        onSortChange={(field, direction) => {
          setProductsSort({ field, direction });
          setProductsPage(1);
        }}
        onRowClick={handleRowClick}
        expandControl="name"
        loading={statusProducts.isProductsLoading}
        triggerTableChange={triggerTableChange}
        outerSelection={licensesPack?.map(val => val.id) ?? []}
        defaultSortBy={initialProductsSort.sort}
        defaultSortDirection={initialProductsSort.direction}
      />
        <TableControls selectedCount={licensesPack.length} currentPage={pagination.currentPage} totalPages={pagination.totalPages} onChangePage={setProductsPage} disabledPagination={disabledPagination}>
            <Button disabled={disableLicensesButton && licenseFromUser} onClick={() => setModalLicensesPackOpened(true)}>Выдать лицензии</Button>
        </TableControls>
        <ModalConfirm opened={modalExportOpened} onClose={() => setModalExportOpened(false)} title="Экспортировать список?" text="Выгрузка списка продуктов будет произведена с учётом настроенных фильтров" accept="Да, экспортировать" decline="Нет, отменить" onAccept={() => handleProductsExport()} />
        <CreateLicensePack createLicensePack={modalLicensesPackOpened} setCreateLicensePack={setModalLicensesPackOpened} />
        <ModalDigitalPack id={digitalPackId} show={modalPackDetail} setShow={setModalPackDetail}/>
    </>
  );
};

export default Products;
