import {
  GetChangeLegalEntityParams,
  GetCheckEmailParams,
  GetSendActivationLinkParams,
  GetUserDetailParams,
  GetUsersParams,
  GetUsersSearchSuggestionsParams,
  PatchBlockUsersParams,
  PatchChangeRoleParams,
  PatchUnblockUsersParams,
  PutUsersParams
} from '@/types';

import {createAsyncThunk} from '@reduxjs/toolkit';

import {usersApi} from '@/api';

import {rejectErrorHandler} from "@/utils/errorHandler";

export const getUsersAssets = createAsyncThunk(
    'users/getUsersAssets', async (arg, {rejectWithValue}) => {
        try {
            const {data} = await usersApi.getUsersAssets();

            return data || rejectWithValue(null);
        } catch (e) {
            return rejectErrorHandler(e, rejectWithValue)
        }
    });

export const getUsers = createAsyncThunk<
    any,
    GetUsersParams
>('users/getUsers', async (arg, {rejectWithValue}) => {
    try {
        const {data} = await usersApi.getUsers(arg);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getUsersSearchSuggestions = createAsyncThunk<
    any,
    GetUsersSearchSuggestionsParams
>('users/getUsersSearchSuggestions', async (arg, {rejectWithValue}) => {
    try {
        const {data} = await usersApi.getUsersSearchSuggestions(arg);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getUserDetail = createAsyncThunk('users/getUserDetail', async (params: GetUserDetailParams, {rejectWithValue}) => {
    try {
        const {data} = await usersApi.getUserDetail(params);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const toggleConsumerAccess = createAsyncThunk('users/toggleConsumerAccess', async (params: GetUserDetailParams, {rejectWithValue}) => {
    try {
        const { data } = await usersApi.toggleConsumerAccess(params);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getUsersRoles = createAsyncThunk('users/getUsersRoles', async (arg, {rejectWithValue}) => {
    try {
        const {data} = await usersApi.getUsersRoles();

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const putUsers = createAsyncThunk<
    any,
    PutUsersParams
>('users/putUsers', async (params: PutUsersParams, {rejectWithValue}) => {
    try {
        const {data} = await usersApi.putUsers(params);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const patchChangeRole = createAsyncThunk<
    any,
    PatchChangeRoleParams
>('users/changeRole', async (params: PatchChangeRoleParams, {rejectWithValue}) => {
    try {
        const {data} = await usersApi.patchChangeRole(params);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const patchBlockUsers = createAsyncThunk<
    any,
    PatchBlockUsersParams
>('users/blockUsers', async (params: PatchBlockUsersParams, {rejectWithValue}) => {
    try {
        const {data} = await usersApi.patchBlockUsers(params);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const patchUnblockUsers = createAsyncThunk<
    any,
    PatchUnblockUsersParams
>('users/unblockUsers', async (params: PatchUnblockUsersParams, {rejectWithValue}) => {
    try {
        const {data} = await usersApi.patchUnblockUsers(params);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getLegalEntities = createAsyncThunk('users/getLegalEntities', async (arg, {rejectWithValue}) => {
    try {
        const {data} = await usersApi.getLegalEntities();

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getChangeLegalEntity = createAsyncThunk('users/getChangeLegalEntity', async (params: GetChangeLegalEntityParams, {rejectWithValue}) => {
    try {
        const {data} = await usersApi.getChangeLegalEntity(params);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getCheckEmail = createAsyncThunk<
    any,
    GetCheckEmailParams
>('users/getCheckEmail', async (arg, {rejectWithValue}) => {
    try {
        const {data} = await usersApi.getCheckEmail(arg);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});

export const getSendActivationLink = createAsyncThunk('users/getSendActivationLink', async (params: GetSendActivationLinkParams, {rejectWithValue}) => {
    try {
        const {data} = await usersApi.getSendActivationLink(params);

        return data || rejectWithValue(null);
    } catch (e) {
        return rejectErrorHandler(e, rejectWithValue)
    }
});
