import { FC } from 'react';
import {useNavigate} from "react-router-dom";
import { createStyles,Group, Header } from '@mantine/core';

import LayoutHeaderButton from './LayoutHeaderButton/LayoutHeaderButton';
import LayoutHeaderLogo from './LayoutHeaderLogo/LayoutHeaderLogo';
import LayoutHeaderNav from './LayoutHeaderNav/LayoutHeaderNav';

import { ReactComponent as DoorExit } from '@/assets/icons/DoorExit.svg';
import { ReactComponent as UserCircle } from '@/assets/icons/UserCircle.svg';
import { useActions, useTypedSelector } from '@/store/hooks';

const useStyles = createStyles(() => ({
  root: {
    boxShadow: '0 4px 32px rgba(0, 0, 0, 0.05)',
  },
}));

const LayoutHeader: FC = () => {
  const { classes } = useStyles();
  const { logout } = useActions();
  const { user } = useTypedSelector((store) => store.auth);
  const navigate = useNavigate();

  const handleClick = () => {
    if (user?.role.code !== 'ROLE_SCHOOL_ADMIN') {
      navigate(`/users/${user?.id}`)
    } else {
      location.href = process.env.NODE_ENV === 'development' ?
          'https://dev-id.prosv.ru/profile?consumer=0c2589d1-f3d0-4488-9b98-0222e7fdc97f' :
          'https://id.prosv.ru/profile?consumer=77996443-1a5d-477c-bd30-170b6cebf813';
    }
  }

  return (
    <Header className={classes.root} height={127} withBorder={false} px={16}>
      <Group noWrap spacing={45} py={12} px={24}>
        <LayoutHeaderLogo />
        <LayoutHeaderButton icon={<UserCircle />} text={user?.name} note={user?.role?.name} onClick={handleClick} />
        <LayoutHeaderButton icon={<DoorExit />} text="Выйти" onClick={() => logout()} />
      </Group>
      <LayoutHeaderNav />
    </Header>
  );
};

export default LayoutHeader;
