import { createStyles } from '@mantine/core';

import { COLORS } from '@/constants/mantine/colors';
export const useStyles = createStyles((theme) => ({
  root: {

    position: 'relative',
    fontSize: theme.fontSizes.sm,
    lineHeight: '19px',
    color: COLORS.GRAY.dark,
    padding: 0,
    paddingBottom: 24,
    margin: 0,


    '&:before': {
      position: 'absolute',
      top: -1,
      left: 0,
      right: 0,
      height: 1,
      background: COLORS.GRAY.icon,
    },
  },
  active: {
    color: theme.black,

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 4,
      background: theme.colors.blue[5],
      borderRadius: '2px 2px 0 0',
    },
  },

  Paper: {
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'flex-start',
    gap: '16px',
    display: 'grid',
    alignItems: 'flex-start',
    gridTemplateColumns: "1fr 1fr 1fr",
  },

  Box: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '16px',
    width: 'auto'
  },

  ColumnBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    border: '1px solid #D1D8E6',
    borderRadius: '16px',
    padding: '16px',
    margin: 'auto',
    width: '97%',
    // minWidth: 300,
    minHeight: 88
  },

  SpecifyFieldsText: {
    fontFamily: 'Golos',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    color: '#5C5C70',
    marginTop: 0
  },

  SpecifyFieldsGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  BoxText: {
    fontFamily: 'Golos',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    color: '#000000',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
},

  TextTitle: {
  fontFamily: 'Golos',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: '#5C5C70',
  marginTop: 0
},

  Title: {
  fontFamily: 'Golos',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '120%',
  color: '#000000',
  marginTop: 0,
  minWidth: 290,
  marginLeft: 5,
  paddingTop: 4
},

  boxInput: {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 0
},
subTitle: {
  color: COLORS.GRAY.text,
},

}));
