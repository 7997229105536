import { User } from '@/types';
import {RoleEnum} from "@/types/enum/RoleEnum";
import {StatusEnum} from "@/types/enum/StatusEnum";

import { FC, useEffect, useState } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Indicator} from '@mantine/core';

import {countFilters, getStatusColor, getStatusLabel} from "@/utils/helpers";

import PageHeader from '@/ui/PageHeader/PageHeader';
import Table from '@/ui/Table/Table';
import TableControls from '@/ui/TableControls/TableControls';

import CreateLicensePack from '../Packs/Modal/CreateLicensePacks';

import BlockUserModal from './Modal/BlockUserModal';
import ChangeRoleModal from './Modal/ChangeRoleModal';
import CreateUserModal from './Modal/CreateUserModal';
import UnblockUserModal from './Modal/UnblockUserModal';
import UsersFilters from './UsersFilters/UsersFilters';

import { ReactComponent as Plus } from '@/assets/icons/Plus.svg';
import { useActions, useTypedSelector } from '@/store/hooks';

const Users: FC = () => {
  const {
    getUsers,
    getUsersSearchSuggestions,
    setUsersPage,
    setUsersSort,
    setUsersSearchQuery,
    setChangeRoleModal,
    setCreateUserModal,
    setSelectedUsers,
    setSingleUser,
    setTriggerTableChange,
    setMutateUsers,
    setLicenseFromUser,
  } = useActions();
  const navigate = useNavigate();
  const { user } = useTypedSelector((store) => store.auth);
  const {
    users,
    pagination,
    sort,
    filters,
    search,
    loadingUsers,
    loadingUsersAssets,
    loadingUsersSearch,
    blockUserModal,
    unblockUserModal,
    mutateUsers,
    initialUsersSort,
    changeRoleModal
  } = useTypedSelector(
    (store) => store.users
  );
  const {triggerTableChange} = useTypedSelector((store) => store.packs);
  const { selectedUsers, singleUser, userDetailId } = useTypedSelector(state => state.users);
  const [modalLicensesPackOpened, setModalLicensesPackOpened] = useState<boolean>(false);

  const RoleA = user?.role.code === 'ROLE_ADMIN';
  const RoleM = user?.role.code === 'ROLE_MANAGER';
  const [disableChangeRoleButton, setDisableChangeRoleButton] = useState<boolean>(true);
  const [disableLicensesButton, setDisableLicensesButton] = useState<boolean>(true);
  const single = selectedUsers.length === 1 || selectedUsers.length === 0;
  const disabledPagination = loadingUsers || loadingUsersAssets || loadingUsersSearch;
  const { id } = useParams();
  const columns = [
    { id: 'status', text: 'Статус', sortable: true, width: 145, noWrap: true, style: {width: 145} },
    { id: 'role', text: 'Роль', sortable: true, width: 180, noWrap: true, spoiler: true, style: {width: 180} },
    { id: 'email', text: 'Email', sortable: true, width: 290, spoiler: true, spoilerClickable: true, style: {width: 290} },
    { id: 'name', text: 'ФИО', sortable: true, width: 250, style: {minWidth: 250} },
    { id: 'juridicalPerson', text: 'Юридическое лицо', sortable: true, width: 300, spoiler: true, spoilerClickable: true, style: {width: 300} },
    { id: 'organization', text: 'Название организации', sortable: true, width: 300, spoiler: true, spoilerClickable: true, style: {width: 300} },
    { id: 'region', text: 'Регион', sortable: true, width: 250, spoiler: true, style: {width: 300} },
  ];

  const onClickTableRow = (row: User) => {
    navigate(`/users/${row.id}`);
  }

  const filledRow: boolean[] = [];

  const tableUsers = users?.map(({ status, region, role, organization, ...item }) => ({
    status: (
      <Indicator color={getStatusColor(status)} position="middle-start" zIndex={0} size={8} px={10}>{ getStatusLabel(status) }</Indicator>
    ),
    region: (
      region?.name
    ),
    role: (
      RoleEnum[role]
    ),
    juridicalPerson: (
      organization?.filter(result => !result.external).length ? organization.filter(result => !result.external).map(result => result.name).join(';\n') : '-'
    ),
    organization: (
      organization?.filter(result => result.external).length ? organization.filter(result => result.external).map(result => result.name).join(';\n') : '-'
    ),
    ...item,
  }));

  tableUsers?.map((value) => {
    const person = value.juridicalPerson?.[0];

    if (value.role === 'Менеджер' && !person) {
      filledRow.push(true);
      value.juridicalPerson = 'Не указано';
    } else {
      filledRow.push(false)
    }
  });

  const sortField = sort?.field === 'juridicalPerson' ? 'entity' : sort?.field

  const onSelectUsers = (selection: number[]) => {
    if (!users) return;
    if (selection.length > selectedUsers.length) {
      const targetIds = selection.filter(sId =>
        !selectedUsers.find(license => license.id === sId)
      );
      setSelectedUsers([...(selectedUsers ?? []), ...users.filter(val => targetIds.includes(val.id))]);
    } else if (selection.length < selectedUsers.length) {
      const targetObjs = selectedUsers.filter(license =>
        !selection.find(sId => sId === license.id)
      );
      setSelectedUsers(selectedUsers.filter(val => !targetObjs.find(item => item.id === val.id)));
    }
  }

  const selected: number[] = [];
  selectedUsers.forEach((val) => selected.push(val.id));

  const uncheckAll = () => {
    setSelectedUsers([]);
    setTriggerTableChange(!triggerTableChange);
  }

  const ownDisable = selected.find((val) => val === user?.id)

  const disableChangeRoleArray: boolean[] = [];
  const disableButtonChangeRole = (e: User[]) => {
    if (RoleM) {
      e.forEach((value) => {
        if ((value.role === 'ROLE_ADMIN') || (value.status === StatusEnum.Blocked) || !!ownDisable) {
          disableChangeRoleArray.push(true)
        } else disableChangeRoleArray.push(false)
      })
      setDisableChangeRoleButton(!!(disableChangeRoleArray.find(item => item)))
    } else if (RoleA) {
        e.forEach((value) => {
          if ((value.status === StatusEnum.Blocked) || !!ownDisable) {
            disableChangeRoleArray.push(true)
          } else disableChangeRoleArray.push(false)
        })
        setDisableChangeRoleButton(!!(disableChangeRoleArray.find(item => item)))
      }
  }
  const AllChangeRoleButton = disableChangeRoleButton;

  const disableLicensesArray: boolean[] = [];
  let firstElement: string;
  const disableButtonLicenses = (e: User[]) => {
    e.forEach((value) => {
      if (value.role === 'ROLE_DISTRIBUTOR') {
        firstElement = 'ROLE_DISTRIBUTOR';
      }

      if (value.role === 'ROLE_SCHOOL_ADMIN') {
        firstElement = 'ROLE_SCHOOL_ADMIN';
      }
    })

    e.forEach((value) => {
      if ((value.role === firstElement) && (value.status === StatusEnum.Active)) {
        disableLicensesArray.push(false);
      } else disableLicensesArray.push(true);
    })

    setDisableLicensesButton(!!(disableLicensesArray.find(item => item)))
  }
  const AllLicensesButton = disableLicensesButton;

  useEffect(() => {
    if (selectedUsers.length === 0) {
      setDisableChangeRoleButton(true);
      setDisableLicensesButton(true);
      return
    }

    if (ownDisable) {
      setDisableChangeRoleButton(true);
      return
    }

    disableButtonChangeRole(selectedUsers);
    disableButtonLicenses(selectedUsers);
  }, [selectedUsers]);

  useEffect(() => {
    setSingleUser(single);
  }, [single]);

  const onGetUsers = () => {
    getUsers({
      page: pagination.currentPage,
      sort: sortField,
      direction: sort?.direction,
      query: search.query,
      ...Object.fromEntries(
        Object.entries(filters).map(([key, value]) => [
          key,
          Array.isArray(value) ? value.join(',') : value,
        ])
      ),
    });
  };

  useEffect(() => {
    if (mutateUsers) {
      onGetUsers();
    }

    setMutateUsers(false);
  }, [mutateUsers]);

  useEffect(() => {
    if (!loadingUsers && !loadingUsersAssets && !loadingUsersSearch && !id)
      onGetUsers();
  }, [
    pagination.currentPage,
    sort?.direction,
    search.query,
    sort?.field,
    filters
  ]);

  const singleUserDetail: number[] = [];
  const selectedID = singleUser ? !selected.length ? singleUserDetail : selected : selected;

  if (userDetailId) singleUserDetail.push(userDetailId);

  return (
    <>
      <ChangeRoleModal modalTitle={"Изменить роль"} selectedID={selectedID} />
      <CreateUserModal modalTitle={"Создать пользователя"} />
      <BlockUserModal modalTitle={singleUser ? "Заблокировать пользователя?" : "Заблокировать пользователей?"} blockButton={"Да, заблокировать"} reverseButton={"Нет, отменить"} single={singleUser} selectedID={selectedID} />
      <UnblockUserModal modalTitle={singleUser ? "Разблокировать пользователя?" : "Разблокировать пользователей?"} unblockButton={"Да, разблокировать"} reverseButton={"Нет, отменить"} single={singleUser} selectedID={selectedID} />
      <CreateLicensePack createLicensePack={modalLicensesPackOpened} setCreateLicensePack={setModalLicensesPackOpened} />

      <PageHeader
        title="Пользователи"
        subTitle={pagination.totalItems}
        filter={<UsersFilters />}
        filterCount={countFilters(filters)}
        search={{
          placeholder: 'Поиск по ID, email, названию организации',
          loading: loadingUsersSearch,
          data: search.suggestions,
          query: search.query ?? search.previousQuery,
          onChange: (query) => getUsersSearchSuggestions({ query }),
          onSubmit: setUsersSearchQuery,
        }}
        rightTopButton={<Button px={13.2} onClick={() => setCreateUserModal(true)}><Plus /></Button>}
        disabledExportButton={!tableUsers?.length}
        uncheckAll={() => uncheckAll()}
        isCheck={selectedUsers.length}
      />

      <Table
        columns={columns}
        data={tableUsers}
        selectable
        onSelectionChange={(selection) => onSelectUsers(selection)}
        onSortChange={(field, direction) => {
          setUsersSort({ field, direction });
          setUsersPage(1);
        }}
        onRowClick={(row) => onClickTableRow(row as User)}
        loading={loadingUsers}
        filledRow={filledRow}
        resetSelect={blockUserModal || unblockUserModal || changeRoleModal}
        triggerTableChange={triggerTableChange}
        outerSelection={selectedUsers?.map(val => val.id) ?? []}
        defaultSortBy={initialUsersSort.sort}
        defaultSortDirection={initialUsersSort.direction}
      />

      <TableControls selectedCount={selectedUsers.length} currentPage={pagination.currentPage} totalPages={pagination.totalPages} onChangePage={setUsersPage} disabledPagination={disabledPagination}>
        <Button disabled={AllLicensesButton} onClick={() => { setModalLicensesPackOpened(true); setLicenseFromUser(true) }}>Выдать лицензии</Button>
        <Button disabled={AllChangeRoleButton} onClick={() => setChangeRoleModal(true)}>Сменить роль</Button>
      </TableControls>
    </>
  );
};

export default Users;
