import * as React from 'react';
import {CSSProperties, useState} from "react";
import {Box, Button, Grid, Group, Modal, Text, Textarea, TextInput} from "@mantine/core";
import {Dropzone, FileWithPath} from "@mantine/dropzone";
import {isNotEmpty, useForm} from "@mantine/form";
import {randomId} from "@mantine/hooks";
import {showNotification} from "@mantine/notifications";

import {feedbackApi} from "@/api/feedback/feedback";

import {COLORS} from "@/constants/mantine/colors";

import { ReactComponent as Attach } from '../assets/icons/Attach.svg';
import { ReactComponent as Clear } from '../assets/icons/Clear.svg';
import { ReactComponent as ErrorCircle } from '../assets/icons/ErrorCircle.svg';

import {useStyles} from "@/pages/Packs/Modal/styles";
import {useTypedSelector} from "@/store/hooks";

interface File extends FileWithPath {
  readonly preview?: string | null;
  readonly id: string
}

const IMAGES_TYPE = ["image/jpeg", "image/png"];

export const FeedbackForm = ({show, setShow}: {show: boolean, setShow: (show: boolean) => void}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const { classes } = useStyles();
  const { user } = useTypedSelector(store => store.auth);
  const styles: Record<string, CSSProperties> = {
    close: {
      position: "absolute",
      top: '-.6em',
      right: '-.6em',
      cursor: 'pointer',
      zIndex: 3,
      border: "2px solid #fff",
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'inline-flex'
    },
    fileExtension: {
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
      fontWeight: 'bold'
    },
    filePreview: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    filePreviewBox: {
      borderRadius: 12,
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  };

  const form = useForm({
    initialValues: {
      email: user?.email ?? '',
      phone: '',
      name: user?.name ?? '',
      subject: '',
      text: '',
      files: [] as File[]
    },
    validateInputOnBlur: true,
    validate: {
      phone: (value) => (value.length === 0 || (/^(\+?\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{2}[\s.-]?\d{2}$/.test(value)) ? null : 'Пожалуйста, введите действительный номер телефона'),
      email: (value) => (value.length === 0 || (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,63})+$/.test(value)) ? null : 'Пожалуйста, введите действительный Email'),
      name: isNotEmpty('Пустое поле. Пожалуйста, введите ваши ФИО'),
      text: isNotEmpty('Пустое поле. Пожалуйста, опишите проблему'),
      subject: isNotEmpty('Пустое поле. Пожалуйста, введите тему обращения'),
      files: (value) => (value.length <= 10 ? null : 'Превышено максимально допустимое количество файлов')
    }
  });

  const handleSubmit = (values: typeof form.values) => {
    setIsLoading(true);

    feedbackApi
      .send(values)
      .then(() => {
        setShowSuccess(true);
        form.values.files.forEach(file => file.preview ? URL.revokeObjectURL(file.preview) : null);
        form.reset();
      })
      .catch(() => {
        showNotification({
            color: 'red',
            title: 'Ошибка',
            message: 'Не удалось отправить сообщение'
          }
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
    ;
  }

  const deleteFile = (index: number) => {
    form.values.files.splice(index, 1);
    form.setFieldValue('files', form.values.files);
  }

  const onClose = () => {
    setShow(false);
    setShowSuccess(false);
  }

  const onDrop = (files: any) => {
    files.forEach((file: File) => Object.assign(file, {
      id: randomId(),
      preview: IMAGES_TYPE.includes(file.type) ? URL.createObjectURL(file) : null
    }));

    form.setFieldValue('files', [
      ...form.values.files,
      ...files
    ]);

    form.validateField('files');
  }

  return (
    <Modal opened={show} onClose={onClose} size={!showSuccess ? 934 : undefined} title={!showSuccess ? 'Обратная связь' : 'Ваше обращение отправлено'}>
      { !showSuccess ? <form onSubmit={form.onSubmit(handleSubmit)}>
        <Text mx={40} ta={'center'}>
          Если при работе с сервисом у вас появился вопрос или возникла проблема, поищите решение в инструкции. В случае, если ответа не нашлось, пожалуйста, напишите нам.
        </Text>
        <Group mt={20} noWrap={true} align={'flex-start'}>
          <TextInput label={"ФИО"} w={"50%"} {...form.getInputProps('name')} maxLength={255} placeholder={'Введите ваши ФИО'} autoComplete={'name'} withAsterisk />
          <TextInput label={'Номер телефона'} type={'tel'} {...form.getInputProps('phone')} placeholder={'+7 (___) ___-__-__'} autoComplete={'tel'} maxLength={25} w={"50%"} />
        </Group>
        <Group mt={20} noWrap={true}>
          <TextInput type={'email'} {...form.getInputProps('email')} label={'Email'} w={"100%"} placeholder={'Введите email'} />
        </Group>
        <Group mt={20} noWrap={true}>
          <TextInput label={"Тема обращения"} w={"100%"} {...form.getInputProps('subject')} maxLength={255} placeholder={'Введите тему обращения'} withAsterisk />
        </Group>
        <Group mt={20} noWrap={true}>
          <Textarea label={"Текст обращения"} {...form.getInputProps('text')} w={"100%"} maxLength={10000} minRows={6} placeholder={'Пожалуйста, расскажите, с чем именно возникли трудности. По возможности приложите скриншот экрана.'} withAsterisk />
        </Group>
        { form.values.files.length < 10 && <Dropzone
          onDrop={onDrop}
          onReject={() => {
            showNotification({
                color: 'red',
                title: 'Ошибка',
                message: 'Превышен максимально допустимый размер или недопустимый формат файлов'
              }
            )
          }}
          accept={['image/png', 'image/jpeg', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
          maxFiles={10}
          maxSize={10485760}
          mt={20}
        >
          <Group position="center" ta={'center'} style={{ minHeight: 79, pointerEvents: 'none' }}>
            <div>
              <Text color={'blue.5'} display={'inline-flex'} style={{alignItems: 'center'}} inline><Attach style={{marginRight: ".5em"}} />Прикрепить файл</Text>
              <Text size="sm" color="dimmed" inline mt={2}>Не более 10 файлов в формате JPG, JPEG, PNG или XLS объемом до 10 МБ каждый</Text>
            </div>
          </Group>
        </Dropzone> }
        { form.values.files.length > 0 && <Grid mt={8} gutter="xl">
          { form.values.files.map((file: File, index: number) => <Grid.Col key={file.id} span={2}>
            <Box style={{position: 'relative'}}>
              <Box style={styles.close} w={'1.7em'} h={'1.7em'} c={'white'} bg={COLORS.GRAY.dark} onClick={() => deleteFile(index)}>
                <Clear style={{width: 16, height: 16}}/>
              </Box>
              <Box pb={'100%'} style={styles.filePreviewBox} h={0} bg={COLORS.GRAY.bg}>
                { file.preview ?
                  <img src={file.preview} style={styles.filePreview} alt={''} /> :
                  <Text color={'blue.5'} w={'100%'} px={10} align={'center'} truncate tt="uppercase" style={styles.fileExtension}>{ file.name.split('.').pop() }</Text>
                }
              </Box>
              <Text size={'sm'} mt={10} truncate>{ file.name }</Text>
            </Box>
          </Grid.Col>) }
        </Grid> }
        { !form.isValid('files') && <Text size={'xs'} mt={5}><ErrorCircle style={{marginRight: 6}}/>Превышено максимально допустимое количество файлов</Text>}
        <Group noWrap={true}>
          <Button w={'50%'} type="submit" className={classes.modalButton} disabled={!form.isValid()} loading={isLoading}>Отправить</Button>
          <Button w={'50%'} className={classes.modalButton} c={COLORS.RED.text} variant="outline" onClick={onClose}>Отменить</Button>
        </Group>
      </form> : <>
        <Text ta={'center'} size="sm">Мы рассмотрим его и свяжемся с вами в ближайшее время.</Text>
        <Button w={'100%'} className={classes.modalButton} onClick={onClose}>Закрыть</Button>
      </> }
    </Modal>
  );
};
