import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    width: '100%'
    // flexGrow: 1,
  },
  input: {
    fontSize: theme.fontSizes.md,
  },
  item: {
    '& .mantine-Mark-root': {
      color: theme.colors.blue[5],
      backgroundColor: 'transparent',
    },
  },
}));
