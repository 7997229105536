import { createStyles } from '@mantine/core';

import { COLORS } from '@/constants/mantine/colors';

export const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    fontSize: theme.fontSizes.sm,
    lineHeight: '19px',
    color: COLORS.GRAY.dark,
    // padding: '24px 0',
  },

  active: {
    color: theme.black,

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 4,
      background: theme.colors.blue[5],
      borderRadius: '2px 2px 0 0',
    },
  },
}));
