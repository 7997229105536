import {Pack} from '@/types'
import {StatusEnum} from "@/types/enum/StatusEnum";

import {FC, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Indicator} from '@mantine/core';

import {countFilters, formatDate, getStatusColor, getStatusLabel} from "@/utils/helpers";

import {COLORS} from '@/constants/mantine/colors';

import PageHeader from '@/ui/PageHeader/PageHeader';
import Table from '@/ui/Table/Table';
import TableControls from '@/ui/TableControls/TableControls';

import {ActivationModals} from './Modal/ActivationModals';
import CreateLicensePack from './Modal/CreateLicensePacks';
import DeactivatePacks from './Modal/DeactivatePacks';
import ExportLicensePacks from './Modal/ExporLicensePacks';
import PacksFilters from './PacksFilters/PacksFilters';

import DeletePacks from "@/pages/Packs/Modal/DeletePacks";
import {useActions, useTypedSelector} from '@/store/hooks';

const Packs: FC = () => {
    const {
        getPacks,
        getPacksSearchSuggestionsParams,
        setPacksPage,
        setPacksSearchQuery,
        setPacksSort,
        setSelectedPacks,
        setTriggerTableChange,
        setMutatePacks,
        setPacksExportLink
    } = useActions();
    const navigate = useNavigate();
    const {user} = useTypedSelector((store) => store.auth);
    const {
        sort,
        packs,
        search,
        filters,
        pagination,
        mutatePacks,
        loadingStatus,
        selectedPacks,
        packsExportLink,
        triggerTableChange,
        isPackDeactivateLoading,
        isPackUpdateLoading,
        isPackEditLoading,
        isLicensesTemplateLoading,
        isTemplateLoading,
        isPacksLicensesLoading,
        isPacksLoading,
        isPacksCheckLoading,
        isPacksDetailLoading,
        initialPacksSort,
    } = useTypedSelector((store) => store.packs);

    const [modalExportOpened, setModalExportOpened] = useState<boolean>(false);
    const [modalLicensesPackOpened, setModalLicensesPackOpened] = useState<boolean>(false);
    const [modalDeactivatePacks, setModalDeactivatePacks] = useState<boolean>(false);
    const [blockDeactivateButton, setBlockDeactivateButton] = useState<boolean>(true);
    const [modalDeletePacks, setModalDeletePacks] = useState<boolean>(false);
    const [deleteButtonStatus, setDeleteButtonStatus] = useState<boolean>(false)

    const disabledPagination =
        loadingStatus.loading ||
        loadingStatus.loadingAssets ||
        loadingStatus.loadingSearch ||
        isPackDeactivateLoading ||
        isPackUpdateLoading ||
        isPackEditLoading ||
        isLicensesTemplateLoading ||
        isTemplateLoading ||
        isPacksLicensesLoading ||
        isPacksLoading ||
        isPacksCheckLoading ||
        isPacksDetailLoading;

    useEffect(() => {
        if (!loadingStatus.loading && !loadingStatus.loadingAssets && !loadingStatus.loadingSearch || mutatePacks) {
            getPacks({
                page: pagination.page,
                sort: sort?.field,
                direction: sort?.direction,
                query: search.query,
                ...Object.fromEntries(
                    Object.entries(filters).map(([key, value]) => [
                        key,
                        Array.isArray(value) ? value.join(',') : value,
                    ])
                ),
            });
            setMutatePacks(false);
        }
    }, [pagination.page, sort?.field, sort?.direction, filters, search.query, mutatePacks]);

    const roleAMD = user?.role.code === 'ROLE_ADMIN' || user?.role.code === 'ROLE_MANAGER' || user?.role.code === 'ROLE_DISTRIBUTOR';

    const columns = [
        {id: 'id', text: 'ID', sortable: true, width: 38},
        {id: 'status', text: 'Статус', sortable: true, width: 145, noWrap: true, style: {width: 145} },
        {id: 'name', text: 'Название', sortable: true, width: 300},
        {id: 'organization', text: 'Продавец', sortable: true, width: 220, spoiler: true, spoilerClickable: true, style: {width: 250}},
        {id: 'userEmail', text: 'Email владельца', sortable: true, width: 250, spoiler: true, spoilerClickable: true, style: {minWidth: 250}},
        {id: 'organizationsNames', text: 'Организация владельца', sortable: true, width: 220, spoiler: true, spoilerClickable: true, style: {width: 200}},
        {id: 'createdAt', text: 'Дата создания', sortable: true, width: 146},
        {id: 'expired', text: 'Дата окончания', sortable: true, width: 146},
        {id: 'activatedAt', text: 'Дата активации', sortable: true, width: 146},
        {id: 'type', text: 'Тип назначения', sortable: true, width: 135},
    ];

    const onSelectPacks = (selection: number[]) => {
        if (!packs) return;

        if (selection.length > selectedPacks.length) {
            const targetIds = selection.filter(sId =>
                !selectedPacks.find(license => license.id === sId)
            );

            setSelectedPacks([...(selectedPacks ?? []), ...packs.filter(val => targetIds.includes(val.id))]);
        } else if (selection.length < selectedPacks.length) {
            const targetObjs = selectedPacks.filter(license =>
                !selection.find(sId => sId === license.id)
            );

            setSelectedPacks(selectedPacks.filter(val => !targetObjs.find(item => item.id === val.id)));
        }
    };

    const uncheckAll = () => {
        setSelectedPacks([]);
        setTriggerTableChange(!triggerTableChange);
    };

    const onClickTableRow = (row: Pack) => {
        navigate(`/set/${row.id}`);
    };

    const tablePacks = packs?.map(({status, activatedAt, expired, createdAt, ...item}) => ({
        status: (
            <Indicator color={getStatusColor(status)} position="middle-start" zIndex={0} size={8} px={10}>{getStatusLabel(status)}</Indicator>
        ),
        createdAt: (
            formatDate(createdAt)
        ),
        activatedAt: (
            formatDate(activatedAt)
        ),
        expired: (
            formatDate(expired)
        ),
        ...item,
    }));

    useEffect(() => {
        if (packsExportLink) {
            const href = URL.createObjectURL(packsExportLink);
            const link = document.createElement('a');
            const createDate = new Date().toLocaleString();
            link.href = href;
            link.setAttribute('download', `Список комплектов лицензий ${createDate}.xls`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setPacksExportLink(null);
        }
    }, [packsExportLink]);

    useEffect(() => {

        if (selectedPacks.length) {
            if (packs?.find((item =>
                    selectedPacks.find(val => val.id === item.id) &&
                    (
                        item.status === StatusEnum.Created ||
                        item.status === StatusEnum.Deleted ||
                        item.status === StatusEnum.Inactive ||
                        item.status === StatusEnum.Expired
                    )
            ))) {
                setBlockDeactivateButton(() => true)
            } else {
                setBlockDeactivateButton(() => false)
            }
        } else {
            setBlockDeactivateButton(() => true)
        }
    }, [selectedPacks]);

    useEffect(() => {
        setDeleteButtonStatus(selectedPacks.filter(pack => pack.status === StatusEnum.Created).length !== selectedPacks.length || selectedPacks.length === 0)
    }, [selectedPacks]);

    return (
        <>
            <ActivationModals />
            <CreateLicensePack createLicensePack={modalLicensesPackOpened} setCreateLicensePack={setModalLicensesPackOpened}/>
            <DeactivatePacks modalDeactivatePacks={modalDeactivatePacks} setModalDeactivatePacks={setModalDeactivatePacks}/>
            <ExportLicensePacks modalExportOpened={modalExportOpened} setModalExportOpened={setModalExportOpened}/>
            <DeletePacks modalDeletePacks={modalDeletePacks} setModalDeletePacks={setModalDeletePacks}/>
            <PageHeader
                title="Комплекты лицензий"
                subTitle={pagination.totalItems}
                search={{
                    placeholder: 'Поиск по ID, названию, email',
                    loading: loadingStatus.loadingSearch,
                    data: search.suggestions,
                    query: search.query ?? search.previousQuery,
                    onChange: (query) => getPacksSearchSuggestionsParams({query}),
                    onSubmit: setPacksSearchQuery,
                }}
                onExport={() => setModalExportOpened(true)}
                filter={<PacksFilters/>}
                filterCount={countFilters(filters)}
                disabledExportButton={!(tablePacks?.length)}
                uncheckAll={uncheckAll}
                isCheck={selectedPacks.length}
            />
            <Table
                columns={columns}
                data={tablePacks}
                selectable
                onSelectionChange={(selection) => onSelectPacks(selection)}
                onSortChange={(field, direction) => {
                    setPacksSort({field, direction});
                    setPacksPage(1);
                }}
                onRowClick={(row) => {
                    onClickTableRow(row as Pack)
                }}
                triggerTableChange={triggerTableChange}
                loading={loadingStatus.loading}
                outerSelection={selectedPacks?.map(val => val.id) ?? []}
                defaultSortBy={initialPacksSort.sort}
                defaultSortDirection={initialPacksSort.direction}
            />
            <TableControls selectedCount={selectedPacks.length} currentPage={pagination.page} totalPages={pagination.totalPages} onChangePage={setPacksPage} disabledPagination={disabledPagination}>
                <Button disabled={blockDeactivateButton || !roleAMD} styles={(theme) => ({
                    root: {
                        backgroundColor: COLORS.RED.text,
                        '&:not([data-disabled]):hover': {backgroundColor: theme.fn.darken(COLORS.RED.text, 0.05)},
                    },
                })} onClick={() => setModalDeactivatePacks(() => true)}>Деактивировать</Button>
                <Button disabled={deleteButtonStatus}
                        variant='outline'
                        styles={() => ({
                            root: {
                                color: COLORS.RED.text,
                                '&:not([data-disabled]):hover': {
                                    color: COLORS.RED.text,
                                    borderColor: COLORS.RED.text,
                                },
                            },
                        })} onClick={() => setModalDeletePacks(() => true)}>Удалить</Button>
            </TableControls>
        </>
    );
};

export default Packs;
