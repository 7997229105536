export const PAGINATION_PER_PAGE = 30;

export const RoleCode = {
  ADMIN: 'ROLE_ADMIN',
  MANAGER: 'ROLE_MANAGER',
  CERT_MANAGER: 'ROLE_CERTIFICATE_MANAGER',
  DISTRIBUTOR: 'ROLE_DISTRIBUTOR',
  SCHOOL_ADMIN: 'ROLE_SCHOOL_ADMIN',
  USER: 'ROLE_USER',
};
