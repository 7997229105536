import { FC } from "react";
import {
    Box,
    Button,
    Container,
    Modal,
    Text,
    Title
} from "@mantine/core";

import { COLORS } from "@/constants/mantine/colors";

import { useStyles } from './styles';

import { useActions, useTypedSelector } from "@/store/hooks";

interface IMultiPackFormedModal {
    email?: string;
}

const MultiPackFormedModal: FC<IMultiPackFormedModal> = ({ email }) => {
    const { setShowMultiPacksFormed, setLicenseFromUser } = useActions();
    const { showMultiPacksFormed } = useTypedSelector(state => state.packs)
    const { classes } = useStyles();

    const onClose = () => {
        setLicenseFromUser(false);
        setShowMultiPacksFormed(false);
    }

    return (
        <Modal opened={showMultiPacksFormed} onClose={onClose} padding={20}>
            <Title className={classes.textTitleBlockModal} ta={'center'}>Комплект сформирован</Title>
            <Text className={classes.noteSuccessModal} h={'auto'} mx={40} ta={'center'}>
                Ваш комплект успешно сформирован. Активационные ссылки продублированы на следующие почты:
                <Text c={COLORS.BLUE.hover}>{ email }</Text>
            </Text>
            <Box>
                <Container className={classes.blocklButtonContainer} pb={12}>
                    <Button mt={20} onClick={onClose}>Закрыть</Button>
                </Container>
            </Box>
        </Modal>
    );
};

export default MultiPackFormedModal;
