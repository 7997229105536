import { AuthorizeResult } from '@/types/api/auth';

import { createSlice } from '@reduxjs/toolkit';

import { objectToJson } from '@/api/instance';

import {
  authorize,
  getAuthUrl,
  logout,
  postAuthUrl,
} from './actions';
import { AuthState } from './types';

const initialState: AuthState = {
  status: {
    isAuthSuccess: undefined,
    isAuthError: false,
    isAccessError: false,
    loadingAuthUrl: false,
    successAuthUrl: false,
    authInProcess: false,
    badOrganization: false
  },
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthFromStorage(state) {
      const userInfo = localStorage.getItem('userInfo');

      if (!!userInfo) {
        state.status.isAuthSuccess = true;
        state.user = JSON.parse(userInfo);
      } else {
        state.status.isAuthSuccess = false;
      }
    },
    setUserLogout(state) {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    // getAuthUrl
    builder
      .addCase(getAuthUrl.pending, (state) => {
        state.status.loadingAuthUrl = true;
      })
      .addCase(getAuthUrl.fulfilled, (state, action) => {
        state.status.loadingAuthUrl = false;
        window.location.href = action.payload.result.auth_url;

      })
      .addCase(getAuthUrl.rejected, (state) => {
        state.status.loadingAuthUrl = false;
        // alert('No LOGIN ACESS')
      });

    // postAuthUrl
    builder
      .addCase(postAuthUrl.pending, (state) => {
        state.status.loadingAuthUrl = true;
        state.status.successAuthUrl = false;

      })
      .addCase(postAuthUrl.fulfilled, (state, action) => {
        state.status.loadingAuthUrl = false;
        state.status.successAuthUrl = true;
        // alert(action.payload.result.auth_url)
        window.location.href = action.payload.result.auth_url;
      })
      .addCase(postAuthUrl.rejected, (state) => {
        state.status.loadingAuthUrl = false;
        state.status.successAuthUrl = false;
      });

    // authorize
    builder
      .addCase(authorize.pending, (state) => {
        state.status.authInProcess = true;
        state.status.isAuthSuccess = false;
        state.status.isAuthError = false;
      })
      .addCase(authorize.fulfilled, (state, action) => {
        state.status.authInProcess = false;
        state.status.isAuthSuccess = true;

        if (action.payload.success) {
          state.user = action.payload.result as AuthorizeResult;
          localStorage.setItem('userInfo', objectToJson(action.payload.result as AuthorizeResult));
        } else {
          if (!action.payload.url) {
            state.status.isAccessError = true;
          } else {
            window.location.href = action.payload.url;
          }
        }

      })
      .addCase(authorize.rejected, (state, action) => {
        state.status.authInProcess = false;
        state.status.isAuthSuccess = false;
        state.status.isAuthError = false;
        //@ts-ignore
        if (action.payload.status === 302) return;
        //@ts-ignore
        if (action.payload.status === 401) {
          //@ts-ignore
          state.status.badOrganization = action.payload.badOrganization;
          state.status.isAccessError = true;
        }
        //@ts-ignore
        else if (action.payload.status === 403) state.status.isAccessError = true;
        else state.status.isAuthError = true;
      });

    // logout
    builder.addCase(logout.fulfilled, (state, action) => {
      localStorage.removeItem('userInfo');

      window.location.href = action.payload.result.url;
    });
  },
});

export const authActions = {
  getAuthUrl,
  postAuthUrl,
  authorize,
  logout,
  ...authSlice.actions,
};

export default authSlice.reducer;
