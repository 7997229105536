import React, {FC, ReactNode, useState} from 'react';
import { Link } from 'react-router-dom';
import {
    ActionIcon,
    Badge,
    Button,
    Divider,
    Group,
    Paper,
    Text,
    Title,
    UnstyledButton} from '@mantine/core';
import {ChevronLeft, Refresh} from 'tabler-icons-react';

import PageHeaderSearch, {IPageHeaderSearch} from './PageHeaderSearch/PageHeaderSearch';
import {useStyles} from './styles';

import {ReactComponent as Download} from '@/assets/icons/Download.svg';
import {ReactComponent as Filter} from '@/assets/icons/Filter.svg';
import {ReactComponent as Upload} from '@/assets/icons/Upload.svg';

interface IPageHeader {
  title: string;
  subTitle?: string | number;
  backLink?: string;
  search?: IPageHeaderSearch;
  onExport?: () => void;
  onImport?: () => void;
  filter?: ReactNode;
  rightButton?: ReactNode;
  rightTopButton?: ReactNode;
  disabledExportButton?: boolean;
  disabledImportButton?: boolean;
  uncheckAll?: () => void;
  isCheck?: number;
  filterCount?: number
}

const  PageHeader: FC<IPageHeader> = ({title, subTitle, backLink, search, onExport, filter, rightButton, disabledExportButton, disabledImportButton, uncheckAll, isCheck, rightTopButton, onImport, filterCount = 0}) => {
  const { classes } = useStyles();
  const [showFilter, setShowFilter] = useState(false);

  return (
    <Paper py={backLink ? 20 : 14} mb={16}>
      <Group noWrap={true}>
        <Group className={classes.titleGroup} align="center" noWrap={true}>
          { backLink && (
            <UnstyledButton className={classes.backButton} title="Вернуться" component={Link} to={backLink}>
              <ChevronLeft size={24} />
            </UnstyledButton>
          )}
          <Title order={1}>{ title }</Title>
          { subTitle && (
            <>
              <Text className={classes.subTitle} size="md" weight={400}>{ subTitle }</Text>
              { !!isCheck &&
                <ActionIcon onClick={() => uncheckAll?.()}>
                  <Refresh size={22} />
                </ActionIcon> }
            </>
          )}
        </Group>
          { search && <PageHeaderSearch {...search} /> }
          { onExport && <Button
            disabled={disabledExportButton}
            classNames={{root: classes.exportButton, leftIcon: classes.exportButtonIcon}}
            variant="outline"
            leftIcon={<Download />}
            onClick={onExport}
          >Экспорт</Button> }
          { onImport && <Button
            disabled={disabledImportButton}
            classNames={{root: classes.exportButton, leftIcon: classes.exportButtonIcon}}
            variant="outline"
            leftIcon={<Upload />}
            onClick={onImport}
          >Загрузить</Button> }
          { filter && <Button classNames={{root: classes.filterButton}} variant={'outline'} onClick={() => setShowFilter(!showFilter)}>
            <Filter /> { filterCount > 0 && <Badge style={{position: 'absolute', right: '-1em', top: '-1em'}}>{ filterCount }</Badge> }
        </Button> }
        { rightTopButton }
      </Group>

      { ((filter || rightButton) && showFilter) && (
        <>
          <Divider my={24} mx={-24} />
          <Group className={classes.filterGroup} position="apart" noWrap={true}>
            { filter }
            { rightButton }
          </Group>
        </>
      ) }
    </Paper>
  );
};

export default PageHeader;
