import {
  GetChangeLegalEntityParams,
  GetChangeLegalEntityResponse,
  GetCheckEmailParams,
  GetCheckEmailResponse,
  GetPacksParams,
  GetSendActivationLinkParams,
  GetSendActivationLinkResponse,
  GetUserDetailParams,
  GetUserDetailResponse,
  GetUsersParams,
  GetUsersSearchSuggestionsParams,
  PatchBlockUsersParams,
  PatchChangeRoleParams,
  PatchUnblockUsersParams,
  PutUsersParams,
  ToggleConsumerAccessResponse
} from '@/types';

import axiosInstance from '../instance';
import { URLS } from '../urls';

import { IUsersLegalEntities, IUsersRoles } from '@/store/users/types';
import {toggleConsumerAccess} from "@/store/users/actions";

export const usersApi = {
  getUsersAssets() {
    return axiosInstance.request({
      method: 'get',
      url: URLS.users.getUsersAssets,
    });
  },

  getUsers(params: GetUsersParams) {
    return axiosInstance.request({
      method: 'get',
      url: URLS.users.getUsers,
      params,
    });
  },

  getUsersSearchSuggestions(params: GetUsersSearchSuggestionsParams) {
    return axiosInstance.request({
      method: 'get',
      url: URLS.users.getUsersSearchSuggestions,
      params,
    });
  },

  getUserDetail(params: GetUserDetailParams) {
    return axiosInstance.request<GetUserDetailResponse>({
      method: 'get',
      url: `${URLS.users.getUserDetail}${params.id}`,
    });
  },

  toggleConsumerAccess(params: GetUserDetailParams) {
    return axiosInstance.request<ToggleConsumerAccessResponse>({
      method: 'post',
      url: `${URLS.users.getUserDetail}${params.id}/consumer`,
    });
  },

  getUsersRoles() {
    return axiosInstance.request<IUsersRoles>({
      method: 'get',
      url: URLS.users.getUsersRoles,
    });
  },

  putUsers(params: PutUsersParams) {
    return axiosInstance.request({
      method: 'put',
      url: `${URLS.users.putUsers}`,
      params,
    });
  },

  patchChangeRole(params: PatchChangeRoleParams) {
    return axiosInstance.request({
      method: 'patch',
      url: `${URLS.users.patchChangeRoleParams}`,
      params,
    })
  },

  patchBlockUsers(params: PatchBlockUsersParams) {
    return axiosInstance.request({
      method: 'patch',
      url: `${URLS.users.patchBlockUsersParams}`,
      params,
    })
  },

  patchUnblockUsers(params: PatchUnblockUsersParams) {
    return axiosInstance.request({
      method: 'patch',
      url: `${URLS.users.patchUnblockUsersParams}`,
      params,
    })
  },

  getLegalEntities() {
    return axiosInstance.request<IUsersLegalEntities>({
      method: 'get',
      url: URLS.users.getLegalEntities,
    });
  },

  getChangeLegalEntity(params: GetChangeLegalEntityParams) {
    return axiosInstance.request<GetChangeLegalEntityResponse>({
      method: 'get',
      url: `/users/${params.id}/entity/change`,
      params: {
        organization: params.organization
      },
    });
  },

  getCheckEmail(params: GetCheckEmailParams) {
    return axiosInstance.request<GetCheckEmailResponse>({
      method: 'get',
      url: URLS.users.getCheckEmail,
      params: {
        email: params.email
      },
    });
  },

  getSendActivationLink(params: GetSendActivationLinkParams) {
    return axiosInstance.request<GetSendActivationLinkResponse>({
      method: 'get',
      url: `/users/${params.id}/resend`,
      params: {
        id: params.id
      }
    });
  },

  getUserPacks(params: GetPacksParams) {
    return axiosInstance.request({
      method: 'get',
      url: `/users/${params.id}/packs`,
      params,
    });
  },

};
