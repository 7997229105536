import {
  GetPackDetailResponse,
  PostCheckUserEmailResponse,
  PostLicenseTemplateResponse,
  PostLinkPacksResponse,
  PostPacksLicensesResponse,
  PostPacksResponse,
  PostTemplateResponse
} from "@/types";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PAGINATION_PER_PAGE } from '@/constants/common';

import { ILicense } from "../licenses/types";
import { IProduct } from "../products/types";

import {
  deletePacks,
  getPackActivate,
  getPackDeactivate,
  getPackResend,
  getPacks,
  getPacksAssets,
  getPacksDetail,
  getPacksExport,
  getPacksExportSimple,
  getPacksOrganizations,
  getPacksRoles,
  getPacksSearchSuggestionsParams,
  getUserPacks,
  patchPackDeactivate,
  patchPackEdit,
  patchPackExpireDate,
  patchPackUpdate,
  postCheckUserEmail,
  postLicensesTemplate,
  postLinkPacks,
  postPacks,
  postPacksLicenses,
  postPacksSchoolLicenses,
  postTemplate,
  postTransferSets,
} from './actions';
import { ILicensesTemplate, Packs, PacksFilters, PacksState, Sort } from "./types";

const initialState: PacksState = {
    error: null,
    // getPacks
    isPacksLoading: false,
    isPacksSuccess: false,
    isPacksError: false,

    transferSetModal: false,

    // getPacksLicenses
    isPacksLicensesLoading: false,
    isPacksLicensesSuccess: false,
    isPacksLicensesError: false,

    packs: null,
    userPacks: null,
    selectedPacks: [],

    //Mutate packs
    mutatePacks: false,

    //Mutate packsDetail
    mutatePacksDetail: false,

    // getPacksDetail
    isPacksDetailLoading: false,
    isPacksDetailSuccess: false,
    isPacksDetailError: false,

    // patchPackEdit
    isPackEditLoading: false,
    isPackEditSuccess: false,
    isPackEditError: false,

    isTransferSetsLoading: false,
    isTransferSetsSuccess: false,
    isTransferSetsError: false,

    // patchPackUpdate
    isPackUpdateLoading: false,
    isPackUpdateSuccess: false,
    isPackUpdateError: false,

    isPacksDeleteLoading: false,
    isPacksDeleteSuccess: false,
    isPacksDeleteError: false,

    // postLinkPacks
    isLinkPacksLoading: false,
    isLinkPacksSuccess: false,
    isLinkPacksError: false,

    isExportPacksLoading: false,
    isExportPacksSuccess: false,
    isExportPacksError: false,

    // patchPacksExpiredDate
    isPackExpiredDateLoading: false,
    isPackExpiredDateSuccess: false,
    isPackExpiredDateError: false,

    packsDetail: null,

    // layout
    packsDetailId: null,

    loadingStatus: {
        loading: false,
        loadingAssets: false,
        loadingSearch: false,
    },

    assets: {
        type: [],
        status: [],
        expired: [],
        organization: [],
        showAll: [],
    },

    pagination: {
        page: 1,
        perPage: PAGINATION_PER_PAGE,
    },

    sort: null,

    filters: {},

    search: {
        query: undefined,
        suggestions: [],
    },

    packsRoles: [],

    packsOrganizations: [],

    // postPacksCheckEmail
    isPacksCheckLoading: false,
    isPacksCheckSuccess: false,
    isPacksCheckError: false,

    packsCheckEmail: {
        success: false,
        message: '',
        error: '',
        organizations: [],
    },

    // postTemplate
    isTemplateLoading: false,
    isTemplateSuccess: false,
    isTemplateError: false,

    packsTemplate: {
        products: [],
        message: '',
        template_quantity_errors: [],
    },

    // postLicensesTemplate
    isLicensesTemplateLoading: false,
    isLicensesTemplateSuccess: false,
    isLicensesTemplateError: false,

    packsLicensesTemplate: {
        licenses: [],
        message: '',
    },

    // patchDeactivate
    isPackDeactivateLoading: false,
    isPackDeactivateSuccess: false,
    isPackDeactivateError: false,

    // getDeactivate
    isGetPackDeactivateLoading: false,
    isGetPackDeactivateSuccess: false,
    isGetPackDeactivateError: false,

    // getActivate
    isGetPackActivateLoading: false,
    isGetPackActivateSuccess: false,
    isGetPackActivateError: false,

    // getResend
    isGetPackResendLoading: false,
    isGetPackResendSuccess: false,
    isGetPackResendError: false,


    licensesPack: [],

    distrLicensesPack: [],

    packsLicensesActivation: [],

    packsActivation: [],

    packsDeactivateResult: {
        success: false,
        result: [],
    },

    packsGetDeactivateResult: {
        success: false,
        result: [],
    },

    packsGetResendResult: {
        success: false,
        result: [],
    },

    packsGetActivateResult: {
        success: false,
        result: [],
    },

    packsLinkActivation: null,

    triggerTableChange: false,

    packsExportLink: null,

    packsExportSimpleLink: null,

    disableCreateLicense: true,

    showPacksDetail: false,
    showPacksFormed: false,
    showMultiPacksFormed: false,
    showMultiPacksSchoolFormed: false,

    initialPacksSort: {
        sort: '',
        direction: 'asc',
    },
    licenseFromUser: false,
    complectName: '',
    licensesValues: [],
    fetchLicenseValues: [],
}

const packsSlice = createSlice({
    name: 'packs',
    initialState,
    reducers: {
        reset: () => initialState,
        resetPackCheckLoading(state) {
            state.isPacksCheckLoading = false;
            state.isPacksCheckSuccess = false;
        },
        resetPackLoading(state) {
            state.isPacksLoading = false;
            state.isPacksSuccess = false;
        },
        resetPacksTemplate(state) {
            state.packsTemplate = {
                products: [],
                message: '',
                template_quantity_errors: [],
            }
        },
        setPackDetail(state, action: PayloadAction<Partial<GetPackDetailResponse>>) {
            for (const [key, value] of Object.entries(action.payload)) {
                if (value && state.packsDetail) {
                    state.packsDetail = {
                        ...state.packsDetail,
                        [key]: value
                    }
                }
            }
        },
        resetPacksLicensesTemplate(state) {
            state.packsLicensesTemplate = {
                licenses: [],
                message: ''
            }
        },
        setLicenseFromUser(state, action: PayloadAction<boolean>) {
            state.licenseFromUser = action.payload;
        },
        setComplectName(state, action: PayloadAction<string>) {
            state.complectName = action.payload;
        },
        addLicenseValues(state, action: PayloadAction<{ id: string; value: string, outMode: string }>) {
            if (action.payload.outMode === 'manual') state.licensesValues.push(action.payload)
            else state.fetchLicenseValues.push(action.payload)
        },
        setTransferSetModal(state, action: PayloadAction<boolean>) {
            state.transferSetModal = action.payload;
        },
        delLicenseValues(state, action: PayloadAction<{ id: string, outMode: string }>) {
            if (action.payload.outMode === 'manual') {
                const targetElIndex = state.licensesValues.findIndex(val => val.id === action.payload.id)
                if (targetElIndex !== -1) {
                    const newState = [...state.licensesValues];
                    newState.splice(targetElIndex, 1);
                    state.licensesValues = [...newState];
                }
            } else {
                const targetElIndex = state.fetchLicenseValues.findIndex(val => val.id === action.payload.id)
                if (targetElIndex !== -1) {
                    const newState = [...state.fetchLicenseValues];
                    newState.splice(targetElIndex, 1);
                    state.fetchLicenseValues = [...newState];
                }
            }

        },
        setLicnseValues(state, action: PayloadAction<{ licenses: { id: string; value: string }[], outMode: string }>) {
            if (action.payload.outMode === 'manual') state.licensesValues = action.payload.licenses
            else state.fetchLicenseValues = action.payload.licenses
        },
        setLicenseValue(state, action: PayloadAction<{ id: string; value: string, outMode: string }>) {
            if (action.payload.outMode === 'manual') {
                const targetElIndex = state.licensesValues.findIndex(val => val.id === action.payload.id);
                if (targetElIndex !== -1) {
                    const newState = [...state.licensesValues];
                    newState[targetElIndex].value = action.payload.value
                    state.licensesValues = [...newState];
                }
            }
            else {
                const targetElIndex = state.fetchLicenseValues.findIndex(val => val.id === action.payload.id);
                if (targetElIndex !== -1) {
                    const newState = [...state.fetchLicenseValues];
                    newState[targetElIndex].value = action.payload.value
                    state.fetchLicenseValues = [...newState];
                }
            }
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isPacksLoading = action.payload;
        },
        setShowPacksFormed(state, action: PayloadAction<boolean>) {
            state.showPacksFormed = action.payload;
        },
        setShowMultiPacksFormed(state, action: PayloadAction<boolean>) {
            state.showMultiPacksFormed = action.payload;
        },
        setShowMultiPacksSchoolFormed(state, action: PayloadAction<boolean>) {
            state.showMultiPacksSchoolFormed = action.payload;
        },
        setPacksExportSimpleLink(state, action: PayloadAction<Blob | null>) {
            state.packsExportSimpleLink = action.payload;
        },
        setLicensesLoading(state, action: PayloadAction<boolean>) {
            state.isPacksLicensesLoading = action.payload;
        },
        setTriggerTableChange(state, action: PayloadAction<boolean>) {
            state.triggerTableChange = action.payload;
        },
        setShowPacksDetail(state, action: PayloadAction<boolean>) {
            state.showPacksDetail = action.payload;
        },
        setPacksExportLink(state, action: PayloadAction<Blob | null>) {
            state.packsExportLink = action.payload;
        },
        setPacksDetailId(state, action: PayloadAction<number | null>) {
            state.packsDetailId = action.payload;
        },
        setPacksPage(state, action: PayloadAction<number>) {
            state.pagination.page = action.payload;
        },
        setPacksSort(state, action: PayloadAction<Sort>) {
            state.sort = action.payload;
        },
        setPacksFilters(state, action: PayloadAction<PacksFilters>) {
            state.filters = action.payload;
            state.pagination.page = initialState.pagination.page;
        },
        setPacksSearchQuery(state, action: PayloadAction<string>) {
            state.search.previousQuery = action.payload ?? state.search.previousQuery;
            state.search.query = action.payload ? action.payload : undefined;
            state.pagination.page = initialState.pagination.page;
        },
        setClearPacksSearchQuery(state) {
            state.search.previousQuery = undefined;
            state.search.query = undefined;
            state.pagination.page = initialState.pagination.page;
        },
        setMutatePacks(state, action: PayloadAction<boolean>) {
            state.mutatePacks = action.payload;
        },
        setMutatePacksDetail(state, action: PayloadAction<boolean>) {
            state.mutatePacksDetail = action.payload;
        },
        setLicenses(state, action: PayloadAction<IProduct[]>) {
            state.licensesPack = [...action.payload];
        },
        setPacksTemplateProducts(state, action: PayloadAction<IProduct[]>) {
            state.packsTemplate.products = [...action.payload];
        },
        setPacksLicensesTemplate(state, action: PayloadAction<ILicensesTemplate[]>) {
            state.packsLicensesTemplate.licenses = [...action.payload];
        },
        setSelectedPacks(state, action: PayloadAction<Packs[]>) {
            state.selectedPacks = [...action.payload];
        },
        addLicense(state, action: PayloadAction<IProduct>) {
            state.licensesPack.push(action.payload);
        },
        delLicense(state, action: PayloadAction<number>) {
            const targetIndex = state.licensesPack.findIndex(val => val.id === action.payload)
            state.licensesPack.splice(targetIndex, 1);
        },
        setDistrLicenses(state, action: PayloadAction<ILicense[]>) {
            state.distrLicensesPack = [...action.payload];
        },
        setTemplateLicenses(state, action: PayloadAction<ILicensesTemplate[]>) {
            state.packsLicensesTemplate.licenses = [...action.payload];
        },
        addDistrLicense(state, action: PayloadAction<ILicense>) {
            state.distrLicensesPack.push(action.payload);
        },
        delDistrLicense(state, action: PayloadAction<number>) {
            const targetIndex = state.distrLicensesPack.findIndex(val => val.id === action.payload)
            state.distrLicensesPack.splice(targetIndex, 1);
        },
        delError(state, action: PayloadAction<null>) {
            state.error = action.payload;
        },
        setDisableCreateLicense(state, action: PayloadAction<boolean>) {
            state.disableCreateLicense = action.payload;
        },

    },
    extraReducers(builder) {
        builder
            .addCase(getPacks.pending, (state) => {
                state.loadingStatus.loading = true;
            })
            .addCase(getPacks.fulfilled, (state, action) => {
                state.loadingStatus.loading = false;
                state.packs = action.payload.result.items;
                state.pagination.page = action.payload.result.currentPage;
                state.pagination.perPage = action.payload.result.perPage;
                state.pagination.totalItems = action.payload.result.totalItems;
                state.pagination.totalPages = action.payload.result.totalPages;
                state.initialPacksSort.sort = action.payload.result.sort;
                state.initialPacksSort.direction = action.payload.result.direction;
            })
            .addCase(getPacks.rejected, (state) => {
                state.loadingStatus.loading = false;
            });

      builder
        .addCase(getUserPacks.pending, (state) => {
          state.loadingStatus.loading = true;
        })
        .addCase(getUserPacks.fulfilled, (state, action) => {
          state.loadingStatus.loading = false;
          state.userPacks = action.payload.result.items;
          state.pagination.page = action.payload.result.currentPage;
          state.pagination.perPage = action.payload.result.perPage;
          state.pagination.totalItems = action.payload.result.totalItems;
          state.pagination.totalPages = action.payload.result.totalPages;
          state.initialPacksSort.sort = action.payload.result.sort;
          state.initialPacksSort.direction = action.payload.result.direction;
        })
        .addCase(getUserPacks.rejected, (state) => {
          state.loadingStatus.loading = false;
        });

        builder
            .addCase(getPacksAssets.pending, (state) => {
                state.loadingStatus.loadingAssets = true;
            })
            .addCase(getPacksAssets.fulfilled, (state, action) => {
                state.loadingStatus.loadingAssets = false;
                state.assets = action.payload.result;
            })
            .addCase(getPacksAssets.rejected, (state) => {
                state.loadingStatus.loadingAssets = false;
            });

        builder
            .addCase(getPacksSearchSuggestionsParams.pending, (state) => {
                state.loadingStatus.loadingSearch = true;
            })
            .addCase(getPacksSearchSuggestionsParams.fulfilled, (state, action) => {
                state.loadingStatus.loadingSearch = false;
                state.search.suggestions = action.payload.result.map((item: string | number) =>
                    item.toString()
                );
            })
            .addCase(getPacksSearchSuggestionsParams.rejected, (state) => {
                state.loadingStatus.loadingSearch = false;
            });

        builder
            .addCase(getPacksDetail.pending, (state) => {
                state.isPacksDetailLoading = true;
                state.isPacksDetailSuccess = false;
                state.isPacksDetailError = false;
                state.packsDetail = null;
            })
            .addCase(getPacksDetail.fulfilled, (state, action: PayloadAction<GetPackDetailResponse>) => {
                state.isPacksDetailLoading = false;
                state.isPacksDetailSuccess = true;
                state.packsDetail = action.payload;
            })
            .addCase(getPacksDetail.rejected, (state) => {
                state.isPacksDetailLoading = false;
                state.isPacksDetailError = true;
            });

        builder
            .addCase(getPacksRoles.pending, (state) => {
                state.loadingStatus.loading = true;
            })
            .addCase(getPacksRoles.fulfilled, (state, action) => {
                state.loadingStatus.loading = false;
                state.packsRoles = action.payload;
            })
            .addCase(getPacksRoles.rejected, (state) => {
                state.loadingStatus.loading = false;
            });

        builder
            .addCase(getPacksOrganizations.pending, (state) => {
                state.loadingStatus.loading = true;
            })
            .addCase(getPacksOrganizations.fulfilled, (state, action) => {
                state.loadingStatus.loading = false;
                state.packsOrganizations = action.payload;
            })
            .addCase(getPacksOrganizations.rejected, (state) => {
                state.loadingStatus.loading = false;
            });

        builder
            .addCase(postCheckUserEmail.pending, (state) => {
                state.isPacksCheckLoading = true;
                state.isPacksCheckSuccess = false;
                state.isPacksCheckError = false;
            })
            .addCase(postCheckUserEmail.fulfilled, (state, action: PayloadAction<PostCheckUserEmailResponse>) => {
                state.isPacksCheckLoading = false;
                state.isPacksCheckSuccess = true;
                state.packsCheckEmail = action.payload;
                state.error = null
            })
            .addCase(postCheckUserEmail.rejected, (state, action) => {
                state.isPacksCheckLoading = false;
                state.isPacksCheckError = true;
                state.packsCheckEmail = {
                    ...state.packsCheckEmail,
                    success: false,
                    error: action.payload?.response?.data?.error || '',
                    message: '',
                }
                state.error = action.payload ?? null;
            });

        builder
            .addCase(postPacksLicenses.pending, (state) => {
                state.isPacksLicensesLoading = true;
                state.isPacksLicensesSuccess = false;
                state.isPacksLicensesError = false;

            })
            .addCase(postPacksLicenses.fulfilled, (state, action: PayloadAction<PostPacksLicensesResponse>) => {
                state.isPacksLicensesLoading = false;
                state.isPacksLicensesSuccess = true;
                state.packsLicensesActivation = action.payload;
            })
            .addCase(postPacksLicenses.rejected, (state, action) => {
                state.isPacksLicensesLoading = false;
                state.isPacksLicensesError = true;
                state.error = action.payload ?? null;
            });

        builder
            .addCase(postPacksSchoolLicenses.pending, (state) => {
                state.isPacksLicensesLoading = true;
                state.isPacksLicensesSuccess = false;
                state.isPacksLicensesError = false;

            })
            .addCase(postPacksSchoolLicenses.fulfilled, (state, action: PayloadAction<PostPacksLicensesResponse>) => {
                state.isPacksLicensesLoading = false;
                state.isPacksLicensesSuccess = true;
                state.packsLicensesActivation = action.payload;
            })
            .addCase(postPacksSchoolLicenses.rejected, (state, action) => {
                state.isPacksLicensesLoading = false;
                state.isPacksLicensesError = true;
                state.error = action.payload ?? null;
            });

        builder
            .addCase(postPacks.pending, (state) => {
                state.isPacksLoading = true;
                state.isPacksSuccess = false;
                state.isPacksError = false;
            })
            .addCase(postPacks.fulfilled, (state, action: PayloadAction<PostPacksResponse>) => {
                state.isPacksLoading = false;
                state.isPacksSuccess = true;
                state.packsActivation = action.payload;
            })
            .addCase(postPacks.rejected, (state, action) => {
                state.isPacksLoading = false;
                state.isPacksError = true;
                state.error = action.payload ?? null;
            });

        builder
            .addCase(postTemplate.pending, (state) => {
                state.isTemplateLoading = true;
                state.isTemplateSuccess = false;
                state.isTemplateError = false;
            })
            .addCase(postTemplate.fulfilled, (state, action: PayloadAction<PostTemplateResponse>) => {
                state.isTemplateLoading = false;
                state.isTemplateSuccess = true;
                state.packsTemplate = {
                    ...action.payload
                };
                const parsedFetchValues: { id: string; value: string }[] = [];

                action.payload.products.forEach(el => {
                  parsedFetchValues.push({
                    id: el.id.toString(),
                    value: el.volume?.toString() || ''
                  })
                })

                state.fetchLicenseValues = parsedFetchValues;
            })
            .addCase(postTemplate.rejected, (state, action) => {
                state.isTemplateLoading = false;
                state.isTemplateError = true;
                state.error = action.payload ?? null;
            });

        builder
            .addCase(postLicensesTemplate.pending, (state) => {
                state.isLicensesTemplateLoading = true;
                state.isLicensesTemplateSuccess = false;
                state.isLicensesTemplateError = false;
            })
            .addCase(postLicensesTemplate.fulfilled, (state, action: PayloadAction<PostLicenseTemplateResponse>) => {
                state.isLicensesTemplateLoading = false;
                state.isLicensesTemplateSuccess = true;
                const parsedResponse = {
                    ...action.payload, licenses: action.payload.licenses.map(val => ({
                        id: val.license_id ?? val.id,
                        externalCode: val.externalCode,
                        nm: val.nm,
                        name: val.name,
                        author: val.author,
                        volume: val.volume,
                    }))
                }
                state.packsLicensesTemplate = { ...parsedResponse };

                const parsedFetchValues: { id: string; value: string }[] = [];

                action.payload.licenses.forEach(el => {
                  parsedFetchValues.push({
                    id: el.id.toString(),
                    value: el.volume?.toString() || ''
                  })
                })

                state.fetchLicenseValues = parsedFetchValues;
            })
            .addCase(postLicensesTemplate.rejected, (state, action) => {
                state.isLicensesTemplateLoading = false;
                state.isLicensesTemplateError = true;
                state.error = action.payload ?? null;
            });

        builder
            .addCase(patchPackEdit.pending, (state) => {
                state.isPackEditLoading = true;
                state.isPackEditSuccess = false;
                state.isPackEditError = false;
            })
            .addCase(patchPackEdit.fulfilled, (state) => {
                state.isPackEditLoading = false;
                state.isPackEditSuccess = true;
            })
            .addCase(patchPackEdit.rejected, (state, action) => {
                state.isPackEditLoading = false;
                state.isPackEditError = true;
                state.error = action.payload ?? null;
            });

      builder
        .addCase(postTransferSets.pending, (state) => {
          state.isTransferSetsLoading = true;
          state.isTransferSetsSuccess = false;
          state.isTransferSetsError = false;
        })
        .addCase(postTransferSets.fulfilled, (state) => {
          state.isTransferSetsLoading = false;
          state.isTransferSetsSuccess = true;
        })
        .addCase(postTransferSets.rejected, (state) => {
          state.isTransferSetsLoading = false;
          state.isTransferSetsError = true;
        });

        builder
            .addCase(patchPackUpdate.pending, (state) => {
                state.isPackUpdateLoading = true;
                state.isPackUpdateError = false;
                state.isPackUpdateSuccess = false;
            })
            .addCase(patchPackUpdate.fulfilled, (state, action) => {
                state.isPackUpdateLoading = false;
                state.isPackUpdateSuccess = true;

                state.packsDetail = state.packsDetail ? {
                  ...state.packsDetail,
                  ...action.meta.arg
                } : null
            })
            .addCase(patchPackUpdate.rejected, (state, action) => {
                state.isPackUpdateLoading = false;
                state.isPackUpdateError = true;
                state.error = action.payload ?? null;
            });

        builder
            .addCase(patchPackExpireDate.pending, (state) => {
                state.isPackExpiredDateLoading = true;
                state.isPackExpiredDateError = false;
                state.isPackExpiredDateSuccess = false;
            })
            .addCase(patchPackExpireDate.fulfilled, (state) => {
                state.isPackExpiredDateLoading = false;
                state.isPackExpiredDateSuccess = true;
            })
            .addCase(patchPackExpireDate.rejected, (state, action) => {
                state.isPackExpiredDateLoading = false;
                state.isPackExpiredDateError = true;
                state.error = action.payload ?? null;
            });

        builder
            .addCase(deletePacks.pending, (state) => {
                state.isPacksDeleteLoading = true;
                state.isPacksDeleteError = false;
                state.isPacksDeleteSuccess = false;
            })
            .addCase(deletePacks.fulfilled, (state) => {
                state.isPacksDeleteLoading = false;
                state.isPacksDeleteSuccess = true;
            })
            .addCase(deletePacks.rejected, (state, action) => {
                state.isPacksDeleteLoading = false;
                state.isPacksDeleteError = true;
                state.error = action.payload ?? null;
            });

        builder
            .addCase(postLinkPacks.pending, (state) => {
                state.isLinkPacksLoading = true;
                state.isLinkPacksSuccess = false;
                state.isLinkPacksError = false;
            })
            .addCase(postLinkPacks.fulfilled, (state, action: PayloadAction<PostLinkPacksResponse>) => {
                state.isLinkPacksError = false;
                state.isLinkPacksLoading = false;
                state.isLinkPacksSuccess = true;
                state.packsLinkActivation = action.payload;
            })
            .addCase(postLinkPacks.rejected, (state, action) => {
                state.isLinkPacksSuccess = false;
                state.isLinkPacksLoading = false;
                state.isLinkPacksError = true;
                state.error = action.payload ?? null;
            });

        builder
            .addCase(getPacksExport.pending, (state) => {
                state.isExportPacksLoading = true;
                state.isExportPacksError = false;
            })
            .addCase(getPacksExport.fulfilled, (state, action) => {
                state.isExportPacksLoading = false;
                state.packsExportLink = action.payload;
            })
            .addCase(getPacksExport.rejected, (state) => {
                state.isExportPacksLoading = false;
                state.isExportPacksError = true;
            });

        builder
            .addCase(getPacksExportSimple.pending, (state) => {
                state.isExportPacksLoading = true;
                state.isExportPacksError = false;
            })
            .addCase(getPacksExportSimple.fulfilled, (state, action) => {
                state.isExportPacksLoading = false;
                state.packsExportSimpleLink = action.payload;
            })
            .addCase(getPacksExportSimple.rejected, (state) => {
                state.isExportPacksLoading = false;
                state.isExportPacksError = true;
            });

        builder
            .addCase(patchPackDeactivate.pending, (state) => {
                state.isPackDeactivateLoading = true;
                state.isPackDeactivateSuccess = false;
                state.isPackDeactivateError = false;
            })
            .addCase(patchPackDeactivate.fulfilled, (state, action) => {
                state.isPackDeactivateLoading = false;
                state.isPackDeactivateSuccess = true;
                state.packsDeactivateResult = action.payload
            })
            .addCase(patchPackDeactivate.rejected, (state, action) => {
                state.isPackDeactivateLoading = false;
                state.isPackDeactivateError = true;
                state.error = action.payload ?? null;
            });

        builder
            .addCase(getPackDeactivate.pending, (state) => {
                state.isGetPackDeactivateLoading = true;
                state.isGetPackDeactivateSuccess = false;
                state.isGetPackDeactivateError = false;
            })
            .addCase(getPackDeactivate.fulfilled, (state, action) => {
                state.isGetPackDeactivateLoading = false;
                state.isGetPackDeactivateSuccess = true;
                state.packsGetDeactivateResult = action.payload
            })
            .addCase(getPackDeactivate.rejected, (state) => {
                state.isGetPackDeactivateLoading = false;
                state.isGetPackDeactivateError = true;
            });

        builder
            .addCase(getPackActivate.pending, (state) => {
                state.isGetPackActivateLoading = true;
                state.isGetPackActivateSuccess = false;
                state.isGetPackActivateError = false;
            })
            .addCase(getPackActivate.fulfilled, (state, action) => {
                state.isGetPackActivateLoading = false;
                state.isGetPackActivateSuccess = true;
                state.packsGetActivateResult = action.payload
            })
            .addCase(getPackActivate.rejected, (state, action) => {
                state.isGetPackActivateLoading = false;
                state.isGetPackActivateError = true;
                state.error = action.payload ?? null
            });

        builder
            .addCase(getPackResend.pending, (state) => {
                state.isGetPackResendLoading = true;
                state.isGetPackResendSuccess = false;
                state.isGetPackResendError = false;
            })
            .addCase(getPackResend.fulfilled, (state, action) => {
                state.isGetPackResendLoading = false;
                state.isGetPackResendSuccess = true;
                state.packsGetResendResult = action.payload
            })
            .addCase(getPackResend.rejected, (state, action) => {
                state.isGetPackResendLoading = false;
                state.isGetPackResendError = true;
                state.error = action.payload ?? null
            });

    },
});

export const packsActions = {
    getPacks,
    getUserPacks,
    getPacksDetail,
    getPacksAssets,
    getPacksRoles,
    getPacksOrganizations,
    getPacksSearchSuggestionsParams,
    getPackActivate,
    getPackDeactivate,
    getPackResend,
    getPacksExport,
    postCheckUserEmail,
    postPacksLicenses,
    postPacksSchoolLicenses,
    postLinkPacks,
    postPacks,
    postTemplate,
    postLicensesTemplate,
    patchPackEdit,
    patchPackUpdate,
    patchPackDeactivate,
    patchPackExpireDate,
    deletePacks,
    getPacksExportSimple,
    postTransferSets,

    ...packsSlice.actions,
};

export default packsSlice.reducer;
