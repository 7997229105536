import { createStyles } from '@mantine/core';

import { COLORS } from '@/constants/mantine/colors';

interface IMultiSelectStylesProps {
  searchable?: boolean;
  fakePlaceholder?: string;
}

export const useStyles = createStyles(
  (theme, { searchable, fakePlaceholder }: IMultiSelectStylesProps) => ({
    input: {
      cursor: !searchable ? 'pointer' : undefined,
    },
    values: {
      position: 'relative',
      flexWrap: 'nowrap',
      marginLeft: 0,

      '&:after': {
        content: !searchable && fakePlaceholder ? `"${fakePlaceholder}"` : undefined,
        color: COLORS.GRAY.text,
        marginTop: 2,
      },
    },
    value: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: '2px 0 0',
    },
    searchInput: {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      maxWidth: '100%',
      margin: 0,
      display: !searchable ? 'none' : undefined,
    },
    item: {
      paddingLeft: 11,

      '& .mantine-Checkbox-body': {
        alignItems: 'center',
      },
      '& .mantine-Checkbox-labelWrapper': {
        lineHeight: '19px',
      },
    },
  })
);
