import {FC, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Indicator} from "@mantine/core";

import {formatDate, getStatusColor, getStatusLabel} from "@/utils/helpers";

import Table from "@/ui/Table/Table";
import TableControls from "@/ui/TableControls/TableControls";

import TransferSets from "@/pages/Users/Modal/TransferSets";
import {useActions, useTypedSelector} from "@/store/hooks";

const UserDetailPacks: FC = () => {
  const {
    userPacks,
    pagination,
    loadingStatus,
    triggerTableChange,
  } = useTypedSelector((store) => store.packs);
  const { user } = useTypedSelector((store) => store.auth);
  const navigate = useNavigate();
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [selectedPacks, setSelectedPacks] = useState<number[]>([]);
  const { getUserPacks, setTransferSetModal} = useActions();
  const { transferSetModal } = useTypedSelector(state => state.packs);

  const columns = [
    { id: 'status', text: 'Статус', width: 145, noWrap: true, style: {width: 145} },
    { id: 'id', text: 'ID комплекта', width: 100 },
    { id: 'name', text: 'Название', width: 264 },
    { id: 'organization', text: 'Продавец', width: 250, spoiler: true, spoilerClickable: true, style: {width: 250} },
    { id: 'organizationsNames', text: 'Организация владельца', width: 250, spoiler: true, spoilerClickable: true, style: {width: 250} },
    { id: 'userEmail', text: 'Email владельца', width: 240, spoiler: true, spoilerClickable: true, style: {minWidth: 240} },
    { id: 'creatorEmail', text: 'Email создателя', width: 240, spoiler: true, spoilerClickable: true, style: {minWidth: 240} },
    { id: 'createdAt', text: 'Дата создания', width: 146 },
    { id: 'expired', text: 'Дата окончания', width: 146 },
    { id: 'activatedAt', text: 'Дата активации', width: 146 },
    { id: 'type', text: 'Тип назначения', width: 135 },
    { id: 'updatedAt', text: 'Дата обновления', width: 146 },
  ];

  const tablePacks = userPacks?.map(({ status, activatedAt, expired, createdAt, updatedAt, ...item }) => ({
    status: <Indicator color={getStatusColor(status)} position="middle-start" zIndex={0} size={8} px={10}>{ getStatusLabel(status) }</Indicator >,
    createdAt: formatDate(createdAt),
    activatedAt: formatDate(activatedAt),
    expired: formatDate(expired),
    updatedAt: formatDate(updatedAt),
    ...item,
  }));

  const disabledPagination =
    loadingStatus.loading ||
    loadingStatus.loadingAssets ||
    loadingStatus.loadingSearch;

  const onGetPacks = () => {
    getUserPacks({
      id: Number(id),
      page: page
    });
  };

  useEffect(() => {
    if (!loadingStatus.loading && !loadingStatus.loadingAssets && !loadingStatus.loadingSearch) {
      onGetPacks();
    }
  }, [page]);

  return (
    <>
      <Table selectable={user?.role.code === 'ROLE_ADMIN'} columns={columns} data={tablePacks} onSelectionChange={setSelectedPacks} resetSelect={transferSetModal} triggerTableChange={triggerTableChange} loading={loadingStatus.loading} onRowClick={(row) => navigate(`/set/${row.id}`)}/>
      <TableControls currentPage={page} totalPages={pagination.totalPages} onChangePage={setPage} selectedCount={selectedPacks.length} disabledPagination={disabledPagination}>
          { user?.role.code === 'ROLE_ADMIN' && (<>
              <Button disabled={selectedPacks.length === 0} onClick={() => setTransferSetModal(true)}>Перенести</Button>
              <TransferSets selectedID={selectedPacks} onGetPacks={onGetPacks} />
            </>
          ) }
      </TableControls>
    </>
  )
}

export default UserDetailPacks;
